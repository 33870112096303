/*
 * @Descripttion:
 * @Version: 1.0.0
 * @Author: 李晨光
 * @Date: 2020-07-06 14:04:04
 * @LastEditors: 李帅
 * @LastEditTime: 2024-03-20 17:43:22
 */

// base library
import Vue from 'vue'
import '$public/core/lazy_lib/components_use'
import '$public/core/lazy_lib/vxe_table'
import mapConfig from '../assets/js/mapConfig'
// ext library
import './directives/action'
import vueWaves from './directives/waves/waves.directive'
import FileUpload from '$public/core/lazy_lib/fileUpload/index'
import vfilter from './filters/vfilter'
import MyLoading from './directives/loading/loading.directive'
import Tooltip from './directives/tooltip/tooltip.directive'
import Util from '$public/assets/js/util'
import '$public/assets/js/mixin/eventBus'
import VueLazyload from 'vue-lazyload'
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
import moment from 'moment'
import VueAMap from 'vue-amap'
// k-form-design自定义组件
import customComponent from '$public/assets/js/customComponent'
import KFormDesign from '$public/components/lib/k-form-design/packages'
import '$base/assets/css/externalForm/form-design.less'

// 引入请求方式
import {
  get,
  post,
  postFD,
  uploadFile,
  getAll,
  exportFile,
  exportFileFD,
  requestCancel,
} from '$public/assets/js/http'

// 引入图片裁剪
// import Croppa from 'vue-croppa'
// 图片懒加载
Vue.use(VueLazyload, {
  preLoad: 1.3,
  // error: require('$public/assets/image/common/loaded-failed.png'),
  loading: require('$public/assets/image/common/loading.gif'),
  attempt: 1,
})
// 图片预览
Vue.use(Viewer)
Viewer.setDefaults({
  zIndexInline: 9999,
  inline: false,
  button: true,
  navbar: false,
  title: false,
  toolbar: true,
  tooltip: false,
  movable: true,
  zoomable: true,
  rotatable: true,
  scalable: false,
  transition: true,
  fullscreen: false,
  keyboard: false,
  url: 'data-source',
})
// 地图配置
Vue.use(VueAMap)
VueAMap.initAMapApiLoader(mapConfig)
// 剪切板
// VueClipboard.config.autoSetContainer = true
// Vue.use(VueClipboard)
// 引入点击水波纹指令
Vue.use(vueWaves)
//loading指令
Vue.use(MyLoading)
Vue.use(Tooltip)
Vue.use(FileUpload)
Vue.use(KFormDesign)
KFormDesign.setConfig({
  ...customComponent,
})

// 注册过滤器
for (let key in vfilter.vfilter) {
  Vue.filter(key, vfilter.vfilter[key])
}

// 公用方法
Vue.use(Util)

// 在vue原型链上注册 axios
// get请求
Vue.prototype.$get = get
// post请求
Vue.prototype.$post = post
// postFD请求
Vue.prototype.$postFD = postFD
// uploadFile请求
Vue.prototype.$uploadFile = uploadFile
//导出
Vue.prototype.$exportFile = exportFile
Vue.prototype.$exportFileFD = exportFileFD
Vue.prototype.$requestCancel = requestCancel

// 多并发请求
Vue.prototype.$getAll = getAll

Vue.prototype.moment = moment
window.moment = moment

process.env.NODE_ENV !== 'production' &&
  console.warn('[antd-pro] NOTICE: Antd use lazy-load.')
