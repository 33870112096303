/*
 * @Description: 劳务
 * @Version: 1.0.0
 * @Author: 李晨光
 * @Date: 2020-11-02 11:23:34
 * @LastEditors: lishuai lishuai@1357.cn
 * @LastEditTime: 2024-10-08 16:11:26
 */
// let isSpm = process.env.VUE_APP_VERSION === 'standard'
import routerName from '../config/routerName.config'
import safetyEducation from './safetyEducation' //安全教育
import thirdParty from './thirdParty' //其他业务
import deductionManage from './deductionManage' //扣款管理
import behaviorSafetyStar from './behaviorSafetyStar' //行为安全之星
export const protectedRoute = [
  {
    path: '/main/labor',
    name: 'Labor',
    component: () => import(/*/* : "劳务页面入口" */ '$labor/views/Index'),
    children: []
  },
  // 劳务管理首页
  {
    path: '/main/labor/home',
    name: 'realNameRegister',
    component: () => import('$labor/views/laborHome/Index.vue'),
    meta: {
      name: '劳务管理首页',
      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_labor_index'
    },
    children: []
  },
  // 实名制登记
  {
    path: '/main/labor/realNameRegister',
    name: 'realNameRegister',
    component: () => import('$labor/views/laborManagement/laborRoster/realNameRegister.vue'),
    meta: {
      name: '实名制登记',
      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_labor_realName_realNameRegister'
    },
    children: []
  },
  // 劳务部分
  {
    path: '/main/labor/laborRoster',
    name: 'LaborRoster',
    component: () => import('$labor/views/laborManagement/laborRoster/laborRoster.vue'),
    meta: {
      name: '工人花名册',
      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_labor_realName_roster'
    },
    children: []
  },
  //临时工人管理
  {
    path: '/main/labor/temporaryWorker',
    name: 'TemporaryWorker',
    component: () => import('$labor/views/laborManagement/laborRoster/temporaryWorker/Index.vue'),
    meta: {
      name: '临时工人管理',
      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_labor_realName_temporaryWorker'
    },
    children: []
  },
  {
    path: '/main/labor/temporaryWorker/add',
    name: 'TemporaryWorkerAdd',
    component: () =>
      import('$labor/views/laborManagement/laborRoster/temporaryWorker/AddTemporaryWorker.vue'),
    meta: {
      name: '新增',
      keepAlive: false,
      isAuth: false,
      authCode: ''
    },
    children: []
  },
  {
    path: '/main/labor/temporaryWorker/edit',
    name: 'EditTemporaryWorker',
    component: () =>
      import('$labor/views/laborManagement/laborRoster/temporaryWorker/AddTemporaryWorker.vue'),
    meta: {
      name: '编辑',

      keepAlive: false
    }
  },
  {
    path: '/main/labor/laborRoster/TemporaryWorkerFileManage',
    name: 'TemporaryWorkerFileManage',
    component: () =>
      import(
        '$labor/views/laborManagement/laborRoster/temporaryWorker/TemporaryWorkerFileManage.vue'
      ),
    meta: {
      name: '资料管理',
      authCode: 'web_pm_labor_realName_roster_file',
      keepAlive: false
    }
  },
  //分包登记（登记）
  {
    path: '/main/labor/subcontractRegistration',
    name: 'SubcontractRegistration',
    component: () => import('$labor/views/subcontractRegistration/Index.vue'),
    meta: {
      name: '分包登记',
      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_labor_realName_subcontractRegistration'
    }
  },
  //分包登记（审核）
  {
    path: '/main/labor/subcontractRegistrationExamine',
    name: 'SubcontractRegistrationExamine',
    component: () => import('$labor/views/subcontractRegistrationExamine/Index.vue'),
    meta: {
      name: '分包登记',
      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_labor_realName_subcontractRegistrationExamine'
    }
  },
  {
    path: '/main/labor/subcontractRegistration/LaborFileManagerSub',
    name: 'LaborFileManagerSub',
    component: () => import('$labor/components/subcontractRegistration/LaborFileManagerSub.vue'),
    meta: {
      name: '资料管理',
      isAuth: false,
      authCode: 'web_pm_labor_realName_subcontractRegistration_laborUser_file',
      keepAlive: false
    }
  },
  {
    path: '/main/labor/subcontractRegistration/add',
    name: 'LaborWorkerAdd',
    component: () => import('$labor/components/subcontractRegistration/LaborWorkerAdd.vue'),
    meta: {
      name: '新增',
      isAuth: false,
      authCode: 'web_pm_labor_realName_subcontractRegistration_laborUser_add',
      keepAlive: false
    },
    children: []
  },
  {
    path: '/main/labor/subcontractRegistration/edit',
    name: 'LaborWorkerEdit',
    component: () => import('$labor/components/subcontractRegistration/LaborWorkerAdd.vue'),
    meta: {
      name: '修改',
      isAuth: false,
      authCode: 'web_pm_labor_realName_subcontractRegistration_laborUser_edit',
      keepAlive: false
    },
    children: []
  },
  {
    path: '/labor/checkWorkAttendance',
    name: 'CheckWorkAttendance',
    component: () =>
      import(/*/* : "每日统计" */ '$labor/views/temporaryWorkers/CheckWorkAttendance'),
    meta: {
      name: '临时工人考勤记录',
      keepAlive: true, //此组件需要被缓存
      isAuth: true,
      authCode: 'web_pm_labor_attendance_attendance',
      id: '1338401161119080450'
    }
  },
  {
    path: '/labor/dailyAttendanceStatistics/CheckWorkAttendanceDetail',
    name: 'CheckWorkAttendanceDetail',
    component: () =>
      import(/*/* : "考勤详情" */ '$labor/views/temporaryWorkers/CheckWorkAttendanceDetail'),
    meta: {
      name: '考勤详情',
      isAuth: false,
      authCode: ''
    }
  },
  //机械部分工人花名册
  {
    path: '/main/labor/mechanicalLaborRoster',
    name: 'LaborRoster',
    component: () => import('$labor/views/laborManagement/laborRoster/laborRoster.vue'),
    meta: {
      name: '工人花名册',
      keepAlive: false,
      isAuth: false,
      authCode: ''
    },
    children: []
  },
  {
    path: '/main/labor/laborRoster/add',
    name: 'AddLaborRoster',
    component: () => import('$labor/views/laborManagement/laborRoster/AddLaborRoster.vue'),
    meta: {
      name: '新增',
      isAuth: false,
      authCode: 'web_pm_labor_realName_roster_add',
      keepAlive: false
    }
  },
  //机械业务-新增工人花名册
  {
    path: '/main/labor/laborRoster/mechanicalAdd',
    name: 'MechanicalAddLaborRoster',
    component: () => import('$labor/views/laborManagement/laborRoster/AddLaborRoster.vue'),
    meta: {
      name: '新增工人花名册',
      isAuth: false,
      authCode: 'web_pm_labor_realName_roster_add',
      keepAlive: false
    }
  },
  {
    path: '/main/labor/laborRoster/LaborFileManager',
    name: 'LaborFileManager',
    component: () => import('$labor/views/laborManagement/laborRoster/LaborFileManager.vue'),
    meta: {
      name: '资料管理',
      authCode: 'web_pm_labor_realName_roster_file',
      keepAlive: false
    }
  },
  //机械业务-资料管理
  {
    path: '/main/labor/laborRoster/mechanicalLaborFileManager',
    name: 'MechanicalLaborFileManager',
    component: () => import('$labor/views/laborManagement/laborRoster/LaborFileManager.vue'),
    meta: {
      name: '资料管理',

      keepAlive: false
    }
  },
  {
    path: '/main/labor/laborRoster/edit',
    name: 'EditLaborRoster',
    component: () => import('$labor/views/laborManagement/laborRoster/AddLaborRoster.vue'),
    meta: {
      name: '编辑',
      authCode: 'web_pm_labor_realName_roster_add',
      keepAlive: false
    }
  },
  {
    path: '/main/labor/laborRoster/detail',
    name: 'LaborRosterDetail',
    component: () => import('$labor/views/laborManagement/laborRoster/laborRosterDetail.vue'),
    meta: {
      name: '工人花名册信息',

      keepAlive: false
    }
  },
  {
    path: '/main/labor/laborRoster/laborRosterImport',
    name: 'LaborRosterImport',
    component: () => import('$labor/views/laborManagement/laborRoster/laborRosterImport.vue'),
    meta: {
      name: '人员导入',

      keepAlive: false
    }
  },
  {
    path: '/main/labor/specialWorkers',
    name: 'SpecialWorkers',
    component: () => import('$labor/views/laborManagement/specialWorkers/specialWorkers.vue'),
    meta: {
      name: '特种工人管理',
      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_labor_realName_special'
    },
    children: []
  },
  {
    path: '/main/labor/WorkersFileManager',
    name: 'WorkersFileManager',
    component: () => import('$labor/views/laborManagement/specialWorkers/WorkersFileManager.vue'),
    meta: {
      name: '特种工人证书管理',
      isAuth: false,
      authCode: '',
      keepAlive: false
    }
  },
  //分包单位库
  {
    path: '/main/labor/SubcontractorsLib',
    name: 'SubcontractorsLib',
    component: () => import('$labor/views/laborManagement/subcontractorsLib/subcontractorsLib.vue'),
    meta: {
      // name: isSpm ? '分包供应商名录' : '分包单位库',
      name: routerName('SubcontractorsLib'),
      keepAlive: false,
      isAuth: true,
      authCode: 'web_base_labor_supplier'
    },
    children: []
  },
  {
    path: '/main/labor/SubcontractorsLib/edit',
    name: 'EditSubcontractorsLib',
    component: () =>
      import('$labor/views/laborManagement/subcontractorsLib/AddSubcontractorsLib.vue'),
    meta: {
      name: '分包单位库编辑',
      isAuth: false,
      authCode: 'web_base_labor_supplier',
      keepAlive: false
    }
  },
  {
    path: '/main/labor/SubcontractorsLib/add',
    name: 'AddSubcontractorsLib',
    component: () =>
      import('$labor/views/laborManagement/subcontractorsLib/AddSubcontractorsLib.vue'),
    meta: {
      name: '分包单位库新增',
      isAuth: false,
      authCode: 'web_base_labor_supplier',
      keepAlive: false
    }
  },
  {
    path: '/main/labor/SubcontractorsLib/detail',
    name: 'SubcontractorsLibDetail',
    component: () =>
      import('$labor/views/laborManagement/subcontractorsLib/subcontractorsLibDetail.vue'),
    meta: {
      name: '分包单位库详情',
      isAuth: false,
      authCode: 'web_base_labor_supplier',
      keepAlive: false
    }
  },

  //分包单位管理
  {
    path: '/main/labor/SubcontractorsManage',
    name: 'SubcontractorsManage',
    component: () =>
      import('$labor/views/laborManagement/subcontractorsManage/subcontractorsManage.vue'),
    meta: {
      name: '分包单位管理',
      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_labor_realName_unit'
    },
    children: []
  },
  {
    path: '/main/labor/SubcontractorsManage/detail',
    name: 'SubcontractorsManageDetail',
    component: () =>
      import('$labor/views/laborManagement/subcontractorsManage/subcontractorsManageDetail.vue'),
    meta: {
      name: '详情',
      isAuth: false,
      authCode: 'web_pm_labor_realName_unit',
      keepAlive: false
    }
  },
  // 分包供应商名录-批量导入
  {
    path: '/main/labor/subcontractorsLib/importExcelSubManage',
    name: 'ImportExcelSubManage',
    component: () => import('$labor/views/laborManagement/subcontractorsLib/ImportExcelSubManage'),
    meta: {
      name: '分包单位批量导入',
      isAuth: false,
      authCode: 'web_base_labor_supplier'
    }
  },
  //班组管理
  {
    path: '/main/labor/GroupManage',
    name: 'GroupManage',
    component: () => import('$labor/views/laborManagement/groupManage/groupManage.vue'),
    meta: {
      // name: isSpm ? '作业班组管理' : '班组管理',
      name: routerName('GroupManage'),
      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_labor_realName_team'
    },
    children: []
  },
  {
    path: '/main/labor/GroupManage/edit',
    name: 'EditGroupManage',
    component: () => import('$labor/views/laborManagement/groupManage/AddGroupManage.vue'),
    meta: {
      name: '成员维护',
      isAuth: false,
      authCode: 'web_pm_labor_realName_team',
      keepAlive: false
    }
  },
  {
    path: '/main/labor/GroupManage/groupManageDetail/detail',
    name: 'GroupManageDetail',
    component: () => import('$labor/views/laborManagement/groupManage/groupManageDetail.vue'),
    meta: {
      name: '班组信息',
      isAuth: false,
      authCode: 'web_pm_labor_realName_team_detail',
      keepAlive: false
    }
  },
  {
    path: '/labor/index',
    name: 'LaborIndex',
    component: () => import(/*/* : "劳务实名制管理" */ '$labor/views/Index'),
    children: [
      {
        path: '/labor/physicalExaminationInformationManagement/physicalExaminationPersonnel',
        name: 'PhysicalExaminationPersonnel',
        component: () =>
          import(
            /*/* : "工人体检管理" */ '$labor/views/physicalExaminationInformationManagement/Tab'
          ),
        meta: {
          name: '工人体检管理',
          isAuth: true,
          keepAlive: false,
          authCode: 'web_base_labor_physicalExaminationPersonnel',
          id: '1338401161119080450'
        }
      }
    ]
  },
  {
    path: '/labor/monthlySummary',
    name: 'MonthlySummary',
    component: () =>
      import(/*/* : "劳务实名制管理" */ '$labor/views/monthlySummary/MonthlySummary'),
    meta: {
      // name: isSpm ? '月度考勤' : '月度汇总',
      name: routerName('MonthlySummary'),
      isAuth: true,
      authCode: 'web_pm_labor_attendance_month',
      id: '1338401161119080450'
    }
  },
  {
    path: '/labor/monthlySummary/customization',
    name: 'MonthlySummaryCustomization',
    component: () =>
      import(/*/* : "劳务实名制管理" */ '$labor/views/monthlySummary/MonthlySummaryCustomization'),
    meta: {
      // name: isSpm ? '月度考勤' : '月度汇总',
      name: '考勤月报',
      isAuth: true,
      authCode: 'web_pm_labor_attendance_month104',
      id: '1338401161119080450'
    }
  },
  {
    path: '/labor/physicalExaminationInformationManagement/physicalExaminationPersonnel/planModal',
    name: 'PlanModal',
    component: () =>
      import(
        /*/* : "新增工人体检" */ '$labor/views/physicalExaminationInformationManagement/components/PlanModal'
      ),
    meta: {
      name: '新增工人体检',
      isAuth: false,
      keepAlive: false,
      authCode: 'web_base_labor_physicalExaminationPersonnel',
      id: ''
    }
  },
  {
    path: '/labor/physicalExaminationInformationManagement/physicalExaminationPersonnel/planModal',
    name: 'PlanModalEdit',
    component: () =>
      import(
        /*/* : "编辑工人体检" */ '$labor/views/physicalExaminationInformationManagement/components/PlanModal'
      ),
    meta: {
      name: '编辑工人体检',
      isAuth: false,
      keepAlive: false,
      authCode: 'web_base_labor_physicalExaminationPersonnel',
      id: ''
    }
  },
  {
    path: '/labor/physicalExaminationInformationManagement/importPhysical',
    name: 'ImportPhysical',
    component: () =>
      import(
        /*/* : "体检工人导入" */ '$labor/views/physicalExaminationInformationManagement/components/ImportPhysical'
      ),
    meta: {
      name: '体检工人导入',
      isAuth: false,
      authCode: 'web_base_labor_physicalExaminationPersonnel',
      id: ''
    }
  },
  {
    path: '/labor/badBehavior/badBehavior',
    name: 'BadBehavior',
    component: () => import(/*/* : "不良行为记录" */ '$labor/views/badBehavior/BadBehavior'),
    meta: {
      name: '不良行为记录',
      isAuth: true,
      authCode: 'web_pm_labor_realName_behavior',
      id: '1338687030237405186'
    }
  },
  {
    path: '/labor/managementInformation/ManagementInformation',
    name: 'BadBehavior',
    component: () =>
      import(/*/* : "管理人员信息" */ '$labor/views/managementInformation/ManagementInformation'),
    meta: {
      name: '管理人员信息',
      isAuth: false,
      authCode: 'web_base_labor_managementInformation',
      id: '1338751338086473730'
    }
  },
  {
    path: '/labor/companyAttendanceInformationAccount/CompanyAttendanceInformationAccount',
    name: 'CompanyAttendanceInformationAccount',
    component: () =>
      import(
        /*/* : "考勤信息统计" */ '$labor/views/companyAttendanceInformationAccount/CompanyAttendanceInformationAccount'
      ),
    meta: {
      // name: isSpm ? '考勤大数据分析' : '考勤信息统计',
      name: routerName('CompanyAttendanceInformationAccount'),
      isAuth: true,
      authCode: 'web_pm_labor_attendance_analysis',
      id: '1338755124699930626'
    }
  },
  {
    path: '/labor/companyAttendanceInformationAccount/CompanyAttendanceInformationAccount/detail',
    name: 'DetailsAttend',
    component: () =>
      import(/*/* : "详情" */ '$labor/views/companyAttendanceInformationAccount/Details'),
    meta: {
      name: '详情',
      authCode: 'web_pm_labor_attendance_analysis_detail',
      isAuth: false,

      id: ''
    }
  },
  {
    path: '/labor/dailyAttendanceStatistics',
    name: 'DailyAttendanceStatistics',
    component: () =>
      import(
        /*/* : "每日统计" */ '$labor/views/dailyAttendanceStatistics/DailyAttendanceStatistics'
      ),
    meta: {
      // name: isSpm ? '每日出勤记录' : '每日统计',
      name: routerName('DailyAttendanceStatistics'),
      keepAlive: true, //此组件需要被缓存
      isAuth: true,
      authCode: 'web_pm_labor_attendance_day',
      id: '1338401161119080450'
    }
  },
  {
    path: '/labor/dailyAttendanceStatistics/attendanceDetail',
    name: 'AttendanceDetail',
    component: () =>
      import(/*/* : "考勤详情" */ '$labor/views/dailyAttendanceStatistics/AttendanceDetail'),
    meta: {
      name: '考勤详情',
      isAuth: false,
      authCode: 'web_pm_labor_attendance_day_detail'
    }
  },

  {
    path: '/labor/dailyAttendanceStatistics/workAttendanceDetail',
    name: 'WorkAttendanceDetail',
    component: () =>
      import(
        /*/* : "考勤详情工人花名册入" */ '$labor/views/dailyAttendanceStatistics/AttendanceDetail'
      ),
    meta: {
      name: '考勤详情',
      isAuth: false,
      authCode: ''
    }
  },
  {
    path: '/labor/Settings',
    name: 'LaborSettings',
    component: () => import(/*/* : "每日统计" */ '$labor/views/settings/Settings'),
    meta: {
      name: '系统设置',
      isAuth: true,
      authCode: 'web_base_labor_set',
      id: '1338401161119080450'
    }
  },
  {
    path: '/labor/typeWork',
    name: 'LaborSettingsTypeWork',
    component: () => import(/*/* : "每日统计" */ '$labor/views/settings/Settings'),
    meta: {
      name: '录入规则设置',
      isAuth: true,
      authCode: 'web_base_labor_SchoolRules',
      id: '1338401161119080450'
    }
  },
  {
    path: '/labor/AttendanceRules',
    name: 'LaborSettingsAttendanceRules',
    component: () => import(/*/* : "每日统计" */ '$labor/views/settings/Settings'),
    meta: {
      name: '考勤规则设置',
      isAuth: true,
      authCode: 'web_base_labor_AttendanceRules',
      id: '1338401161119080450'
    }
  },
  // 准入规则设置
  // {
  //     path: '/labor/accessRules',
  //     name: 'AccessRules',
  //     component: () =>
  //         import('$labor/views/settings/Settings'),
  //     meta: {
  //         name: '准入规则设置',
  //         isAuth: true,
  //         authCode: 'web_base_labor_accessRules',
  //         id: '1338401161119080450',
  //     },
  // },
  {
    path: '/labor/BadBehaviorCategory',
    name: 'LaborSettingsBadBehaviorCategory',
    component: () => import(/*/* : "每日统计" */ '$labor/views/settings/Settings'),
    meta: {
      name: '不良行为类别设置',
      isAuth: true,
      authCode: 'web_base_labor_BadBehaviorCategory',
      id: '1338401161119080450'
    }
  },
  {
    path: '/labor/WarningManagementSetting',
    name: 'LaborSettingsWarningManagementSetting',
    component: () => import(/*/* : "每日统计" */ '$labor/views/settings/Settings'),
    meta: {
      name: '预警管理设置',
      isAuth: true,
      authCode: 'web_base_labor_WarningManagementSetting',
      id: '1338401161119080450'
    }
  },

  {
    path: '/labor/MedicalValidity',
    name: 'LaborSettingsMedicalValidity',
    component: () => import(/*/* : "每日统计" */ '$labor/views/settings/Settings'),
    meta: {
      name: '体检有效期设置',
      isAuth: true,
      authCode: 'web_base_labor_MedicalValidity',
      id: '1338401161119080450'
    }
  },
  {
    path: '/labor/warningManagement',
    name: 'WarningManagement',
    component: () => import('$labor/views/warningManagement/WarningManagement'),
    meta: {
      name: '人员预警',
      isAuth: true,
      authCode: 'web_pm_labor_alarm_warn'
    }
  },
  {
    path: '/labor/fiveFullCoverage',
    name: 'fiveFullCoverage',
    component: () =>
      import(/*/* : "五个全覆盖" */ '$labor/views/fiveFullCoverage/FiveFullCoverage'),
    meta: {
      name: '五个全覆盖',
      isAuth: true,
      authCode: 'web_base_labor_fiveFullCoverage',
      id: '1338401161119080450'
    }
  },
  {
    path: '/labor/operationProject',
    name: 'OperationProject',
    component: () =>
      import(/*/* : "五个全覆盖项目级编辑" */ '$labor/views/fiveFullCoverage/OperationProject'),
    meta: {
      name: '编辑',
      isAuth: false,
      authCode: 'web_base_labor_fiveFullCoverage_edit',
      id: '1338401161119080450'
    }
  },
  {
    path: '/labor/FullCoverageCompanyDetails',
    name: 'FullCoverageCompanyDetails',
    component: () =>
      import(
        /*/* : "五个全覆盖/公司/详情" */ '$labor/views/fiveFullCoverage/FullCoverageCompanyDetails'
      ),
    meta: {
      name: '分公司详情',
      isAuth: false,
      authCode: 'web_base_labor_fiveFullCoverage_look',
      id: '1338401161119080450'
    }
  },
  {
    path: '/labor/fullCoverageBranchDetails',
    name: 'FullCoverageBranchDetails',
    component: () =>
      import(
        /*/* : "五个全覆盖/分公司/详情" */ '$labor/views/fiveFullCoverage/FullCoverageBranchDetails'
      ),
    meta: {
      name: '项目级详情',
      isAuth: false,
      authCode: 'web_base_labor_fiveFullCoverage_detail',
      id: '1338401161119080450'
    }
  },
  {
    path: '/labor/FullCoverageCompanyDetailsII',
    name: 'FullCoverageCompanyDetailsII',
    component: () =>
      import(
        /*/* : "五个全覆盖/公司/详情" */ '$labor/views/fiveFullCoverage/FullCoverageCompanyDetailsII'
      ),
    meta: {
      name: '项目级详情',
      isAuth: false,
      authCode: 'web_base_labor_fiveFullCoverage_look',
      id: '1338401161119080450'
    }
  },
  {
    path: '/labor/deviceManage',
    name: 'DeviceManage',
    component: () => import(/*/* : "设备管理" */ '$labor/views/deviceManage/Index'),
    meta: {
      name: '场布设备管理',
      isAuth: true,
      authCode: 'web_pm_labor_intelligentAccessControl_deviceManage'
    }
  },
  {
    path: '/labor/deviceManage/details',
    name: 'DeviceManageDetails',
    component: () =>
      import(/*/* : "设备管理" */ '$labor/components/deviceManage/deviceList/Details'),
    meta: {
      name: '设备详情',
      isAuth: false,
      authCode: 'web_pm_labor_intelligentAccessControl_deviceManage_detail'
    }
  },
  {
    path: '/labor/deviceManage/taskManagement',
    name: 'TaskManagement',
    component: () => import(/*/* : "任务管理" */ '$labor/views/taskManagement/Index'),
    meta: {
      name: '任务管理',
      isAuth: true,
      authCode: 'web_pm_labor_intelligentAccessControl_taskManage'
    }
  },
  {
    path: '/labor/deviceManage/taskManagement/detail',
    name: 'TaskManagementDetail',
    component: () =>
      import(/*/* : "任务管理" */ '$labor/views/taskManagement/TaskManagementDetail'),
    meta: {
      name: '任务详情',
      isAuth: false,
      authCode: 'web_pm_labor_intelligentAccessControl_taskManage'
    }
  },
  {
    path: '/labor/absenceFromDuty',
    name: 'AbsenceFromDuty',
    component: () => import(/*/* : "任务管理" */ '$labor/views/taskManagement/AbsenceFromDuty.vue'),
    meta: {
      name: '缺勤统计台账',
      isAuth: true,
      authCode: 'web_pm_labor_attendance_absenceFromDuty'
    }
  },
  {
    path: '/labor/userIssue',
    name: 'UserIssue',
    component: () => import(/*/* : "任务管理" */ '$labor/views/taskManagement/UserIssue.vue'),
    meta: {
      name: '人员下发管理',
      isAuth: true,
      authCode: 'web_pm_labor_intelligentAccessControl_userIssue'
    }
  },
  {
    path: '/labor/userIssue/detail',
    name: 'UserIssueDetail',
    component: () => import(/*/* : "任务管理" */ '$labor/views/taskManagement/UserIssueDetail.vue'),
    meta: {
      name: '人员详情',
      isAuth: false,
      authCode: 'web_pm_labor_intelligentAccessControl_userIssue'
    }
  },

  //劳务表单
  {
    path: '/labor/projectSatisfaction',
    name: 'ProjectSatisfaction',
    component: () =>
      import(
        /*/* : "项目满意度" */ '$labor/views/laborForm/projectSatisfaction/ProjectSatisfaction'
      ),
    meta: {
      name: '项目满意度',
      isAuth: true,
      authCode: 'web_pm_labor_form_satisfied'
    }
  },

  //疫情防控
  {
    path: '/labor/epidemicSituation/UserHealth',
    name: 'UserHealth',
    component: () => import(/*/* : "工人健康信息" */ '$labor/views/epidemicSituation/UserHealth'),
    meta: {
      name: '工人健康信息',
      isAuth: true,
      authCode: 'web_pm_labor_epidemicSituation_UserHealth'
    }
  },
  {
    path: '/labor/epidemicSituation/EntranceAndExit',
    name: 'EntranceAndExit',
    component: () =>
      import(/*/* : "进出场信息" */ '$labor/views/epidemicSituation/EntranceAndExit'),
    meta: {
      name: '进出闸健康信息',
      isAuth: true,
      authCode: 'web_pm_labor_epidemicSituation_EntranceAndExit'
    }
  },
  {
    path: '/labor/epidemicSituation/VaccinationRegistration',
    name: 'VaccinationRegistration',
    component: () =>
      import(/*/* : "疫苗接种登记" */ '$labor/views/epidemicSituation/VaccinationRegistration'),
    meta: {
      name: '疫苗接种登记',
      isAuth: true,
      authCode: 'web_pm_labor_epidemicSituation_vaccinationRegistration'
    }
  },
  {
    path: '/labor/epidemicSituation/ImportExcelVaccination',
    name: 'ImportExcelVaccination',
    component: () =>
      import(
        /*/* : "疫苗接种登记导入" */ '$labor/views/epidemicSituation/modal/ImportExcelVaccination'
      ),
    meta: {
      name: '疫苗接种登记导入',
      isAuth: false,
      authCode: 'web_pm_labor_epidemicSituation_vaccinationRegistration_export'
    }
  },
  {
    path: '/labor/epidemicSituation/NucleicAcidMonitoringRegistration',
    name: 'NucleicAcidMonitoringRegistration',
    component: () =>
      import(
        /*/* : "核酸检测登记" */ '$labor/views/epidemicSituation/NucleicAcidMonitoringRegistration'
      ),
    meta: {
      name: '核酸检测登记',
      isAuth: true,
      authCode: 'web_pm_labor_epidemicSituation_NucleicAcidMonitoringRegistration'
    }
  },
  {
    path: '/labor/epidemicSituation/NucleicAcidListEdit',
    name: 'NucleicAcidListEdit',
    component: () =>
      import(/*/* : "核酸检测编辑" */ '$labor/views/epidemicSituation/NucleicAcidList'),
    meta: {
      name: '核酸检测编辑',
      isAuth: false,
      authCode: 'web_pm_labor_epidemicSituation_NucleicAcidMonitoringRegistration'
    }
  },
  {
    path: '/labor/epidemicSituation/NucleicAcidListDetails',
    name: 'NucleicAcidListDetails',
    component: () =>
      import(/*/* : "核酸检测详情" */ '$labor/views/epidemicSituation/NucleicAcidList'),
    meta: {
      name: '核酸检测详情',
      isAuth: false,
      authCode: 'web_pm_labor_epidemicSituation_NucleicAcidMonitoringRegistration'
    }
  },
  {
    path: '/labor/epidemicSituation/ImportExcelNuclecic',
    name: 'ImportExcelNuclecic',
    component: () =>
      import(/*/* : "核酸检测登记" */ '$labor/views/epidemicSituation/modal/ImportExcelNuclecic'),
    meta: {
      name: '核酸检测登记导入',
      isAuth: false,
      authCode: 'web_pm_labor_epidemicSituation_NucleicAcidMonitoringRegistration_export'
    }
  },
  {
    path: '/labor/epidemicSituation/HomeRegistration',
    name: 'HomeRegistration',
    component: () => import(/*/* : "返乡登记" */ '$labor/views/epidemicSituation/HomeRegistration'),
    meta: {
      name: '返乡登记',
      isAuth: true,
      authCode: 'web_pm_labor_epidemicSituation_HomeRegistration'
    }
  },
  {
    path: '/labor/epidemicSituation/ImportHomeRegistration',
    name: 'ImportHomeRegistration',
    component: () =>
      import(/*/* : "返乡登记" */ '$labor/views/epidemicSituation/modal/ImportHomeRegistration'),
    meta: {
      name: '返乡登记导入',
      isAuth: false,
      authCode: 'web_pm_labor_epidemicSituation_HomeRegistration_import'
    }
  },

  // =={===> 薪资管理

  //工资单
  {
    path: '/labor/salaryManagement/Payroll',
    name: 'salaryManagementPayroll',
    component: () => import(/*/* : "工资单" */ '$labor/views/salaryManagement/payRoll/Payroll'),
    meta: {
      name: '工资单',
      isAuth: true,
      authCode: 'web_pm_labor_salaryManagement_payroll'
    }
  },
  //工资单详情
  {
    path: '/labor/salaryManagement/Payroll/payrollDetail',
    name: 'PayrollDetail',
    component: () =>
      import(/*/* : "工资单" */ '$labor/views/salaryManagement/payRoll/components/PayrollDetail'),
    meta: {
      name: '工资单详情',
      isAuth: false,
      authCode: 'web_pm_labor_salaryManagement_payroll'
    }
  },
  //个人工资明细
  {
    path: '/labor/salaryManagement/PersonalWages',
    name: 'PersonalWages',
    component: () =>
      import(/*/* : "个人工资明细" */ '$labor/views/salaryManagement/personalWages/PersonalWages'),
    meta: {
      name: '个人工资明细',
      isAuth: true,
      authCode: 'web_pm_labor_salaryManagement_personalWages'
    }
  },
  //工资单详情
  {
    path: '/labor/salaryManagement/PersonalWages/PersonalWagesDetail',
    name: 'PersonalWagesDetail',
    component: () =>
      import(
        /*/* : "个人工资明细详情" */ '$labor/views/salaryManagement/personalWages/components/PersonalWagesDetail'
      ),
    meta: {
      name: '个人工资详情',
      isAuth: false,
      authCode: 'web_pm_labor_salaryManagement_personalWages'
    }
  },
  //薪资管理设置
  {
    path: '/labor/PayrollManagement',
    name: 'PayrollManagement',
    component: () => import(/*/* : "每日统计" */ '$labor/views/settings/PayrollManagement'),
    meta: {
      name: '薪资管理设置',
      isAuth: true,
      authCode: 'web_base_labor_payrollManagement'
    }
  },
  // =={===> 临时工人管理 (一级菜单)

  //临时工人花名册
  {
    path: '/labor/temporaryWorkerManagement/choreBoy',
    name: 'ChoreBoy',
    component: () =>
      import(/*/* : "临时工人花名册单" */ '$labor/views/temporaryWorkerManagement/choreBoy/Index'),
    meta: {
      name: '临时工人花名册',
      isAuth: true,
      authCode: 'web_pm_labor_temporaryWorkerManagement_choreBoy'
    }
  },
  {
    path: '/labor/temporaryWorkerManagement/choreBoyAttendance',
    name: 'ChoreBoyAttendance',
    component: () =>
      import(
        /*/* : "临时工人考勤" */ '$labor/views/temporaryWorkerManagement/choreBoyAttendance/Index'
      ),
    meta: {
      name: '临时工人考勤',
      isAuth: true,
      authCode: 'web_pm_labor_temporaryWorkerManagement_choreBoyAttendance'
    }
  },
  {
    path: '/labor/dailyAttendanceStatistics/choreBoyAttendanceDetai',
    name: 'ChoreBoyAttendanceDetai',
    component: () =>
      import(
        /*/* : "考勤详情" */ '$labor/views/temporaryWorkerManagement/choreBoyAttendance/CheckWorkAttendanceDetail'
      ),
    meta: {
      name: '考勤详情',
      isAuth: false,
      authCode: 'web_pm_labor_temporaryWorkerManagement_choreBoyAttendance_detail'
    }
  },
  {
    path: '/labor/choreBoySet',
    name: 'ChoreBoySet',
    component: () => import(/*/* : "临时工人设置" */ '$labor/views/settings/ChoreBoySet.vue'),
    meta: {
      name: '临时工人设置',
      isAuth: false,
      authCode: 'web_base_labor_choreBoySet',
      id: ''
    }
  },

  // =={===>  Ai识别管理
  {
    path: '/labor/aiIdentify/realTimeDynamic',
    name: 'realTimeDynamic',
    component: () =>
      import(/*/* : "ai识别实时动态" */ '$labor/views/aiIdentify/realTimeDynamic/Index'),
    meta: {
      name: 'AI考勤管理',
      isAuth: true,
      authCode: 'web_pm_labor_realTimeManagement_realTimeDynamic',
      id: ''
    }
  },
  {
    path: '/labor/aiIdentify/realTImeRecord',
    name: 'realTImeRecord',
    component: () => import(/*/* : "ai识别记录" */ '$labor/views/aiIdentify/realTImeRecord/Index'),
    meta: {
      name: 'AI识别记录',
      isAuth: true,
      authCode: 'web_pm_labor_realTimeManagement_realTImeRecord',
      id: ''
    }
  },
  {
    path: '/labor/aiIdentify/realTImeRecord/realTImeRecordWorkAttendanceDetail',
    name: 'realTImeRecordWorkAttendanceDetail',
    component: () =>
      import(
        /*/* : "ai识别记录" */ '$labor/views/aiIdentify/realTImeRecord/CheckWorkAttendanceDetail'
      ),
    meta: {
      name: '考勤记录',
      isAuth: false,
      authCode: 'web_pm_labor_realTimeManagement_realTImeRecord_detail',
      id: ''
    }
  },
  {
    path: '/labor/aiIdentify/realTimeDynamic/deatail',
    name: 'AttednanceDeatail',
    component: () =>
      import(
        /*/* : "AI考勤管理 详情" */ '$labor/views/aiIdentify/realTimeDynamic/AttednanceDeatail'
      ),
    meta: {
      name: '详情',
      isAuth: false,
      authCode: 'web_pm_labor_realTimeManagement_realTimeDynamic',
      id: ''
    }
  },
  {
    path: '/labor/attendanceSettings/LocaleSettings',
    name: 'LocaleSettings',
    component: () =>
      import(/*/* : "AI考勤管理 详情" */ '$labor/views/attendanceSettings/LocaleSettings'),
    meta: {
      name: '区域设置',
      isAuth: true,
      authCode: 'web_base_labor_LocaleSettings',
      id: ''
    }
  },
  {
    path: '/labor/complaint/InitiateComplaints',
    name: 'InitiateComplaints',
    component: () =>
      import(/*/* : "AI考勤管理 详情" */ '$labor/views/complaint/InitiateComplaints'),
    meta: {
      name: '发起投诉',
      isAuth: true,
      authCode: 'web_pm_labor_complaint_InitiateComplaints',
      id: ''
    }
  },
  {
    path: '/labor/complaint/InitiateComplaintsEdit',
    name: 'InitiateComplaintsEdit',
    component: () =>
      import(/*/* : "AI考勤管理 详情" */ '$labor/views/complaint/InitiateComplaints'),
    meta: {
      name: '编辑',
      isAuth: false,
      authCode: 'web_pm_labor_complaint_ComplaintLedger',
      id: ''
    }
  },
  {
    path: '/labor/complaint/ComplaintLedger',
    name: 'ComplaintLedger',
    component: () => import(/*/* : "AI考勤管理 详情" */ '$labor/views/complaint/ComplaintLedger'),
    meta: {
      name: '投诉台账',
      isAuth: true,
      authCode: 'web_pm_labor_complaint_ComplaintLedger',
      id: ''
    }
  },
  {
    path: '/labor/complaint/Details',
    name: 'ComplaintLedgerDetails',
    component: () => import(/*/* : "AI考勤管理 详情" */ '$labor/views/complaint/Details'),
    meta: {
      name: '投诉详情',
      isAuth: false,
      authCode: 'web_pm_labor_complaint_ComplaintLedger',
      id: ''
    }
  },
  //工资支付台账

  {
    path: '/labor/wages/PayrollPaymentAccount',
    name: 'PayrollPaymentAccount',
    component: () => import('$labor/views/wages/PayrollPaymentAccount.vue'),
    meta: {
      name: '工资支付台账',
      isAuth: true,
      authCode: 'web_pm_labor_wages_PayrollPaymentAccount'
    }
  },
  {
    path: '/labor/wages/ImportExcel',
    name: 'PayrollPaymentAccountImportExcel',
    component: () => import('$labor/views/wages/ImportExcel.vue'),
    meta: {
      name: '工资导入',
      isAuth: false,
      authCode: 'web_pm_labor_wages_PayrollPaymentAccount'
    }
  },
  {
    path: '/labor/wages/HistoricalRecord',
    name: 'PayrollPaymentAccountHistoricalRecord',
    component: () => import('$labor/views/wages/HistoricalRecord.vue'),
    meta: {
      name: '历史记录',
      authCode: 'web_pm_labor_wages_PayrollPaymentAccount',
      isAuth: false
    }
  },
  {
    path: '/labor/subpackageAlarmManage/subpackageAlarmSetting',
    name: 'SubpackageAlarmSetting',
    component: () => import('$labor/views/subpackageAlarmManage/SubpackageAlarmSetting.vue'),
    meta: {
      name: '分包单位预警设置',
      isAuth: true,
      authCode: 'web_base_labor_subpackageAlarmSetting'
    }
  },
  {
    path: '/labor/subpackageAlarmManage/subpackageAlarmList',
    name: 'SubpackageAlarmList',
    component: () => import('$labor/views/subpackageAlarmManage/SubpackageAlarmList.vue'),
    meta: {
      name: '分包单位预警',
      isAuth: true,
      authCode: 'web_pm_labor_alarm_subpackage'
    }
  },
  {
    path: '/labor/evaluationParticipatingUnits/month',
    name: 'EvaluationParticipatingUnitsMonth',
    component: () => import('$labor/views/evaluationParticipatingUnits/Index.vue'),
    meta: {
      name: '月度评价',
      isAuth: true,
      pageType: 1,
      authCode: 'web_pm_labor_evaluationParticipatingUnits_month'
    }
  },
  {
    path: '/labor/evaluationParticipatingUnits/quarter',
    name: 'EvaluationParticipatingUnitsQuarter',
    component: () => import('$labor/views/evaluationParticipatingUnits/Index.vue'),
    meta: {
      name: '季度评价',
      isAuth: true,
      pageType: 2,
      authCode: 'web_pm_labor_evaluationParticipatingUnits_quarter'
    }
  },
  {
    path: '/labor/evaluationParticipatingUnits/year',
    name: 'EvaluationParticipatingUnitsYear',
    component: () => import('$labor/views/evaluationParticipatingUnits/Index.vue'),
    meta: {
      name: '年度评价',
      isAuth: true,
      pageType: 3,
      authCode: 'web_pm_labor_evaluationParticipatingUnits_year'
    }
  },
  // 工人评分记录
  {
    path: '/main/labor/workerRatingRecords',
    name: 'WorkerRatingRecords',
    component: () => import('$labor/views/workerRatingRecords/Index.vue'),
    meta: {
      name: '工人评分记录',
      isAuth: true,
      pageType: 3,
      authCode: 'web_pm_labor_realName_workerRatingRecords'
    }
  },
  //工人评价设置
  {
    path: '/main/labor/workerEvaluationSettings',
    name: 'WorkerEvaluationSettings',
    component: () =>
      import(/*/* : "工人评价设置" */ '$labor/views/workerEvaluationSettings/Index.vue'),
    meta: {
      name: '工人评价设置',
      isAuth: true,
      authCode: 'web_base_labor_workerEvaluationSettings'
    }
  },
  //安全教育
  ...safetyEducation,
  ...thirdParty,
  ...deductionManage,
  //行为安全之星
  ...behaviorSafetyStar
]
