/*
 * @Description:
 * @Version: 1.0.0
 * @Author: 李帅
 * @Date: 2021-01-19 10:27:19
 * @LastEditors: 杜凡
 * @LastEditTime: 2024-05-14 10:22:36
 */
const financialManage = [
  {
    path: 'financialManage',
    name: 'FinancialManage',
    meta: {
      name: '财务管理'
    },
    component: () =>
      import(
        // 财务管理
        '$quality/views/financialManage/Index'
      ),
    children: [
      {
        path: 'settings',
        name: 'Settings',
        meta: {
          name: '财务设置'
        },
        component: () =>
          import(
            // 财务设置
            '$quality/views/financialManage/settings/Index'
          ),
        children: [
          {
            path: 'reportNode',
            name: 'ReportNode',
            meta: {
              name: '报表节点设置',
              isAuth: false,
              authCode: 'web_pm_finance_setting_reportNode'
            },
            component: () =>
              import(
                // 报表节点设置
                '$quality/views/financialManage/settings/reportNode/ReportNode'
              ),
            children: []
          },
          {
            path: 'financialBudget',
            name: 'FinancialBudget',
            meta: {
              name: '财务预算指标',
              isAuth: false,
              authCode: 'web_pm_finance_setting_financialBudget'
            },
            component: () =>
              import(
                // 财务预算指标
                '$quality/views/financialManage/settings/financialBudget/FinancialBudget'
              ),
            children: []
          },
          {
            path: 'Projectmonthlyfundplan',
            name: 'Projectmonthlyfundplan',
            meta: {
              name: '项目月度资金计划',
              isAuth: false,
              authCode: 'web_pm_finance_capitalPlan_Projectmonthlyfundplan'
            },
            component: () => import('$quality/views/financialManage/new/Projectmonthlyfundplan'),
            children: []
          },
          {
            path: 'SystemSetting',
            name: 'SystemSetting',
            meta: {
              name: '系统设置',
              isAuth: false,
              authCode: 'web_pm_finance_capitalPlan_systemSetting'
            },
            component: () => import('$quality/views/financialManage/new/SystemSetting'),
            children: []
          },
          {
            path: 'Addprojectplanform',
            name: 'Addprojectplanform',
            meta: {
              name: '增加',
              keepAlive: false,
              isAuth: false,
              authCode: 'web_pm_finance_capitalPlan_Projectmonthlyfundplan_add'
            },
            component: () =>
              import(
                /*/*  项目月度资金计划增加  项目层 */
                '$quality/views/financialManage/new/Addprojectplanform'
              ),
            children: []
          },
          {
            path: 'Fundplandetailsandediting',
            name: 'Fundplandetailsandediting',
            meta: {
              name: '详情',
              keepAlive: false,
              isAuth: false,
              authCode: 'web_pm_finance_capitalPlan_Projectmonthlyfundplan_detail'
            },
            component: () =>
              import(
                /*/*  详情和编辑 项目层 */
                '$quality/views/financialManage/new/Fundplandetailsandediting'
              ),
            children: []
          },
          {
            path: 'edit',
            name: 'edit',
            meta: {
              name: '编辑',
              keepAlive: false,
              isAuth: false,
              authCode: 'web_pm_finance_capitalPlan_Projectmonthlyfundplan_edit'
            },
            component: () =>
              import(
                /*/*   详情和编辑 项目层 */
                '$quality/views/financialManage/new/edit'
              ),
            children: []
          }
        ]
      },
      {
        path: 'cashFlow',
        name: 'CashFlow',
        meta: {
          name: '现金流量表',
          isAuth: false,
          authCode: 'web_pm_finance_report_cashFlow'
        },
        component: () =>
          import(
            // 现金流量表
            '$quality/views/financialManage/cashFlow/CashFlow'
          ),
        children: []
      },

      {
        path: 'importCashFlow',
        name: 'ImportCashFlow',
        meta: {
          name: '现金流量表导入',
          isAuth: false,
          authCode: 'web_pm_finance_report_cashFlow_add'
        },
        component: () =>
          import(
            // 现金流量表
            '$quality/views/financialManage/cashFlow/ImportCashFlow'
          ),
        children: []
      },
      {
        path: 'assetsLiabilities',
        name: 'AssetsLiabilities',
        meta: {
          name: '资产负债表',
          isAuth: false,
          authCode: 'web_pm_finance_report_assetsLiabilities'
        },
        component: () =>
          import(
            // 资产负债表
            '$quality/views/financialManage/assetsLiabilities/AssetsLiabilities'
          ),
        children: []
      },
      {
        path: 'importAssetsLiabilities',
        name: 'ImportAssetsLiabilities',
        meta: {
          name: '资产负债表导入',
          isAuth: false,
          authCode: 'web_pm_finance_report_assetsLiabilities_export'
        },
        component: () =>
          import(
            // 资产负债表
            '$quality/views/financialManage/assetsLiabilities/ImportAssetsLiabilities'
          ),
        children: []
      },
      {
        path: 'profit',
        name: 'Profit',
        meta: {
          name: '利润表',
          isAuth: false,
          authCode: 'web_pm_finance_report_profit'
        },
        component: () =>
          import(
            // 利润表
            '$quality/views/financialManage/profit/Profit'
          ),
        children: []
      },
      {
        path: 'importProfit',
        name: 'ImportProfit',
        meta: {
          name: '利润表导入',
          isAuth: false,
          authCode: ''
        },
        component: () =>
          import(
            // 利润表
            '$quality/views/financialManage/profit/ImportProfit'
          ),
        children: []
      },
      {
        path: 'cashFlowMonitor',
        name: 'CashFlowMonitor',
        meta: {
          name: '现金流监控表',
          isAuth: false,
          authCode: 'web_pm_finance_report_cashFlowMonitor'
        },
        component: () =>
          import(
            // 现金流监控表
            '$quality/views/financialManage/cashFlowMonitor/CashFlowMonitor'
          ),
        children: []
      },
      {
        path: 'add',
        name: 'AddCashFlowMonitor',
        meta: {
          name: '现金流监控表新增',
          isAuth: false,
          authCode: 'web_pm_finance_report_cashFlowMonitor_add'
        },
        component: () =>
          import(
            // 现金流监控表新增
            '$quality/views/financialManage/cashFlowMonitor/SetCashFlowMonitor'
          )
      },
      {
        path: 'edit',
        name: 'EditCashFlowMonitor',
        meta: {
          name: '现金流监控表编辑',
          isAuth: false,
          authCode: 'web_pm_finance_report_cashFlowMonitor_edit'
        },
        component: () =>
          import(
            // 现金流监控表编辑
            '$quality/views/financialManage/cashFlowMonitor/SetCashFlowMonitor'
          )
      },
      {
        path: 'view',
        name: 'ViewCashFlowMonitor',
        meta: {
          name: '现金流监控表详情',
          isAuth: false,
          authCode: 'web_pm_finance_report_cashFlowMonitor_detail'
        },
        component: () =>
          import(
            // 现金流监控表详情
            '$quality/views/financialManage/cashFlowMonitor/SetCashFlowMonitor'
          )
      },
      // 104资金 start
      {
        // 优化需求，该页面做缓存
        path: 'fundingPlan/PlanReport',
        name: 'fundingPlan/PlanReport',
        meta: {
          name: '资金计划上报管理',
          keepAlive: true,
          isAuth: true,
          authCode: 'web_pm_finance_fundingPlanReport'
        },
        component: () => import('$quality/views/financialManage/fundingPlan/planReport/PlanReport'),
        children: []
      },
      {
        path: 'fundingPlan/PlanReport/AddPlanReport',
        name: 'AddPlanReport',
        meta: {
          name: '上报',
          keepAlive: false,
          isAuth: true,
          authCode: 'web_pm_finance_fundingPlanReport_add'
        },
        component: () =>
          import('$quality/views/financialManage/fundingPlan/planReport/AddPlanReport'),
        children: []
      },
      {
        path: 'fundingPlan/PlanReport/AddPlanReport',
        name: 'AddPlanReport',
        meta: {
          name: '申请',
          keepAlive: false,
          isAuth: true,
          authCode: 'web_pm_finance_fundingPlanReport_add'
        },
        component: () =>
          import('$quality/views/financialManage/fundingPlan/planReport/AddPlanReport'),
        children: []
      },
      {
        path: 'fundingPlan/PlanManagements',
        name: 'fundingPlan/PlanManagements',
        meta: {
          name: '资金计划管理',
          keepAlive: true,
          isAuth: true,
          authCode: 'web_pm_finance_fundingPlanManagements'
        },
        component: () =>
          import('$quality/views/financialManage/fundingPlan/planManagement/PlanManagement'),
        children: []
      },
      {
        path: 'fundingPlan/BusinessApprove',
        name: 'fundingPlan/BusinessApprove',
        meta: {
          name: '审批', // 业务部门填报详情
          keepAlive: false,
          isAuth: false,
          authCode: 'web_pm_finance_fundingPlanManagements'
        },
        component: () =>
          import('$quality/views/financialManage/fundingPlan/planManagement/BusinessApprove'),
        children: []
      },
      {
        path: 'fundingPlan/BusinessDetail',
        name: 'fundingPlan/BusinessDetail',
        meta: {
          name: '详情', // 业务部门填报详情
          keepAlive: false,
          isAuth: false,
          authCode: 'web_pm_finance_fundingPlanManagements'
        },
        component: () =>
          import('$quality/views/financialManage/fundingPlan/planManagement/BusinessDetail'),
        children: []
      },
      {
        path: 'fundingPlan/SynthesisDetail',
        name: 'fundingPlan/SynthesisDetail',
        meta: {
          name: '详情', // 综合办公室填报详情
          keepAlive: false,
          isAuth: false,
          authCode: 'web_pm_finance_fundingPlanManagements'
        },
        component: () =>
          import('$quality/views/financialManage/fundingPlan/planManagement/SynthesisDetail'),
        children: []
      },
      {
        path: 'fundingPlan/FinanceDetail',
        name: 'fundingPlan/FinanceDetail',
        meta: {
          name: '详情', // 财务部门填报详情
          keepAlive: false,
          isAuth: false,
          authCode: 'web_pm_finance_fundingPlanManagements'
        },
        component: () =>
          import('$quality/views/financialManage/fundingPlan/planManagement/FinanceDetail'),
        children: []
      },
      {
        path: 'fundingPlan/PlanSetting',
        name: 'fundingPlan/PlanSetting',
        meta: {
          name: '资金计划设置',
          keepAlive: false,
          isAuth: true,
          authCode: 'web_base_finance_fundingPlanSetting'
        },
        component: () =>
          import('$quality/views/financialManage/fundingPlan/planSetting/PlanSetting'),
        children: []
      },
      {
        path: 'fundingPlan/subcontractManage',
        name: 'fundingPlan/subcontractManage',
        meta: {
          name: '分包合同管理',
          keepAlive: false,
          isAuth: true,
          authCode: 'web_base_finance_subcontractManage'
        },
        component: () =>
          import('$quality/views/financialManage/fundingPlan/subcontractManage/SubcontractManage'),
        children: []
      },
      {
        // 优化需求，该页面做缓存
        path: 'fundingPlan/AccountFund',
        name: 'fundingPlan/AccountFund',
        meta: {
          name: '账面资金情况',
          keepAlive: true,
          isAuth: true,
          authCode: 'web_pm_finance_accountFund'
        },
        component: () => import('$quality/views/financialManage/accountFund/Index'),
        children: []
      },
      {
        path: 'fundingPlan/AddFund',
        name: 'AddFund',
        meta: {
          name: '新增',
          keepAlive: false,
          isAuth: true,
          authCode: 'web_pm_finance_accountFund_add'
        },
        component: () => import('$quality/views/financialManage/accountFund/addFund'),
        children: []
      },
      {
        path: 'fundingPlan/DetailFund',
        name: 'DetailFund',
        meta: {
          name: '详情',
          keepAlive: false,
          isAuth: true,
          authCode: 'web_pm_finance_accountFund_detail'
        },
        component: () => import('$quality/views/financialManage/accountFund/detailFund'),
        children: []
      },
      {
        path: 'fundingPlan/EditFund',
        name: 'EditFund',
        meta: {
          name: '编辑',
          keepAlive: false,
          isAuth: true,
          authCode: 'web_pm_finance_accountFund_edit'
        },
        component: () => import('$quality/views/financialManage/accountFund/editFund'),
        children: []
      },
      {
        //  优化需求，该页面做缓存
        path: 'fundingPlan/planApprove',
        name: 'PlanApprove',
        meta: {
          name: '资金计划审批',
          keepAlive: true,
          isAuth: true,
          authCode: 'web_pm_finance_approve'
        },
        component: () =>
          import('$quality/views/financialManage/fundingPlan/planApprove/PlanApprove.vue'),
        children: []
      },
      {
        //资金计划审批 审批
        path: 'fundingPlan/planApprove/ApprovePlan',
        name: 'ApprovePlan',
        meta: {
          name: '审批',
          keepAlive: false,
          isAuth: true,
          authCode: 'web_pm_finance_approve_approve'
        },
        component: () =>
          import('$quality/views/financialManage/fundingPlan/planApprove/CheckAndApprove.vue'),
        children: []
      },
      {
        //资金计划审批 复核
        path: 'fundingPlan/planApprove/checkPlan',
        name: 'CheckPlan',
        meta: {
          name: '复核',
          keepAlive: false,
          isAuth: true,
          authCode: 'web_pm_finance_approve_check'
        },
        component: () =>
          import('$quality/views/financialManage/fundingPlan/planApprove/CheckAndApprove.vue'),
        children: []
      },
      {
        //资金计划审批 详情
        path: 'fundingPlan/planApprove/detailPlan',
        name: 'DetailPlan',
        meta: {
          name: '详情',
          keepAlive: false,
          isAuth: true,
          authCode: 'web_pm_finance_approve_detail'
        },
        component: () =>
          import('$quality/views/financialManage/fundingPlan/planApprove/CheckAndApprove.vue'),
        children: []
      }
      // 104资金 end
    ]
  }
]
export default financialManage
