/*
 * @Descripttion:
 * @Version: 1.0.0
 * @Author: 李帅
 * @Date: 2020-10-30 20:03:51
 * @LastEditors: 李帅
 * @LastEditTime: 2023-08-10 15:53:13
 */
import './tooltip.less'
const vueTooltip = {}
const tempDiv = document.createElement('div')
const content = document.createElement('div')
const arrow = document.createElement('div')
const inner = document.createElement('div')

function createElement(el, binding, isShow) {
  let rect = el.getBoundingClientRect()
  tempDiv.className = 'ant-tooltip ant-tooltip-placement-bottom'
  tempDiv.style.display = 'block'
  tempDiv.style.position = 'fixed'
  tempDiv.style.left = rect.left + 'px'
  tempDiv.style.top = rect.top + rect.height + 'px'
  content.className = 'ant-tooltip-content'
  arrow.className = 'ant-tooltip-arrow'
  arrow.style.left = el.parentElement.clientWidth / 2 + 'px'
  inner.className = 'ant-tooltip-inner'
  inner.setAttribute('role', 'tooltip')
  inner.style.whiteSpace = 'normal'
  inner.innerHTML = ''
  inner.innerHTML = el.innerHTML
  // console.log(inner)
  tempDiv.appendChild(content)
  content.appendChild(arrow)
  content.appendChild(inner)
  el.classList.add('ant-tooltip-open')
  // el.className = "ant-tooltip-open"
  if (el.children && el.children.length) {
    el.children.forEach((i, index) => {
      if (i.className.indexOf('ant-tooltip') > -1) {
        el.children[index].remove()
      }
    })
    el.appendChild(tempDiv)
  } else {
    el.appendChild(tempDiv)
  }
}

vueTooltip.install = (Vue, options = {}) => {
  Vue.directive('tooltip', {
    bind(el, binding) {
      el.addEventListener(
        'mouseover',
        function (e) {
          let children = el?.children?.length ? new Array(...el.children) : []
          children.forEach((i, index) => {
            if (i.className.indexOf('ant-tooltip') > -1) {
              el.children[index].remove()
            }
          })
          // if (el.className == 'custom-tooltip') {
          let isShow = el.offsetWidth < el.parentElement.offsetWidth
          if (!isShow) createElement(el, binding)
          // }
        },
        false
      )
      el.addEventListener(
        'mouseout',
        function () {
          tempDiv.style.display = 'none'
          el.classList.remove('ant-tooltip-open')
        },
        false
      )
    },
    inserted(el, binding) {
      // console.log(binding)
    },
  })
}
export default vueTooltip
