<!--
 * @Description:
 * @Version: 1.0.0
 * @Author: 李晨光
 * @Date: 2020-11-09 19:56:48
 * @LastEditors: 李晨光
 * @LastEditTime: 2020-11-12 16:55:55
-->
<comment>
# 组件注释
</comment>
<template>
  <div id="Actions" class="m-l-8 m-r-8">
    <!-- 按钮 < 4个 -->
    <template v-if="config.length < 4">
      <template v-for="(conf, index) in config">
        <span :key="index">
          <!-- <a @click="onClick(conf,rowData)"
             :disabled="conf.disabled">{{conf.label}}</a> -->
          <a v-if="!conf.disabled" @click="onClick(conf, rowData)">{{ conf.label }}</a>
          <span v-else class="btn-link">{{ conf.label }}</span>
          <a-divider v-if="index < config.length - 1" type="vertical" />
        </span>
      </template>
    </template>
    <!-- 按钮 >=4 个 -->
    <template v-else>
      <template v-for="(conf, index) in config.slice(0, 2)">
        <span :key="index">
          <a v-if="!conf.disabled" @click="onClick(conf, rowData)">{{ conf.label }}</a>
          <span v-else class="btn-link">{{ conf.label }}</span>
          <a-divider type="vertical" />
        </span>
      </template>
      <a-dropdown>
        <a>
          更多
          <a-icon type="down" />
        </a>
        <a-menu slot="overlay">
          <a-menu-item
            v-for="(conf, index) in config.slice(2)"
            :key="index"
            :disabled="conf.disabled"
            @click="onClick(conf, rowData)">
            <a v-if="!conf.disabled">{{ conf.label }}</a>
            <span v-else class="btn-link">{{ conf.label }}</span>
          </a-menu-item>
        </a-menu>
      </a-dropdown>
    </template>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  name: 'Actions',
  //import引入的组件需要注入到对象中才能使用
  components: {},
  props: {
    rowData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    config: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    //这里存放数据
    return {}
  },
  //监听属性 类似于data概念
  computed: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    onClick(option, rowData) {
      this.$emit('click', { target: option, rowData })
    }
  },
  destroyed() {} //生命周期 - 销毁完成
}
</script>
<style lang="less">
#Actions {
  white-space: nowrap;
  .btn-link {
    cursor: no-drop;
    color: #b4b8bd;
  }
}
</style>
