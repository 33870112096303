<!--
 * @Description:
 * @Version: 1.0.0
 * @Author: 李晨光
 * @Date: 2020-11-23 10:59:36
 * @LastEditors: 李晨光
 * @LastEditTime: 2020-11-23 14:22:27
-->
<comment>
# 组件注释
</comment>
<template>

  <transition name="slide">
    <span class="pos-fixed notify"
          :style="{top:top}"
          v-if="visible">
      <a-alert :message="content"
               banner
               @close="visible = false"
               closable
               :type="type" />
    </span>
    <!--<div class="message-wrap" :class="type" v-if="visible">-->
    <!--<div class="content">{{content}}</div>-->
    <!--</div>-->
  </transition>

</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  name: 'Notice',
  //import引入的组件需要注入到对象中才能使用
  components: {},
  props: {},
  data() {
    //这里存放数据
    return {
      content: '',
      top: '100px',
      visible: false,
      type: 'info',
      duration: 3000
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.startTimer()
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    startTimer() {
      if (this.duration) {
        setTimeout(() => {
          this.visible = false
        }, this.duration)
      }
    }
  },
  destroyed() {} //生命周期 - 销毁完成
}
</script>
<style lang='less' >

.notify {
  position: fixed;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 999;
  display: inline;
  text-align: center;
  .ant-alert {
    display: inline-flex;
    align-items: center;
  }
}
.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
  transition: all 0.2s ease;
}

.slide-enter,
.slide-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}
</style>
