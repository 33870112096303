/*
 * @Description: 工单系统
 * @Version: 1.0.0
 * @Author: 任笠
 * @Date: 2022-06-07 10:32:50
 * @LastEditors: 任笠
 * @LastEditTime: 2023-02-23 13:46:30
 */

export const protectedRoute = [
  {
    path: "/main/workOrder",
    name: "workOrder",
    component: () => import("$workOrder/views/Index"),
    children: [
      // 流程管理
      {
        path: "processManage",
        name: "processManage",
        component: () =>
          import(
            /*/* : "流程管理" */
            "$workOrder/views/processManage/Index"
          ),
        meta: {
          name: "流程管理",
          isAuth: true,
          authCode: "web_pm_workOrder_process",
        },
      },
      // 流程设计-新增
      {
        path: "processManage/add",
        name: "AddProcess",
        component: () =>
          import(
            /*/* /* : "流程管理-流程设计器 新增" */
            "$workOrder/views/processManage/SetProcess.vue"
          ),
        meta: {
          isAuth: true,
          name: "新增",
          authCode: "web_pm_workOrder_process_add",
        },
      },
      // 流程设计-编辑
      {
        path: "processManage/edit",
        name: "EditProcess",
        component: () =>
          import(
            /*/* /* : "流程管理-流程设计器" */
            "$workOrder/views/processManage/SetProcess.vue"
          ),
        meta: {
          isAuth: true,
          name: "编辑",
          authCode: "web_pm_workOrder_process_edit",
        },
      },
      // 表单管理
      {
        path: "/main/workOrder/formManage",
        name: "formWorkManage",
        component: () =>
          import(
            /*/* : "表单管理" */
            "$workOrder/views/formManage/Index"
          ),
        meta: {
          name: "表单管理",
          isAuth: true,
          authCode: "web_pm_workOrder_form",
        },
      },
      // 表单管理-新增
      {
        path: "/main/workOrder/formManage/add",
        name: "AddWorkForm",
        component: () =>
          import(
            /*/* /* : "流程管理-流程设计器 新增" */
            "$workOrder/views/formManage/SetFormDesign.vue"
          ),
        meta: {
          isAuth: true,
          name: "新增",
          authCode: "web_pm_workOrder_form_add",
        },
      },
      // 表单管理-编辑
      {
        path: "/main/workOrder/formManage/edit",
        name: "EditWorkForm",
        component: () =>
          import(
            /*/* /* : "流程管理-流程设计器" */
            "$workOrder/views/formManage/SetFormDesign.vue"
          ),
        meta: {
          isAuth: true,
          name: "编辑",
          authCode: "web_pm_workOrder_form_edit",
        },
      },
      // 工单申请
      {
        path: "apply",
        name: "apply",
        component: () =>
          import(
            /*/* : "工单申请" */
            "$workOrder/views/apply/Index"
          ),
        meta: {
          name: "工单申请",
          isAuth: true,
          authCode: "web_pm_workOrder_apply",
        },
      },
      // 工单申请-发起流程
      {
        path: "apply/createOrder",
        name: "CreateOrder",
        component: () => import("$workOrder/views/apply/CreateOrder.vue"),
        meta: {
          isAuth: true,
          name: "工单申请",
          authCode: "web_pm_workOrder_apply_createOrder",
        },
      },
      // 我的待办
      {
        path: "todo",
        name: "WorkOrderTodo",
        component: () =>
          import(
            /*/* : "工单申请" */
            "$workOrder/views/todo/Index"
          ),
        meta: {
          name: "我的待办",
          isAuth: true,
          authCode: "web_pm_workOrder_todo",
        },
      },
      // 待办-处理工单
      {
        path: "todo/dealOrder",
        name: "DealOrder",
        component: () => import("$workOrder/views/todo/DealOrder.vue"),
        meta: {
          isAuth: true,
          name: "处理工单",
          authCode: "web_pm_workOrder_todo_deal",
        },
      },
      // 详情
      {
        path: "todo/todoDetail",
        name: "TodoDetail",
        component: () => import("$workOrder/views/todo/OrderDetail.vue"),
        meta: {
          isAuth: true,
          name: "工单详情",
          authCode: "web_pm_workOrder_todo_detail",
        },
      },
      // 我创建的
      {
        path: "create",
        name: "apply",
        component: () =>
          import(
            /*/* : "我创建的" */
            "$workOrder/views/create/Index"
          ),
        meta: {
          name: "我创建的",
          isAuth: true,
          authCode: "web_pm_workOrder_create",
        },
      },
      // 详情
      {
        path: "create/createDetail",
        name: "CreateDetail",
        component: () => import("$workOrder/views/create/OrderDetail.vue"),
        meta: {
          isAuth: true,
          name: "工单详情",
          authCode: "web_pm_workOrder_create_detail",
        },
      },
      // 我相关的
      {
        path: "related",
        name: "Related",
        component: () =>
          import(
            /*/* : "我相关的" */
            "$workOrder/views/related/Index"
          ),
        meta: {
          name: "我相关的",
          isAuth: true,
          authCode: "web_pm_workOrder_related",
        },
      },
      // 详情
      {
        path: "related/relatedDetail",
        name: "RelatedDetail",
        component: () => import("$workOrder/views/related/OrderDetail.vue"),
        meta: {
          isAuth: true,
          name: "工单详情",
          authCode: "web_pm_workOrder_create_detail",
        },
      },
    ],
  },
];
