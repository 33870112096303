/*
 * @Description: 监理整改通知单
 * @Version: 1.0.0
 * @Author: 王召强
 * @Date: 2022-08-15 16:48:49
 * @LastEditors: 杜凡
 * @LastEditTime: 2024-05-13 16:37:08
 */
const rectificationForm = [
  // =={===> 监理整改通知单
  {
    path: '/rectificationForm',
    name: 'RectificationForm',
    component: () =>
      import(
        /*/* : "监理整改通知单" */ '$supervisionPlatform/views/rectificationForm/RectificationForm.vue'
      ),
    meta: {
      name: '监理整改通知单',
      isAuth: true,
      authCode: 'web_pm_supervisionPlatform_rectificationForm'
    }
  },
  // 监理整改通知单 2024-4-25 康东科交底新版金甸专用
  {
    path: '/supervisionForm',
    name: 'SupervisionForm',
    component: () => import('$supervisionPlatform/views/supervisionForm/SupervisionForm.vue'),
    meta: {
      name: '监理整改通知单',
      isAuth: true,
      authCode: 'web_pm_supervisionPlatform_supervisionForm'
    }
  },
  // 监理整改通知单-详情
  {
    path: '/supervisionDetails',
    name: 'SupervisionDetails',
    component: () => import('$supervisionPlatform/views/supervisionForm/SupervisionDetails.vue'),
    meta: {
      name: '详情',
      isAuth: true,
      authCode: 'web_pm_supervisionPlatform_supervisionForm_detail'
    }
  }
]

export default rectificationForm
