<!--
 * @Description: 附件上传自定义组件 - 需同平台端一致 oss
 * @Version: 1.0.0
 * @Author: 任笠
 * @Date: 2022-03-05 14:16:18
 * @LastEditors: 任笠
 * @LastEditTime: 2022-09-29 19:49:29
-->
<comment>
  # 组件注释
</comment>
<template>
  <div id="AppendixUpload">
    <div :style="`width:${record.options.width}`">
      <a-upload
        class="uploadFile m-t-3"
        :directory="false"
        :show-upload-list="false"
        :file-list="fileList"
        :multiple="record.options.multiple"
        :max-count="record.options.limit"
        :before-upload="beforeUpload"
        @change="fileChange"
        @customRequest="handleUpload">
        <a-button
          v-if="fileList.length < record.options.limit"
          :disabled="disabled || record.options.disabled || parentDisabled">
          <a-icon type="upload" /> {{ record.options.placeholder }}
        </a-button>
      </a-upload>
      <span class="m-l-8" style="color: #919398; font-size: 14px">
        支持格式
        <a-tooltip>
          <template slot="title"> 单个文件大小不超过5M，文件个数不超过1个 </template>
          <a-icon
            type="question-circle"
            class="pointer m-l-4"
            style="font-size: 14px; vertical-align: -0.125em" />
        </a-tooltip>
      </span>
    </div>
    <!-- 上传列表 -->
    <a-table
      :style="`width:${record.options.width}`"
      class="m-t-8 uploadList"
      :columns="initColumns"
      :scroll="{ y: 380 }"
      :pagination="false"
      :data-source="tmpList"
      :locale="{ emptyText: '暂无数据' }"
      row-key="uid"
      :expanded-row-keys="expandedRowKeys"
      size="middle">
      <template slot="name" slot-scope="text, record">
        <div class="flex align-center">
          <div style="display: flex; align-items: center; justify-content: flex-start">
            <img
              :src="iconFun(record)"
              alt=""
              style="width: 24px; height: 24px; cursor: pointer"
              @click="clickFile(record)" />
            <div class="m-l-8 fileName" style="cursor: pointer" @click="clickFile(record)">
              <a-tooltip>
                <template slot="title">
                  {{ text ? text : '--' }}
                </template>
                <div class="over" style="width: 100px">
                  {{ site(record)[0] }}
                </div>
              </a-tooltip>
              <!-- 搜索后显示路径 -->
              <!-- <div
                v-if="params.fileName.length != 0"
                class="over"
                style="
                  color: #b4b8bd;
                  font-family: PingFangSC-Regular;
                  font-size: 12px;
                  margin-top: 4px;
                  width: 450px;
                "
              >
                {{ record.codePath ? record.codePath : "--" }}
              </div> -->
            </div>
          </div>
        </div>
      </template>
      <!-- 大小 -->
      <template slot="size" slot-scope="text">
        {{ text ? renderSize(text) : '--' }}
      </template>
      <!-- 类型 -->
      <template slot="type" slot-scope="text, record">
        {{ text == 0 ? '文件夹' : record.fileType }}
      </template>
      <template slot="customTitle">
        <span>操作</span>
      </template>
      <template slot="action" slot-scope="text, record, index">
        <action
          :config="filterLineData(record)"
          :row-data="{ record, index }"
          @click="actionLink"></action>
      </template>
    </a-table>
    <!-- 图片预览 -->
    <viewer v-if="photo" :images="photo">
      <img
        v-for="(item, index) in photo"
        :id="item"
        :key="index"
        :src="item"
        :data-source="item"
        style="width: 50px; height: 50px; display: none"
        alt=""
        @click="clickImg" />
    </viewer>
  </div>
</template>

<script>
import oss from '$public/core/lazy_lib/upload/oss.upload'
import Actions from '$public/components/lib/Actions'
import { mapState } from 'vuex'
import { Encrypt } from '$public/core/desense'
export default {
  name: 'AppendixUpload',
  props: [
    'record',
    'value',
    'parentDisabled',
    'disabled',
    'dynamicData',
    'multiple',
    'dynamicKey',
    'config'
  ],
  components: {
    // upload,
    action: Actions
  },
  data() {
    return {
      fileList: [], // 选择结果
      tmpList: [],
      photo: ['$public/assets/image/dataManagementIcon/img.svg'], // 图片
      // 上传接口，需要存入，防止上传时候切换文件夹
      uploadData: {},
      initColumns: [
        {
          title: '名称',
          dataIndex: 'name',
          scopedSlots: {
            customRender: 'name'
          }
        },
        {
          title: '文件格式',
          dataIndex: 'type',
          scopedSlots: {
            customRender: 'type'
          }
        },
        {
          title: '大小',
          dataIndex: 'size',
          scopedSlots: {
            customRender: 'size'
          }
        },
        {
          title: '操作',
          dataIndex: 'operation',
          slots: { title: 'customTitle' },
          width: 180,
          align: 'center',
          // fixed: "right",
          scopedSlots: {
            customRender: 'action'
          }
        }
      ],
      actionConfig: [
        {
          label: '删除',
          disabled: false
        }
      ]
    }
  },
  computed: {
    // optionsData() {
    //   try {
    //     return JSON.parse(this.record.options.data);
    //   } catch (err) {
    //     console.error(err);
    //     return {};
    //   }
    // },
    ...mapState({
      curr_org: state => state.curr_org, // 当前组织
      tenantId: state => state.curr_tenant, // 租户id
      login_msg: state => state.login_msg // 当前用户
    })
  },
  created() {
    this.uploadData = {
      tenantId: this.tenantId,
      orgId: this.curr_org.id
    }
  },

  mounted() {},
  watch: {
    value: {
      handler(val) {
        if (val) {
          // 当传入value改变时，fileList也要改变
          // 如果传入的值为字符串，则转成json
          if (typeof val === 'string') {
            this.fileList = JSON.parse(val)
            this.tmpList = JSON.parse(val)
            // 将转好的json覆盖组件默认值的字符串
            this.$emit('change', this.tmpList)
            this.$emit('input', this.tmpList)
            // this.handleSelectChange();
          } else {
            this.tmpList = val
          }
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    // 列表点击事件
    clickFile(record) {
      if (
        [
          'mp4',
          'mkv',
          'flv',
          'xlsx',
          'xls',
          'txt',
          'html',
          'doc',
          'docx',
          'pdf',
          'ppt',
          'pptx',
          'fbx',
          'obj',
          'stl',
          'dae',
          'ply',
          'gltf'
        ].includes(this.site(record)[1])
      ) {
        // 文件
        window.open(`${Util.baseUrlPreview}/#/preview?fileUrl=${Encrypt(record.url)}`)
      } else if (['jpg', 'png', 'jpeg', 'gif', 'bmp', 'webp'].includes(this.site(record)[1])) {
        // 图片
        this.photo = []
        this.photo.push(record.url)
        this.$nextTick(() => {
          document.getElementById(record.url).click()
        })
      } else {
        this.$message.error('当前文件格式不支持线上预览')
      }
    },
    // 模拟图片点击事件
    clickImg() {
      console.log('点击了图片')
    },
    // 格式化文件大小
    renderSize(value) {
      if (null == value || value == '' || value == 0) {
        return '0 Bytes'
      }
      var unitArr = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      var index = 0
      var srcsize = parseFloat(value)
      index = Math.floor(Math.log(srcsize) / Math.log(1024))
      var size = srcsize / Math.pow(1024, index)
      size = size.toFixed(0) //保留的小数位数
      return size + ' ' + unitArr[index]
    },
    // 单文件下载 end
    // 获取名称/后缀
    site(record) {
      let site = record.name.lastIndexOf('.'),
        type = record.name.substring(site + 1, record.name.length).toLowerCase()
      if (record.type == 0) {
        // 文件夹
        return [record.name]
      } else {
        // 文件
        return [record.name.replace('.' + type, ''), type]
      }
    },
    // 图片
    iconFun(record) {
      let imgUrl = ''
      if (record.type == 0) {
        imgUrl = require('$public/assets/image/dataManagementIcon/fold.svg')
      } else {
        if (['jpg', 'png', 'jpeg', 'gif', 'bmp', 'webp'].includes(this.site(record)[1])) {
          imgUrl = require('$public/assets/image/dataManagementIcon/img.svg')
        } else if (['mp3'].includes(this.site(record)[1])) {
          imgUrl = require('$public/assets/image/dataManagementIcon/audio.svg')
        } else if (['mp4', 'mkv', 'flv'].includes(this.site(record)[1])) {
          imgUrl = require('$public/assets/image/dataManagementIcon/movie.svg')
        } else if (['xlsx', 'xls'].includes(this.site(record)[1])) {
          imgUrl = require('$public/assets/image/dataManagementIcon/excel.svg')
        } else if (['doc', 'docx', 'txt', 'html'].includes(this.site(record)[1])) {
          imgUrl = require('$public/assets/image/dataManagementIcon/word.svg')
        } else if (['pdf'].includes(this.site(record)[1])) {
          imgUrl = require('$public/assets/image/dataManagementIcon/pdf.svg')
        } else if (['ppt', 'pptx'].includes(this.site(record)[1])) {
          imgUrl = require('$public/assets/image/dataManagementIcon/ppt.svg')
        } else if (['zip', 'rar'].includes(this.site(record)[1])) {
          imgUrl = require('$public/assets/image/dataManagementIcon/zip.svg')
        } else if (['fbx', 'obj', 'stl', 'dae', 'ply', 'gltf'].includes(this.site(record)[1])) {
          // 3D
          imgUrl = require('$public/assets/image/dataManagementIcon/unity.svg')
        } else {
          imgUrl = require('$public/assets/image/dataManagementIcon/other.svg')
        }
      }
      return imgUrl
    },
    // 文件上传 new
    beforeUpload(file) {
      if (file.size / 1024 / 1024 > 5) {
        this.$message.error('文件大小不能超过5M')
        return false
      } else {
        this.fileList.push(file)
        this.handleUpload()
      }
      return false
    },
    // 图片上传
    handleUpload() {
      this.fileList.forEach(file => {
        oss.upload(
          file,
          'libs/' + this.tenantId + '/' + this.curr_org.id + '/',
          file.name,
          {
            status: -1,
            isPaused: false,
            progress: 0,
            size: file.size,
            uid: file.uid
          },
          // 重试次数
          3,
          (progress, uploadName) => {
            this.fileList.forEach(item => {
              if (item.uid == file.uid) {
                item.percent = progress
                item.uploadName = uploadName
                item.status = 1
                if (progress == 100) {
                  item.status = 5 // oss上传成功
                }
              }
            })
          },
          res => {
            // 上传完成后将oss返回图片地址储存到fileList
            this.fileList.forEach(item => {
              if (item.uid == file.uid) {
                item.responseUrl = res
                // 上传成功回调 -  业务代码
                this.successTotalUploadFile(item)
              }
            })
            this.fileList = []
          },
          (err, fileObj) => {
            console.log(err, 'err')
            this.fileList.forEach(item => {
              if (item.uid == fileObj.uid) {
                item.percent = '0'
                item.status = -1
              }
            })
          }
        )
      })
    },
    // 上传0SS成功回调
    successTotalUploadFile(file) {
      file.status = 2
      // 用于处理重复文件名展示问题
      let num = this.tmpList.filter(v => v.fileName == file.name).length
      let site = file.name.lastIndexOf('.'),
        type = file.name.substring(site + 1, file.name.length).toLowerCase()
      this.tmpList.push({
        fileName: file.name,
        url: file.responseUrl,
        size: file.size,
        uploadName: file.uploadName[0],
        name: num > 0 ? `${file.name.replace('.' + type, '')}(${num}).${type}` : file.name,
        fileType: type,
        uid: file.uid
      })

      this.$emit('change', this.tmpList || this.fileList)
      this.$emit('input', this.tmpList || this.fileList)
      return
      let apiData = {
        tenantId: this.tenantId,
        orgId: this.curr_org.id
      }
      apiData.files = []
      let obj = {
        fileName: file.name,
        url: file.responseUrl,
        size: file.size,
        upLoadName: file.uploadName[0]
      }
      //   if (file.originFileObj.webkitRelativePath != "") {
      //     obj.path = file.originFileObj.webkitRelativePath;
      //   }
      apiData.files.push(obj)
      if (apiData.files.length > 0) {
        this.$post(Util.baseUrlSafety + '/web/document/up-load', apiData)
          .then(response => {
            Util.processRes(
              response,
              () => {
                file.status = 2
                // 用于处理重复文件名展示问题
                let num = this.tmpList.filter(v => v.fileName == file.name).length
                let site = file.name.lastIndexOf('.'),
                  type = file.name.substring(site + 1, file.name.length).toLowerCase()
                this.tmpList.push({
                  fileName: file.name,
                  url: file.responseUrl,
                  size: file.size,
                  uploadName: file.uploadName[0],
                  name:
                    num > 0 ? `${file.name.replace('.' + type, '')}(${num}).${type}` : file.name,
                  fileType: file.type,
                  uid: file.uid
                })

                this.$emit('change', this.tmpList || [])
                this.$emit('input', this.tmpList || [])
              },
              () => {
                // 未成功列表
                file.status = -1
                this.$message.error(`文件: ${file.uploadName[0]}上传失败,请重新上传!`)
              }
            )
            Util.processError(this, response)
          })
          .catch(res => {
            file.status = -1
            Util.processError(this, res)
          })
      }
    },
    /**
     * 上传中 显示 暂停 取消
     * 上传成功 不显示操作列
     * 上传失败 重试 删除
     * 暂停中 继续  取消
     * 未上传 暂停 取消
     */
    filterLineData(record) {
      let arr = Util.deepCopy(this.actionConfig),
        newArr = []
      arr[0].disabled = this.disabled || this.parentDisabled
      // 上传成功
      newArr = [arr[0]]
      return newArr
    },
    actionLink(operation) {
      let { target, rowData } = operation
      switch (target.label) {
        case '删除':
          this.remove(rowData.record)
          break
        // case '取消':
        //   this.cancel(rowData.record)
        //   break
      }
    },
    /**
     * 删除
     */
    remove(record) {
      this.$confirm({
        title: '确认要删除吗?',
        content: `删除后，该条信息将永久删除`,
        okText: '删 除',
        okType: 'danger',
        cancelText: '取 消',
        onOk: () => {
          this.tmpList.forEach((v, i) => {
            if (v.uid == record.uid) this.tmpList.splice(i, 1)
          })
          this.$emit('change', this.tmpList || [])
          this.$emit('input', this.tmpList || [])
        },
        onCancel: () => {}
      })
    }
  },
  destroyed() {}
}
</script>

<style lang="less">
#AppendixUpload {
  .uploadFile {
    display: inline-block;
    line-height: 32px;
    width: 110px;
    height: 32px;
    padding: 0 15px;
    margin-bottom: 8px;
    border: 0.5px solid #ccc;
    cursor: pointer;
  }
  .uploadFile:hover {
    color: #40a9ff;
    border-color: #40a9ff;
  }
}
.uploadList {
  .fileName:hover {
    color: #1890ff;
  }
}
</style>
