/*
 * @Descripttion:
 * @Version: 1.0.0
 * @Author: 李晨光
 * @Date: 2020-07-06 14:04:04
 * @LastEditors: 李帅
 * @LastEditTime: 2023-07-14 18:10:25
 */
/* eslint-disable */
/**
 * 该文件是为了按需加载，剔除掉了一些不需要的框架组件。
 * 减少了编译支持库包大小
 *
 * 当需要更多组件依赖时，在该文件加入即可
 */
import Vue from 'vue'
import {
  ConfigProvider,
  Layout,
  Input,
  InputNumber,
  Button,
  Switch,
  Radio,
  Checkbox,
  Select,
  Card,
  Form,
  FormModel,
  Row,
  Col,
  Modal,
  Table,
  Tabs,
  Icon,
  Badge,
  Popover,
  Dropdown,
  List,
  Avatar,
  Breadcrumb,
  Steps,
  Spin,
  Menu,
  Drawer,
  Tooltip,
  Alert,
  Tag,
  Divider,
  DatePicker,
  TimePicker,
  Upload,
  Progress,
  Skeleton,
  Popconfirm,
  message,
  notification,
  Carousel,
  Pagination,
  Tree,
  AutoComplete,
  Cascader,
  Slider,
  Descriptions,
  BackTop,
  Timeline,
  Anchor,
  TreeSelect,
  Transfer,
  Calendar,
  Statistic,
  Affix,
  Collapse,
  Rate,
  Empty,
  Space
} from 'ant-design-vue'
// import VueCropper from 'vue-cropper'
Vue.use(ConfigProvider)
Vue.use(Layout)
Vue.use(Input)
Vue.use(InputNumber)
Vue.use(Button)
Vue.use(Switch)
Vue.use(Radio)
Vue.use(Checkbox)
Vue.use(Select)
Vue.use(Card)
Vue.use(Form)
Vue.use(FormModel)
Vue.use(Row)
Vue.use(Col)
Vue.use(Modal)
Vue.use(Table)
Vue.use(Tabs)
Vue.use(Icon)
Vue.use(Badge)
Vue.use(Popover)
Vue.use(Dropdown)
Vue.use(List)
Vue.use(Avatar)
Vue.use(Breadcrumb)
Vue.use(Steps)
Vue.use(Spin)
Vue.use(Menu)
Vue.use(Drawer)
Vue.use(Tooltip)
Vue.use(Alert)
Vue.use(Tag)
Vue.use(Divider)
Vue.use(DatePicker)
Vue.use(TimePicker)
Vue.use(Upload)
Vue.use(Progress)
Vue.use(Skeleton)
Vue.use(Popconfirm)
// Vue.use(VueCropper)
Vue.use(notification)
Vue.use(Carousel)
Vue.use(Pagination)
Vue.use(Tree)
Vue.use(AutoComplete)
Vue.use(Cascader)
Vue.use(Slider)
Vue.use(Descriptions)
Vue.use(BackTop)
Vue.use(Timeline)
Vue.use(Anchor)
Vue.use(TreeSelect)
Vue.use(Transfer)
Vue.use(Calendar)
Vue.use(Statistic)
Vue.use(Affix)
Vue.use(Collapse)
Vue.use(Rate)
Vue.use(Empty)
Vue.use(Space)

Vue.prototype.$confirm = Modal.confirm

message.config({
  top: `80px`,
  duration: 3,
  maxCount: 1,
})
Vue.prototype.$message = message

Vue.prototype.$notification = notification
Vue.prototype.$info = Modal.info
Vue.prototype.$success = Modal.success
Vue.prototype.$error = Modal.error
Vue.prototype.$warning = Modal.warning

//自定义 font 图标
const IconFont = Icon.createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/font_1271362_8sk6l2crmdb.js',
})
const IconFont2 = Icon.createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/font_1986849_uznu0qaqrdm.js',
})
const IconFont3 = Icon.createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/font_2058337_facjl8ubydc.js',
})
const IconFont4 = Icon.createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/font_2441999_m1tkcjp9ff.js',
})
const IconFont5 = Icon.createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/font_2441999_hdrx4m408eo.js',
})
// Huazhu_Web_Icon
const IconFont6 = Icon.createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/font_2441999_7pk8zw7nlho.js',
})
const IconFont7 = Icon.createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/c/font_3963702_i1wvy3tcp1r.js',
})
const IconFont8 = Icon.createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/c/font_3963702_t5apt4ttxo.js',
})
Vue.component('icon-font', {
  ...IconFont,
  ...IconFont2,
  ...IconFont3,
  ...IconFont4,
  ...IconFont5,
  ...IconFont6,
  ...IconFont7,
  ...IconFont8,
})

import Loading from '../lazy_lib/loading/index'
Vue.use(Loading)
import Notify from '../lazy_lib/notice/index'
Vue.use(Notify)
