var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-form-item',{style:(_vm.formConfig.layout === 'horizontal' && _vm.formConfig.labelLayout === 'flex'
      ? { display: 'flex' }
      : {}),attrs:{"label":_vm.record.label,"label-col":_vm.formConfig.layout === 'horizontal'
      ? _vm.formConfig.labelLayout === 'flex'
        ? { style: `width:${_vm.formConfig.labelWidth}px` }
        : _vm.formConfig.labelCol
      : {},"wrapper-col":_vm.formConfig.layout === 'horizontal'
      ? _vm.formConfig.labelLayout === 'flex'
        ? { style: 'width:auto;flex:1' }
        : _vm.formConfig.wrapperCol
      : {}}},[_c(_vm.customComponent,{directives:[{name:"decorator",rawName:"v-decorator",value:([
      _vm.record.model,
      {
        initialValue: _vm.record.options.defaultValue,
        rules: _vm.record.rules
      }
    ]),expression:"[\n      record.model,\n      {\n        initialValue: record.options.defaultValue,\n        rules: record.rules\n      }\n    ]"}],tag:"component",style:(`width:${_vm.record.options.width}`),attrs:{"record":_vm.record,"disabled":_vm.disabled,"dynamicData":_vm.dynamicData,"height":typeof _vm.record.options.height !== 'undefined'
        ? _vm.record.options.height
        : ''},on:{"change":_vm.handleChange}})],1)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }