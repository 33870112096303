/*
 * @Description: aiot
 * @Version: 1.0.0
 * @Author: 李晨光
 * @Date: 2020-11-02 11:23:34
 * @LastEditors: 王召强
 * @LastEditTime: 2024-05-08 17:33:19
 */

const laborRoute = [
    {
        path: '/aiot/tunnel/labor/SubcontractorsLib',
        name: 'TunnelSubcontractorsLib',
        component: () =>
            import(
                '$aiot/views/tunnel/labor/subcontractorsLib/subcontractorsLib.vue'
            ),
        meta: {
            name: '分包供应商名录',
            keepAlive: false,
            isAuth: true,
            authCode: 'web_pm_tunnel_personnel_TunnelSubcontractorsLib',
        },
        children: [],
    },
    {
        path: '/aiot/tunnel/labor/SubcontractorsLib/edit',
        name: 'TunnelEditSubcontractorsLib',
        component: () =>
            import(
                '$aiot/views/tunnel/labor/subcontractorsLib/AddSubcontractorsLib.vue'
            ),
        meta: {
            name: '分包单位库编辑',
            isAuth: false,
            authCode: 'web_pm_tunnel_personnel_TunnelSubcontractorsLib',
            keepAlive: false,
        },
    },
    {
        path: '/aiot/tunnel/labor/SubcontractorsLib/add',
        name: 'TunnelAddSubcontractorsLib',
        component: () =>
            import(
                '$aiot/views/tunnel/labor/subcontractorsLib/AddSubcontractorsLib.vue'
            ),
        meta: {
            name: '分包单位库新增',
            isAuth: false,
            authCode: 'web_pm_tunnel_personnel_TunnelSubcontractorsLib',
            keepAlive: false,
        },
    },
    {
        path: '/aiot/tunnel/labor/SubcontractorsLib/detail',
        name: 'TunnelSubcontractorsLibDetail',
        component: () =>
            import(
                '$aiot/views/tunnel/labor/subcontractorsLib/subcontractorsLibDetail.vue'
            ),
        meta: {
            name: '分包单位库详情',
            isAuth: false,
            authCode: 'web_pm_tunnel_personnel_TunnelSubcontractorsLib',
            keepAlive: false,
        },
    },
    {
        path: '/aiot/tunnel/labor/importExcelSubManage',
        name: 'TunnelImportExcelSubManage',
        component: () =>
            import(
                '$aiot/views/tunnel/labor/subcontractorsLib/ImportExcelSubManage'
            ),
        meta: {
            name: '分包单位批量导入',
            isAuth: false,
            authCode: 'web_pm_tunnel_personnel_TunnelSubcontractorsLib',
        },
    },






    //分包单位管理
    {
        path: '/aiot/tunnel/labor/SubcontractorsManage',
        name: 'TunnelSubcontractorsManage',
        component: () =>
            import(
                '$aiot/views/tunnel/labor/subcontractorsManage/subcontractorsManage.vue'
            ),
        meta: {
            name: '分包单位管理',
            keepAlive: false,
            isAuth: true,
            authCode: 'web_pm_tunnel_personnel_TunnelSubcontractorsManage',
        },
        children: [],
    },
    {
        path: '/aiot/tunnel/labor/SubcontractorsManage/detail',
        name: 'TunnelSubcontractorsManageDetail',
        component: () =>
            import(
                '$aiot/views/tunnel/labor/subcontractorsManage/subcontractorsManageDetail.vue'
            ),
        meta: {
            name: '详情',
            isAuth: false,
            authCode: 'web_pm_tunnel_personnel_TunnelSubcontractorsManage',
            keepAlive: false,
        },
    },


    //班组管理
    {
        path: '/aiot/tunnel/labor/GroupManage',
        name: 'TunnelGroupManage',
        component: () =>
            import('$aiot/views/tunnel/labor/groupManage/groupManage.vue'),
        meta: {
            name: '作业班组管理',
            keepAlive: false,
            isAuth: true,
            authCode: 'web_pm_tunnel_personnel_tunnelGroupManage',
        },
        children: [],
    },
    {
        path: '/aiot/tunnel/labor/GroupManage/groupManageDetail/detail',
        name: 'TunnelGroupManageDetail',
        component: () => import('$aiot/views/tunnel/labor/groupManage/groupManageDetail.vue'),
        meta: {
            name: '班组信息',
            isAuth: false,
            authCode: 'web_pm_tunnel_personnel_tunnelGroupManage',
            keepAlive: false
        }
    },


    {
        path: '/aiot/tunnel/labor/laborRoster',
        name: 'TunnelLaborRoster',
        component: () => import('$aiot/views/tunnel/labor/laborRoster/laborRoster.vue'),
        meta: {
            name: '工人花名册',
            keepAlive: false,
            isAuth: true,
            authCode: 'web_pm_tunnel_personnel_TunnelLaborRoster'
        },
        children: []
    },
    {
        path: '/aiot/tunnel/labor/laborRoster/add',
        name: 'TunnelAddLaborRoster',
        component: () => import('$aiot/views/tunnel/labor/laborRoster/AddLaborRoster.vue'),
        meta: {
            name: '新增',
            isAuth: false,
            authCode: 'web_pm_tunnel_personnel_TunnelLaborRoster',
            keepAlive: false
        }
    },
    {
        path: '/aiot/tunnel/labor/laborRoster/edit',
        name: 'TunnelEditLaborRoster',
        component: () => import('$aiot/views/tunnel/labor/laborRoster/AddLaborRoster.vue'),
        meta: {
            name: '编辑',
            authCode: 'web_pm_tunnel_personnel_TunnelLaborRoster',
            keepAlive: false
        }
    },
    {
        path: '/aiot/tunnel/labor/laborRoster/worker',
        name: 'TunnelWorker',
        component: () => import('$aiot/views/tunnel/labor/laborRoster/laborSetting'),
        meta: {
            name: '工种维护',
            authCode: 'web_pm_tunnel_personnel_TunnelLaborRoster',
            keepAlive: false
        }
    },

]
export default laborRoute
