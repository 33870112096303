/*
 * @Description:
 * @Version: 1.0.0
 * @Author: 李晨光
 * @Date: 2020-11-23 10:59:50
 * @LastEditors: 李晨光
 * @LastEditTime: 2020-11-23 13:47:19
 */
import Vue from 'vue'
import Notice from './Notice.vue'

const NoticeBox = Vue.extend(Notice) // 创建的是一个组件构造器，不是实例
const Notify = {
  install: (options, type, duration = 3000) => {
    if (options === undefined || options === null) {
      options = {
        content: '',
      }
    } else if (typeof options === 'string' || typeof options === 'number') {
      options = {
        content: options,
      }
      if (type != undefined && options != null) {
        options.type = type
      }
      if (duration != undefined && options != null) {
        options.duration = duration
      }
    }
    let instance = new NoticeBox({
      data: options,
    }).$mount()
    document.body.appendChild(instance.$el) // 添加dom元素
    Vue.nextTick(() => {
      // dom元素渲染完成后执行回调
      if (options.content) {
        instance.visible = true
      }
    })
  },
}
Vue.prototype.$notify = Notify.install
;['info', 'success', 'error', 'warning'].forEach((type) => {
  Vue.prototype.$notify[type] = (content, duration = 3000) => {
    return Vue.prototype.$notify(content, type, duration)
  }
})
export default Notify
