<!--
 * @Description:
 * @Version: 1.0.0
 * @Author: 李晨光
 * @Date: 2020-07-23 09:57:39
 * @LastEditors: 李帅
 * @LastEditTime: 2021-08-30 18:49:17
-->
<!--  -->
<comment>
# 组件注释
</comment>
<template>
  <div id='FileUpload'
       class="p-10"
       v-show="show">
    <icon-font type="spm-icon-guanbi"
               @click="close"></icon-font>
    <a-table :columns="initColumns"
             :scroll="{x: 750,y:500}"
             :pagination="false"
             :dataSource="tableData"
             :locale="{emptyText: '暂无数据'}"
             rowKey="uid"
             :expandedRowKeys="expandedRowKeys"
             size="small">
      <template slot="name"
                slot-scope="text">{{text}}</template>
      <template slot="size"
                slot-scope="text">{{(text/1024/1024).toFixed(2)}}MB</template>
      <template slot="percent"
                slot-scope="text,record">
        <div v-if="record.status == 2">
          已完成
        </div>
        <div v-else-if="record.status == -1">
          上传失败
        </div>
        <div v-else>
          <a-progress :status="record.status == 3?'exception':''"
                      :percent="text"
                      :show-info="true" />
        </div>
      </template>
      <template slot="customTitle">
        <span class="m-l-16">操作</span>
      </template>
      <template slot="action"
                slot-scope="text, record, index">
        <action class="m-l-16"
                :config="filterLineData(record)"
                @click="actionLink"
                :row-data="{record,index}"></action>
      </template>
    </a-table>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import oss from '../upload/oss.upload'
import Actions from '$mechanical/components/lib/Actions'
import HzProgress from './Progress'
export default {
  name: 'FileUpload',
  //import引入的组件需要注入到对象中才能使用
  components: { action: Actions },
  props: {
    show: {
      type: Boolean
    },
    tableData: {
      type: Array,
      default: []
    }
  },
  data() {
    //这里存放数据
    return {
      initColumns: [
        {
          title: '文件名',
          dataIndex: 'name',
          scopedSlots: {
            customRender: 'name'
          }
        },
        {
          title: '文件类型',
          dataIndex: 'type',
          scopedSlots: {
            customRender: 'type'
          }
        },
        {
          title: '文件大小',
          dataIndex: 'size',
          scopedSlots: {
            customRender: 'size'
          }
        },
        {
          title: '上传进度',
          dataIndex: 'percent',
          width: 200,
          scopedSlots: {
            customRender: 'percent'
          }
        },
        {
          title: '操作',
          align: 'left',
          dataIndex: 'operation',
          slots: { title: 'customTitle' },
          width: 180,
          fixed: 'right',
          scopedSlots: {
            customRender: 'action'
          }
        }
      ],
      actionConfig: [
        {
          label: '暂停',
          disabled: false
        },
        {
          label: '继续',
          disabled: false
        },
        {
          label: '重试',
          disabled: false
        },
        {
          label: '删除',
          disabled: false
        },
        {
          label: '取消',
          disabled: false
        }
      ],
      icons: {
        up: require('$public/assets/image/common/upload/up.png'),
        success: require('$public/assets/image/common/upload/success.png'),
        error: require('$public/assets/image/common/upload/error.png')
      },
      showDetail: {}
    }
  },

  //监听属性 类似于data概念
  computed: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},

  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    /**
     * 格式化文件名
     */
    // setName(name,num){
    //   let str = ''
    //   name.match(/.*\./).length>num
    //   return str
    // },
    /**
     * 上传中 显示 暂停 取消
     * 上传成功 不显示操作列
     * 上传失败 重试 删除
     * 暂停中 继续  取消
     * 未上传 暂停 取消
     */
    filterLineData(record) {
      let arr = Util.deepCopy(this.actionConfig),
        newArr = []
      // 上传完成去掉暂停按键
      if (record.status == '-1') {
        // 上传失败
        newArr = [arr[2], arr[3]]
      } else if (record.status == '0') {
        // 未上传
        newArr = [arr[0], arr[4]]
      } else if (record.status == '1') {
        // 上传中
        newArr = [arr[0], arr[4]]
      } else if (record.status == '2') {
        // 上传成功
        newArr = [arr[3]]
      } else if (record.status == '3') {
        // 暂停中
        newArr = [arr[1], arr[4]]
      }
      return newArr
    },
    actionLink(operation) {
      let { target, rowData } = operation
      switch (target.label) {
        case '暂停':
          this.swatchSpend(target.label, rowData.record)
          break
        case '继续':
          this.swatchSpend(target.label, rowData.record)
          break
        case '重试':
          this.retry(rowData.record)
          break
        case '删除':
          this.remove(rowData.record)
          break
        // case '取消':
        //   this.cancel(rowData.record)
        //   break
      }
    },
    /**
     * 切换暂停启动
     */
    swatchSpend(label, record) {
      if (label == '暂停') {
        oss.pause(record, () => {
          record.status = 3
        })
      } else {
        this.$eventBus.$emit('changeFileUpload', {
          file: record,
          type: 'continue',
          fileList: this.tableData
        })
      }
    },
    /**
     * 重试
     */
    retry(record) {
      this.$eventBus.$emit('changeFileUpload', {
        file: record,
        type: 'retry',
        fileList: this.tableData
      })
    },
    /**
     * 删除
     */
    remove(record) {
      this.$eventBus.$emit('changeFileUpload', {
        file: record,
        type: 'remove',
        fileList: this.tableData
      })
    },
    /**
     * 取消
     */
    cancel(record) {
      this.$eventBus.$emit('changeFileUpload', {
        file: record,
        type: 'cancel',
        fileList: this.tableData
      })
    },
    /**
     * uploadFrequency 上传次数
     * options 列表
     */
    changeTable(options, uploadFrequency) {
      this.tableData = options
      this.$nextTick(() => {
        let text = ''
        let icons = null
        if (options && options.length && options.some((v) => v.status == 2)) {
          text = '上传成功'
          icons = this.icons['success']
        } else if (
          options &&
          options.length &&
          options.every((v) => v.status == -1)
        ) {
          text = '上传失败'
          icons = this.icons['error']
        } else {
          text = '正在上传'
          icons = this.icons['up']
        }
        if (options && options.length)
          this.showNotification(options, text, uploadFrequency, icons)
        else {
          this.showDetail[uploadFrequency] = false
          this.$notification.close(uploadFrequency)
          // this.$notification.destroy()
        }
      })
    },
    showNotification(options, text, uploadFrequency, icons) {
      let allStatus = options.map((v) => v.status) // 仅用于解决重试时监听不到数据变化，无任何意义
      // this.$nextTick(() => {
      this.$notification.open({
        message: (h) =>
          h(HzProgress, {
            props: {
              options: options,
              text: text,
              uploadFrequency: uploadFrequency,
              showDetail: this.showDetail[uploadFrequency],
              status: options.map((v) => v.status),
              allStatus: allStatus
            },
            on: {
              // 关闭弹层
              closeProgress: ({ uploadFrequency }) => {
                this.showDetail[uploadFrequency] = false
                this.$notification.close(uploadFrequency)
              },
              // 切换显示上传详情
              setShowDetail: (value) => {
                this.showDetail[uploadFrequency] = value
                this.showNotification(options, text, uploadFrequency, icons)
              },
              // 切换操作上传暂停与继续
              swatchSpend: ({ record, isSuspend }) => {
                this.$eventBus.$emit('changeFileUpload', {
                  file: record,
                  type: isSuspend ? 'pause' : 'continue',
                  fileList: options,
                  uploadFrequency
                })
              },
              // 重试
              retry: ({ record }) => {
                this.$eventBus.$emit('changeFileUpload', {
                  file: record,
                  type: 'retry',
                  fileList: options,
                  uploadFrequency
                })
              },
              //取消上传
              removeProgress: ({ record, uploadFrequency }) => {
                this.$eventBus.$emit('changeFileUpload', {
                  file: record,
                  type: 'remove',
                  fileList: options,
                  uploadFrequency
                })
              }
            }
          }),
        class: 'updateList',
        duration: null,
        key: uploadFrequency,
        placement: 'bottomRight',
        description: '',
        onClick: () => {
          this.showDetail[uploadFrequency] = true
          this.showNotification(options, text, uploadFrequency, icons)
        },
        icon: (h) =>
          h('img', {
            attrs: {
              style: 'width:18px;height:18px;margin-right:7px;',
              src: icons
            }
          })
      })
      // })
    },
    close() {
      this.$fileUpload.hide(false)
    }
  },
  destroyed() {} //生命周期 - 销毁完成
}
</script>
<style lang='less' >

#FileUpload {
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 99999;
  width: 800px;
  background: #fff;
}
.updateList {
  border-radius: 4px;
  padding: 16px 18px;
  transition: all 0.4s;
  .ant-notification-notice-icon {
    line-height: 15px;
  }
  .ant-notification-notice-with-icon {
    // height: 86px;
  }
  .ant-notification-notice-with-icon .ant-notification-notice-message {
    width: calc(100% - 29px);
    margin-left: 29px;
    margin-bottom: 0;
    padding-right: 0;
  }
  .ant-notification-notice-close {
    display: none;
  }
}
.ant-notification {
  bottom: 50px !important;
}
</style>
