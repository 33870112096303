/** * @Author: yuqiang * @Date: 2020-06-10 * @Version: 3.0 * @Last Modified by: yuqiang * @Last
Modified time: 2020-06-10 **/
<comment>
  # 组件注释
  修改手机号
</comment>
<template>
  <a-modal
    title="修改手机号"
    centered
    :width="500"
    :visible="formModalVisible"
    :mask-closable="false"
    @ok="submitParticInConstr"
    @cancel="closeModal()">
    <a-skeleton active :loading="detailLoading" :paragraph="{ rows: 10 }">
      <a-form :form="form">
        <a-row :gutter="20">
          <a-form-item
            label="旧手机号码为："
            :validate-status="formError(form, 'oldPhone') ? 'error' : ''"
            :help="formError(form, 'oldPhone') || ''">
            <a-input v-decorator="['oldPhone']" :disabled="true" type="text" placeholder />
          </a-form-item>

          <a-form-item
            label="手机号"
            :validate-status="formError(form, 'phone') ? 'error' : ''"
            :help="formError(form, 'phone') || ''">
            <a-input
              v-decorator="[
                'phone',
                {
                  rules: [
                    { required: true, message: '请输入手机号' },
                    { pattern: Util.tel, message: '请输入正确手机号!' },
                    { validator: validatorName }
                  ]
                }
              ]"
              size="large"
              type="tel"
              autocomplete="false"
              placeholder="请输入手机号">
              <a-icon slot="prefix" type="user" :style="{ color: 'rgba(0,0,0,.25)' }" />
            </a-input>
          </a-form-item>

          <a-form-item
            label="验证码"
            :validate-status="formError(form, 'code') ? 'error' : ''"
            :help="formError(form, 'code') || ''">
            <a-input
              v-decorator="['code', { rules: [{ required: true, message: '请输入验证码' }] }]"
              size="large"
              type="text"
              autocomplete="false"
              placeholder="请输入短信验证码"
              @keyup.enter="login">
              <a-icon slot="prefix" type="lock" :style="{ color: 'rgba(0,0,0,.25)' }" />
            </a-input>
          </a-form-item>
          <a-form-item>
            <div class="flex justify-end align-center" style="font-size: 12px">
              <a @click="sendAuthCodeCountDown()">{{ sendBtn }}</a>
            </div>
          </a-form-item>
        </a-row>
        <contact-modal
          v-if="contactVisible"
          :is-visible="contactVisible"
          @closed="contactVisible = false"
          @change="setValue('contact', $event)"></contact-modal>
      </a-form>
    </a-skeleton>
    <template slot="footer">
      <a-button @click="closeModal()">取 消</a-button>
      <a-button
        style="margin-left: 10px"
        type="primary"
        :disabled="hasErrors(form.getFieldsError())"
        @click="submitParticInConstr"
        >确 认</a-button
      >
    </template>
  </a-modal>
</template>
<script>
import debounce from 'lodash.debounce'
import { mapState } from 'vuex'

export default {
  name: 'ChangeMobile',
  components: {},
  props: [],
  data() {
    return {
      ///web/tenant-admin/add
      Util,
      api: {
        sendAuthCodeApi: '/web/sms/send-code', // 发送验证码
        validateTelMsgApi: '/web/sms/verify-code' // 验证 验证码
      },
      sendBtn: '发送验证码至手机',
      visible: false,
      title: '',
      type: '', // 标题显示title
      form: this.$form.createForm(this),
      detailLoading: false,
      formModalVisible: false,
      sendAuthCode: false,
      oldPhone: '',
      contactVisible: false
    }
  },
  computed: {
    ...mapState({
      tenantId: state => state.curr_tenant
    })
  },
  created() {},
  mounted() {
    //  this.form.setFieldsValue({
    //   tenantId: this.tenantId
    // })
  },
  watch: {},
  methods: {
    /**
     * 监听input 执行debounce 延时1S后执行搜索方法
     */
    deb: debounce(
      function () {
        // 搜索方法
        this.search()
      },
      1000 // 延迟时间
    ),
    /**
     * 检验手机号
     */
    validatorTel(rule, value, callback) {
      const form = this.form
      if (Util.tel.test(String(value))) {
        callback()
      } else {
        callback('请输入正确的手机号码！')
      }
    },
    /**
     * 发送手机验证码倒计时
     */
    sendAuthCodeCountDown() {
      let tel = this.form.getFieldValue('phone')
      let params = {
        phone: tel,
        // 0登录 1修改手机号码 2修改密码 3忘记密码
        smsType: '1'
      }
      !this.sendAuthCode &&
        tel &&
        this.$post(this.api.sendAuthCodeApi, params)
          .then(response => {
            this.loading = false
            Util.processRes(response, () => {
              this.$message.success('已发送短信，请注意查收！')
              if (!this.sendAuthCode) {
                this.sendAuthCode = true
                Util.countDown(
                  60,
                  res => {
                    this.countDown = res
                    this.sendBtn = this.countDown + 's后重新发送'
                  },
                  () => {
                    this.sendBtn = '重新发送'
                    this.sendAuthCode = false
                  }
                )
              }
            })
            Util.processError(this, response)
          })
          .catch(err => {
            this.loading = false
          })
    },
    /**
     * 关闭弹窗
     */
    closeModal() {
      this.formModalVisible = false
      this.form.resetFields()
    },
    /**
     * 打开弹窗
     */
    openModal(val) {
      this.oldPhone = val
      this.formModalVisible = true
      this.$nextTick(() => {
        this.form.resetFields()
        this.form.validateFields()
        this.form.setFieldsValue({
          oldPhone: this.oldPhone
        })
      })
    },
    /**
     * 提交信息
     */
    submitParticInConstr() {
      this.form.validateFields((err, values) => {
        if (!err) {
          delete values.oldPhone
          // 0登录 1修改手机号吗 2修改密码 3忘记密码
          values.smsType = '1'
          this.$post(this.api.validateTelMsgApi, values)
            .then(response => {
              Util.processRes(response, () => {
                this.$message.success(response.statusMessage)
                this.$emit('callback', {
                  phone: values.phone,
                  // 重置密码时，需要将验证通过的验证码带入
                  code: this.form.getFieldValue('code')
                })
                this.closeModal()
              })
              Util.processError(this, response)
            })
            .catch(res => {
              console.log(res)
              // Util.processError(this, res)
            })
        }
      })
    },
    /***
     * 表单自定义验证,用于验证新旧手机号是否相等
     */
    validatorName(rule, value, callback) {
      const form = this.form
      if (value == form.getFieldValue('oldPhone')) {
        callback('新旧手机号不能相等！')
      } else {
        callback()
      }
    },
    // 验证表单 获取表单validate 状态 按钮根据状态可不可操作
    formError(form, field) {
      const { getFieldError, isFieldTouched } = form
      return isFieldTouched(field) && getFieldError(field)
    },
    hasErrors(fieldsError) {
      return Object.keys(fieldsError).some(field => fieldsError[field])
    }
  }
}
</script>
<style scoped></style>
