/*
 * @Description:安全管理业务设置模块路由
 * @Version: 1.0.0
 * @Author: 杜凡
 * @Date: 2022-03-29 10:21:33
 * @LastEditors: 申文慧
 * @LastEditTime: 2024-05-20 11:07:20
 */
import routerName from '../config/routerName.config'

const setUpRoute = [
  //大数据分析=============================================
  {
    //风险分级管控大数据分析
    path: '/bigDataAnalysis/riskClassificationBigData/riskClassificationBigData',
    name: 'RiskClassificationBigData',
    component: () =>
      import('$safety/views/bigDataAnalysis/riskClassificationBigData/RiskClassificationBigData'),
    meta: {
      name: '风险分级管控',
      isAuth: true,
      authCode: 'web_base_safe_bigData_riskClass'
    }
  },
  {
    //危大工程大数据分析
    path: '/bigDataAnalysis/dangerousProjectBigData/dangerousProjectBigData',
    name: 'DangerousProjectBigData',
    component: () =>
      import('$safety/views/bigDataAnalysis/dangerousProjectBigData/DangerousProjectBigData'),
    meta: {
      name: '危大工程',
      isAuth: true,
      authCode: 'web_base_safe_bigData_dangerous'
    }
  },
  {
    //隐患排查大数据分析
    path: '/bigDataAnalysis/hiddenDangerBigData/hiddenDangerBigData',
    name: 'HiddenDangerBigData',
    component: () =>
      import('$safety/views/bigDataAnalysis/hiddenDangerBigData/HiddenDangerBigData'),
    meta: {
      name: '隐患排查',
      isAuth: true,
      authCode: 'web_base_safe_bigData_hidden'
    }
  },
  {
    //危险作业大数据分析
    path: '/bigDataAnalysis/operationBigData/operationBigData',
    name: 'OperationBigData',
    component: () => import('$safety/views/bigDataAnalysis/operationBigData/OperationBigData'),
    meta: {
      name: '危险作业',
      isAuth: true,
      authCode: 'web_base_safe_bigData_operat'
    }
  },
  {
    //安全验收大数据分析
    path: '/bigDataAnalysis/acceptanceBigData/acceptanceBigData',
    name: 'AcceptanceBigData',
    component: () => import('$safety/views/bigDataAnalysis/acceptanceBigData/AcceptanceBigData'),
    meta: {
      name: '安全验收',
      isAuth: true,
      authCode: 'web_base_safe_bigData_accep'
    }
  },

  //基础设置=============================================
  {
    //危大工程设置
    path: '/setUp/dangerousProjectsSetUp',
    name: 'DangerousProjectsSetUp',
    component: () => import('$safety/views/setUp/dangerousProjectsSetUp/DangerousProjectsSetUp'),
    meta: {
      name: routerName('DangerousProjectsSetUp'),
      isAuth: true,
      authCode: 'web_base_safe_engineering'
    }
  },
  {
    //重大危险源源类别设置
    path: '/setUp/majorHazardInstallationsSetUp',
    name: 'MajorHazardInstallationsSetUp',
    component: () =>
      import('$safety/views/setUp/majorHazardInstallationsSetUp/MajorHazardInstallationsSetUp'),
    meta: {
      name: routerName('MajorHazardInstallationsSetUp'),
      isAuth: true,
      authCode: 'web_base_safe_hazardSources'
    }
  },
  {
    //隐患排查设置
    path: '/setUp/hiddenDangerInvestigationSetUp',
    name: 'HiddenDangerInvestigationSetUp',
    component: () =>
      import('$safety/views/setUp/hiddenDangerInvestigationSetUp/HiddenDangerInvestigationSetUp'),
    meta: {
      name: '隐患排查设置',
      isAuth: true,
      authCode: 'web_base_safe_hiddenDanger'
    }
  },
  {
    //隐患库导入
    path: '/setUp/hiddenDangerInvestigationSetUp/ImportRisks',
    name: 'ImportRisks',
    component: () => import('$safety/views/setUp/hiddenDangerInvestigationSetUp/ImportRisks'),
    meta: {
      isAuth: true,
      authCode: 'web_base_safe_hiddenDanger_check_importAll',
      name: '导入'
    }
  },
  {
    //验收设置
    path: '/setUp/acceptanceSetUp',
    name: 'AcceptanceSetUp',
    component: () => import('$safety/views/setUp/acceptanceSetUp/AcceptanceSetUp'),
    meta: {
      name: '验收设置',
      isAuth: true,
      authCode: 'web_base_safe_accep'
    }
  },
  {
    //验收项导入
    path: '/setUp/acceptanceSetUp/importAccept',
    name: 'ImportAccept',
    component: () => import('$safety/views/setUp/acceptanceSetUp/ImportAccept'),
    meta: {
      isAuth: true,
      name: '导入',
      authCode: 'web_base_safe_accep_import'
    }
  },
  {
    //风险清单库设置
    path: '/main/safety/hazardIdentification/riskLibs',
    name: 'RiskLibs',
    meta: {
      name: routerName('RiskLibs'),
      isAuth: true,
      authCode: 'web_base_safe_riskList'
    },
    component: () => import('$safety/views/setUp/riskLibs/RiskLibs')
  },
  {
    //风险清单库导入
    path: '/main/safety/hazardIdentification/riskLibs/import',
    name: 'ImportRiskLibs',
    meta: {
      name: '风险清单库导入',
      isAuth: true,
      authCode: 'web_base_safe_riskList_import'
    },
    component: () => import('$safety/views/setUp/riskLibs/ImportRisks')
  },

  //设置=============================================
  {
    //安全活动设置
    path: '/setUp/safetyActivitiesSetUp',
    name: 'SafetyActivitiesSetUp',
    component: () => import('$safety/views/setUp/safetyActivitiesSetUp/SafetyActivitiesSetUp'),
    meta: {
      name: '安全活动设置',
      isAuth: true,
      authCode: 'web_base_safe_set_safetyActivitie'
    }
  },
  {
    //消防安全设置
    path: '/setUp/fireSafetySetUp',
    name: 'FireSafetySetUp',
    component: () => import('$safety/views/setUp/fireSafetySetUp/FireSafetySetUp'),
    meta: {
      name: '消防安全设置',
      isAuth: true,
      authCode: 'web_base_safe_set_fireSafety'
    }
  },
  {
    //安全员与项目经理
    path: '/safetyOfficer/safetyOfficer',
    name: 'SafetyOfficer',
    component: () => import('$safety/views/safetyOfficer/SafetyOfficer'),
    meta: {
      name: '安全员与项目经理',
      isAuth: true,
      authCode: 'web_base_safe_set_safetyOfficer'
    }
  },
  {
    //重大危险源判定设置
    path: '/setUp/decideHazardInstallationsSetUp',
    name: 'DecideHazardInstallationsSetUp',
    component: () =>
      import('$safety/views/setUp/decideHazardInstallationsSetUp/DecideHazardInstallationsSetUp'),
    meta: {
      name: routerName('DecideHazardInstallationsSetUp'),
      isAuth: true,
      authCode: 'web_base_safe_set_level'
    }
  },
  {
    //区域设置-质量房建页面入口
    path: '/wbs/safetyHouse',
    name: 'SafetyHouse',
    meta: {
      name: '区域设置',
      isAuth: true,
      authCode: 'web_base_safe_set_region',
      id: ''
    },
    component: () => import('$quality/views/settings/qualityInspect/safetyWbs/house/Index')
  },
  {
    path: '/wbs/safetyHouse/planResponsibilityArea/edit',
    name: 'safetyPlanResponsibilityAreaEdit',
    meta: {
      name: '编辑',
      isAuth: false,
      authCode: 'web_base_safe_set_region_responsibilityAreaPlan_edit'
    },
    component: () =>
      import(
        /*/* : "质量责任区域平面图编辑页面入口" */
        '$quality/views/settings/qualityInspect/safetyWbs/house/EditOrRecordResponse.vue'
      ),
    children: []
  },
  {
    //施工作业设置
    path: '/setUp/jobSetting',
    name: 'jobSetting',
    component: () => import('$safety/views/setUp/jobSetting/JobSetting'),
    meta: {
      name: '施工作业设置',
      isAuth: true,
      authCode: 'web_base_safe_jobSetting'
    }
  },
  {
    //施工作业设置检查项导入
    path: '/setUp/jobSetting/importExcel',
    name: 'SetUpImportExcel',
    component: () => import('$safety/views/setUp/jobSetting/ImportExcel.vue'),
    meta: {
      name: '检查项导入',
      isAuth: true,
      authCode: 'web_base_safe_jobSetting_import'
    }
  },
  {
    // 审批人设置
    path: '/setUp/approverSettings',
    name: 'ApproverSettings',
    component: () => import('$safety/views/setUp/approverSettings/Index.vue'),
    meta: {
      name: '审批人设置',
      isAuth: true,
      authCode: 'web_base_safe_approverSettings'
    }
  }
]

export default setUpRoute
