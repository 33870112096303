/*
 * @Description:
 * @Version: 1.0.0
 * @Author: 李帅
 * @Date: 2021-01-19 10:27:19
 * @LastEditors: 赵亮
 * @LastEditTime: 2023-11-22 16:12:29
 */
const engineeringApplication = [
  {
    path: '/quality/engineeringApplication/constructionPermit',
    name: 'ConstructionPermit',
    meta: {
      name: '开工许可台账',
      keepAlive: false,
      isAuth: false,
      authCode: 'web_pm_quality_engineeringApplication_constructionPermit'
    },
    component: () => import('$quality/views/engineeringApplication/constructionPermit/Index'),
    children: []
  },
  {
    path: '/quality/engineeringApplication/constructionPermit/add',
    name: 'ConstructionPermitAdd',
    meta: {
      name: '新增',
      keepAlive: false,
      isAuth: false,
      authCode: 'web_pm_quality_engineeringApplication_constructionPermit'
    },
    component: () => import('$quality/views/engineeringApplication/constructionPermit/Add'),
    children: []
  },
  {
    path: '/quality/engineeringApplication/constructionPermit/details',
    name: 'ConstructionPermitDetails',
    meta: {
      name: '详情',
      keepAlive: false,
      isAuth: false,
      authCode: 'web_pm_quality_engineeringApplication_constructionPermit'
    },
    component: () => import('$quality/views/engineeringApplication/constructionPermit/Add'),
    children: []
  },
  {
    path: '/quality/engineeringApplication/constructionPermit/approve',
    name: 'ConstructionPermitApprove',
    meta: {
      name: '审批',
      keepAlive: false,
      isAuth: false,
      authCode: 'web_pm_quality_engineeringApplication_constructionPermit'
    },
    component: () => import('$quality/views/engineeringApplication/constructionPermit/Add'),
    children: []
  },
  {
    path: '/quality/engineeringApplication/processControl',
    name: 'ProcessControl',
    meta: {
      name: '工序控制台账',
      keepAlive: false,
      isAuth: false,
      authCode: 'web_pm_quality_engineeringApplication_processControl'
    },
    component: () => import('$quality/views/engineeringApplication/processControl/Index'),
    children: []
  },
  {
    path: '/quality/engineeringApplication/processControl/details',
    name: 'ProcessControlDetails',
    meta: {
      name: '详情',
      keepAlive: false,
      isAuth: false,
      authCode: 'web_pm_quality_engineeringApplication_processControl'
    },
    component: () => import('$quality/views/engineeringApplication/processControl/Details'),
    children: []
  },
  {
    path: '/quality/engineeringApplication/finishedProduct',
    name: 'FinishedProduct',
    meta: {
      name: '成品检验台账',
      keepAlive: false,
      isAuth: false,
      authCode: 'web_pm_quality_engineeringApplication_FinishedProduct'
    },
    component: () => import('$quality/views/engineeringApplication/finishedProduct/Index.vue'),
    children: []
  },
  {
    path: '/quality/engineeringApplication/calculation',
    name: 'Calculation',
    meta: {
      name: '产值计算台账',
      keepAlive: false,
      isAuth: false,
      authCode: 'web_pm_quality_engineeringApplication_Calculation'
    },
    component: () => import('$quality/views/engineeringApplication/calculation/Index'),
    children: []
  },

  {
    path: '/quality/engineeringApplication/processConfiguration',
    name: 'ProcessConfiguration',
    meta: {
      name: '工序配置',
      keepAlive: false,
      isAuth: false,
      authCode: 'web_base_quality_ProcessConfiguration'
    },
    component: () => import('$quality/views/engineeringApplication/processConfiguration/Index'),
    children: []
  },
  {
    path: '/quality/engineeringApplication/alertConfiguration',
    name: 'AlertConfiguration',
    meta: {
      name: '预警配置',
      keepAlive: false,
      isAuth: false,
      authCode: 'web_base_quality_AlertConfiguration'
    },
    component: () => import('$quality/views/engineeringApplication/alertConfiguration/Index.vue'),
    children: []
  },
  {
    path: '/quality/engineeringApplication/warningList',
    name: 'WarningList',
    meta: {
      name: '预警列表',
      keepAlive: false,
      isAuth: false,
      authCode: 'web_pm_quality_engineeringApplication_WarningList'
    },
    component: () => import('$quality/views/engineeringApplication/warningList/Index.vue'),
    children: []
  },
  {
    path: '/quality/engineeringApplication/threeInspectionConfig',
    name: 'ThreeInspectionConfig',
    meta: {
      name: '三检配置',
      keepAlive: false,
      isAuth: false,
      authCode: 'web_base_quality_engineeringSetting_threeInspectionConfig'
    },
    component: () =>
      import('$quality/views/engineeringApplication/threeInspectionConfig/Index.vue'),
    children: []
  },
  {
    path: '/quality/engineeringApplication/subcontractConfig',
    name: 'SubcontractConfig',
    meta: {
      name: '分包配置',
      keepAlive: false,
      isAuth: false,
      authCode: 'web_base_quality_engineeringSetting_SubcontractConfig'
    },
    component: () => import('$quality/views/engineeringApplication/subcontractConfig/Index.vue'),
    children: []
  },
  {
    path: '/quality/engineeringApplication/processAcceptance',
    name: 'ProcessAcceptance',
    meta: {
      name: '工序验收规范库',
      keepAlive: false,
      isAuth: false,
      authCode: 'web_base_quality_engineeringSetting_ProcessAcceptance'
    },
    component: () => import('$quality/views/engineeringApplication/processAcceptance/Index.vue'),
    children: []
  }
]

export default engineeringApplication
