<!--
 * @Description:
 * @Version: 1.0.0
 * @Author: 李帅
 * @Date: 2021-08-17 19:46:18
 * @LastEditors: 李帅
 * @LastEditTime: 2021-08-30 18:48:34
-->
<comment>
# 组件注释
</comment>
<template>
  <div id='Progress'>
    <div :style="{'marginBottom':showDetail?'20px':0}">
      <div class="text">
        {{text}} ({{options.filter(v=>v.status == (text=='上传成功'?2:-1)).length}}/{{options.length}})

        <!-- // 仅用于解决重试时监听不到数据变化，无任何意义 -->
        <div v-show="false">{{allStatus}}</div>
      </div>
      <a-progress strokeWidth="4"
                  :percent="percent"
                  v-if="(text != '上传成功' && text != '上传失败' && !showDetail)"
                  :show-info="false"
                  status="active" />
      <div class="imgs">
        <a-icon class="icon"
                type="minus"
                v-if="showDetail"
                @click.stop="minimum" />
        <a-icon class="icon close"
                type="close"
                v-if="options.every(v=>v.status !=1 && v.status !=5 && v.status != 3)"
                @click.stop="closeProgress()" />
      </div>
    </div>

    <div v-if="showDetail">
      <ul class="list">
        <li v-for="(item,i) in options"
            :key="i">
          <i class="bg-progress"
             v-if="item.status != 2 && item.status != -1"
             :style="{width:item.percent+'%'}"></i>
          <img :src="getIcon(item)"
               alt="">
          <div class="content">
            <div style="line-height:22px;"
                 :title="item.name"> {{setFileName(item.name)}}</div>
            <div v-if="item.status == -1"
                 class="error"
                 style="color:#FF4D4F;line-height:20px;">上传失败，请检查网络或重试</div>
            <div v-else-if="item.status == 2">
              {{Util.renderSize(item.size)}} - 上传成功
            </div>
            <div style="line-height:20px;"
                 v-else>{{Util.renderSize(item.size*(item.percent/100) )}} / {{Util.renderSize(item.size)}}</div>
          </div>
          <div class="actions">
            <div class="imgs">
              <!-- 暂停 -->
              <a-icon class="icon"
                      type="pause"
                      v-if="item.status == 1"
                      @click.stop="swatchSpend(item,1)" />
              <!-- 继续 -->
              <a-icon class="icon"
                      type="caret-right"
                      v-if="item.status  == 3"
                      @click.stop="swatchSpend(item,2)" />
              <!-- 重试 -->
              <a-icon class="icon"
                      type="redo"
                      v-if="item.status == -1"
                      @click.stop="retry(item)" />
              <!-- 删除 -->
              <a-icon class="icon close"
                      type="close"
                      v-if="item.status == 1 || item.status == -1||item.status == 3"
                      @click.stop="removeProgress(item)" />
            </div>
          </div>
          <a-progress strokeWidth="4"
                      :percent="item.percent"
                      v-if="item.status == 1||item.status == 3"
                      :show-info="false" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { mapState } from 'vuex'
export default {
  name: 'Progress',
  //import引入的组件需要注入到对象中才能使用
  components: {},
  props: {
    options: {
      type: Array,
      default: []
    },
    //todo 仅用于解决重试时监听不到数据变化，无任何意义
    allStatus: {
      type: Array,
      default: []
    },
    text: {
      type: String,
      default: ''
    },
    uploadFrequency: {
      type: String,
      default: ''
    },
    showDetail: {
      type: Boolean,
      default: false
    }
  },
  data() {
    //这里存放数据
    return {
      Util,
      isSuspend: false // 是否暂停
    }
  },
  //监听属性 类似于data概念
  computed: {
    percent() {
      return (
        this.options.map((v) => v.percent).reduce((acc, val) => acc + val, 0) /
        this.options.map((v) => v.percent).length
      )
    },
    ...mapState({
      color: (state) => state.color // 主题色
    })
  },

  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  //监控data中的数据变化
  watch: {
    options: {
      handler: function (val) {
        this.options = val
      },
      deep: true
    }
  },
  //方法集合
  methods: {
    /**
     * 设置名称
     */
    setFileName(name) {
      let str = '',
        max = 18
      if (name.length > max) {
        let arr = name.split('.')
        let lastName = arr[arr.length - 1]
        str =
          name.slice(0, max / 2 + 1) +
          '...' +
          name.slice(max / 2 + 1 + 3, max) +
          '.' +
          lastName
        return str
      } else {
        return name
      }
      return name
    },
    // 获取图标
    getIcon(record) {
      let imgUrl = ''
      let site = record.name.lastIndexOf('.'),
        type = record.name.substring(site + 1, record.name.length).toLowerCase()
      if (['jpg', 'png', 'jpeg', 'gif', 'bmp'].includes(type)) {
        imgUrl = require('$public/assets/image/dataManagementIcon/img.svg')
      } else if (['mp3'].includes(type)) {
        imgUrl = require('$public/assets/image/dataManagementIcon/audio.svg')
      } else if (['mp4', 'rmvb', 'mkv', 'wmv', 'flv'].includes(type)) {
        imgUrl = require('$public/assets/image/dataManagementIcon/movie.svg')
      } else if (['xlsx', 'xls'].includes(type)) {
        imgUrl = require('$public/assets/image/dataManagementIcon/excel.svg')
      } else if (['doc', 'docx'].includes(type)) {
        imgUrl = require('$public/assets/image/dataManagementIcon/word.svg')
      } else if (['pdf'].includes(type)) {
        imgUrl = require('$public/assets/image/dataManagementIcon/pdf.svg')
      } else if (['ppt', 'pptx'].includes(type)) {
        imgUrl = require('$public/assets/image/dataManagementIcon/ppt.svg')
      } else if (['zip', 'rar'].includes(type)) {
        imgUrl = require('$public/assets/image/dataManagementIcon/zip.svg')
      } else {
        imgUrl = require('$public/assets/image/dataManagementIcon/other.svg')
      }
      return imgUrl
    },
    /**
     * 最小化
     */
    minimum() {
      this.$emit('setShowDetail', false)
    },
    /**
     * 暂停或开始
     */
    swatchSpend(record, num) {
      this.isSuspend = num == 1
      this.$emit('swatchSpend', { record: record, isSuspend: this.isSuspend })
    },
    /**
     * 重试
     */
    retry(record) {
      this.$emit('retry', { record: record })
    },
    /**
     * 删除上传
     */
    removeProgress(record) {
      this.$emit('removeProgress', {
        record: record,
        uploadFrequency: this.uploadFrequency
      })
    },
    closeProgress() {
      this.$emit('closeProgress', { uploadFrequency: this.uploadFrequency })
    }
  },
  destroyed() {} //生命周期 - 销毁完成
}
</script>
<style lang='less' >

#Progress {
  .text {
    font-size: 14px;
    color: #303133;
    line-height: 22px;
    cursor: pointer;
  }
  .ant-progress {
    position: absolute;
    left: 0;
    bottom: -8px;
    .ant-progress-inner,
    .ant-progress-bg {
      border-radius: 0 !important;
    }
  }
  .list {
    width: calc(100% + 105px);
    position: relative;
    max-height: 320px;
    min-height: 64px;
    overflow-y: auto;
    overflow-x: hidden;
    left: -87px;
    margin-bottom: -8px;
    li {
      height: 64px;
      display: flex;
      position: relative;
      padding: 14px 18px 10px 22px;
      z-index: 9;
      overflow: hidden;
      > img {
        width: 40px;
        height: 40px;
        margin-right: 16px;
      }
      .content {
        flex: 1;
        width: 240px;
        > div:first-child {
          font-size: 14px;
          color: #303133;
        }
        > div:last-child {
          font-size: 12px;
          color: #909399;
        }
      }
      .actions {
        width: 44px;
      }
      .bg-progress {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        background: #f5f5f5;
        z-index: -1;
        transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
      }
    }
  }
  .imgs {
    position: absolute;
    top: 18px;
    right: 18px;
    color: #606266;
    outline: none;
    > .icon {
      font-size: 16px;
      cursor: pointer;
    }
    > .icon:last-child {
      margin-left: 12px;
    }
    > .icon:hover {
      .primary_color();
    }
    > .icon.close:hover {
      color: #ee4f61;
    }
  }
  &::before {
    width: 100%;
    height: 1px;
    content: '';
    position: absolute;
    left: 0;
    top: 56px;
    background: #f5f5f5;
  }
}
</style>
