/*
 * @Description:
 * @Version: 1.0.0
 * @Author: 王召强
 * @Date: 2024-04-01 10:37:56
 * @LastEditors: 申文慧
 * @LastEditTime: 2024-05-23 14:56:56
 */
const responseAreaPlan = [
  {
    path: '/main/safety/responsibilityAreaPlan',
    name: 'ResponsibilityAreaPlan',
    meta: {
      name: '责任区域平面图',
      isAuth: true,
      authCode: 'web_pm_safety_responsibilityAreaPlan'
    },
    component: () => import('$safety/views/responseAreaPlan/index.vue')
  }
]

export default responseAreaPlan
