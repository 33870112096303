/*
 * @Description: 质量检查管理
 * @Version: 1.0.0
 * @Author: 李晨光
 * @Date: 2020-11-12 15:35:13
 * @LastEditors: 赵亮
 * @LastEditTime: 2024-04-23 10:30:37
 */
const inspections = [
  {
    path: '/main/quality/inspect',
    name: 'QualityInspection',
    meta: {
      // name: '质量检查',
    },
    component: () => import('$quality/views/qualityInspection/Index'),
    children: [
      {
        path: '/main/quality/inspect/generalMission',
        name: 'GeneralInspectionMission',
        meta: {
          name: '检查任务'
        },
        component: () => import('$quality/views/qualityInspection/generalInspectionMission/Index'),
        children: [
          {
            path: '/main/quality/inspect/generalMission/list',
            name: 'GeneralInspectionMissionList',
            meta: {
              name: '检查任务',
              keepAlive: true,
              doFresh: false,
              isAuth: true,
              authCode: 'web_pm_quality_inspect_task'
            },
            component: () =>
              import(
                '$quality/views/qualityInspection/generalInspectionMission/GeneralInspectionMission'
              )
          },
          {
            path: '/main/quality/inspect/generalMission/detail',
            name: 'GeneralInspectionMissionDetail',
            meta: {
              name: '检查任务详情',
              keepAlive: false,
              isAuth: true,
              authCode: 'web_pm_quality_inspect_task_detail'
            },
            component: () =>
              import(
                '$quality/views/qualityInspection/generalInspectionMission/GeneralInspectionMissionDetail'
              )
          },
          {
            path: '/main/quality/inspect/generalMission/record',
            name: 'GeneralInspectionRecord',
            meta: {
              name: '检查记录',
              keepAlive: false,
              isAuth: true,
              authCode: 'web_pm_quality_inspect_task_record'
            },
            component: () =>
              import(
                '$quality/views/qualityInspection/generalInspectionMission/GeneralInspectionRecord'
              )
          },
          {
            path: '/main/quality/inspect/generalMission/recordDetail',
            name: 'GeneralInspectionRecordDetail',
            meta: {
              name: '检查记录详情',
              isAuth: false,
              authCode: 'web_pm_quality_inspect_task_record'
            },
            component: () =>
              import(
                '$quality/views/qualityInspection/generalInspectionMission/GeneralInspectionRecordDetail'
              )
          },
          {
            path: '/main/quality/inspect/generalMission/add',
            name: 'AddGeneralInspectionMission',
            meta: {
              name: '新增检查任务',
              isAuth: false,
              authCode: 'web_pm_quality_inspect_task_add'
            },
            component: () =>
              import(
                '$quality/views/qualityInspection/generalInspectionMission/SetGeneralInspectionMission'
              )
          },
          {
            path: '/main/quality/inspect/generalMission/edit',
            name: 'EditGeneralInspectionMission',
            meta: {
              name: '编辑检查任务',
              isAuth: false,
              authCode: ''
            },
            component: () =>
              import(
                '$quality/views/qualityInspection/generalInspectionMission/SetGeneralInspectionMission'
              )
          }
        ]
      },
      {
        path: '/main/quality/inspect/record',
        name: 'InspectionRecord',
        meta: {
          name: '检查记录'
        },
        component: () => import('$quality/views/qualityInspection/inspectionRecords/Index'),
        children: [
          {
            path: '/main/quality/inspect/record/list',
            name: 'InspectionRecordList',
            meta: {
              name: '检查记录',
              isAuth: true,
              keepAlive: true,
              authCode: 'web_pm_quality_inspect_record'
            },
            component: () =>
              import('$quality/views/qualityInspection/inspectionRecords/InspectionRecords')
          },
          {
            path: '/main/quality/inspect/record/detail',
            name: 'InspectionRecordDetail',
            meta: {
              name: '检查记录详情',
              isAuth: true,
              authCode: 'web_pm_quality_inspect_record_detail'
            },
            component: () =>
              import('$quality/views/qualityInspection/inspectionRecords/InspectionRecordDetail')
          }
        ]
      },
      {
        path: '/main/quality/inspect/problem',
        name: 'ProblemRecord',
        meta: {
          name: '问题台账'
        },
        component: () => import('$quality/views/qualityInspection/problemRecords/Index'),
        children: [
          {
            path: '/main/quality/inspect/problem/list',
            name: 'ProblemRecordList',
            meta: {
              name: '问题台账',
              isAuth: true,
              keepAlive: true,
              authCode: 'web_pm_quality_inspect_problem'
            },
            component: () =>
              import('$quality/views/qualityInspection/problemRecords/ProblemRecords')
          },
          {
            path: '/main/quality/inspect/problem/detail',
            name: 'ProblemRecordDetail',
            meta: {
              name: '问题详情',
              isAuth: true,
              authCode: 'web_pm_quality_inspect_problem_detail'
            },
            component: () =>
              import('$quality/views/qualityInspection/problemRecords/ProblemRecordDetail')
          }
        ]
      },
      {
        path: '/main/quality/inspect/ticket',
        name: 'TicketRecord',
        meta: {
          name: '罚款单台账'
        },
        component: () => import('$quality/views/qualityInspection/ticketRecords/Index'),
        children: [
          {
            path: '/main/quality/inspect/ticket/list',
            name: 'TicketRecordList',
            meta: {
              name: '罚款单',
              isAuth: true,
              keepAlive: true,
              authCode: 'web_pm_quality_inspect_ticket'
            },
            component: () => import('$quality/views/qualityInspection/ticketRecords/TicketRecords')
          },
          {
            path: '/main/quality/inspect/ticket/detail',
            name: 'TicketRecordDetail',
            meta: {
              name: '罚款单详情',
              isAuth: true,
              authCode: 'web_pm_quality_inspect_ticket_detail'
            },
            component: () =>
              import('$quality/views/qualityInspection/ticketRecords/TicketRecordDetail')
          }
        ]
      },
      {
        path: '/main/quality/inspect/rectificationNotice',
        name: 'RectificationNoticeRecord',
        meta: {
          name: '整改通知单'
        },
        component: () => import('$quality/views/qualityInspection/inspectionRecords/Index'),
        children: [
          {
            path: '/main/quality/inspect/rectificationNotice/list',
            name: 'RectificationNoticeRecordList',
            meta: {
              name: '整改通知单',
              isAuth: true,
              keepAlive: true,
              authCode: 'web_pm_quality_inspect_rectification'
            },
            component: () =>
              import(
                '$quality/views/qualityInspection/rectificationNoticeRecords/RectificationNoticeRecords'
              )
          },
          {
            path: '/main/quality/inspect/rectificationNotice/detail',
            name: 'RectificationNoticeRecordDetail',
            meta: {
              name: '整改单详情',
              isAuth: false,
              authCode: 'web_pm_quality_inspect_rectification_detail'
            },
            component: () =>
              import(
                '$quality/views/qualityInspection/rectificationNoticeRecords/RectificationNoticeRecordDetail'
              )
          },
          {
            path: '/main/quality/inspect/rectificationNotice/report',
            name: 'RectificationNoticeRecordReport',
            meta: {
              name: '整改通知报告',
              isAuth: true,
              keepAlive: true,
              authCode: 'web_pm_quality_inspect_report'
            },
            component: () =>
              import(
                '$quality/views/qualityInspection/rectificationNoticeRecords/RectificationNoticeReport'
              )
          }
        ]
      },
      {
        path: '/main/quality/inspect/specialInspectionTask',
        name: 'SpecialInspectionTask',
        meta: {
          name: '专项检查任务'
        },
        component: () => import('$quality/views/qualityInspection/specialInspectionTask/Index'),
        children: [
          {
            path: '/main/quality/inspect/specialInspectionTask/list',
            name: 'SpecialInspectionTaskList',
            meta: {
              name: '专项检查任务',
              isAuth: true,
              keepAlive: true,
              authCode: 'web_pm_quality_inspect_special'
            },
            component: () =>
              import(
                '$quality/views/qualityInspection/specialInspectionTask/SpecialInspectionTaskList'
              )
          },
          {
            path: '/main/quality/inspect/specialInspectionTask/add',
            name: 'AddSpecialInspectionTask',
            meta: {
              name: '新增专项检查任务',
              isAuth: false,
              authCode: 'web_pm_quality_inspect_special_add'
            },
            component: () =>
              import(
                '$quality/views/qualityInspection/specialInspectionTask/SetSpecialInspectionTask'
              )
          },
          {
            path: '/main/quality/inspect/specialInspectionTask/edit',
            name: 'EditSpecialInspectionTask',
            meta: {
              name: '编辑专项检查任务',
              isAuth: false,
              authCode: 'web_pm_quality_inspect_special_edit'
            },
            component: () =>
              import(
                '$quality/views/qualityInspection/specialInspectionTask/SetSpecialInspectionTask'
              )
          },
          {
            path: '/main/quality/inspect/specialInspectionTask/see',
            name: 'SeeSpecialInspectionTask',
            meta: {
              name: '查看专项检查任务',
              isAuth: true,
              authCode: 'web_pm_quality_inspect_special_detail'
            },
            component: () =>
              import(
                '$quality/views/qualityInspection/specialInspectionTask/SetSpecialInspectionTask'
              )
          },
          {
            path: '/main/quality/inspect/specialInspectionTask/inspectionRecord',
            name: 'TaskInspectionRecord',
            meta: {
              name: '检查记录',
              isAuth: true,
              authCode: 'web_pm_quality_inspect_special_record'
            },
            component: () =>
              import('$quality/views/qualityInspection/specialInspectionTask/InspectionRecord')
          },
          {
            path: '/main/quality/inspect/specialInspectionTask/projectRecord',
            name: 'ProjectRecord',
            meta: {
              name: '项目记录',
              isAuth: false,
              authCode: 'web_pm_quality_inspect_special_record'
            },
            component: () =>
              import('$quality/views/qualityInspection/specialInspectionTask/ProjectRecord')
          },
          {
            path: '/main/quality/inspect/specialInspectionTask/projectRecordDetail',
            name: 'ProjectRecordDetail',
            meta: {
              name: '项目记录详情',
              isAuth: false,
              authCode: 'web_pm_quality_inspect_special_record'
            },
            component: () =>
              import('$quality/views/qualityInspection/specialInspectionTask/RecordDetails')
          },
          {
            path: '/main/quality/inspect/specialInspectionTask/inspectRecordDetail',
            name: 'InspectRecordDetail',
            meta: {
              name: '检查记录详情',
              isAuth: false,
              authCode: 'web_pm_quality_inspect_special_record'
            },
            component: () =>
              import('$quality/views/qualityInspection/specialInspectionTask/RecordDetails')
          },
          {
            path: '/main/quality/marketingFill/biddingInformation',
            name: 'biddingInformation',
            meta: {
              name: '年度计划中标额管理',
              isAuth: false,
              authCode: 'web_pm_sales_management_target_manage'
            },
            component: () => import('$quality/views/marketingFill/biddingInformation/Index')
          },
          {
            path: '/main/quality/marketingFill/biddingManagement',
            name: 'biddingManagement',
            meta: {
              name: '中标信息台账',
              isAuth: false,
              authCode: 'web_pm_sales_management_biddingManagement'
            },
            component: () => import('$quality/views/marketingFill/biddingManagement/Index')
          },
          {
            path: '/main/quality/marketingFill/biddingManagement/ImportBidding',
            name: 'ImportBidding',
            meta: {
              name: '中标信息台账导入',
              isAuth: false,
              authCode: ''
            },
            component: () => import('$quality/views/marketingFill/biddingManagement/ImportBidding')
          }
        ]
      },
      {
        path: '/main/quality/inspectScore',
        name: 'InspectScore',
        meta: {
          name: '质量检查评分',
          isAuth: false,
          keepAlive: true,
          authCode: 'web_pm_quality_inspect_InspectScore'
        },
        component: () => import('$quality/views/qualityInspection/ticketRecords/InspectScore')
      }
    ]
  }
]
export default inspections
