/*
 * @Description:管理流程类路由
 * @Version: 1.0.0
 * @Author: 杜凡
 * @Date: 2022-03-29 10:21:33
 * @LastEditors: 赵亮
 * @LastEditTime: 2024-04-26 14:17:15
 */
import routerName from '../config/routerName.config'

const manageMenuRoute = [
  //危大工程=============================================
  {
    //危大工程管理
    path: '/dangerousEngineering/dangerousEngineeringAdministration',
    name: 'DangerousEngineeringAdministration',
    component: () =>
      import('$safety/views/dangerousEngineering/DangerousEngineeringAdministration'),
    meta: {
      name: routerName('DangerousEngineeringAdministration'),
      isAuth: true,
      authCode: 'web_pm_safety_process_engineering'
    }
  },
  {
    //新增危大工程
    path: '/dangerousEngineering/addDangerousEngineering',
    name: 'AddDangerousEngineering',
    component: () => import('$safety/views/dangerousEngineering/AddDangerousEngineering'),
    meta: {
      name: '新增',
      isAuth: true,
      authCode: 'web_pm_safety_process_engineering_passed_add'
    }
  },
  {
    //编辑危大工程未通过
    path: '/dangerousEngineering/failedEditDangerousEngineering',
    name: 'failedEditDangerousEngineering',
    component: () => import('$safety/views/dangerousEngineering/AddDangerousEngineering'),
    meta: {
      name: '编辑',
      isAuth: true,
      authCode: 'web_pm_safety_process_engineering_failed_edit'
    }
  },
  {
    //编辑危大工程审核中
    path: '/dangerousEngineering/processEditDangerousEngineering',
    name: 'processEditDangerousEngineering',
    component: () => import('$safety/views/dangerousEngineering/AddDangerousEngineering'),
    meta: {
      isAuth: true,
      name: '编辑',
      authCode: 'web_pm_safety_process_engineering_process_edit'
    }
  },

  //危险作业=============================================
  {
    //危险作业管理
    path: '/hazardousOperation/dangerousOperation',
    name: 'DangerousOperation',
    component: () => import('$safety/views/hazardousOperation/DangerousOperation'),
    meta: {
      name: routerName('DangerousOperation'),
      isAuth: true,
      keepAlive: true,
      authCode: 'web_pm_safety_task_task'
    }
  },
  {
    //新增危险作业
    path: '/dangerousEngineering/addDangerousOperation',
    name: 'AddDangerousOperation',
    component: () => import('$safety/views/hazardousOperation/EditDangerousOperation'),
    meta: {
      isAuth: true,
      name: '新增',
      authCode: 'web_pm_safety_task_task_add'
    }
  },
  {
    //编辑危险作业
    path: '/dangerousEngineering/editDangerousOperation',
    name: 'EditDangerousOperation',
    component: () => import('$safety/views/hazardousOperation/EditDangerousOperation'),
    meta: {
      isAuth: true,
      name: '编辑',
      authCode: 'web_pm_safety_task_task_edit'
    }
  },
  {
    //危险作业详情
    path: '/dangerousEngineering/hazardousOperationDetails',
    name: 'hazardousOperationDetails',
    component: () => import('$safety/views/hazardousOperation/hazardousOperationDetails'),
    meta: {
      isAuth: true,
      name: '危险作业详情',
      authCode: 'web_pm_safety_task_task_detail'
    }
  },

  //资料管理=============================================
  {
    path: '/dataManagement',
    name: 'dataManagement',
    meta: {
      name: '资料管理',
      isAuth: true,
      authCode: 'web_pm_collaboration_dataManagement',
      id: ''
    },
    component: () => import('$safety/views/dataManagement/Index')
  },

  //验收管理=============================================
  {
    //验收管理
    path: '/acceptance/acceptance',
    name: 'Acceptance',
    component: () => import('$safety/views/acceptance/Acceptance'),
    meta: {
      name: '验收管理',
      isAuth: true,
      keepAlive: true,
      authCode: 'web_pm_safety_accept_accept'
    }
  },
  {
    //验收管理详情
    path: '/acceptance/detailAcceptance',
    name: 'DetailAcceptance',
    component: () => import('$safety/views/acceptance/DetailAcceptance'),
    meta: {
      isAuth: true,
      name: '详情',
      authCode: 'web_pm_safety_accept_accept_detail'
    }
  },

  //安全测试=============================================
  {
    //安全测试
    path: '/safetyActivities/securityTest',
    name: 'SecurityTest',
    component: () => import('$safety/views/safetyActivities/securityTest/SecurityTest'),
    meta: {
      name: '安全测试',
      isAuth: true,
      authCode: 'web_pm_safety_process_safetyActivitie'
    }
  },
  {
    //新增安全测试
    path: '/safetyActivities/addSecurityTest',
    name: 'AddSecurityTest',
    component: () => import('$safety/views/safetyActivities/securityTest/AddSecurityTest'),
    meta: {
      isAuth: true,
      name: '新增',
      authCode: 'web_pm_safety_process_safetyActivitie_add'
    }
  },
  // 作业管理===========
  {
    //作业管理
    path: '/jobManage/jobManage',
    name: 'jobManage',
    component: () => import('$safety/views/jobManage/JobManage'),
    meta: {
      isAuth: true,
      name: '作业管理',
      authCode: 'web_pm_safety_jobManage'
    }
  },
  {
    //详情
    path: '/jobManage/jobManage/detail',
    name: 'jobManageDetail',
    component: () => import('$safety/views/jobManage/Detail'),
    meta: {
      isAuth: true,
      name: '详情',
      authCode: 'web_pm_safety_jobManage_detail'
    }
  }
]

export default manageMenuRoute
