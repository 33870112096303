/*
 * @Description: 
 * @Version: 1.0.0
 * @Author: 任笠
 * @Date: 2022-08-15 10:04:17
 * @LastEditors: 任笠
 * @LastEditTime: 2022-08-15 10:58:52
 */
import KFormItem from "./index.vue";

KFormItem.install = function(Vue) {
  Vue.component(KFormItem.name, KFormItem);
};

export default KFormItem;
