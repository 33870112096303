/*
 * @Description:报表填报类模块路由
 * @Version: 1.0.0
 * @Author: 杜凡
 * @Date: 2022-03-29 10:21:33
 * @LastEditors: 赵亮
 * @LastEditTime: 2023-03-29 15:43:29
 */

const reportFillingRoute = [
  //安全调度周报及体系报告=============================================
  {
    //安全调度周报表
    path: '/weeklySafety',
    name: 'WeeklySafety',
    meta: {
      name: '安全调度周报表',
      isAuth: true,
      authCode: 'web_pm_safety_reportForm_weekly'
    },
    component: () => import('$safety/views/weeklySafety/WeeklySafety')
  },
  {
    //新增安全调度周报表
    path: '/addWeeklySafety',
    name: 'AddWeeklySafety',
    meta: {
      name: '新增',
      isAuth: true,
      authCode: 'web_pm_safety_reportForm_weekly_add'
    },
    component: () => import('$safety/views/weeklySafety/AddWeeklySafety')
  },
  {
    //编辑安全调度周报表
    path: '/editWeeklySafety',
    name: 'EditWeeklySafety',
    meta: {
      name: '编辑',
      isAuth: true,
      authCode: 'web_pm_safety_reportForm_weekly_edit'
    },
    component: () => import('$safety/views/weeklySafety/AddWeeklySafety')
  },
  {
    //重新填报安全调度周报表
    path: '/againAddWeeklySafety',
    name: 'AgainAddWeeklySafety',
    meta: {
      name: '重新填报',
      isAuth: true,
      authCode: 'web_pm_safety_reportForm_weekly_againAdd',
      id: ''
    },
    component: () => import('$safety/views/weeklySafety/AddWeeklySafety')
  },
  {
    //详情安全调度周报表
    path: '/detailWeeklySafety',
    name: 'DetailWeeklySafety',
    meta: {
      name: '详情',
      isAuth: true,
      authCode: 'web_pm_safety_reportForm_weekly_detail',
      id: ''
    },
    component: () => import('$safety/views/weeklySafety/DetailWeeklySafety')
  },
  {
    //安全体系报告
    path: '/systemReport',
    name: 'SystemReport',
    component: () => import('$safety/views/systemReport/Index'),
    meta: {
      name: '安全体系报告',
      isAuth: true,
      authCode: 'web_pm_safety_systemReport_systemReport'
    }
  },

  //填报类=============================================
  {
    //消防安全
    path: '/fireSafety/fireSafety',
    name: 'FireSafety',
    component: () => import('$safety/views/fireSafety/FireSafety'),
    meta: {
      name: '消防安全管理',
      isAuth: true,
      authCode: 'web_pm_safety_process_fire'
    }
  },
  {
    //消防安全公司级详情
    path: '/fireSafety/fireSafetyDetail',
    name: 'FireSafetyDetail',
    component: () => import('$safety/views/fireSafety/FireSafety'),
    meta: {
      isAuth: true,
      name: '详情',
      authCode: 'web_pm_safety_process_fire_detail'
    }
  },
  {
    //行为安全之星
    path: '/fillingPage/securityBehavior',
    name: 'securityBehavior',
    component: () => import('$safety/views/fillingPage/securityBehavior'),
    meta: {
      name: '行为安全之星',
      isAuth: true,
      authCode: 'web_pm_safety_process_securityBehavior'
    }
  },
  {
    //安全生产责任书考核
    path: '/fillingPage/SecurityResponsibilityAssessment',
    name: 'SecurityResponsibilityAssessment',
    component: () => import('$safety/views/fillingPage/securityResponsibilityAssessment'),
    meta: {
      name: '安全生产责任书考核',
      isAuth: true,
      authCode: 'web_pm_safety_process_securityResponsibilityAssessment'
    }
  },
  {
    //应急管理
    path: '/fillingPage/urgentAdmin',
    name: 'urgentAdmin',
    component: () => import('$safety/views/fillingPage/urgentAdmin'),
    meta: {
      name: '应急管理',
      isAuth: true,
      authCode: 'web_pm_safety_process_urgentAdmin'
    }
  },
  {
    //劳务人员教育
    path: '/fillingPage/PersonnelEducation',
    name: 'PersonnelEducation',
    component: () => import('$safety/views/fillingPage/PersonnelEducation'),
    meta: {
      name: '劳务人员教育',
      id: ''
    }
  },
  {
    //危险作业 填报，废弃
    path: '/fillingPage/dangerousOperation',
    name: 'dangerousOperation',
    component: () => import('$safety/views/fillingPage/dangerousOperation'),
    meta: {
      name: '危险作业'
    }
  }
]

export default reportFillingRoute
