/*
 * @Description: 路由名称配置
 * @Version: 1.0.0
 * @Author: 赵亮
 * @Date: 2021-10-20 09:24:59
 * @LastEditors: 杜凡
 * @LastEditTime: 2021-12-09 16:14:48
 */
const routerName = path => {
  const routerNameObj = {
    standard: {
      MajorHazardInstallationsSetUp: '重大危险源设置',
      DecideHazardInstallationsSetUp: '重大危险源级别设置',
      DangerousOperation: '危险作业管理',
      MajorHazardInstallations: '重大危险源监管',
      InspectionTask: '检查任务管理',
      InspectionRecord: '检查台账',
      FormManagement: '隐患排查表单',
      RiskLibs: '风险清单库设置',
      RiskLibsImport: '风险清单库设置',
      DangerousEngineeringAdministration: '危大工程管理',
      DangerousProjectsSetUp: '危大工程设置'
    }, // 标版
    bjnf: {
      MajorHazardInstallationsSetUp: '重大风险工程类别设置',
      DecideHazardInstallationsSetUp: '重大危险源判定设置',
      DangerousOperation: '危险作业',
      MajorHazardInstallations: '重大风险工程管理',
      InspectionTask: '检查任务',
      InspectionRecord: '检查记录',
      FormManagement: '表单管理',
      RiskLibs: '风险清单库',
      RiskLibsImport: '风险清单库',
      DangerousEngineeringAdministration: '一告知双验收',
      DangerousProjectsSetUp: '一告知双验收设置'
    } // 八局南方
  }
  let routerName = routerNameObj[process.env.VUE_APP_VERSION][path]
  return routerName
}
export default routerName
