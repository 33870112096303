<!--
 * @Description:电子签名组件
 * @Version: 1.0.0
 * @Author: 任笠
 * @Date: 2023-03-21 19:08:05
 * @LastEditors: 王召强
 * @LastEditTime: 2024-07-16 17:26:55
-->
<template>
  <div id='ElectronicSignature'>
    <div class="d-flex align-center">
      <div ref="canvasHW"
           class="canvasBox d-flex justify-center p-10">
        <canvas ref="canvasF"
                height="200"
                width="400"
                @touchstart="touchStart"
                @touchmove="touchMove"
                @touchend="touchEnd"
                @mousedown="mouseDown"
                @mousemove="mouseMove"
                @mouseup="mouseUp">
        </canvas>
      </div>
      <div class="signature-btnArea m-l-16">
        <a-button type="primary"
                  ghost
                  @click.native.prevent="handleOverwrite()">重签</a-button>
      </div>
    </div>

  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  name: 'ElectronicSignature',
  props: {
    defaultImageUrl: {
      type: String,
      default: ''
    } // 需要转换的签名 url
  },
  data() {
    return {
      points: [],
      canvasTxt: null,
      stage_info: [],
      startX: 0,
      startY: 0,
      moveY: 0,
      moveX: 0,
      isDown: false,
      strokeStyle: '#000',
      lineWidth: 2,
      triggered: false
    }
  },
  mounted() {
    this.initCanvas()
  },
  watch: {
    points: {
      handler: function (val, oldVal) {
        this.$emit('signChange', val.length)
      },
      deep: true,
      immediate: true
    },
    /**
     * 监听是否有回显的签名
     */
    defaultImageUrl: {
      handler: function (val, oldVal) {
        if (val && val?.length && !this.triggered) {
          this.triggered = true
          this.getBase64(val)
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    /**
     * 图片url转buse64
     */
    getBase64(imgUrl) {
      let _this = this
      window.URL = window.URL || window.webkitURL
      var xhr = new XMLHttpRequest()
      xhr.open('get', imgUrl, true)
      // 至关重要
      xhr.responseType = 'blob'
      xhr.onload = function () {
        if (this.status == 200) {
          //得到一个blob对象
          var blob = this.response
          // 至关重要
          let oFileReader = new FileReader()
          oFileReader.onloadend = function (e) {
            // 此处拿到的已经是 base64的图片了
            let base64File = e.target.result
            if (base64File) {
              _this.reveal(base64File)
            }
          }
          oFileReader.readAsDataURL(blob)
        }
      }
      xhr.send()
    },
    /**
     * canvas回显
     * @param base64File 64文件
     */
    reveal(base64File) {
      let canvas = this.$refs.canvasF
      let c1 = canvas.getContext('2d')
      var img = new Image()
      img.onload = function () {
        // 当图片加载完毕后，将其绘制到canvas上
        c1.drawImage(img, 0, 0, canvas.width, canvas.height)
      }
      img.src = base64File
      // 默认给一条数据，以为有点
      this.points = [{ x: 0, y: 0 }]
    },
    /**
     * 初始化Canvas
     */
    initCanvas() {
      let canvas = this.$refs.canvasF
      // 获取画布的高度
      canvas.height = this.$refs.canvasHW.offsetHeight - 20
      // 获取画布的宽度
      canvas.width = this.$refs.canvasHW.offsetWidth - 20
      // 创建 context 对象
      this.canvasTxt = canvas.getContext('2d')
      this.stage_info = canvas.getBoundingClientRect()
      // 画布颜色
      this.canvasTxt.fillStyle = '#fff'
      this.canvasTxt.fillRect(0, 0, 400, 200)
    },
    /**
     * 鼠标按下事件 - 准备绘画
     * @param ev 鼠标按下事件返回参数
     */
    mouseDown(ev) {
      ev = ev || event
      ev.preventDefault()
      if (ev) {
        let obj = {
          x: ev.offsetX,
          y: ev.offsetY
        }
        this.startX = obj.x
        this.startY = obj.y
        this.canvasTxt.beginPath()
        this.canvasTxt.moveTo(this.startX, this.startY)
        this.canvasTxt.lineTo(obj.x, obj.y)
        this.canvasTxt.stroke()
        this.canvasTxt.closePath()
        this.points.push(obj)
        this.isDown = true
      }
    },
    /**
     * 触摸开始事件 - 准备绘画
     * @param ev 触摸开始事件返回参数
     */
    touchStart(ev) {
      ev = ev || event
      ev.preventDefault()
      if (ev.touches.length == 1) {
        let obj = {
          x: ev.targetTouches[0].clientX - this.stage_info.left,
          y: ev.targetTouches[0].clientY - this.stage_info.top
        }
        this.startX = obj.x
        this.startY = obj.y
        this.canvasTxt.beginPath()
        this.canvasTxt.moveTo(this.startX, this.startY)
        this.canvasTxt.lineTo(obj.x, obj.y)
        this.canvasTxt.stroke()
        this.canvasTxt.closePath()
        this.points.push(obj)
      }
    },
    /**
     * 鼠标移动事件 - 开始绘画
     * @param ev 鼠标移动事件返回参数
     */
    mouseMove(ev) {
      ev = ev || event
      ev.preventDefault()
      if (this.isDown) {
        let obj = {
          x: ev.offsetX,
          y: ev.offsetY
        }
        this.moveY = obj.y
        this.moveX = obj.x
        this.canvasTxt.strokeStyle = this.strokeStyle
        this.canvasTxt.lineWidth = this.lineWidth
        this.canvasTxt.beginPath()
        this.canvasTxt.moveTo(this.startX, this.startY)
        this.canvasTxt.lineTo(obj.x, obj.y)
        this.canvasTxt.stroke()
        this.canvasTxt.closePath()
        this.startY = obj.y
        this.startX = obj.x
        this.points.push(obj)
      }
    },
    /**
     * 触摸移动事件 - 开始绘画
     * @param ev 触摸移动事件返回参数
     */
    touchMove(ev) {
      ev = ev || event
      ev.preventDefault()
      if (ev.touches.length == 1) {
        let obj = {
          x: ev.targetTouches[0].clientX - this.stage_info.left,
          y: ev.targetTouches[0].clientY - this.stage_info.top
        }
        this.moveY = obj.y
        this.moveX = obj.x
        // 设置线条颜色
        this.canvasTxt.strokeStyle = this.strokeStyle
        // 设置线条宽度
        this.canvasTxt.lineWidth = this.lineWidth
        // 绘制开始路径
        this.canvasTxt.beginPath()
        // 定义线条开始坐标
        this.canvasTxt.moveTo(this.startX, this.startY)
        // 定义线条结束坐标
        this.canvasTxt.lineTo(obj.x, obj.y)
        // 绘制线条
        this.canvasTxt.stroke()
        this.canvasTxt.closePath()
        this.startY = obj.y
        this.startX = obj.x
        this.points.push(obj)
      }
    },
    /**
     * 松开鼠标事件 - 停止绘画
     * @param ev 松开鼠标事件返回参数
     */
    mouseUp(ev) {
      ev = ev || event
      ev.preventDefault()
      if (ev) {
        let obj = {
          x: ev.offsetX,
          y: ev.offsetY
        }
        this.canvasTxt.beginPath()
        this.canvasTxt.moveTo(this.startX, this.startY)
        this.canvasTxt.lineTo(obj.x, obj.y)
        this.canvasTxt.stroke()
        this.canvasTxt.closePath()
        this.points.push(obj)
        this.points.push({ x: -1, y: -1 })
        this.isDown = false
      }
      this.handleSubmit()
    },
    /**
     * 触摸结束事件 - 停止绘画
     * @param ev 触摸结束事件返回参数
     */
    touchEnd(ev) {
      ev = ev || event
      ev.preventDefault()
      if (ev.touches.length == 1) {
        let obj = {
          x: ev.targetTouches[0].clientX - this.stage_info.left,
          y: ev.targetTouches[0].clientY - this.stage_info.top
        }
        this.canvasTxt.beginPath()
        this.canvasTxt.moveTo(this.startX, this.startY)
        this.canvasTxt.lineTo(obj.x, obj.y)
        this.canvasTxt.stroke()
        this.canvasTxt.closePath()
        this.points.push(obj)
        this.points.push({ x: -1, y: -1 })
      }
    },
    /**
     * 重写
     */
    handleOverwrite() {
      this.canvasTxt.clearRect(0, 0, this.$refs.canvasF.width, this.$refs.canvasF.height)
      this.points = []

      this.$emit('content-change', { signature: '' })
    },
    /**
     * 提交
     * @param values 点击提交返回参数
     */
    handleSubmit() {
      let signature = this.points.length == 0 ? undefined : this.$refs.canvasF.toDataURL()
      this.$emit('content-change', { signature: signature })
      // this.handleOverwrite()
    }
  }
}
</script>
<style lang='less' >
//@import url(); 引入公共css类
#ElectronicSignature {
  // .canvas-signature {
  //   width: 300px;
  //   height: 80px;
  // }
  .canvasBox {
    width: 420px;
    height: 220px;
    border: 1px solid #ccc;
  }
}
</style>