/*
 * @Description:
 * @Version: 1.0.0
 * @Author: 任笠
 * @Date: 2022-03-29 16:03:52
 * @LastEditors: 任笠
 * @LastEditTime: 2022-03-29 17:14:00
 */
import routerName from '../config/routerName.config'
export const protectedRoute = [
  {
    path: '/main/guard/mechanical/towerCraneMonitor',
    name: 'TowerCraneMonitor',
    meta: {
      // name: isSpm ? '塔机监测' : '塔机监测',
      name: routerName('towerCraneMonitor'),
      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_tower_towerCraneMonitor'
    },
    component: () => import('$mechanical/views/specialEquipment/towerCraneMonitor/Index')
  },
  {
    path: '/main/guard/mechanical/tower/EquipInspection',
    name: 'EquipInspection',
    component: () =>
      import('$mechanical/views/specialEquipment/equipInspection/EquipInspection.vue'),
    meta: {
      // name: isSpm ? '检查管理' : '机械检查',
      name: '塔机检查管理',
      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_tower_check'
    },
    children: []
  },
  {
    path: '/main/guard/mechanical/EquipInspection/planDetial',
    name: 'GuardEquipInspectionPlanDetial',
    component: () => import('$mechanical/views/specialEquipment/equipInspection/PlanDetial.vue'),
    meta: {
      // name: isSpm ? '详情' : '机械检查详情',
      name: routerName('EquipInspectionPlanDetial'),
      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_tower_check_detail'
    },
    children: []
  },
  {
    path: '/main/guard/mechanical/tower/HiddenDanger',
    name: 'HiddenDanger',
    meta: {
      // name: isSpm ? '隐患台账' : '机械隐患',
      name: '塔机隐患台账',
      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_tower_HiddenDanger'
    },
    component: () => import('$mechanical/views/specialEquipment/hiddenDanger/HiddenDanger.vue')
  },
  {
    path: '/main/guard/mechanical/HiddenDanger/detail',
    name: 'GuardHiddenDangerDetail',
    meta: {
      // name: isSpm ? '详情' : '机械隐患详情',
      name: routerName('HiddenDangerDetail'),

      keepAlive: false,
      isAuth: false,
      authCode: 'web_pm_tower_HiddenDanger_detail'
    },
    component: () => import('$mechanical/views/specialEquipment/hiddenDanger/HiddenDangerDetail')
  },
  {
    path: '/main/guard/mechanical/tower/equipmentAcceptance',
    name: 'EquipmentAcceptance',
    component: () =>
      import('$mechanical/views/specialEquipment/equipmentAcceptance/EquipmentAcceptance.vue'),
    meta: {
      // name: isSpm ? '验收管理' : '机械验收',
      name: '塔机验收管理',
      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_tower_acceptance'
    }
  },
  {
    path: '/main/guard/mechanical/equipmentAcceptance/detail',
    name: 'GuardEquipmentAcceptanceDetail',
    component: () =>
      import(
        '$mechanical/views/specialEquipment/equipmentAcceptance/EquipmentAcceptanceDetail.vue'
      ),
    meta: {
      // name: isSpm ? '详情' : '机械验收详情',
      name: routerName('EquipmentAcceptanceDetail'),

      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_tower_acceptance_detail'
    }
  },
  {
    path: '/main/guard/mechanical/tower/equipmentRepair',
    name: 'EquipmentRepair',
    component: () =>
      import('$mechanical/views/specialEquipment/equipmentRepair/EquipmentRepair.vue'),
    meta: {
      // name: isSpm ? '维修台账' : '机械维修',
      name: '塔机维修台账',
      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_tower_repairAccount'
    }
  },
  {
    path: '/main/guard/mechanical/equipmentRepair/detail',
    name: 'GuardEquipmentRepairDetail',
    component: () =>
      import('$mechanical/views/specialEquipment/equipmentRepair/EquipmentRepairDetail.vue'),
    meta: {
      // name: isSpm ? '详情' : '机械维修详情',
      name: routerName('EquipmentRepairDetail'),

      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_tower_repairAccount_detail'
    }
  },
  {
    path: '/main/guard/mechanical/tower/equipmentMaintain',
    name: 'EquipmentMaintain',
    component: () =>
      import('$mechanical/views/specialEquipment/equipmentMaintain/EquipmentMaintain.vue'),
    meta: {
      // name: isSpm ? '保养管理' : '机械保养',
      name: '塔机保养管理',
      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_tower_maintain'
    }
  },
  {
    path: '/main/guard/mechanical/equipmentMaintain/detail',
    name: 'GuardEquipmentMaintainDetail',
    component: () =>
      import('$mechanical/views/specialEquipment/equipmentMaintain/EquipmentMaintainDetail.vue'),
    meta: {
      // name: isSpm ? '详情' : '机械保养详情',
      name: routerName('EquipmentMaintainDetail'),

      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_tower_maintain_detail'
    }
  },
  {
    path: '/main/guard/mechanical/tower/equipmentManagement',
    name: 'EquipmentManagement',
    component: () =>
      import('$mechanical/views/specialEquipment/equipmentManagement/EquipmentManagement.vue'),
    meta: {
      name: '塔机管理',
      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_tower_mechanics'
    }
  },
  {
    path: '/main/guard/mechanical/tower/equipmentManagement/add',
    name: 'GuardAddEquipmentMangeTower',
    component: () =>
      import('$mechanical/views/specialEquipment/equipmentManagement/AddEquipmentMange.vue'),
    meta: {
      name: '新增',
      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_tower_mechanics_add'
    }
  },
  {
    path: '/main/guard/mechanical/equipmentManagement/add',
    name: 'GuardAddEquipmentMange',
    component: () =>
      import('$mechanical/views/specialEquipment/equipmentManagement/AddEquipmentMange.vue'),
    meta: {
      name: '新增',

      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_tower_mechanics_add'
    }
  },
  {
    path: '/main/guard/mechanical/equipmentManagement/edit',
    name: 'GuardEditEquipmentMange',
    component: () =>
      import('$mechanical/views/specialEquipment/equipmentManagement/EditEquipmentMange.vue'),
    meta: {
      name: '编辑',

      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_tower_mechanics_edit'
    }
  },
  {
    path: '/main/guard/equip/specEquip/tower/specialWorker',
    name: 'SpecialWorker',
    component: () => import('$mechanical/views/specialEquipment/specialWorker/SpecialWorker.vue'),
    meta: {
      // name: isSpm ? '人员管理' : '特种人员',
      name: '特种人员管理',
      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_tower_users'
    },
    children: []
  },
  {
    path: '/main/guard/mechanical/SpecialWorker/detail',
    name: 'GuardSpecialWorkerDetail',
    meta: {
      name: '详情',
      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_tower_users_detail'
    },
    component: () => import('$mechanical/views/specialEquipment/specialWorker/SpecialWorkerDetail')
  },
  {
    path: '/main/guard/equip/specEquip/tower/mechanicalAdd',
    name: 'GuardAddLaborRosterMechanicalTower',
    component: () => import('$mechanical/views/specialEquipment/specialWorker/AddLaborRoster.vue'),
    meta: {
      // name: isSpm ? '新增' : '新增特种人员',
      name: routerName('AddLaborRosterMechanical'),

      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_tower_users_add'
    }
  },
  {
    path: '/main/guard/equip/specEquip/mechanicalAdd',
    name: 'GuardAddLaborRosterMechanical',
    component: () => import('$mechanical/views/specialEquipment/specialWorker/AddLaborRoster.vue'),
    meta: {
      // name: isSpm ? '新增' : '新增特种人员',
      name: routerName('AddLaborRosterMechanical'),

      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_tower_users_add'
    }
  },
  {
    path: '/main/guard/equip/specEquip/tower/mechanicalEdit',
    name: 'GuardEditLaborRosterMechanicalTower',
    component: () => import('$mechanical/views/specialEquipment/specialWorker/AddLaborRoster.vue'),
    meta: {
      // name: isSpm ? '编辑' : '编辑特种人员',
      name: routerName('EditLaborRosterMechanical'),

      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_tower_users_edit'
    }
  },
  {
    path: '/main/guard/equip/specEquip/mechanicalEdit',
    name: 'GuardEditLaborRosterMechanical',
    component: () => import('$mechanical/views/specialEquipment/specialWorker/AddLaborRoster.vue'),
    meta: {
      // name: isSpm ? '编辑' : '编辑特种人员',
      name: routerName('EditLaborRosterMechanical'),

      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_tower_users_edit'
    }
  },
  {
    path: '/main/guard/mechanical/SpecialWorker/edit',
    name: 'GuardSpecialWorkerEdit',
    meta: {
      name: '资料管理',
      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_tower_users_data'
    },
    component: () => import('$mechanical/views/specialEquipment/specialWorker/SpecialWorkerAdd')
  },
  {
    path: '/main/guard/mechanical/tower/KeyLib',
    name: 'KeyLib',
    meta: {
      // name: isSpm ? '关键资料管理' : '关键资料',
      name: '关键资料管理',
      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_tower_keyInfo'
    },
    component: () => import('$mechanical/views/specialEquipment/keyLib/KeyLib')
  },
  {
    path: '/main/guard/mechanical/tower/KeyLib/add',
    name: 'GuardAddKeyLibTower',
    meta: {
      name: '新增',

      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_tower_keyInfo_add'
    },
    component: () => import('$mechanical/views/specialEquipment/keyLib/addKeyLib')
  },
  {
    path: '/main/guard/mechanical/KeyLib/add',
    name: 'GuardAddKeyLib',
    meta: {
      name: '新增',

      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_tower_keyInfo_add'
    },
    component: () => import('$mechanical/views/specialEquipment/keyLib/addKeyLib')
  },
  {
    path: '/main/guard/mechanical/tower/KeyLib/edit',
    name: 'GuardEditKeyLibTower',
    meta: {
      name: '编辑',

      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_tower_keyInfo_edit'
    },
    component: () => import('$mechanical/views/specialEquipment/keyLib/addKeyLib')
  },
  {
    path: '/main/guard/mechanical/KeyLib/edit',
    name: 'GuardEditKeyLib',
    meta: {
      name: '编辑',

      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_tower_keyInfo_edit'
    },
    component: () => import('$mechanical/views/specialEquipment/keyLib/addKeyLib')
  }
]
