/*
 * @Description: 监理平台
 * @Version: 1.0.0
 * @Author: 李晨光
 * @Date: 2020-11-02 11:23:34
 * @LastEditors: 申文慧
 * @LastEditTime: 2024-03-26 10:02:19
 */

import rectificationForm from './rectificationForm' //监理平台
export const protectedRoute = [
  {
    path: '/main/supervisionPlatform',
    name: 'supervisionPlatform',
    component: () => import(/*/* : "监理平台" */ '$supervisionPlatform/views/Index'),
    children: []
  },
  ...rectificationForm
]
