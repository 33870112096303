/*
 * @Description: 安全
 * @Version: 1.0.0
 * @Author: 李晨光
 * @Date: 2020-11-02 11:23:34
 * @LastEditors: 王召强
 * @LastEditTime: 2023-08-17 11:33:59
 */

export const protectedRoute = [
  {
    path: '/main/completionData',
    component: () => import(/*/* : "完工资料页面入口" */ '$completionData/views/Index'),
    children: [
      {
        path: '/completionData/dataManage',
        name: 'completionData/dataManage',
        meta: {
          name: '资料管理',
          keepAlive: true,
          isAuth: true,
          authCode: 'web_pm_completionData_dataManage',
          id: ''
        },
        component: () => import('$completionData/views/dataManage/Index')
      },
      {
        path: '/completionData/dataManage/detailsRecord',
        name: 'DataManageDetailsRecord',
        meta: {
          name: '详细记录',
          isAuth: false,
          authCode: 'web_pm_completionData_dataManage',
          id: ''
        },
        component: () => import('$completionData/views/dataManage/DetailsRecord')
      },
      {
        path: '/completionData/approve',
        name: 'completionData/approve',
        meta: {
          name: '审批管理',
          keepAlive: true,
          isAuth: true,
          authCode: 'web_pm_completionData_approve',
          id: ''
        },
        component: () => import('$completionData/views/approve/Index')
      }
    ]
  }
]
