<!--
 * @Description: 上栏导航--新版
 * @Version: 1.0.0
 * @Author: 李帅
 * @Date: 2020-11-25 09:37:40
 * @LastEditors: 王召强
 * @LastEditTime: 2024-05-31 11:45:05
-->
<comment>
# 组件注释
</comment>
<template>
  <a-layout-header id="Toolbar"
                   class="pos-relative d-flex justify-space-between">
    <div class="d-flex align-center">
      <div class="menu-icon pointer"
           :style="{ background: color || '#1890FF' }"
           @click="switchMenu">
        <a-icon v-if="!isShowMenu"
                type="menu"
                class="icon" />
        <a-icon v-else
                type="close"
                class="icon" />
      </div>
      <img style="height: 28px; margin-left: 16px"
           :src="logo ? logo : Util.hostSet().logo"
           v-if="tripartiteName!='智能安全管理平台'"
           alt=""
           class="float-left pointer"
           @click="clickRoute" />
      <div class="m-l-16 workbench pointer"
           type="normal"
           @click="clickRoute">
        <a-button icon="home"> 工作台 </a-button>
      </div>
    </div>
    <!-- 右侧工作台 -->
    <div class="d-flex align-center m-r-10"
         style="height: 48px">
      <a-button v-if="isDev"
                type="link"
                :loading="skipLoading"
                @click="skip">低代码平台</a-button>
      <a-popover trigger="click"
                 placement="bottomLeft">
        <div class="d-flex align-center pointer p-l-12 p-r-12 m-r-8 toggleSwitch"
             @click="toggleSwitch">
          <span style="color: rgba(255, 255, 255, 0.6); line-height: 40px">当前组织：</span>
          <div class="over"
               :title="curr_org && curr_org.shortName ? curr_org.shortName : curr_org.name"
               style="max-width: 500px; line-height: 40px; color: #fff">
            {{ curr_org && curr_org.shortName ? curr_org.shortName : curr_org.name || '--' }}
          </div>
          <img :src="require('../../assets/image/Icon-Fill-Caret-Left@3x.png')"
               alt=""
               class="m-l-4 organize"
               :style="{ transform: orgReload ? 'rotate(180deg)' : 'rotate(0deg)' }"
               style="width: 12px; height: 12px" />
        </div>
        <div slot="content">
          <org-tree></org-tree>
        </div>
      </a-popover>
      <user-menu></user-menu>
    </div>
  </a-layout-header>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
import OrgTree from '$public/components/layouts/menu/OrgTree'
import UserMenu from '$public/components/layouts/menu/UserMenuNew'
import { mapState } from 'vuex'
import Cookie from 'js-cookie'
const isDev = process.env.NODE_ENV === 'dev'
export default {
  name: 'Toolbar',
  //import引入的组件需要注入到对象中才能使用
  components: { 'org-tree': OrgTree, 'user-menu': UserMenu },
  props: {
    isShowMenu: {
      type: Boolean,
      default: false
    }
  },
  data() {
    //这里存放数据
    return {
      tripartiteName: sessionStorage.getItem('tripartiteName'),
      isDev,
      Util,
      api: {
        //获取消息状态
        getMessageStateApi: '/web/message/unread',
        skipLowerCodePlant: 'http://121.36.50.36:31000' + '/api/oauth/third-login'
      },
      skipLoading: false,
      isStandard: process.env.VUE_APP_VERSION == 'standard',
      count: 0,
      orgReload: false,
      cascaderIds: []
    }
  },
  //监听属性 类似于data概念
  computed: {
    ...mapState({
      curr_org: state => state.curr_org,
      resources: state => state.resources,
      description: state => state.description,
      logo: state => state.logo,
      color: state => state.color
    })
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getMessageState()
    window.addEventListener('storage', this.handleStorageChange)
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  //监控data中的数据变化
  watch: {
    resources: {
      handler(val) {},
      immediate: true,
      deep: true
    }
  },
  //方法集合
  methods: {
    handleStorageChange(e) {
      if (e.key === 'tripartiteName') {
        this.tripartiteName = e.newValue
      }
    },
    skip() {
      let _params = {
          // n: 1630290061,
          client_id: 'admin',
          client_secret: '123456',
          scope: 'all',
          grant_type: 'password'
        },
        _data = {
          account: Cookie.get('account'),
          huazhuToken: Cookie.get('simpleToken')
        }
      this.skipLoading = true
      this.$post(this.api.skipLowerCodePlant, _data, _params)
        .then(response => {
          console.log(response.data.token)

          window.open(
            // 'http://121.36.50.36:8000/home?token=' + response.data.token
            'http://121.199.39.218/home?token=' + response.data.token
            // 'http://localhost:3000/home?token=' + response.data.token
          )
          this.skipLoading = false
        })
        .catch(res => {
          this.skipLoading = true
          console.log(res)
        })
    },
    // 切换重载
    toggleSwitch() {
      this.orgReload = !this.orgReload
    },
    delOption(options) {
      let arr = []
      options &&
        options.length &&
        options.forEach((v, i) => {
          if (v.children && v.children.length) {
            this.delOption(v.children)
          }
          if (!v.children && !v.uri) {
            if (!Util.getTree(v.id, this.resources).children) {
              options.splice(i, 1)
              this.delOption(options)
            }
          }
        })
      return options
    },
    /**
     * @remark:接口完成之后在created中调用，接口返回未读条数时可以考虑显示未读条数
     * @Description: 获取消息状态
     * @Param:
     * @Return:
     */
    getMessageState() {
      this.count = 0
      this.$get(this.api.getMessageStateApi, {})
        .then(response => {
          Util.processRes(response, () => {
            this.count = response.data
          })
          Util.processError(this, response)
        })
        .catch(res => {
          // this.count = 0
          console.log(res)
        })
    },
    //点击我的消息
    Information() {
      this.$router.push({
        name: 'Message',
        query: {}
      })
    },
    //全屏操作
    handleFullScreen() {
      this.fullscreen = !this.fullscreen
      Util.toggleFullScreen()
    },
    onChange(item, e) {
      if (e) {
        this.cascaderIds = item
        if (e.length > 1) {
          this.$nextTick(() => {
            this.$root.eventHub.$emit('changeBar', e)
          })
        } else {
          if (e[0].uri) {
            let menu = e[0]
            if (menu.appType != 1) {
              // 第三方iframe页面
              if (menu.appType == 2) {
                this.$router.push({
                  path: menu.uri || menu.children[0].uri,
                  query: {
                    pid: menu.pid || menu.children[0].pid
                  }
                })
              } else if (menu.appType == 4) {
                window.open(menu.uri || menu.children[0].uri, '_blank')
              } else {
                this.$router.push({
                  name: 'App',
                  query: {
                    url: menu.uri || menu.children[0].uri
                  }
                })
              }
            } else {
              this.$router.push({
                path: menu.uri || (menu.children ? menu.children[0].uri : ''),
                query: {}
              })
            }
          }
        }
      }
    },
    clickRoute() {
      this.$router.push({
        path: '/main/welcome'
      })
      this.$store.commit('SET_OPENKEYS', [])
    },
    // 获取指定节点下指定几个层级节点
    getTreeLevel(tree, level) {
      let newTree = Util.deepCopy(tree)
      let index = 0
      let setTree = (arr, level, index) => {
        arr &&
          arr.length &&
          arr.forEach((v, i) => {
            if (index >= level) {
              arr.splice(i, 1)
              setTree(newTree, level, 0)
            } else {
              if (v.children && v.children.length) {
                setTree(v.children, level, index + 1)
              }
            }
            if (v.children && !v.children.length) {
              delete v.children
            }
          })
      }
      setTree(newTree, level, index)
      return newTree
    },
    switchMenu() {
      this.$emit('switchMenu', this.isShowMenu)
    }
  },
  destroyed() {} //生命周期 - 销毁完成
}
</script>
<style lang="less">
#Toolbar {
  padding: 0;
  height: 50px !important;
  //box-shadow: 0px 1px 0px 0px #ebeef5;
  background-color: #001528;
  z-index: 12;
  .menu-icon {
    width: 50px;
    height: 50px;
    position: relative;
    &:hover {
      background-color: #40a9ff !important;
    }
    .icon {
      width: 20px;
      height: 20px;
      font-size: 20px;
      color: #fff;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
    }
  }
  .ant-badge:not(.ant-badge-status) {
    margin-right: 20px;
  }
  .bell {
    font-size: @icon-size-20;
  }
  .switch-btn {
    height: 24px;
    border: 1px solid #3b97fe;
    .primary_color;
    .primary_border_color;
    border-radius: 2px;
  }
  .workbench {
    width: 82px;
    height: 30px;
    line-height: 30px;
    .ant-btn {
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.12);
      color: rgba(255, 255, 255, 0.8);
      border: none;
      font-size: 14px;
      padding: 0 8px;
      &:hover {
        background: rgba(255, 255, 255, 0.2);
        color: rgba(255, 255, 255, 1);
      }
    }
  }
  .organize {
    transition: all 0.2s;
  }
  .toggleSwitch {
    height: 100%;
    transition: all 0.5s;
    &:hover {
      background: rgba(255, 255, 255, 0.12);
    }
  }
}
</style>
