<!--
 * @Description:
 * @Version: 1.0.0
 * @Author: 李帅
 * @Date: 2021-11-13 15:31:45
 * @LastEditors: 李帅
 * @LastEditTime: 2022-01-12 21:11:22
-->
<comment>
# 组件注释
</comment>
<template>
  <div id="Right">
    <a-input placeholder="搜索" style="margin-bottom: 32px" @change="onSearch">
      <a-icon slot="prefix" type="search" />
    </a-input>
    <div v-if="menus && menus.length" class="content">
      <div v-for="(menu, menuIndex) in menus" :key="menuIndex" class="menus">
        <!--        <p>{{menu.name}}</p>-->
        <ul
          class="level1"
          :style="{ columnCount: menu.children.length > 4 ? 4 : menu.children.length }">
          <li v-for="(item, itemIndex) in menu.children" :key="itemIndex">
            <!-- 当前节点存在且存在子节点且存在页面节点或当前资源类别为外部资源或外部系统时显示 -->
            <span
              v-if="
                (item &&
                  item.children &&
                  item.children.length &&
                  item.children.some(v => (v.children && v.children.length) || v.uri)) ||
                item.appType == 3 ||
                item.appType == 4
              "
              :hz-code="item.appCode"
              @click="routerLink(item)"
              >{{ item.name }}</span
            >
            <ul v-for="child in item && item.children" :key="child.appCode" class="level2">
              <li v-if="(child.children && child.children.length) || child.uri">
                <span :hz-code="child.appCode" @click="routerLink(child)">{{ child.name }}</span>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <div v-else>
      <a-empty class="empty" :image="Empty.PRESENTED_IMAGE_SIMPLE" />
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { mapState } from 'vuex'
import { Empty } from 'ant-design-vue'
import Cookie from 'js-cookie'
export default {
  name: 'Right',
  //import引入的组件需要注入到对象中才能使用
  components: {},
  props: {},
  data() {
    //这里存放数据
    return {
      Empty,
      menus: []
    }
  },
  //监听属性 类似于data概念
  computed: {
    ...mapState({
      open_keys: state => state.open_keys,
      resources: state => state.resources,
      menu_mode: state => state.menu_mode,
      curr_org: state => state.curr_org,
      tenantId: state => state.curr_tenant,
      last_activity: state => state.last_activity
    })
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.menus = this.resources.filter(v => v.appCode == 'web_pm')
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    routerLink(tree) {
      let data = Util.getTreeUriFirst(tree.id, this.resources)
      this.$root.eventHub.$emit('isShowDrawer', false)
      if (data.appType == 3 || data.appType == 4) {
        window.open(data.uri)
      } else if (data.appType == 1 || data.appType == 2) {
        this.$store.commit('SET_FEATUREAUTH', data.children)
        this.$router.push({ path: data.uri })
      }
      this.$root.eventHub.$emit('routeSetLastActivity', [data])
    },
    /**
     * 筛选
     */
    onSearch(event) {
      let val = event.target.value
      let resources = this.resources
      if (val) this.menus = Util.treeSearchFuzzyResource(val, 'name', resources)
      else this.menus = resources
    }
  },
  destroyed() {} //生命周期 - 销毁完成
}
</script>
<style lang="less">
#Right {
  min-width: 208px;
  padding: 32px 40px;
  .content {
    width: 100%;
    height: calc(100% - 48px);
    overflow: auto;
    // display: inline-block;
    .menus {
      margin-bottom: 12px;
      > p {
        font-size: 16px;
        color: #303133;
        line-height: 22px;
        margin-bottom: 18px;
        font-weight: bold;
      }
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
      }
      ul.level1 {
        width: 100%;
        height: 100%;
        max-width: 768px;
        // display: flex;
        // flex-direction: column;
        // flex-wrap: wrap;
        // column-count: 4;
        column-gap: 16px;
        margin: 0 auto;
        > li {
          width: 196px;
          color: #1890ff;
          font-size: 14px;
          line-height: 20px;
          break-inside: avoid;
          cursor: pointer;
          padding-bottom: 18px;
          padding-right: 18px;
          > span {
            margin-bottom: 18px;
            display: inline-block;
            font-weight: bold;
            &:hover {
              color: #40a9ff;
            }
          }
        }

        ul.level2 {
          li {
            padding-bottom: 18px;
            span {
              color: #606266;
              &:hover {
                color: #1890ff;
              }
            }
          }
        }
      }
    }
  }
  .empty {
    svg path {
      fill: unset;
    }
  }
}
</style>
