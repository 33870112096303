/*
 * @Description:
 * @Version: 1.0.0
 * @Author: 李帅
 * @Date: 2020-11-09 11:27:31
 * @LastEditors: 赵亮
 * @LastEditTime: 2023-03-28 15:32:43
 */
const settings = [
  {
    path: 'businessAffairsManage',
    name: 'BusinessAffairsManage',
    meta: {
      name: '商务管理',
      keepAlive: false
    },
    component: () =>
      import(
        /*/* : "商务管理页面入口" */
        '$quality/views/businessAffairsManage/Index'
      ),
    children: [
      {
        path: 'meteringManage',
        name: 'MeteringManage',
        meta: {
          name: '工程计量台账',
          keepAlive: false,
          isAuth: false,
          authCode: 'web_pm_business_metering_meteringManage'
        },
        component: () =>
          import(
            /*/* : "工程计量台账页面入口" */
            '$quality/views/businessAffairsManage/meteringManage/Index'
          ),
        children: []
      },
      {
        path: 'ImportMeteringManage',
        name: 'ImportMeteringManage',
        meta: {
          name: '计量管理导入',
          keepAlive: false,
          isAuth: false,
          authCode: ''
        },
        component: () =>
          import(
            /*/* : "计量管理页面导入" */
            '$quality/views/businessAffairsManage/meteringManage/ImportMeteringManage'
          ),
        children: []
      },
      {
        path: 'reportManage',
        name: 'ReportManage',
        meta: {
          name: '分包月度报量管理台账',
          keepAlive: false,
          isAuth: false,
          authCode: 'web_pm_business_amount_reportManage'
        },
        component: () =>
          import(
            /*/* : "分包月度报量管理台账页面入口" */
            '$quality/views/businessAffairsManage/reportManage/Index'
          ),
        children: []
      },
      {
        path: 'ImportReportManage',
        name: 'ImportReportManage',
        meta: {
          name: '分包月度报量管理台账导入',
          keepAlive: false,
          isAuth: false,
          authCode: ''
        },
        component: () =>
          import(
            /*/* : "分包月度报量管理台账页面导入" */
            '$quality/views/businessAffairsManage/reportManage/ImportReportManage'
          ),
        children: []
      },
      {
        path: 'ProjectPlanningForm',
        name: 'ProjectPlanningForm',
        meta: {
          name: '商务策划立项表',
          keepAlive: false,
          isAuth: false,
          authCode: 'web_pm_business_businessPlan_ProjectPlanningForm'
        },
        component: () =>
          import(
            /*/* : "商务策划立项表" */
            '$quality/views/businessPlan/ProjectPlanningForm'
          ),
        children: []
      },
      {
        path: 'ProjectPlanningForm/pro',
        name: 'ProjectPlanningFormPro',
        meta: {
          name: '商务策划立项表',
          keepAlive: false,
          isAuth: false,
          authCode: 'web_pm_business_businessPlan_ProjectPlanningForm'
        },
        component: () =>
          import(
            /*/* : "商务策划立项表 项目层" */
            '$quality/views/businessPlan/ProjectPlanningFormPro'
          ),
        children: []
      },
      {
        path: 'PlanningEffectTable',
        name: 'PlanningEffectTable',
        meta: {
          name: '商务策划效果表',
          keepAlive: false,
          isAuth: false,
          authCode: 'web_pm_business_businessPlan_PlanningEffectTables'
        },
        component: () =>
          import(
            /*/* : "商务策划效果表" */
            '$quality/views/businessPlan/PlanningEffectTables'
          ),
        children: []
      },
      {
        path: 'PlanningEffectTable/Pro',
        name: 'PlanningEffectTablePro',
        meta: {
          name: '商务策划效果表',
          keepAlive: false,
          isAuth: false,
          authCode: 'web_pm_business_businessPlan_PlanningEffectTables'
        },
        component: () =>
          import(
            /*/* : "商务策划效果表-项目级" */
            '$quality/views/businessPlan/PlanningEffectTablesPro'
          ),
        children: []
      }
    ]
  }
]
export default settings
