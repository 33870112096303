/*
 * @Description: 机械
 * @Version: 1.0.0
 * @Author: cyl
 * @Date: 2020-11-02 11:23:34
 * @LastEditors: 李帅
 * @LastEditTime: 2023-03-30 15:33:05
 */
// let isSpm = process.env.VUE_APP_VERSION === 'standard'
import routerName from '../config/routerName.config'
export const protectedRoute = [
  {
    path: '/main/mechanical',
    name: 'Mechanical',
    meta: {
      keepAlive: false
    },
    component: () => import('$mechanical/views/Index'),
    children: []
  },
  {
    path: '/main/mechanical/setting',
    name: 'MechanicalSetting',
    meta: {
      // name: isSpm ? '机械参数设置' : '机械设置',
      name: routerName('MechanicalSetting'),
      keepAlive: false,
      isAuth: true,
      authCode: 'web_base_mechanical_param'
    },
    component: () => import('$mechanical/views/specialEquipment/settings/Settings')
  },
  {
    path: '/main/mechanical/syssetting',
    name: 'MechanicalSysSetting',
    meta: {
      name: '系统设置',
      keepAlive: false
    },
    component: () => import('$mechanical/views/specialEquipment/settings/settings/Settings')
  },
  {
    path: '/main/mechanical/SettingParams',
    name: 'SettingParams',
    meta: {
      name: '编辑机械基本参数',
      keepAlive: false
    },
    component: () => import('$mechanical/views/specialEquipment/settings/SettingParams')
  },
  {
    path: '/main/mechanical/SettingInfo',
    name: 'SettingInfo',
    meta: {
      name: '编辑拓展信息',
      keepAlive: false
    },
    component: () => import('$mechanical/views/specialEquipment/settings/SettingInfo')
  },
  {
    path: '/main/mechanical/EquipResume',
    name: 'EquipResume',
    meta: {
      name: '机械履历',
      keepAlive: false
    },
    component: () => import('$mechanical/views/specialEquipment/equipResume/EquipResume')
  },
  {
    path: '/main/mechanical/GuidanceLib',
    name: 'GuidanceLib',
    meta: {
      // name: isSpm ? '机械指导库设置' : '指导库',
      name: routerName('GuidanceLib'),
      keepAlive: false,
      isAuth: true,
      authCode: 'web_base_mechanical_guidance'
    },
    component: () => import('$mechanical/views/specialEquipment/guidanceLib/GuidanceLib.vue')
  },

  {
    path: '/main/mechanical/GuidanceLib/add',
    name: 'GuidanceLibAdd',
    meta: {
      name: '新增',

      keepAlive: false,
      isAuth: true,
      authCode: 'web_base_mechanical_guidance_add'
    },
    component: () => import('$mechanical/views/specialEquipment/guidanceLib/GuidanceLibAdd.vue')
  },
  {
    path: '/main/mechanical/GuidanceLib/edit',
    name: 'GuidanceLibEdit',
    meta: {
      name: '编辑',

      keepAlive: false,
      isAuth: true,
      authCode: 'web_base_mechanical_guidance_edit'
    },
    component: () => import('$mechanical/views/specialEquipment/guidanceLib/GuidanceLibAdd.vue')
  },
  {
    path: '/main/mechanical/GuidanceLib/detail',
    name: 'GuidanceLibDetail',
    meta: {
      name: '详情',

      keepAlive: false,
      isAuth: true,
      authCode: 'web_base_mechanical_guidance_detail'
    },
    component: () => import('$mechanical/views/specialEquipment/guidanceLib/GuidanceLibDetail.vue')
  },
  {
    path: '/main/mechanical/HiddenDanger',
    name: 'HiddenDanger',
    meta: {
      // name: isSpm ? '隐患台账' : '机械隐患',
      name: routerName('HiddenDanger'),
      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_mechanical_hiddenAccount'
    },
    component: () => import('$mechanical/views/specialEquipment/hiddenDanger/HiddenDanger.vue')
  },
  {
    path: '/main/mechanical/tower/HiddenDanger',
    name: 'HiddenDanger',
    meta: {
      // name: isSpm ? '隐患台账' : '机械隐患',
      name: '塔机隐患台账',
      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_IoTplatform_tower_HiddenDanger'
    },
    component: () => import('$mechanical/views/specialEquipment/hiddenDanger/HiddenDanger.vue')
  },
  {
    path: '/main/mechanical/HiddenDanger/edit',
    name: 'EditHiddenDanger',
    meta: {
      // name: isSpm ? '编辑隐患台账' : '编辑机械隐患',
      name: routerName('EditHiddenDanger'),
      isAuth: false,
      authCode: '',
      keepAlive: false
    },
    component: () => import('$mechanical/views/specialEquipment/hiddenDanger/EditHiddenDanger')
  },
  {
    path: '/main/mechanical/HiddenDanger/detail',
    name: 'HiddenDangerDetail',
    meta: {
      // name: isSpm ? '详情' : '机械隐患详情',
      name: routerName('HiddenDangerDetail'),

      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_mechanical_hiddenAccount_detail'
    },
    component: () => import('$mechanical/views/specialEquipment/hiddenDanger/HiddenDangerDetail')
  },
  {
    path: '/main/mechanical/KeyLib',
    name: 'KeyLib',
    meta: {
      // name: isSpm ? '关键资料管理' : '关键资料',
      name: routerName('KeyLib'),
      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_mechanical_keyInfo'
    },
    component: () => import('$mechanical/views/specialEquipment/keyLib/KeyLib')
  },
  {
    path: '/main/mechanical/tower/KeyLib',
    name: 'KeyLib',
    meta: {
      // name: isSpm ? '关键资料管理' : '关键资料',
      name: '关键资料管理',
      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_IoTplatform_tower_keyInfo'
    },
    component: () => import('$mechanical/views/specialEquipment/keyLib/KeyLib')
  },
  {
    path: '/main/mechanical/KeyLib/add',
    name: 'AddKeyLib',
    meta: {
      name: '新增',

      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_mechanical_keyInfo_add'
    },
    component: () => import('$mechanical/views/specialEquipment/keyLib/addKeyLib')
  },
  {
    path: '/main/mechanical/tower/KeyLib/add',
    name: 'AddKeyLibTower',
    meta: {
      name: '新增',

      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_IoTplatform_tower_keyInfo_add'
    },
    component: () => import('$mechanical/views/specialEquipment/keyLib/addKeyLib')
  },
  {
    path: '/main/mechanical/KeyLib/edit',
    name: 'EditKeyLib',
    meta: {
      name: '编辑',

      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_mechanical_keyInfo_edit'
    },
    component: () => import('$mechanical/views/specialEquipment/keyLib/addKeyLib')
  },
  {
    path: '/main/mechanical/tower/KeyLib/edit',
    name: 'EditKeyLibTower',
    meta: {
      name: '编辑',

      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_IoTplatform_tower_keyInfo_edit'
    },
    component: () => import('$mechanical/views/specialEquipment/keyLib/addKeyLib')
  },
  {
    path: '/main/mechanical/KeyLib/childadd',
    name: 'AddItemKeyLib',
    meta: {
      name: '资料新增',
      isAuth: false,
      authCode: '',
      keepAlive: false
    },
    component: () => import('$mechanical/views/specialEquipment/keyLib/SetItemKeyLib')
  },
  {
    path: '/main/mechanical/KeyLib/childedit',
    name: 'EditItemKeyLib',
    meta: {
      name: '资料编辑',
      isAuth: false,
      authCode: '',
      keepAlive: false
    },
    component: () => import('$mechanical/views/specialEquipment/keyLib/SetItemKeyLib')
  },
  {
    path: '/main/mechanical/fillForm',
    name: 'fillForm',
    meta: {
      // name: isSpm ? 'BI填报设置' : '表单填报',
      name: routerName('fillForm'),
      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_mechanical_biFillIn'
    },
    component: () => import('$mechanical/views/specialEquipment/fillForm/formSetting')
  },
  {
    path: '/main/mechanical/towerCraneMonitor',
    name: 'TowerCraneMonitor',
    meta: {
      // name: isSpm ? '塔机监测' : '塔机监测',
      name: routerName('towerCraneMonitor'),
      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_IoTplatform_tower_towerCraneMonitor'
    },
    component: () => import('$mechanical/views/specialEquipment/towerCraneMonitor/Index')
  },
  //合约管理  分包
  {
    path: '/main/mechanical/SubManagement',
    name: 'SubManagement',
    meta: {
      name: '专业分包合同台账',
      keepAlive: false,
      authCode: 'web_pm_contract_report_SubManagement'
    },
    component: () => import('$mechanical/views/specialEquipment/contractManager/SubManagement')
  },
  {
    path: '/main/mechanical/SubManagement/add',
    name: 'SubManagementAdd',
    meta: {
      name: '新增',
      isAuth: false,
      authCode: 'web_pm_contract_report_SubManagement_add',
      keepAlive: false
    },
    component: () => import('$mechanical/views/specialEquipment/contractManager/AddsubMange')
  },
  {
    //TotalManagement
    path: '/main/mechanical/SubManagement/edit',
    name: 'SubManagementEdit',
    meta: {
      name: '编辑',
      isAuth: false,
      authCode: 'web_pm_contract_report_SubManagement_edit',
      keepAlive: false
    },
    component: () => import('$mechanical/views/specialEquipment/contractManager/EditsubMange')
  },
  {
    path: '/main/mechanical/SubManagement/detail',
    name: 'SubManagementDetail',
    meta: {
      name: '详情',
      isAuth: false,
      authCode: '',
      keepAlive: false
    },
    component: () =>
      import('$mechanical/views/specialEquipment/contractManager/subManagementDetail')
  },
  {
    path: '/main/mechanical/SubManagement/import',
    name: 'SubImportExcel',
    meta: {
      name: '导入',
      isAuth: false,
      authCode: 'web_pm_contract_report_SubManagement_import',
      keepAlive: false
    },
    component: () => import('$mechanical/views/specialEquipment/contractManager/subImportExcel')
  },
  //合约管理  总包
  {
    path: '/main/mechanical/TotalManagement',
    name: 'TotalManagement',
    meta: {
      name: '总包合同台账',
      keepAlive: false,
      isAuth: false,
      authCode: 'web_pm_contract_report_TotalManagement'
    },
    component: () => import('$mechanical/views/specialEquipment/contractManager/TotalManagement')
  },
  {
    path: '/main/mechanical/TotalManagement/add',
    name: 'TotalManagementAdd',
    meta: {
      name: '新增',
      isAuth: false,
      authCode: '',
      keepAlive: false
    },
    component: () => import('$mechanical/views/specialEquipment/contractManager/AddTotalMange')
  },
  {
    //TotalManagement
    path: '/main/mechanical/TotalManagement/edit',
    name: 'TotalManagementEdit',
    meta: {
      name: '编辑',
      isAuth: false,
      authCode: '',
      keepAlive: false
    },
    component: () => import('$mechanical/views/specialEquipment/contractManager/EditTotalMange')
  },
  {
    path: '/main/mechanical/TotalManagement/detail',
    name: 'TotalManagementDetail',
    meta: {
      name: '详情',
      isAuth: false,
      authCode: '',
      keepAlive: false
    },
    component: () =>
      import('$mechanical/views/specialEquipment/contractManager/TotalManagementDetail')
  },
  {
    path: '/main/mechanical/TotalManagement/import',
    name: 'TotalImportExcel',
    meta: {
      name: '导入',
      isAuth: false,
      authCode: '',
      keepAlive: false
    },
    component: () => import('$mechanical/views/specialEquipment/contractManager/totalImportExcel')
  },

  {
    path: '/main/mechanical/SpecialWorker',
    name: 'SpecialWorker',
    meta: {
      // name: isSpm ? '人员管理' : '特种人员',
      name: routerName('SpecialWorker'),
      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_mechanical_users'
    },
    component: () => import('$mechanical/views/specialEquipment/specialWorker/SpecialWorker')
  },
  {
    path: '/main/equip/specEquip/tower/specialWorker',
    name: 'SpecialWorker',
    component: () => import('$mechanical/views/specialEquipment/specialWorker/SpecialWorker.vue'),
    meta: {
      // name: isSpm ? '人员管理' : '特种人员',
      name: '特种人员管理',
      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_IoTplatform_tower_users'
    },
    children: []
  },
  {
    path: '/main/mechanical/SpecialWorker/add',
    name: 'SpecialWorkerAdd',
    meta: {
      name: '特种人员新增',
      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_mechanical_users_add'
    },
    component: () => import('$mechanical/views/specialEquipment/specialWorker/SpecialWorkerAdd')
  },
  {
    path: '/main/mechanical/SpecialWorker/edit',
    name: 'SpecialWorkerEdit',
    meta: {
      name: '资料管理',
      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_mechanical_users_data'
    },
    component: () => import('$mechanical/views/specialEquipment/specialWorker/SpecialWorkerAdd')
  },
  {
    path: '/main/mechanical/SpecialWorker/detail',
    name: 'SpecialWorkerDetail',
    meta: {
      name: '详情',
      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_mechanical_users_detail'
    },
    component: () => import('$mechanical/views/specialEquipment/specialWorker/SpecialWorkerDetail')
  },
  {
    path: '/main/mechanical/equipInspection',
    name: 'EquipInspection',
    component: () =>
      import('$mechanical/views/specialEquipment/equipInspection/EquipInspection.vue'),
    meta: {
      // name: isSpm ? '检查管理' : '机械检查',
      name: routerName('EquipInspection'),
      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_mechanical_check'
    },
    children: []
  },
  {
    path: '/main/mechanical/tower/equipInspection',
    name: 'EquipInspection',
    component: () =>
      import('$mechanical/views/specialEquipment/equipInspection/EquipInspection.vue'),
    meta: {
      // name: isSpm ? '检查管理' : '机械检查',
      name: '塔机检查管理',
      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_IoTplatform_tower_check'
    },
    children: []
  },
  {
    path: '/main/mechanical/addOrEditEquipInspectionPlan',
    name: 'AddEquipInspectionPlan',
    component: () =>
      import('$mechanical/views/specialEquipment/equipInspection/AddOrEditEquipInspectionPlan.vue'),
    meta: {
      keepAlive: false,
      name: '新增检查任务',
      isAuth: false,
      authCode: ''
    },
    children: []
  },
  {
    path: '/main/mechanical/addOrEditEquipInspectionPlan',
    name: 'EditEquipInspectionPlan',
    component: () =>
      import('$mechanical/views/specialEquipment/equipInspection/AddOrEditEquipInspectionPlan.vue'),
    meta: {
      keepAlive: false,
      name: '编辑检查任务',
      isAuth: false,
      authCode: ''
    },
    children: []
  },
  {
    path: '/main/mechanical/EquipInspection/planDetial',
    name: 'EquipInspectionPlanDetial',
    component: () => import('$mechanical/views/specialEquipment/equipInspection/PlanDetial.vue'),
    meta: {
      // name: isSpm ? '详情' : '机械检查详情',
      name: routerName('EquipInspectionPlanDetial'),
      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_mechanical_check_detail'
    },
    children: []
  },
  //机械维修 repair
  {
    path: '/main/mechanical/equipmentRepair',
    name: 'EquipmentRepair',
    component: () =>
      import('$mechanical/views/specialEquipment/equipmentRepair/EquipmentRepair.vue'),
    meta: {
      // name: isSpm ? '维修台账' : '机械维修',
      name: routerName('EquipmentRepair'),
      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_mechanical_repairAccount'
    }
  },
  {
    path: '/main/mechanical/tower/equipmentRepair',
    name: 'EquipmentRepair',
    component: () =>
      import('$mechanical/views/specialEquipment/equipmentRepair/EquipmentRepair.vue'),
    meta: {
      // name: isSpm ? '维修台账' : '机械维修',
      name: '塔机维修台账',
      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_IoTplatform_tower_repairAccount'
    }
  },
  {
    path: '/main/mechanical/equipmentRepair/detail',
    name: 'EquipmentRepairDetail',
    component: () =>
      import('$mechanical/views/specialEquipment/equipmentRepair/EquipmentRepairDetail.vue'),
    meta: {
      // name: isSpm ? '详情' : '机械维修详情',
      name: routerName('EquipmentRepairDetail'),
      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_mechanical_repairAccount_detail'
    }
  },
  //机械验收
  {
    path: '/main/mechanical/equipmentAcceptance',
    name: 'EquipmentAcceptance',
    component: () =>
      import('$mechanical/views/specialEquipment/equipmentAcceptance/EquipmentAcceptance.vue'),
    meta: {
      // name: isSpm ? '验收管理' : '机械验收',
      name: routerName('EquipmentAcceptance'),
      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_mechanical_acceptance'
    }
  },
  {
    path: '/main/mechanical/tower/equipmentAcceptance',
    name: 'EquipmentAcceptance',
    component: () =>
      import('$mechanical/views/specialEquipment/equipmentAcceptance/EquipmentAcceptance.vue'),
    meta: {
      // name: isSpm ? '验收管理' : '机械验收',
      name: '塔机验收管理',
      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_IoTplatform_tower_acceptance'
    }
  },
  {
    path: '/main/mechanical/equipmentAcceptance/detail',
    name: 'EquipmentAcceptanceDetail',
    component: () =>
      import(
        '$mechanical/views/specialEquipment/equipmentAcceptance/EquipmentAcceptanceDetail.vue'
      ),
    meta: {
      // name: isSpm ? '详情' : '机械验收详情',
      name: routerName('EquipmentAcceptanceDetail'),
      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_mechanical_acceptance_detail'
    }
  },
  {
    path: '/main/mechanical/equipmentAcceptance/edit',
    name: 'EquipmentAcceptanceEdit',
    component: () =>
      import('$mechanical/views/specialEquipment/equipmentAcceptance/EquipmentAcceptanceAdd.vue'),
    meta: {
      // name: isSpm ? '编辑验收管理' : '编辑机械验收',
      name: routerName('EquipmentAcceptanceEdit'),
      keepAlive: false,
      isAuth: false,
      authCode: 'web_pm_mechanical_acceptance_edit'
    }
  },
  {
    path: '/main/mechanical/equipmentMaintain',
    name: 'EquipmentMaintain',
    component: () =>
      import('$mechanical/views/specialEquipment/equipmentMaintain/EquipmentMaintain.vue'),
    meta: {
      // name: isSpm ? '保养管理' : '机械保养',
      name: routerName('EquipmentMaintain'),
      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_mechanical_maintain'
    }
  },
  {
    path: '/main/mechanical/tower/equipmentMaintain',
    name: 'EquipmentMaintain',
    component: () =>
      import('$mechanical/views/specialEquipment/equipmentMaintain/EquipmentMaintain.vue'),
    meta: {
      // name: isSpm ? '保养管理' : '机械保养',
      name: '塔机保养管理',
      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_IoTplatform_tower_maintain'
    }
  },
  {
    path: '/main/mechanical/equipmentMaintain/detail',
    name: 'EquipmentMaintainDetail',
    component: () =>
      import('$mechanical/views/specialEquipment/equipmentMaintain/EquipmentMaintainDetail.vue'),
    meta: {
      // name: isSpm ? '详情' : '机械保养详情',
      name: routerName('EquipmentMaintainDetail'),
      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_mechanical_maintain_detail'
    }
  },
  {
    path: '/main/mechanical/equipmentMaintain/edit',
    name: 'EquipmentMaintainEdit',
    component: () =>
      import('$mechanical/views/specialEquipment/equipmentMaintain/EquipmentMaintainAdd.vue'),
    meta: {
      // name: isSpm ? '编辑保养管理' : '编辑机械保养',
      name: routerName('EquipmentMaintainEdit'),
      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_mechanical_maintain_edit'
    }
  },
  {
    path: '/main/mechanical/equipmentWarning',
    name: 'EquipmentWarning',
    component: () =>
      import('$mechanical/views/specialEquipment/equipmentWarning/EquipmentWarning.vue'),
    meta: {
      name: '机械预警台账',
      keepAlive: false,
      isAuth: false,
      authCode: ''
    }
  },
  {
    path: '/main/mechanical/equipmentSupplier',
    name: 'EquipmentSupplier',
    component: () =>
      import('$mechanical/views/specialEquipment/equipmentSupplier/EquipmentSupplier.vue'),
    meta: {
      // name: isSpm ? '供应商管理' : '机械供应商管理',
      name: routerName('EquipmentSupplier'),
      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_mechanical_supplier'
    }
  },
  {
    path: '/main/mechanical/equipmentSupplier/add',
    name: 'EquipmentSupplierAdd',
    component: () =>
      import('$mechanical/views/specialEquipment/equipmentSupplier/EquipmentSupplierAdd.vue'),
    meta: {
      name: '机械供应商新增',
      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_mechanical_supplier_add'
    }
  },
  {
    path: '/main/mechanical/equipmentSupplier/edit',
    name: 'EquipmentSupplierEdit',
    component: () =>
      import('$mechanical/views/specialEquipment/equipmentSupplier/EquipmentSupplierAdd.vue'),
    meta: {
      name: '机械供应商编辑',
      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_mechanical_supplier_edit'
    }
  },
  {
    path: '/main/mechanical/equipmentManagement',
    name: 'EquipmentManagement',
    component: () =>
      import('$mechanical/views/specialEquipment/equipmentManagement/EquipmentManagement.vue'),
    meta: {
      name: '机械管理',
      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_mechanical_mechanics'
    }
  },
  {
    path: '/main/mechanical/tower/equipmentManagement',
    name: 'EquipmentManagement',
    component: () =>
      import('$mechanical/views/specialEquipment/equipmentManagement/EquipmentManagement.vue'),
    meta: {
      name: '塔机管理',
      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_IoTplatform_tower_mechanics'
    }
  },
  {
    path: '/main/mechanical/equipmentManagement/add',
    name: 'AddEquipmentMange',
    component: () =>
      import('$mechanical/views/specialEquipment/equipmentManagement/AddEquipmentMange.vue'),
    meta: {
      name: '新增',
      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_mechanical_mechanics_add'
    }
  },
  {
    path: '/main/mechanical/tower/equipmentManagement/add',
    name: 'AddEquipmentMangeTower',
    component: () =>
      import('$mechanical/views/specialEquipment/equipmentManagement/AddEquipmentMange.vue'),
    meta: {
      name: '新增',
      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_IoTplatform_tower_mechanics_add'
    }
  },
  {
    path: '/main/mechanical/equipmentManagement/edit',
    name: 'EditEquipmentMange',
    component: () =>
      import('$mechanical/views/specialEquipment/equipmentManagement/EditEquipmentMange.vue'),
    meta: {
      name: '编辑',
      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_mechanical_mechanics_edit'
    }
  },
  {
    path: '/main/equip/specEquip/specialWorker',
    name: 'SpecialWorker',
    component: () => import('$mechanical/views/specialEquipment/specialWorker/SpecialWorker.vue'),
    meta: {
      // name: isSpm ? '人员管理' : '特种人员',
      name: routerName('SpecialWorker'),
      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_mechanical_users'
    },
    children: []
  },
  {
    path: '/main/equip/specEquip/mechanicalAdd',
    name: 'AddLaborRosterMechanical',
    component: () => import('$mechanical/views/specialEquipment/specialWorker/AddLaborRoster.vue'),
    meta: {
      // name: isSpm ? '新增' : '新增特种人员',
      name: routerName('AddLaborRosterMechanical'),
      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_mechanical_users_add'
    }
  },
  {
    path: '/main/equip/specEquip/tower/mechanicalAdd',
    name: 'AddLaborRosterMechanicalTower',
    component: () => import('$mechanical/views/specialEquipment/specialWorker/AddLaborRoster.vue'),
    meta: {
      // name: isSpm ? '新增' : '新增特种人员',
      name: routerName('AddLaborRosterMechanical'),
      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_IoTplatform_tower_users_add'
    }
  },
  {
    path: '/main/equip/specEquip/mechanicalEdit',
    name: 'EditLaborRosterMechanical',
    component: () => import('$mechanical/views/specialEquipment/specialWorker/AddLaborRoster.vue'),
    meta: {
      // name: isSpm ? '编辑' : '编辑特种人员',
      name: routerName('EditLaborRosterMechanical'),
      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_mechanical_users_edit'
    }
  },
  {
    path: '/main/equip/specEquip/tower/mechanicalEdit',
    name: 'EditLaborRosterMechanicalTower',
    component: () => import('$mechanical/views/specialEquipment/specialWorker/AddLaborRoster.vue'),
    meta: {
      // name: isSpm ? '编辑' : '编辑特种人员',
      name: routerName('EditLaborRosterMechanical'),
      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_IoTplatform_tower_users_edit'
    }
  },
  {
    path: '/main/mechanical/toSettle',
    name: 'toSettle',
    component: () => import('$mechanical/views/specialEquipment/toSettle/toSettle.vue'),
    meta: {
      name: '复工返岗统计',
      keepAlive: false,
      isAuth: false,
      authCode: ''
    }
  },
  // demo路由区域
  {
    path: '/main/mechanical/demoForm',
    name: 'demoForm',
    component: () => import('$mechanical/views/specialEquipment/demoTemplate/formTemplate.vue'),
    meta: {
      name: '表单模板',
      keepAlive: false,
      isAuth: false,
      authCode: ''
    }
  },
  {
    path: '/main/mechanical/demoList',
    name: 'demoList',
    component: () => import('$mechanical/views/specialEquipment/demoTemplate/list.vue'),
    meta: {
      name: '列表模板',
      keepAlive: false,
      isAuth: false,
      authCode: ''
    }
  },
  // 合约管理  分供合同数据库
  {
    path: '/main/mechanical/SubSupplyContractDatabase',
    name: 'SubSupplyContractDatabase',
    meta: {
      name: '分供合同数据库',
      keepAlive: false,
      isAuth: false,
      authCode: 'web_pm_contract_report_SubSupplyContractDatabase'
    },
    component: () =>
      import('$mechanical/views/specialEquipment/contractManager/SubSupplyContractDatabase')
  },
  {
    path: '/main/mechanical/SubSupplyContractDatabase/import',
    name: 'SubSupplyImportExcel',
    meta: {
      name: '导入',
      isAuth: false,
      authCode: 'web_pm_contract_report_SubSupplyContractDatabase_import',
      keepAlive: false
    },
    component: () =>
      import('$mechanical/views/specialEquipment/contractManager/SubSupplyImportExcel')
  },
  {
    path: '/main/mechanical/SubSupplyContractDatabase/add',
    name: 'AddSubSupply',
    meta: {
      name: '新增',
      isAuth: false,
      authCode: 'web_pm_contract_report_SubSupplyContractDatabase_add',
      keepAlive: false
    },
    component: () => import('$mechanical/views/specialEquipment/contractManager/AddSubSupply')
  },
  {
    path: '/main/mechanical/SubSupplyContractDatabase/edit',
    name: 'EidtSubSupply',
    meta: {
      name: '编辑',
      isAuth: false,
      authCode: 'web_pm_contract_report_SubSupplyContractDatabase_edit',
      keepAlive: false
    },
    component: () => import('$mechanical/views/specialEquipment/contractManager/EditSubSupply')
  },
  {
    path: '/main/mechanical/overdueTime',
    name: 'overdueTime',
    meta: {
      name: '检查逾期时间设置',
      keepAlive: false,
      isAuth: true,
      authCode: 'web_base_mechanical_overdueTime'
    },
    component: () => import('$mechanical/views/specialEquipment/overdueTime/Index')
  },
  {
    path: '/main/mechanical/baseSetting',
    name: 'BaseSetting',
    meta: {
      name: '通用设置',
      keepAlive: false,
      isAuth: true,
      authCode: 'web_base_mechanical_baseSetting'
    },
    component: () => import('$mechanical/views/specialEquipment/baseSetting/BaseSetting')
  }
]
