/*
 * @Description: 投标中心
 * @Version: 1.0.0
 * @Author: 李晨光
 * @Date: 2020-11-02 11:23:34
 * @LastEditors: 赵亮
 * @LastEditTime: 2021-05-13 14:23:17
 */

export const protectedRoute = [
  // 投标中心
  {
    path: '/main/bidding',
    name: 'Bidding',
    component: () => import('$bidding/views/Index'),
    meta: {
      name: '投标中心'
    },
    children: [
      // 一级建造师
      {
        path: '/main/bidding/constructor',
        name: 'Constructor',
        component: () => import('$bidding/views/constructor/Index.vue'),
        children: [],
        meta: {
          name: '一级建造师'
        }
      },
      // 安全员
      {
        path: '/main/bidding/safetyOfficer',
        name: 'SafetyOfficer',
        component: () => import('$bidding/views/SafetyOfficer/Index.vue'),
        children: [],
        meta: {
          name: '安全员'
        }
      },
      // 安全员导入
      {
        path: '/main/bidding/ImportOfficer',
        name: 'ImportOfficer',
        component: () => import('$bidding/views/SafetyOfficer/ImportOfficer.vue'),
        children: [],
        meta: {
          name: '安全员导入'
        }
      },
      // 所辖区域诚信分及排名
      {
        path: '/main/bidding/regionRanking',
        name: 'regionRanking',
        component: () => import('$bidding/views/regionRanking/Index.vue'),
        children: [],
        meta: {
          name: '所辖区域诚信分及排名'
        }
      },
      // 在投项目台账
      {
        path: '/main/bidding/bidPro',
        name: 'BidPro',
        component: () => import('$bidding/views/bidPro/Index.vue'),
        children: [],
        meta: {
          name: '在投项目台账'
        }
      }
    ]
  }
]
