/*
 * @Descripttion: getter
 * @Version: 1.0.0
 * @Author: 李晨光
 * @Date: 2020-07-07 13:46:46
 * @LastEditors: 任笠
 * @LastEditTime: 2021-09-23 10:15:54
 */

export const name = state => {
  return state.name
}

export const login_msg = state => {
  return state.login_msg
}

export const contractorInfo = state => {
  return state.contractorInfo
}

export const color = state => {
  return state.color
}

export const route = state => {
  return state.route
}

export const open_keys = state => {
  return state.open_keys
}

export const curr_org = state => {
  return state.curr_org
}
export const menu_mode = state => {
  return state.menu_mode
}
export const curr_tenant = state => {
  return state.curr_tenant
}

export const curr_tenant_code = state => {
  return state.curr_tenant_code
}
export const loading_finish = state => {
  return state.loading_finish
}
export const collections = state => {
  return state.collections
}
export const citys = state => {
  return state.citys
}
export const resources = state => {
  return state.resources
}
export const dicts = state => {
  return state.dicts
}
export const logo = state => {
  return state.logo
}
export const featureAuth = state => {
  return state.featureAuth
}
export const description = state => {
  return state.description
}

export const embedd_bread_crumbs = state => {
  return state.embedd_bread_crumbs
}
export const last_activity = state => {
  return state.last_activity
}
