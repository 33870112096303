/*
 * @Description: 生产进度
 * @Version: 1.0.0
 * @Author: 李晨光
 * @Date: 2020-11-02 11:23:34
 * @LastEditors: 潘刚
 * @LastEditTime: 2021-07-06 17:53:57
 */

export const protectedRoute = [
  // 生产进度入口
  {
    path: '/main/productionSchedule',
    name: 'ProductionSchedule',
    component: () => import('$productionSchedule/views/Index'),
    meta: {
      name: '生产计划管理',
      isAuth: true,
      authCode: 'web_pm_productionSchedule_productionPlan',
      id: ''
    },
    children: [
      // 生产进度——总计划
      {
        path: '/main/productionSchedule/masterPlan',
        name: 'MasterPlan',
        component: () => import('$productionSchedule/views/masterPlan/Index'),
        children: [],
        meta: {
          name: '总计划',
          isAuth: true,
          authCode: 'web_pm_productionSchedule_productionPlan_masterPlan',
          id: ''
        }
      },
      // 生产进度——年计划
      {
        path: '/main/productionSchedule/yearlyPlan',
        name: 'YearlyPlan',
        component: () => import('$productionSchedule/views/yearlyPlan/Index'),
        children: [],
        meta: {
          name: '年计划',
          isAuth: true,
          authCode: 'web_pm_productionSchedule_productionPlan_yearlyPlan',
          id: ''
        }
      },
      // 生产进度——月计划
      {
        path: '/main/productionSchedule/monthlyPlan',
        name: 'MonthlyPlan',
        component: () => import('$productionSchedule/views/monthlyPlan/Index'),
        children: [],
        meta: {
          name: '月计划',
          isAuth: true,
          authCode: 'web_pm_productionSchedule_productionPlan_monthlyPlan',
          id: ''
        }
      },
      // 生产进度——周计划
      {
        path: '/main/productionSchedule/weeklyPlan',
        name: 'WeeklyPlan',
        component: () => import('$productionSchedule/views/weeklyPlan/Index'),
        children: [],
        meta: {
          name: '周计划',
          isAuth: true,
          authCode: 'web_pm_productionSchedule_productionPlan_weeklyPlan',
          id: ''
        }
      },
      // 生产进度——节点管理
      {
        path: '/main/productionSchedule/node',
        name: 'Node',
        component: () => import('$productionSchedule/views/nodeManagement/Index'),
        children: [],
        meta: {
          name: '节点管理',
          isAuth: true,
          authCode: 'web_pm_productionSchedule_nodePlan_nodeAdministration',
          id: ''
        }
      },
      // 生产进度——工程投诉管理
      {
        path: '/main/productionSchedule/dataFillIn/engineeringComplaint',
        name: 'engineeringComplaint',
        component: () => import('$productionSchedule/views/dataFillIn/engineeringComplaint'),
        children: [],
        meta: {
          name: '工程投诉管理',
          isAuth: true,
          authCode: 'web_pm_productionSchedule_processAdmin_engineeringComplaint',
          id: ''
        }
      },
      // 生产进度——信用中国公示表格
      {
        path: '/main/productionSchedule/dataFillIn/chinaTable',
        name: 'chinaTable',
        component: () => import('$productionSchedule/views/dataFillIn/chinaTable'),
        children: [],
        meta: {
          name: '信用中国公示台账',
          isAuth: true,
          authCode: 'web_pm_productionSchedule_processAdmin_chinaTable',
          id: ''
        }
      },
      // 生产进度——能耗信息
      {
        path: '/main/productionSchedule/dataFillIn/energyInformation',
        name: 'energyInformation',
        component: () => import('$productionSchedule/views/dataFillIn/energyInformation'),
        children: [],
        meta: {
          name: '节能减排与生态环境保护台账',
          isAuth: true,
          authCode: 'web_pm_productionSchedule_processAdmin_energyInformation',
          id: ''
        }
      },
      // 生产进度——工程维保管理
      {
        path: '/main/productionSchedule/dataFillIn/engineeringMaintenance',
        name: 'engineeringMaintenance',
        component: () => import('$productionSchedule/views/dataFillIn/engineeringMaintenance'),
        children: [],
        meta: {
          name: '工程回访与保修管理',
          isAuth: true,
          authCode: 'web_pm_productionSchedule_processAdmin_engineeringMaintenance',
          id: ''
        }
      },
      // 生产进度——项目信息台账
      {
        path: '/main/productionSchedule/dataFillIn/ProductPricePlan',
        name: 'ProductPricePlan',
        component: () => import('$productionSchedule/views/dataFillIn/ProductPricePlan'),
        children: [],
        meta: {
          name: '产值计划管理',
          isAuth: true,
          authCode: 'web_pm_productionSchedule_processAdmin_productPricePlan',
          id: ''
        }
      },
      // 生产进度——产值管理 (区别与产值计划管理 新增月度计划 增加了实际产值)
      {
        path: '/main/productionSchedule/dataFillIn/ProductionManage',
        name: 'ProductPricePlan',
        component: () => import('$productionSchedule/views/dataFillIn/ProductionManage'),
        children: [],
        meta: {
          name: '产值管理',
          isAuth: true,
          authCode: 'web_pm_productionSchedule_processAdmin_productionManage',
          id: ''
        }
      },
      // 生产进度——设置
      {
        path: '/main/productionSchedule/setting',
        name: 'Setting',
        component: () => import('$productionSchedule/views/settings/Index'),
        children: [],
        meta: {
          name: '延期设置',
          isAuth: true,
          authCode: 'web_pm_productionSchedule_basicsSetUp_setting',
          id: '',
          keepAlive: true,
          doFrech: false
        }
      },
      {
        path: '/main/productionSchedule/masterPlan/gantt',
        name: 'Gantt',
        component: () => import('$productionSchedule/views/masterPlan/Gantt'),
        children: [],
        meta: {
          name: '甘特图示例'
        }
      },
      // 导入页面
      {
        path: '/main/productionSchedule/Plan/GeneralFileImport',
        name: 'PlanGeneralFileImport',
        component: () => import('$productionSchedule/components/base/GeneralFileImport'),
        children: [],
        meta: {
          name: '计划导入'
        }
      }
    ]
  }
]
