/*
 * @Description:
 * @Version: 1.0.0
 * @Author: 李帅
 * @Date: 2020-11-09 11:27:31
 * @LastEditors: liupan
 * @LastEditTime: 2023-10-16 20:33:28
 */
/*
 * @Description:
 * @Version: 1.0.0
 * @Author: 李帅
 * @Date: 2020-11-09 11:27:31
 * @LastEditors: 李帅
 * @LastEditTime: 2020-12-11 15:13:25
 */
const settings = [
  {
    path: 'settings',
    name: 'Settings',
    meta: {
      name: '系统设置',
      keepAlive: false
    },
    component: () =>
      import(
        /*/* : "质量设置页面入口" */
        '$quality/views/settings/Index'
      ),
    children: [
      {
        path: 'qualityInspect',
        name: 'QualityInspect',
        meta: {
          name: '质量检查',
          keepAlive: false
        },
        component: () =>
          import(
            /*/* : "质量检查类型设置页面入口" */
            '$quality/views/settings/qualityInspect/Index'
          ),
        children: [
          {
            path: 'inspect',
            name: 'Inspect',
            meta: {
              name: '通用设置',
              keepAlive: false,
              isAuth: true,
              authCode: 'web_base_quality_settings'
            },
            component: () =>
              import(
                /*/* : "质量检查类型设置页面导入问题库入口" */
                '$quality/views/settings/qualityInspect/inspect/Index'
              ),
            children: []
          },
          {
            path: 'importProblemBase',
            name: 'ImportProblemBase',
            meta: {
              name: '导入问题库',
              keepAlive: false,
              isAuth: false,
              authCode: ''
            },
            component: () =>
              import(
                /*/* : "质量检查类型设置页面导入问题库入口" */
                '$quality/views/settings/qualityInspect/inspect/ImportProblemBase'
              ),
            children: []
          },
          {
            path: 'importChecklist',
            name: 'ImportChecklist',
            meta: {
              name: '导入检查表',
              keepAlive: false,
              isAuth: false,
              authCode: 'web_base_quality_settings_check_import'
            },
            component: () =>
              import(
                /*/* : "质量检查类型设置页面检查表导入入口" */
                '$quality/views/settings/qualityInspect/inspect/ImportChecklist'
              ),
            children: []
          },
          {
            path: 'addCheckList',
            name: 'AddCheckList',
            meta: {
              name: '新增检查表',
              keepAlive: false,
              isAuth: false,
              authCode: 'web_base_quality_settings_check_add'
            },
            component: () =>
              import(
                /*/* : "质量检查新增检查表新增页面入口" */
                '$quality/views/settings/qualityInspect/inspect/AddCheckList'
              ),
            children: []
          },
          {
            path: 'wbs',
            name: 'WBS',
            meta: {
              name: '项目基础数据'
            },
            component: () =>
              import(
                /*/* : "质量项目基础数据页面入口" */
                '$quality/views/settings/qualityInspect/wbs/Index'
              ),
            children: []
          },
          {
            path: 'wbsDismantling',
            name: 'WbsDismantling',
            meta: {
              name: 'wbs拆解',
              isAuth: false,
              authCode: ''
            },
            component: () =>
              import(
                /*/* : "质量wbs拆解页面入口" */
                '$quality/views/settings/qualityInspect/wbs/WbsDismantling'
              ),
            children: []
          },
          {
            path: 'planResponsibilityArea',
            name: 'PlanResponsibilityArea',
            meta: {
              name: '责任区域平面图',
              isAuth: true,
              authCode: 'web_base_quality_plan',
              id: ''
            },
            component: () =>
              import(
                /*/* : "质量责任区域平面图页面入口" */
                '$quality/views/settings/qualityInspect/planResponsibilityArea/Index'
              ),
            children: []
          },
          {
            path: 'planResponsibilityArea/edit',
            name: 'planResponsibilityAreaEdit',
            meta: {
              name: '编辑',
              isAuth: false,
              authCode: 'web_base_quality_plan_edit'
            },
            component: () =>
              import(
                /*/* : "质量责任区域平面图编辑页面入口" */
                '$quality/views/settings/qualityInspect/planResponsibilityArea/EditOrRecord'
              ),
            children: []
          },
          {
            path: 'planResponsibilityArea/record',
            name: 'planResponsibilityAreaRecord',
            meta: {
              name: '历史记录',
              isAuth: false,
              authCode: 'web_base_quality_plan_detail'
            },
            component: () =>
              import(
                /*/* : "质量责任区域平面图历史记录页面入口" */
                '$quality/views/settings/qualityInspect/planResponsibilityArea/EditOrRecord'
              ),
            children: []
          },
          {
            path: 'wbs/house',
            name: 'House',
            meta: {
              name: '房建',
              isAuth: true,
              authCode: 'web_base_quality_wbs',
              id: ''
            },
            component: () =>
              import(
                /*/* : "质量房建页面入口" */
                '$quality/views/settings/qualityInspect/wbs/house/Index'
              )
          }
        ]
      },
      {
        path: 'qualityAcceptance',
        name: 'QualityAcceptance',
        meta: {
          name: '质量验收',
          keepAlive: false
        },
        component: () =>
          import(
            /*/* : "质量验收设置页面入口" */
            '$quality/views/settings/qualityAcceptance/Index'
          ),
        children: [
          {
            path: 'standardSpecification',
            name: 'StandardSpecification',
            meta: {
              name: '验收标准规范库（自定义）',
              keepAlive: false,
              isAuth: true,
              authCode: 'web_base_quality_norm'
            },
            component: () =>
              import(
                /*/* : "质量检查类型设置页面导入问题库入口" */
                '$quality/views/settings/qualityAcceptance/standardSpecification/Index'
              ),
            children: []
          },
          {
            path: 'inspectionLot',
            name: 'InspectionLot',
            meta: {
              name: '检验批管理',
              keepAlive: false,
              isAuth: true,
              authCode: 'web_base_quality_lot',
              id: ''
            },
            component: () =>
              import(
                /*/* : "质量检查类型设置页面导入问题库入口" */
                '$quality/views/settings/qualityAcceptance/inspectionLot/Index'
              ),
            children: []
          },
          {
            path: 'maintenanceItems',
            name: 'MaintenanceItems',
            meta: {
              name: '标准分部分项库',
              keepAlive: false,
              isAuth: true,
              authCode: 'web_base_quality_standard'
            },
            component: () =>
              import(
                /*/* : "质量检查类型设置页面导入问题库入口" */
                '$quality/views/settings/qualityAcceptance/maintenanceItems/Index'
              ),
            children: []
          }
        ]
      },
      {
        path: 'engineeringSetting',
        name: 'EngineeringSetting',
        meta: {
          name: '工程报检',
          keepAlive: false
        },
        component: () =>
          import(
            /*/* : "质量验收设置页面入口" */
            '$quality/views/settings/engineeringSetting/Index'
          ),
        children: [
          {
            path: 'construction',
            name: 'Construction',
            meta: {
              name: '开工许可设置',
              keepAlive: false,
              isAuth: true,
              authCode: 'web_base_quality_engineeringSetting_construction'
            },
            component: () =>
              import(
                /*/* : "质量检查类型设置页面导入问题库入口" */
                '$quality/views/settings/engineeringSetting/construction/Index'
              ),
            children: []
          }
        ]
      }
    ]
  },
  {
    path: '/main/quality/ceshi',
    name: 'Ceshi',
    meta: {
      name: '测试专用'
    },
    component: () =>
      import(
        /*/* : "测试专用" */
        '$quality/views/ceshi/Index'
      ),
    children: []
  }
]
export default settings
