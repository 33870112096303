/*
 * @Description: 飞筑 协同
 * @Version: 1.0.0
 * @Author: 李晨光
 * @Date: 2020-11-02 11:23:34
 * @LastEditors: 王召强
 * @LastEditTime: 2024-07-31 10:24:19
 */
export const protectedRoute = [
    {
        path: '/main/oa',
        name: 'oa',
        component: () =>
            /*/* : "飞筑 协同" */
            import('$oa/views/Index'),
        children: [
            {
                path: 'constructionLog',
                name: 'ConstructionLog',
                component: () =>
                    import(
                        /*/* : "施工日志" */
                        '$oa/views/constructionLog/Index'
                    ),
                meta: {
                    name: '施工日志',
                    isAuth: true,
                    keepAlive: true,
                    authCode: 'web_pm_collaboration_constructionLog'
                }
            },
            {
                path: 'constructionLog/add',
                name: 'AddConstructionLog',
                component: () =>
                    import(
                        /*/* : "新增施工日志" */
                        '$oa/views/constructionLog/SetConstructionLog'
                    ),
                meta: {
                    isAuth: true,
                    name: '新增',
                    authCode: 'web_pm_collaboration_constructionLog_add'
                }
            },
            {
                path: 'constructionLog/edit',
                name: 'EditConstructionLog',
                component: () =>
                    import(
                        /*/* : "编辑施工日志" */
                        '$oa/views/constructionLog/SetConstructionLog'
                    ),
                meta: {
                    isAuth: true,
                    name: '编辑',
                    authCode: 'web_pm_collaboration_constructionLog_edit'
                }
            },
            {
                path: 'constructionLog/details',
                name: 'DetailsConstructionLog',
                component: () =>
                    import(
                        /*/* : "施工日志详情" */
                        '$oa/views/constructionLog/DetailsConstructionLog'
                    ),
                meta: {
                    isAuth: true,
                    name: '详情',
                    authCode: 'web_pm_collaboration_constructionLog_detail'
                }
            }
        ]
    }
]
