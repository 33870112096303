/*
 * @Description: 增加antd ui 组件
 * @Version: 1.0.0
 * @Author: 任笠
 * @Date: 2022-08-15 10:04:17
 * @LastEditors: 任笠
 * @LastEditTime: 2022-08-15 11:02:36
 */

// 导入ant组件
import "./core/components_use";
import * as all from "./mini";

export default all.default;
export const setFormDesignConfig = all.setFormDesignConfig;
export const setFormBuildConfig = all.setFormBuildConfig;
export const KFormDesign = all.KFormDesign;
export const KFormPreview = all.KFormPreview;
export const KFormBuild = all.KFormBuild;
export const KFormItem = all.KFormItem;
