/*
 * @Description:
 * @Version: 1.0.0
 * @Author: 李晨光
 * @Date: 2020-08-20 14:11:45
 * @LastEditors: 李晨光
 * @LastEditTime: 2020-09-04 16:42:58
 */
import Vue from 'vue'
Vue.prototype.$eventBus = new Vue()

// 发布者 (监听事件)  一直监听
////   this.$eventBus.on('事件', () => {})
// 监听一次
////   this.$eventBus.$once('事件', () => {})
// 订阅者 (触发事件)
////   this.$eventBus.$emit('事件', params)
// 移除事件监听
////   this.$eventBus.$off('事件', params)
