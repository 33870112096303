import { render, staticRenderFns } from "./operatingArea.vue?vue&type=template&id=6ea523bf"
import script from "./operatingArea.vue?vue&type=script&lang=js"
export * from "./operatingArea.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/gitlab-runner/ci-build-dir/huazhu-cloud/vue/construction-cloud/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6ea523bf')) {
      api.createRecord('6ea523bf', component.options)
    } else {
      api.reload('6ea523bf', component.options)
    }
    module.hot.accept("./operatingArea.vue?vue&type=template&id=6ea523bf", function () {
      api.rerender('6ea523bf', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/public/components/lib/k-form-design/packages/KFormDesign/module/operatingArea.vue"
export default component.exports