/*
 * @Description: 路由名称配置
 * @Version: 1.0.0
 * @Author: 赵亮
 * @Date: 2021-10-20 09:45:34
 * @LastEditors: 赵亮
 * @LastEditTime: 2021-10-20 09:57:50
 */
const routerName = path => {
  const routerNameObj = {
    standard: {
      MechanicalSetting: '机械参数设置',
      GuidanceLib: '机械指导库设置',
      HiddenDanger: '隐患台账',
      EditHiddenDanger: '编辑隐患台账',
      HiddenDangerDetail: '详情',
      KeyLib: '关键资料管理',
      fillForm: 'BI填报设置',
      towerCraneMonitor: '塔机监测',
      EquipInspection: '检查管理',
      EquipInspectionPlanDetial: '详情',
      EquipmentRepair: '维修台账',
      EquipmentRepairDetail: '详情',
      EquipmentAcceptance: '验收管理',
      EquipmentAcceptanceDetail: '详情',
      EquipmentAcceptanceEdit: '编辑验收管理',
      EquipmentMaintain: '保养管理',
      EquipmentMaintainDetail: '详情',
      EquipmentMaintainEdit: '编辑保养管理',
      EquipmentSupplier: '供应商管理',
      SpecialWorker: '人员管理',
      AddLaborRosterMechanical: '新增',
      EditLaborRosterMechanical: '编辑'
    }, // 标版
    bjnf: {
      MechanicalSetting: '机械设置',
      GuidanceLib: '指导库',
      HiddenDanger: '机械隐患',
      EditHiddenDanger: '编辑机械隐患',
      HiddenDangerDetail: '机械隐患详情',
      KeyLib: '关键资料',
      fillForm: '表单填报',
      towerCraneMonitor: '塔机监测',
      EquipInspection: '机械检查',
      EquipInspectionPlanDetial: '机械检查详情',
      EquipmentRepair: '机械维修',
      EquipmentRepairDetail: '机械维修详情',
      EquipmentAcceptance: '机械验收',
      EquipmentAcceptanceDetail: '机械验收详情',
      EquipmentAcceptanceEdit: '编辑机械验收',
      EquipmentMaintain: '机械保养',
      EquipmentMaintainDetail: '机械保养详情',
      EquipmentMaintainEdit: '编辑机械保养',
      EquipmentSupplier: '机械供应商管理',
      SpecialWorker: '特种人员',
      AddLaborRosterMechanical: '新增特种人员',
      EditLaborRosterMechanical: '编辑特种人员'
    } // 八局南方
  }
  let routerName = routerNameObj[process.env.VUE_APP_VERSION][path]
  return routerName
}
export default routerName
