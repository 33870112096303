/*
 * @Descripttion: action
 * @Version: 1.0.0
 * @Author: 李晨光
 * @Date: 2020-07-07 13:46:46
 * @LastEditors: 任笠
 * @LastEditTime: 2021-09-23 10:13:37
 */

import {
  SET_NAME,
  SET_LOGINMSG,
  SET_CONTRACTOR_INFO,
  SET_COLOR,
  SET_OPENKEYS,
  SET_CURR_ORG,
  SET_MENU_MODE,
  SET_CURR_TENANT,
  SET_LOADING_FINISH,
  SET_COLLECTIONS,
  SET_RESOURCES,
  SET_DICTS,
  SET_LOGO,
  SET_AUTHBUTTON,
  SET_EMBEDD_BREAD_CRUMBS,
  SET_LAST_ACTIVITY
} from './mutationTypes'
export default {
  ColorAsyn({ commit }, color) {
    commit('SET_COLOR', color)
  },
  OpenKeysAsyn({ commit }, open_keys) {
    commit('SET_OPENKEYS', open_keys)
  },
  CurrOrgAsyn({ commit }, curr_org) {
    commit('SET_CURR_ORG', curr_org)
  },
  CurrMenuAsyn({ commit }, menu_mode) {
    commit('SET_MENU_MODE', menu_mode)
  },
  CurrTenantAsyn({ commit }, curr_tenant) {
    commit('SET_CURR_TENANT', curr_tenant)
  },
  CurrTenantCodeAsyn({ commit }, curr_tenant_code) {
    commit('SET_CURR_TENANT_CODE', curr_tenant_code)
  },
  LoadingFinishAsyn({ commit }, loading_finish) {
    commit('SET_LOADING_FINISH', loading_finish)
  },
  collectionsAsyn({ commit }, collections) {
    commit('SET_COLLECTIONS', collections)
  },
  citysAsyn({ commit }, citys) {
    commit('SET_CITYS', citys)
  },
  resourcesAsyn({ commit }, resources) {
    commit('SET_RESOURCES', resources)
  },
  featureAuthSync({ commit }, featureAuth) {
    commit('SET_AUTHBUTTON', featureAuth)
  },
  dictsAsyn({ commit }, dicts) {
    commit('SET_DICTS', dicts)
  },
  logoAsyn({ commit }, logo) {
    commit('SET_LOGO', logo)
  },
  contractorInfoAsyn({ commit }, contractorInfo) {
    commit('SET_CONTRACTOR_INFO', contractorInfo)
  },
  setEmbeddDreadCrumbsAsyn({ commit }, embeddBreadCrumbs) {
    commit('SET_EMBEDD_BREAD_CRUMBS', embeddBreadCrumbs)
  },
  lastactivityAsyn({ commit }, lastactivity) {
    commit('SET_LAST_ACTIVITY', lastactivity)
  }
}
