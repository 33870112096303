/*
 * @Description: 路由名称配置
 * @Version: 1.0.0
 * @Author: 赵亮
 * @Date: 2021-10-20 09:45:34
 * @LastEditors: 李晨光
 * @LastEditTime: 2023-03-25 15:02:15
 */
const routerName = path => {
  const routerNameObj = {
    standard: {
      SubcontractorsLib: '分包供应商名录',
      GroupManage: '作业班组管理',
      MonthlySummary: '月度考勤',
      CompanyAttendanceInformationAccount: '历史考勤统计',
      DailyAttendanceStatistics: '每日出勤记录'
    }, // 标版
    bjnf: {
      SubcontractorsLib: '分包单位库',
      GroupManage: '班组管理',
      MonthlySummary: '月度汇总',
      CompanyAttendanceInformationAccount: '考勤信息统计',
      DailyAttendanceStatistics: '每日统计'
    } // 八局南方
  }
  let routerName = routerNameObj[process.env.VUE_APP_VERSION][path]
  return routerName
}
export default routerName
