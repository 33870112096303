/*
 * @Description:
 * @Version: 1.0.0
 * @Author: 李帅
 * @Date: 2020-11-09 11:27:31
 * @LastEditors: 李帅
 * @LastEditTime: 2023-09-19 16:53:05
 */
const dataStatistics = [
  /*/* : "检查数据统计分析页面入口" */
  {
    path: 'dataStatistics',
    name: 'DataStatistics',
    meta: {
      name: '检查数据统计分析',
      keepAlive: false,
      isAuth: false,
      authCode: ''
    },
    component: () => import('$quality/views/dataStatistics/Index'),
    children: [
      /*/* : "检查数据统计分析页面入口" */
      {
        path: 'inspection',
        name: 'Inspection',
        meta: {
          name: '质量检查统计分析',
          keepAlive: false,
          isAuth: true,
          authCode: 'web_pm_quality_data_dataStatistics'
        },
        component: () => import('$quality/views/dataStatistics/inspection/Index'),
        children: []
      },
      /*/* : "专项检查数据统计分析页面入口" */
      {
        path: 'special',
        name: 'Special',
        meta: {
          name: '专项检查数据统计分析',
          keepAlive: false,
          isAuth: false,
          authCode: ''
        },
        component: () => import('$quality/views/dataStatistics/special/Index'),
        children: []
      },
      /*/* : "质量验收统计分析页面入口" */
      {
        path: 'acceptance',
        name: 'Acceptance',
        meta: {
          name: '质量验收统计分析',
          keepAlive: false,
          isAuth: true,
          authCode: 'web_pm_quality_data_statistics'
        },
        component: () => import('$quality/views/dataStatistics/acceptance/Index'),
        children: []
      },
      // 质量行为分析
      {
        path: 'behavior',
        name: 'Behavior',
        meta: {
          name: '质量行为统计分析',
          keepAlive: false,
          isAuth: true,
          authCode: 'web_pm_quality_data_behavior'
        },
        component: () => import('$quality/views/dataStatistics/behavior/Index'),
        children: []
      }
    ]
  }
]
export default dataStatistics
