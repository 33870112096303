/*
 * @Description:
 * @Version: 1.0.0
 * @Author: 李帅
 * @Date: 2021-01-19 10:27:19
 * @LastEditors: 赵亮
 * @LastEditTime: 2024-04-23 10:42:00
 */
const qualityAcceptance = [
  {
    path: 'qualityAcceptance',
    name: 'QualityAcceptance',
    meta: {
      name: '质量验收'
    },
    component: () =>
      import(
        // 质量验收
        '$quality/views/qualityAcceptance/Index'
      ),
    children: [
      {
        path: 'list',
        name: 'QualityAcceptanceLists',
        meta: {
          name: '自定义验收台账',
          isAuth: true,
          keepAlive: true,
          authCode: 'web_pm_quality_acceptance_standing'
        },
        component: () =>
          import(
            // 验收台账
            '$quality/views/qualityAcceptance/QualityAcceptance'
          ),
        children: []
      },
      {
        path: 'record',
        name: 'QualityAcceptanceRecord',
        meta: {
          name: '质量验收记录',
          isAuth: true,
          authCode: 'web_pm_quality_acceptance_standing_record'
        },
        component: () =>
          import(
            // 质量验收记录
            '$quality/views/qualityAcceptance/QualityAcceptanceRecord'
          ),
        children: []
      },
      {
        path: 'record/detail',
        name: 'QualityAcceptanceRecordDetail',
        meta: {
          name: '质量验收记录详情',
          isAuth: false,
          authCode: 'web_pm_quality_acceptance_standing_record'
        },
        component: () =>
          import(
            // 质量验收记录详情
            '$quality/views/qualityAcceptance/QualityAcceptanceRecordDetail'
          ),
        children: []
      },
      {
        path: 'record/questionDetails',
        name: 'QuestionDetails',
        meta: {
          name: '问题详情',
          isAuth: false,
          authCode: 'web_pm_quality_acceptance_standing_record'
        },
        component: () =>
          import(
            // 质量验收记录详情
            '$quality/views/qualityAcceptance/QuestionDetails'
          ),
        children: []
      },
      {
        path: 'overview',
        name: 'Overview',
        meta: {
          name: '验收进度总览',
          isAuth: true,
          keepAlive: true,
          authCode: 'web_pm_quality_acceptance_overview'
        },
        component: () =>
          import(
            // 验收进度总览
            '$quality/views/qualityAcceptance/overview/Index'
          ),
        children: []
      },
      {
        path: '/measuredRealQuantity/MeasuredRealQuantityLibrary',
        name: 'MeasuredRealQuantityLibrary',
        meta: {
          name: '实测实量库',
          isAuth: true,
          authCode: 'web_base_quality_quantityLlibrary',
          id: ''
        },
        component: () =>
          import(
            /*/* : "实测实量库" */
            '$quality/views/measuredRealQuantity/MeasuredRealQuantityLibrary'
          ),
        children: []
      },
      {
        path: '/measuredRealQuantity/MeasuringParameter',
        name: 'MeasuringParameter',
        meta: {
          name: '实测实量台账',
          isAuth: true,
          authCode: 'web_pm_quality_measuring_parameter'
        },
        component: () =>
          import(
            /*/* : "实测实量台账" */
            '$quality/views/measuredRealQuantity/MeasuringParameter'
          ),
        children: []
      }
    ]
  }
]
export default qualityAcceptance
