/** * @Author: 李晨光 * @Date: 2019/8/20 * @Version: 3.0 * @Last Modified by: 任笠 * @Last Modified
time: 2021-12-28 **/
<comment>
  # 组件注释
  修改密码
</comment>
<template>
  <div id="ChangePwd">
    <a-modal
      title="修改密码"
      centered
      :width="500"
      :visible="formModalVisible"
      :mask-closable="false"
      @ok="changePwd"
      @cancel="closeModal()">
      <a-skeleton active :loading="loading" :paragraph="{ rows: 10 }">
        <a-form :form="form">
          <a-row :gutter="20">
            <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <a-form-item
                label="当前密码"
                :validate-status="formError(form, 'oldPassword') ? 'error' : ''"
                :help="formError(form, 'oldPassword') || ''">
                <a-input
                  v-decorator="[
                    'oldPassword',
                    { rules: [{ required: true, message: '请输入当前密码！' }] }
                  ]"
                  type="password"
                  placeholder="请输入密码" />
              </a-form-item>
              <a-form-item
                label="新密码"
                :validate-status="formError(form, 'password') ? 'error' : ''"
                :help="formError(form, 'password') || ''">
                <a-input
                  v-decorator="[
                    'password',
                    {
                      rules: [
                        { required: true, message: '请输入 8-16 位字符（字母、数字和符号的组合）' },
                        {
                          pattern: Util.password,
                          message: '请输入 8-16 位字符（字母、数字和符号的组合）'
                        }
                      ]
                    }
                  ]"
                  type="password"
                  placeholder="请输入 8-16 位字符（字母、数字和符号的组合）"
                  @keyup="inputKeyUp" />
              </a-form-item>
              <a-form-item
                label="确认密码"
                :validate-status="formError(form, 'newPassword') ? 'error' : ''"
                :help="formError(form, 'newPassword') || ''">
                <a-input
                  v-decorator="[
                    'newPassword',
                    {
                      rules: [
                        { required: true, message: '请输入 8-16 位字符（字母、数字和符号的组合）' },
                        {
                          pattern: Util.password,
                          message: '请输入 8-16 位字符（字母、数字和符号的组合）'
                        },
                        { validator: compareToFirstPassword }
                      ]
                    }
                  ]"
                  type="password"
                  placeholder="请再次输入新密码"
                  @blur="handleConfirmBlur"
                  @keyup="inputKeyUp" />
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </a-skeleton>
      <template slot="footer">
        <a-button @click="closeModal()">取 消</a-button>
        <a-button
          style="margin-left: 10px"
          type="primary"
          :disabled="hasErrors(form.getFieldsError())"
          @click="changePwd"
          >确 认
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { Encrypt } from '$public/core/desense'
import Cookie from 'js-cookie'

export default {
  name: 'ChangePwd',
  components: {},
  props: {},
  data() {
    return {
      Util,
      isPlatform: '',
      api: {
        changePwdApi: '/web/passport/user/update-pw' // 修改密码
      },
      form: this.$form.createForm(this),
      formModalVisible: false,
      confirmDirty: false, // 新密码二次确认
      formParams: {
        oldPassword: undefined, // 旧密码
        newPassword: undefined // 新密码
      },
      loading: false
    }
  },
  computed: {},
  created() {
    this.isPlatform = Cookie.get('isPlatform')
  },
  mounted() {},
  watch: {},
  methods: {
    openModal() {
      this.formModalVisible = true
      this.$nextTick(() => {
        this.form.validateFields()
      })
    },
    closeModal() {
      this.formModalVisible = false
      this.form.resetFields()
    },
    // 验证表单 获取表单validate 状态 按钮根据状态可不可操作
    formError(form, field) {
      const { getFieldError, isFieldTouched } = form
      return isFieldTouched(field) && getFieldError(field)
    },
    hasErrors(fieldsError) {
      console.log(Object.keys(fieldsError).some(field => fieldsError[field]))
      return Object.keys(fieldsError).some(field => fieldsError[field])
    },
    // 验证密码
    compareToFirstPassword(rule, value, callback) {
      const form = this.form
      if (
        value &&
        value.length >= 8 &&
        value.length <= 16 &&
        value !== form.getFieldValue('password')
      ) {
        callback('您输入的两个密码不一致!')
      } else {
        callback()
      }
    },
    // 键抬起时触发校验
    inputKeyUp() {
      this.$nextTick(() => {
        this.form.validateFields()
      })
    },
    handleConfirmBlur(e) {
      const value = e.target.value
      this.confirmDirty = this.confirmDirty || !!value
    },
    /**
     * 修改密码
     */
    changePwd() {
      this.form.validateFields((err, values) => {
        if (!err) {
          Object.assign(this.formParams, values)
          delete this.formParams.password
          this.formParams.oldPassword = Encrypt(this.formParams.oldPassword)
          this.formParams.newPassword = Encrypt(this.formParams.newPassword)
          this.$post(this.api.changePwdApi, this.formParams)
            .then(response => {
              this.loading = false
              if (response.statusCode == 200) {
                Util.processRes(response, () => {
                  this.closeModal()
                  this.$message.success(response.statusMessage)
                  // 成功
                  Cookie.remove('jwt')
                  localStorage.removeItem('currentData')
                  localStorage.removeItem('resources')
                  if (this.isPlatform == 'yes') {
                    this.$router.push({ name: 'LoginByAdmin' })
                  } else {
                    this.$router.push({ name: 'Login' })
                    this.$store.commit('USER_SIGNOUT')
                  }
                  // setTimeout(() => {
                  //   this.$router.back()
                  // }, 500);
                })
              } else {
                this.$message.error(response.statusMessage)
                this.loading = false
              }
              // Util.processError(this, err);
            })
            .catch(err => {
              console.log(err)
              this.$message.error(err.statusMessage)
              this.loading = false
            })
        }
      })
    }
  },
  destroyed() {}
}
</script>

<style lang="less">
#ChangePwd {
  background: rgba(248, 248, 248, 1);
  height: 100%;
  padding: 5% 15% 10%;
  margin: 0 auto;
}
</style>
