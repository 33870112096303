/*
 * @Description:
 * @Version: 1.0.0
 * @Author: 申文慧
 * @Date: 2024-04-25 16:22:18
 * @LastEditors: 申文慧
 * @LastEditTime: 2024-04-29 15:26:06
 */
const behaviorSafetyStar = [
  {
    path: '/labor/behaviorSafetyStar/basicSettings',
    name: 'BasicSettings',
    component: () => import('$labor/views/behaviorSafetyStar/BasicSettings.vue'), // 行为与安全
    meta: {
      name: '基础设置',
      isAuth: true,
      authCode: 'web_base_labor_behaviorSafetyStar_basicSettings'
    }
  },
  {
    path: '/labor/behaviorSafetyStar/behaviorObservationSettings',
    name: 'BehaviorObservationSettings',
    component: () => import('$labor/views/behaviorSafetyStar/BehaviorObservationSettings.vue'),
    meta: {
      name: '行为观察设置',
      isAuth: true,
      authCode: 'web_base_labor_behaviorSafetyStar_behaviorObservationSettings'
    }
  },

  {
    path: '/labor/behaviorSafetyStar/productSettings',
    name: 'ProductSettings',
    component: () => import('$labor/views/behaviorSafetyStar/ProductSettings.vue'),
    meta: {
      name: '商品设置',
      isAuth: true,
      authCode: 'web_base_labor_behaviorSafetyStar_productSettings'
    }
  },
  {
    path: '/labor/behaviorSafetyStar/hiddenReportingRecords',
    name: 'HiddenReportingRecords',
    component: () => import('$labor/views/behaviorSafetyStar/HiddenReportingRecords.vue'),
    meta: {
      name: '隐患上报记录',
      isAuth: true,
      authCode: 'web_pm_labor_behaviorSafetyStar_hiddenReportingRecords'
    }
  },
  {
    path: '/labor/behaviorSafetyStar/hiddenReportingRecords',
    name: 'HiddenReportingRecords',
    component: () => import('$labor/views/behaviorSafetyStar/HiddenReportingRecords.vue'),
    meta: {
      name: '隐患上报记录',
      isAuth: true,
      authCode: 'web_pm_labor_behaviorSafetyStar_hiddenReportingRecords'
    }
  },
  {
    //检查任务详情上级-子详情
    path: '/labor/behaviorSafetyStar/hiddenReportingRecords/detail',
    name: 'DetailHidden',
    component: () =>
      import('$labor/components/behaviorSafetyStar/hiddenReportingRecords/DetailHidden'),
    meta: {
      name: '详情',
      isAuth: false
      // authCode: 'web_pm_safety_hiddenDanger_checkTask'
    }
  },
  {
    path: '/labor/behaviorSafetyStar/behaviorObservationRecords',
    name: 'BehaviorObservationRecords',
    component: () => import('$labor/views/behaviorSafetyStar/BehaviorObservationRecords.vue'),
    meta: {
      name: '行为观察记录',
      isAuth: true,
      authCode: 'web_pm_labor_behaviorSafetyStar_behaviorObservationRecords'
    }
  },
  {
    path: '/behaviorSafetyStar/pointsLedger',
    name: 'PointsLedger',
    component: () => import('$labor/views/pointPenalty/PointsLedger.vue'),
    meta: {
      name: '工人积分台账',
      isAuth: true,
      authCode: 'web_pm_labor_behaviorSafetyStar_pointsLedger'
    }
  },
  {
    path: '/pointPenalty/OrderManagement',
    name: 'OrderManagement',
    component: () => import('$labor/views/pointPenalty/OrderManagement.vue'),
    meta: {
      name: '订单管理',
      isAuth: true,
      authCode: 'web_pm_labor_behaviorSafetyStar_orderManagement'
    }
  },
  {
    path: '/behaviorSafetyStar/pointsLedger/record',
    name: 'behaviorPointsLedgerRecord',
    component: () => import('$labor/views/pointPenalty/PointsLedgerRecord.vue'),
    meta: {
      name: '积分记录',
      isAuth: true,
      authCode: 'web_pm_labor_behaviorSafetyStar_pointsLedger_pointsRecord'
    }
  },
  {
    path: '/labor/behaviorSafetyStar/pointsVerificationRecord',
    name: 'PointsVerificationRecord',
    component: () => import('$labor/views/behaviorSafetyStar/PointsVerificationRecord.vue'),
    meta: {
      name: '积分核销记录',
      isAuth: true,
      authCode: 'web_pm_labor_behaviorSafetyStar_pointsVerificationRecord'
    }
  }
]
export default behaviorSafetyStar
