import throttle from 'lodash.throttle'
const viewWidth = window.innerWidth || document.documentElement.clientWidth
const viewHeight = window.innerHeight || document.documentElement.clientHeight
let $ = dom => document.querySelectorAll(dom)

function Scroll({
  el = '.ant-table-body', // 当前要滚动元素
  bgColor = '#FAFAFA', // scroll背景色
  color = '#C0C4CC', // 滚动块颜色
  hoverColor = '#606266', // 滚动块颜色
  scrollXHeight = '16', // 显示scrollX高度
  scrollXChildHeight = '8', // scrollX child 高度
  showScrollX = true, // 显示scrollX
  isEnableHoverShow = false, // 是否启用鼠标移入显示scroll 移出取消显示
  type = 'page' //table所在父级范围 page页面 modal弹窗
}) {
  let observer = null
  let affixBox = null
  if ($('section>main .ant-affix').length) {
    affixBox = $('section>main .ant-affix')[0]
  }
  if ($('section>main .affix-box').length) {
    affixBox = $('section>main .affix-box')[0]
  }

  function getOptions(el) {
    if (!showScrollX) return
    let options = $(el)
    if (options && options.length)
      for (let i = 0, length = options.length; i < length; i++) {
        isShowEle(options[i])
      }
  }

  /**
   * @Description:
   * @Param:
   * @Return:
   * @param {*} element
   */
  function setOptions(element) {
    let scrollXWidth = element.clientWidth
    element.style.position = 'relative'
    element.style.boxSizing = 'border-box'
    element.children[0].style.position = 'relative'
    if (element.children && [...element.children].map(v => v.className).indexOf('scroll-x') === -1)
      createX(element)

    let scrollX = $('scroll-x')[0]

    isShowScroll(0)

    isEnableHover(element)

    // 固定滚动条X轴样式
    function createX(element) {
      let content = element.children[0]
      let scrollX = document.createElement('div')
      scrollX.className = 'scroll-x'
      scrollX.innerHTML = '<i></i>'
      scrollX.style.position = 'fixed'
      scrollX.style.width = scrollXWidth + 'px'
      scrollX.style.bottom = (affixBox ? affixBox.clientHeight : 0) + 'px'
      scrollX.style.left = element.entry.boundingClientRect.left + 'px'
      scrollX.style.height = scrollXHeight + 'px'
      scrollX.style.zIndex = '99'
      scrollX.style.boxShadow = 'inset 0 1px 0 0 #EBEEF5, inset 0 -1px 0 0 #EBEEF5'
      scrollX.style.background = bgColor
      element.appendChild(scrollX)
      let scrollBlock = scrollX.children[0]
      scrollBlock.style.position = 'absolute'
      scrollBlock.style.left = '0'
      scrollBlock.style.top = '0'
      scrollBlock.style.bottom = '0'
      scrollBlock.style.margin = 'auto'
      content.style.left = '0'
      scrollBlock.style.width =
        Math.ceil((element.clientWidth * element.clientWidth) / element.scrollWidth) + 'px' // 设置X轴滚动元素宽度
      scrollBlock.style.height = scrollXChildHeight + 'px'
      scrollBlock.style.background = color
      scrollBlock.style.borderRadius = scrollXChildHeight + 'px'

      if (element.clientWidth >= content.clientWidth) {
        content.style.marginBottom = '0px'
        scrollX.style.display = 'none'
      }

      move({ dv: scrollBlock, element, scrollBlock, scrollX }) // 添加拖拽事件
      scrollXEvent(scrollX, scrollBlock, element)
      element.addEventListener('scroll', () => updateScrollX(element, scrollX))
    }

    //scrollX事件
    function scrollXEvent(scrollX, scrollBlock, element) {
      // 没有用click事件是因为鼠标在拖动过程中如果在scrollX中松开左键也会触发该事件，导致滑块未滑动
      scrollX.onmousedown = function (e) {
        // X轴点击事件
        if (this === e.target) {
          let scale = scrollX.clientWidth / element.scrollWidth
          let left =
            e.offsetX > scrollBlock.clientWidth + scrollBlock.offsetLeft
              ? scrollBlock.clientWidth
              : -scrollBlock.clientWidth
          element.scrollBy({ left: left / scale, top: 0, behavior: 'smooth' })
          isShowScroll(1)
        }
      }

      // X轴鼠标移入事件
      scrollX.onmouseover = () => (scrollBlock.style.background = hoverColor)

      // X轴鼠标移出事件
      scrollX.onmouseout = () => (scrollBlock.style.background = color)
    }
    //鼠标移动
    function move({ dv, element, scrollBlock, scrollX }) {
      //获取元素
      let x = 0
      let y = 0
      let l = 0
      let t = 0
      let isDown = false
      //鼠标按下事件
      dv.addEventListener('mousedown', function (e) {
        e.preventDefault()
        //获取x坐标和y坐标
        x = e.clientX
        y = e.clientY
        //获取左部和顶部的偏移量
        l = dv.offsetLeft
        t = dv.offsetTop
        //开关打开
        isDown = true
      })

      //鼠标移动
      window.addEventListener('mousemove', function (e) {
        if (isDown == false) {
          return
        }
        scrollBlock.style.background = hoverColor
        //获取x和y
        let nx = e.clientX
        let ny = e.clientY
        //计算移动后的左偏移量和顶部的偏移量 *2是提高滚动速率2倍
        let nl = nx - (x - l)
        let nt = ny - (y - t)
        if (dv.parentElement.className === 'scroll-x') {
          // X轴
          nl = nl < 0 ? 0 : nl
          nl =
            nl > scrollX.clientWidth - scrollBlock.clientWidth
              ? scrollX.clientWidth - scrollBlock.clientWidth
              : nl
          element.scroll(nl / (scrollX.clientWidth / element.scrollWidth), 0)
        }
        isShowScroll(1)
      })

      //鼠标抬起事件
      window.addEventListener('mouseup', function () {
        scrollBlock.style.background = color
        //获取左部和顶部的偏移量
        l = dv.offsetLeft
        t = dv.offsetTop
        //开关关闭
        isDown = false
        isShowScroll(0)
      })

      // 鼠标离开事件
      window.addEventListener('mouseleave', function () {
        scrollBlock.style.background = color
        //开关关闭
        isDown = false
        isShowScroll(0)
      })
    }
    //是否显示scroll
    function isShowScroll(num) {
      if (isEnableHoverShow) {
        if (showScrollX) {
          scrollX.style.opacity = num
        }
      }
    }

    //是否启用鼠标移入显示scroll
    function isEnableHover(element) {
      // 鼠标移入显示滚动条
      element.onmouseover = () => isShowScroll(1)
      // 鼠标移出隐藏滚动条
      element.onmouseout = () => isShowScroll(0)
    }
  }

  let winScroll = throttle(() => {
    let options = $(el)
    if (options && options.length)
      for (let i = 0, length = options.length; i < length; i++) {
        isShowEle(options[i])
      }
  }, 100)

  function isShowEle(option) {
    let scrollHeight = type === 'modal' ? $('.ant-modal-body')[0].clientHeight : viewHeight
    observer = new IntersectionObserver(
      entries => {
        let scrollX = [...option.children].find(v => v.className === 'scroll-x')
        let entry = entries[0]
        option.entry = entry
        let { bottom, left, top, height } = entry.boundingClientRect
        // 重新设置滚动条位置 防止页面存在滚动条与弹窗中滚动条位置冲突
        if (scrollX) scrollX.style.left = left + 'px'
        // 当前内容是否存在滚动条
        if (option.scrollWidth > option.clientWidth) {
          let modalRect = $('.ant-modal-body')[0]?.getBoundingClientRect()
          // 页面中存在a-affix 距下高度为affix高度 否则为0
          let affixBoxHeight = affixBox ? affixBox.clientHeight : 0
          // table表头高度
          let theadHeight = option.querySelector('.ant-table-thead')?.clientHeight || 0
          // 可见
          // 部分可见
          // intersectionRatio>0 && intersectionRatio<1 部分可见
          if (entry.intersectionRatio > 0) {
            let otherHeight = type === 'modal' ? -modalRect.top : affixBoxHeight
            if (
              bottom + otherHeight < scrollHeight ||
              top + theadHeight + otherHeight > scrollHeight
            ) {
              if (scrollX) scrollX.style.display = 'none'
            } else {
              if (scrollX) scrollX.style.display = 'block'
              else setOptions(option)
            }
          }
          // 完全可见
          if (entry.intersectionRatio === 1) {
            // 弹窗中的距离父级下边框距离为0 页面中存在a-affix 距下高度为affix高度 否则为0
            let otherHeight = type === 'modal' ? 0 : affixBoxHeight
            if (affixBox) {
              if (scrollX)
                scrollX.style.display = scrollHeight - bottom < otherHeight ? 'block' : 'none'
            } else {
              if (scrollX) scrollX.style.display = 'none'
            }
          }
          // 不可见
          if (entry.intersectionRatio === 0) {
            if (scrollX) scrollX.style.display = 'none'
          }
          if (scrollX) updateScrollX(option, scrollX)
        } else {
          if (scrollX) scrollX.style.display = 'none'
        }
      },
      {
        root: type === 'modal' ? $('.ant-modal-body')[0] : document.body
      }
    )

    observer.observe(option)
  }

  function updateScrollX(options, scrollX) {
    scrollX.style.width = options.clientWidth + 'px'
    if (type === 'modal') {
      let bottom = $('.ant-modal-body')[0].getBoundingClientRect().bottom
      scrollX.style.bottom = viewHeight - bottom + 'px'
    }
    let scrollBlock = scrollX?.children[0]
    if (scrollBlock) {
      scrollBlock.style.left =
        (options.scrollLeft * (scrollX.clientWidth / options.scrollWidth)).toFixed(0) + 'px'

      scrollBlock.style.width =
        Math.ceil((options.clientWidth / options.scrollWidth) * scrollX.clientWidth) + 'px' // 设置X轴滚动元素宽度
    }
  }

  function findScroller(element) {
    element.onscroll = function () {
      winScroll()
    }
    Array.from(element.children).forEach(findScroller)
  }

  /**
   * @Description: resize scroll事件
   * @Param:
   * @Return:
   */
  function eventCenter() {
    if (window.addEventListener) {
      window.addEventListener('resize', () => getOptions(el), false)
      findScroller(document.body)
    }
  }

  function setTableScrollStyle() {
    let scrollStyle = `
      <style css-id="scroll">
        /* 滚动条 */
        /*定义整个滚动条高宽及背景：高宽分别对应横竖滚动条的尺寸*/
        .ant-table-body::-webkit-scrollbar
        {
            background-color:${bgColor};
        }
        /*定义滚动条轨道：内阴影+圆角*/
        .ant-table-body::-webkit-scrollbar-track
        {
            box-shadow:inset 0 1px 0 0 #EBEEF5, inset 0 -1px 0 0 #EBEEF5;
            background-color:${bgColor};
        }
        /*定义滑块：内阴影+圆角*/
        .ant-table-body::-webkit-scrollbar-thumb
        {
            border-radius:${scrollXChildHeight}px;
            background-color:${color};
        }
        .ant-table-body::-webkit-scrollbar-thumb:hover{
          background-color:${hoverColor};
        }
      </style>
    `
    let div = document.createElement('div')
    let _scrollNode = document.querySelector("[css-id='scroll']") || null
    if (_scrollNode) {
      document.querySelector('head').removeChild(document.querySelector("[css-id='scroll']"))
    }
    div.innerHTML = scrollStyle
    let newScrollNode = div.querySelector("[css-id='scroll']")
    document.getElementsByTagName('head')[0].appendChild(newScrollNode)
  }

  function init() {
    setTableScrollStyle() // 设置table默认滚动条样式
    getOptions(el)
    eventCenter()
  }

  init()
}

export default Scroll
