/*
 * @Description:
 * @Version: 1.0.0
 * @Author: 李晨光
 * @Date: 2020-08-20 14:11:46
 * @LastEditors: 任笠
 * @LastEditTime: 2021-09-23 10:16:16
 */

const state = {
  color: '#1890FF', //主题色
  token: '',
  login_msg: {}, //登录信息
  // 参建单位登录信息
  contractorInfo: {},
  route: '', //当前路由
  open_keys: [], //当前菜单展开项
  curr_tenant: {
    //当前租户
    name: '未知'
  },
  curr_org: {
    //当前组织
    name: '未知'
  },
  menu_mode: 1,
  loading_finish: false,
  collections: [], // 收藏
  citys: [], // 省市数据
  resources: [], // 导航资源
  dicts: [], // 字典
  logo: '',
  curr_tenant_code: '',
  featureAuth: [],
  description: false,
  embedd_bread_crumbs: [],
  last_activity: [] // 最近访问
}
export default state
