<!--
 * @Description:
 * @Version: 1.0.0
 * @Author: 李晨光
 * @Date: 2020-11-02 15:05:53
 * @LastEditors: 李帅
 * @LastEditTime: 2021-11-22 16:43:59
-->
<comment>
  # 组件注释
  配合menu，递归生成左侧导航
</comment>
<template>
  <a-sub-menu :key="menuInfo.id" v-bind="$props" :hz-code="menuInfo.appCode" v-on="$listeners">
    <span slot="title" style="display: flex">
      <icon-font
        v-if="menuInfo.iconName"
        :type="menuInfo.iconName"
        :style="{ fontSize: collapsed ? '18px' : '16px' }"
        style="line-height: 46px" />
      <img
        v-if="menuInfo.icon && !menuInfo.iconName"
        style="width: 18px; height: 18px; margin-top: 11px"
        :src="menuInfo.icon"
        alt="" />
      <a-avatar
        v-if="!menuInfo.icon && !menuInfo.iconName && collapsed"
        style="width: 16px; height: 16px; line-height: 14px; margin-top: 12px"
        class="avatar">
        {{ menuInfo.name.substring(0, 1) }}
      </a-avatar>
      <div class="over" style="flex: 1">
        <span v-if="!collapsed" v-tooltip>
          {{ menuInfo.name }}
        </span>
      </div>
    </span>
    <template v-for="item in menuInfo.children">
      <template v-if="!item.children">
        <a-menu-item
          v-if="item.uri"
          :key="item.id"
          :hz-code="item.appCode"
          @click="routerLink(item)">
          <icon-font
            v-if="item.iconName"
            :type="item.iconName"
            :style="{ fontSize: collapsed ? '18px' : '16px' }"
            style="margin-top: -4px; margin-left: 8px" />
          <img
            v-if="item.icon && !item.iconName"
            style="width: 18px; height: 18px; margin-top: -4px; margin-left: 8px"
            :src="item.icon"
            alt="" />

          <div class="over">
            <span v-tooltip>
              {{ item.name }}
            </span>
          </div>
        </a-menu-item>
      </template>
      <sub-menu v-else :key="item.id" :menu-info="item" />
    </template>
  </a-sub-menu>
</template>

<script>
import { Menu } from 'ant-design-vue'
import { mapState } from 'vuex'
export default {
  isSubMenu: true,
  name: 'SubMenu',
  components: {},
  props: {
    ...Menu.SubMenu.props,
    menuInfo: {
      type: Object,
      default: () => ({})
    },
    collapsed: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  computed: {
    ...mapState({
      open_keys: state => state.open_keys,
      resources: state => state.resources,
      color: state => state.color
    })
  },
  created() {},
  mounted() {},
  watch: {},
  methods: {
    routerLink(menu) {
      if (menu.appType != 1) {
        // 第三方iframe页面
        if (menu.appType == 2) {
          this.$router.push({
            path: menu.uri || menu.children[0].uri
          })
          this.$root.eventHub.$emit('routeSetLastActivity', [menu])
        } else if (menu.appType == 4) {
          window.open(menu.uri || menu.children[0].uri, '_blank')
          this.$root.eventHub.$emit('routeSetLastActivity', [menu])
        } else {
          this.$router.push({
            name: 'App',
            query: {
              url: menu.uri || menu.children[0].uri
            }
          })
        }
      } else {
        this.$router.push({
          path: menu.uri || (menu.children ? menu.children[0].uri : '/main/welcome')
        })
        this.$root.eventHub.$emit('routeSetLastActivity', [menu])
      }
    }
  },
  destroyed() {}
}
</script>

<style lang="less">
.avatar {
  background: transparent;
  border: 1px solid #606266;
  color: #606266;
  transition: all 0.2s;
}

.ant-menu-submenu-active {
  .avatar {
    border-color: #1890ff;
    color: #1890ff;
  }
}
</style>
