/*
 * @Description:
 * @Version: 1.0.0
 * @Author: 王召强
 * @Date: 2023-02-20 10:09:39
 * @LastEditors: 王召强
 * @LastEditTime: 2023-03-15 23:20:09
 */
const inspect = [
  {
    path: '/quality/inspect/selfInspection',
    name: 'SelfInspection',
    meta: {
      name: '自检验收台账',

      authCode: 'web_pm_quality_acceptance_selfInspection'
    },
    component: () => import('$quality/views/inspect/SelfInspection'),
    children: []
  },
  {
    path: '/quality/inspect/selfInspection/details',
    name: 'SelfInspectionDetails',
    meta: {
      name: '详情',
      isAuth: false,
      authCode: 'web_pm_quality_acceptance_selfInspection'
    },
    component: () => import('$quality/views/inspect/SelfInspectionDetails'),
    children: []
  },
  {
    path: '/quality/inspect/handoverInspection',
    name: 'HandoverInspection',
    meta: {
      name: '交接检验收台账',

      authCode: 'web_pm_quality_acceptance_handoverInspection'
    },
    component: () => import('$quality/views/inspect/HandoverInspection'),
    children: []
  },
  {
    path: '/quality/inspect/handoverInspection/details',
    name: 'HandoverInspectionDetails',
    meta: {
      name: '详情',
      authCode: 'web_pm_quality_acceptance_handoverInspection',
      isAuth: false
    },
    component: () => import('$quality/views/inspect/HandoverInspectionDetails'),
    children: []
  },
  {
    path: '/quality/inspect/specialInspection',
    name: 'SpecialInspection',
    meta: {
      name: '专项验收台账',

      authCode: 'web_pm_quality_acceptance_SpecialInspection'
    },
    component: () => import('$quality/views/inspect/SpecialInspection'),
    children: []
  },
  {
    path: '/quality/inspect/specialInspection/details',
    name: 'SpecialInspectionDetails',
    meta: {
      name: '详情',
      authCode: 'web_pm_quality_acceptance_SpecialInspection',
      isAuth: false
    },
    component: () => import('$quality/views/inspect/SpecialInspectionDetails'),
    children: []
  },
  {
    path: '/quality/inspect/acceptanceStandardLibrary/index',
    name: 'AcceptanceStandardLibrary',
    meta: {
      name: '验收标准规范库',

      authCode: 'web_base_quality_acceptanceStandardLibrary'
    },
    component: () => import('$quality/views/inspect/acceptanceStandardLibrary/Index'),
    children: []
  }
]
export default inspect
