import { render, staticRenderFns } from "./index.vue?vue&type=template&id=66b87af1&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=66b87af1&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66b87af1",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/gitlab-runner/ci-build-dir/huazhu-cloud/vue/construction-cloud/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('66b87af1')) {
      api.createRecord('66b87af1', component.options)
    } else {
      api.reload('66b87af1', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=66b87af1&scoped=true", function () {
      api.rerender('66b87af1', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/public/components/lib/k-form-design/packages/KChangeOption/index.vue"
export default component.exports