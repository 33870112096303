<!--
 * @Description: 一级导航(业务导航) -- 新版
 * @Version: 1.0.0
 * @Author: 李晨光
 * @Date: 2020-11-24 17:19:52
 * @LastEditors: 李帅
 * @LastEditTime: 2023-09-19 16:54:25
-->
<comment>
# 组件注释
</comment>
<template>
  <div id="NavStandard">
    <a-layout-sider
      v-if="menus && menus.length"
      ref="nav"
      v-model="collapsed"
      width="208px"
      class="nav"
      :collapsed-width="50"
      :trigger="null"
      collapsible>
      <div ref="menu" class="menu">
        <a-affix :target="() => $refs.menu">
          <div class="primary-logo m-b-8 p-l-16 p-r-16">
            <span v-if="!collapsed" class="title" style="">{{ title }}</span>
            <a-icon
              :type="collapsed ? 'menu-unfold' : 'menu-fold'"
              :style="{ fontSize: collapsed ? '16px' : '14px' }"
              @click="toggleCollapsed" />
          </div>
        </a-affix>
        <a-menu
          v-model="selectKeys"
          :open-keys.sync="openKeys"
          inline-indent="16"
          :force-sub-menu-render="true"
          mode="inline"
          @openChange="openChange">
          <template v-for="item in menus[0].children">
            <template v-if="!item.children">
              <a-menu-item
                v-if="item.uri"
                :key="item.id"
                :hz-code="item.appCode"
                @click="routerLink(item)">
                <span style="display: flex">
                  <icon-font
                    v-if="item.iconName"
                    :type="item.iconName"
                    :style="{ fontSize: collapsed ? '18px' : '16px' }"
                    style="line-height: 46px" />
                  <img
                    v-if="item.icon && !item.iconName"
                    style="width: 18px; height: 18px; margin-left: 8px"
                    :src="item.icon"
                    alt="" />
                  <a-avatar
                    v-if="!item.icon && !item.iconName && collapsed"
                    style="width: 16px; height: 16px; line-height: 14px; margin-top: 12px"
                    class="avatar">
                    {{ item.name.substring(0, 1) }}
                  </a-avatar>
                  <div class="over" style="flex: 1">
                    <span v-tooltip>
                      {{ item.name }}
                    </span>
                  </div>
                </span>
                <div class="over">
                  <span v-if="!collapsed" v-tooltip class="m-l-8">
                    {{ item.name }}
                  </span>
                </div>
              </a-menu-item>
            </template>
            <sub-menu v-else :key="item.id" :menu-info="item" :collapsed="collapsed" />
          </template>
        </a-menu>
      </div>
    </a-layout-sider>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
import { mapState } from 'vuex'
import SubMenu from '$public/components/layouts/menu/SubMenuStandardNew'
export default {
  name: 'Nav',
  //import引入的组件需要注入到对象中才能使用
  components: { 'sub-menu': SubMenu },
  props: {},

  data() {
    //这里存放数据
    return {
      api: {},
      openKeys: [],
      selectKeys: [],
      menus: [],
      title: '',
      collapsed: false
    }
  },
  //监听属性 类似于data概念
  computed: {
    ...mapState({
      open_keys: state => state.open_keys,
      resources: state => state.resources,
      curr_org: state => state.curr_org,
      tenantId: state => state.curr_tenant
    })
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getResource()
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  //监控data中的数据变化
  watch: {
    $route: {
      handler(val) {
        this.getResource()
      },
      deep: true
    }
  },
  //方法集合
  methods: {
    toggleCollapsed() {
      this.collapsed = !this.collapsed
    },
    // 去除当前菜单有uri时的子集
    delChild(resources) {
      let copyResources = resources
      if (copyResources && copyResources.length) {
        for (let i = 0; i < copyResources.length; i++) {
          if (copyResources[i].uri) {
            copyResources[i].children = undefined
          }
          if (copyResources[i].children && copyResources[i].children.length) {
            this.delChild(copyResources[i].children)
          }
        }
      }

      return copyResources
    },

    subNavChange(appCode) {
      let item = {}
      let currentData = Util.getTreeChild(
        appCode || this.$route.meta.authCode,
        this.resources,
        'appCode'
      )
      let currentId = currentData && currentData.length && currentData[0].id
      let currentPid = currentData && currentData.length && currentData[0].pid
      // 重新获取菜单 防止切换层级后按钮级权限不对应问题
      let newCurrentData = Util.getTree(currentId, this.resources)
      if (Object.keys(newCurrentData).length) {
        currentData = [newCurrentData]
      } else {
        // 用于解决切换层级时当前菜单不存在时跳转当前父级下的第一项
        currentData = [Util.getTreeUriFirst(currentPid, this.resources)]
      }
      item = currentData[0]
      this.$store.commit('SET_FEATUREAUTH', item.children || [])
      let obj = Util.getTreeUriFirst(item.id, this.resources)

      let openKeysIds = Util.getTreeIds(obj.id || item.id, this.resources)
      this.selectKeys = [obj.id || item.id]
      this.openKeys = openKeysIds
      this.openChange(openKeysIds)
    },
    /**
     * 获取资源
     * */
    getResource(appCode) {
      let copyResources = this.delChild(Util.deepCopy(this.resources))
      let _resources = Util.getTreeChild(
        appCode || this.$route.meta.authCode,
        copyResources,
        'appCode'
      )[0]
      if (_resources && Object.keys(_resources).length) {
        if (_resources.appCode.indexOf('web_base') > -1) {
          this.menus = copyResources.filter(v => v.appCode == 'web_base')
        } else if (_resources.appCode.indexOf('web_back') > -1) {
          this.menus = copyResources.filter(v => v.appCode == 'web_back')
        } else {
          let webPms = copyResources.find(v => v.appCode === 'web_pm').children
          this.menus = webPms.filter(v => _resources.appCode.includes(v.appCode))
        }
        this.title = this.menus[0].name
        this.subNavChange(appCode)
      } else {
        let code = appCode || this.$route.meta.authCode

        let authCode = code.split('_')
        authCode.splice(authCode.length - 1, 1)
        console.log(this.$route)

        if (authCode && authCode.length) {
          this.getResource(authCode.join('_'))
        } else {
          // iframe 页面跳转逻辑单独处理
          // if (this.$route.name === 'Iframe') {
          let currentUrl = Util.getTreeChild(
            decodeURIComponent(
              this.$route.name === 'Iframe' ? this.$route.fullPath : this.$route.path
            ),
            copyResources,
            'uri'
          )
          let webPms = copyResources.find(v => v.appCode === 'web_pm').children
          this.menus = webPms.filter(v => currentUrl[0].appCode.includes(v.appCode))
          this.title = this.menus[0].name
          let openKeysIds = Util.getTreeIds(currentUrl[0].id, this.resources)
          this.selectKeys = [currentUrl[0].id]
          this.openKeys = openKeysIds
          this.openChange(openKeysIds)
          // }
        }
      }
    },

    routerLink(menu) {
      let obj = Util.getTreeUriFirst(menu.id, this.resources)
      if (menu.uri) {
        if (menu.appType == 1 || menu.appType == 2) {
          this.$router.push({ path: menu.uri })
        } else if (menu.appType == 3 || menu.appType == 4) {
          window.open(menu.uri)
        }
        this.$root.eventHub.$emit('routeSetLastActivity', [menu])
      } else {
        this.routerLink(obj)
        this.selectKeys = [obj.id]
        this.openKeys = [obj.pid]
        this.openChange([obj.pid])
      }
      this.$store.commit('SET_FEATUREAUTH', obj.children || [])
    },
    openChange(val) {
      this.$store.commit('SET_OPENKEYS', val)
    }
  },
  destroyed() {
    console.log('destroyed')
    this.$root.eventHub.$off('routeData')
    this.$root.eventHub.$off('subNavChange')
  } //生命周期 - 销毁完成
}
</script>
<style lang="less">
#NavStandard {
  .nav {
    height: 100%;
    background: #fff;
  }
  .primary-logo {
    width: 100%;
    height: 48px;
    overflow: hidden;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 24px;
    color: #303133;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f0f0f0;
    background: #fff;
    img {
      border: 0;
      width: 100%;
      height: 40px;
      object-fit: scale-down;
      // background: #fff;
    }
  }
  .menu {
    height: 100%;
    padding-bottom: 50px;
    overflow: hidden;
    *::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
      height: 10px;
    }
    *::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 10px;
      background: #c0c4cc;
    }
    *::-webkit-scrollbar-track {
      /*滚动条里面轨道*/
      -webkit-box-shadow:
        inset -1px 0 0 0 #ebeef5,
        inset 1px 0 0 0 #ebeef5;
      background: #ebeef5;
    }
    *::-webkit-scrollbar-thumb:hover {
      /*滚动条里面轨道*/
      background: #606266;
    }
  }
  .menu .title {
    position: relative;
    min-width: 100px;
    max-width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .menu:hover {
    overflow-y: auto !important;
  }

  .ant-menu-inline .ant-menu-item,
  .ant-menu-inline .ant-menu-submenu-title {
    /* width: calc(100% + 1px); */
    width: 100%;
    // padding-left: 16px !important;
  }

  .ant-menu-inline-collapsed {
    width: 48px;
  }
  .ant-menu-inline-collapsed > .ant-menu-item,
  .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
  .ant-menu-inline-collapsed
    > .ant-menu-item-group
    > .ant-menu-item-group-list
    > .ant-menu-submenu
    > .ant-menu-submenu-title,
  .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
    padding: 0 16px !important;
  }
  .ant-menu-sub {
    padding-left: 10px !important;
  }
  .sider {
    height: 100%;
    box-shadow: none;
    // position: absolute;
    z-index: 999;
  }
  .menu {
    // border-right: 1px solid @border-color-1;
    z-index: 999;
    position: relative;
    box-shadow: 2px 0 8px 0 rgba(0, 0, 0, 0.08);
  }
  .ant-menu-root.ant-menu-vertical,
  .ant-menu-root.ant-menu-vertical-left,
  .ant-menu-root.ant-menu-vertical-right,
  .ant-menu-root.ant-menu-inline {
    border: 0;
  }
}
.ant-menu-inline-collapsed-tooltip {
  img {
    display: none;
  }
  span {
    margin-left: 0;
  }

  .ant-tooltip-arrow::before {
    background: #303133;
  }
  .ant-tooltip-inner {
    color: #fff;
    background: #303133;
    box-shadow: 0px 2px 8px 0px rgba(172, 172, 172, 0.5);
  }
}
.ant-tooltip .avatar {
  display: none;
  margin-right: -20px;
}
</style>
