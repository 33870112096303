import { render, staticRenderFns } from "./LayoutStandard.vue?vue&type=template&id=28d0693e"
import script from "./LayoutStandard.vue?vue&type=script&lang=js"
export * from "./LayoutStandard.vue?vue&type=script&lang=js"
import style0 from "./LayoutStandard.vue?vue&type=style&index=0&id=28d0693e&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./LayoutStandard.vue?vue&type=custom&index=0&blockType=comment"
if (typeof block0 === 'function') block0(component)

/* hot reload */
if (module.hot) {
  var api = require("/home/gitlab-runner/ci-build-dir/huazhu-cloud/vue/construction-cloud/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('28d0693e')) {
      api.createRecord('28d0693e', component.options)
    } else {
      api.reload('28d0693e', component.options)
    }
    module.hot.accept("./LayoutStandard.vue?vue&type=template&id=28d0693e", function () {
      api.rerender('28d0693e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/public/views/layout/LayoutStandard.vue"
export default component.exports