/*
 * @Description:车辆定位模块路由
 * @Version: 1.0.0
 * @Author: 杜凡
 * @Date: 2024-04-12 15:33:48
 * @LastEditors: 杜凡
 * @LastEditTime: 2024-04-19 10:08:01
 */
export const carIdentifyRoute = [
  //分组管理
  {
    path: '/aiot/carPosition/groupManage',
    name: 'GroupManage',
    component: () => import('$aiot/views/carPosition/groupManage/GroupManage.vue'),
    meta: {
      name: '分组管理',
      isAuth: true,
      authCode: 'web_base_IoTplatform_carPosition_groupManage'
    }
  },
  //车辆列表
  {
    path: '/aiot/carPosition/vehicleList',
    name: 'VehicleList',
    component: () => import('$aiot/views/carPosition/vehicleList/VehicleList.vue'),
    meta: {
      name: '车辆列表',
      isAuth: true,
      authCode: 'web_base_IoTplatform_carPosition_vehicleList'
    }
  },
  //围栏设置
  {
    path: '/aiot/carPosition/fenceSet',
    name: 'FenceSet',
    component: () => import('$aiot/views/carPosition/fenceSet/FenceSet.vue'),
    meta: {
      name: '围栏设置',
      isAuth: true,
      authCode: 'web_base_IoTplatform_carPosition_fenceSet'
    }
  },
  // 围栏设置-新增
  {
    path: '/aiot/carPosition/addFenceSet',
    name: 'AddFenceSet',
    component: () => import('$aiot/views/carPosition/fenceSet/AddFenceSet.vue'),
    meta: {
      name: '新增',
      isAuth: true,
      authCode: 'web_base_IoTplatform_carPosition_fenceSet_add'
    }
  },
  // 围栏设置-编辑
  {
    path: '/aiot/carPosition/editFenceSet',
    name: 'EditFenceSet',
    component: () => import('$aiot/views/carPosition/fenceSet/AddFenceSet.vue'),
    meta: {
      name: '编辑',
      isAuth: true,
      authCode: 'web_base_IoTplatform_carPosition_fenceSet_edit'
    }
  },
  // 围栏设置-车辆
  {
    path: '/aiot/carPosition/carFenceSet',
    name: 'CarFenceSet',
    component: () => import('$aiot/views/carPosition/fencedVehicles/FencedVehicles.vue'),
    meta: {
      name: '围栏车辆',
      isAuth: true,
      authCode: 'web_base_IoTplatform_carPosition_fenceSet_carList'
    }
  },
  //围栏车辆
  {
    path: '/aiot/carPosition/fencedVehicles',
    name: 'FencedVehicles',
    component: () => import('$aiot/views/carPosition/fencedVehicles/FencedVehicles.vue'),
    meta: {
      name: '围栏车辆',
      isAuth: true,
      authCode: 'web_base_IoTplatform_carPosition_fencedVehicles'
    }
  }
]
