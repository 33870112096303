/*
 * @Description: 入口文件
 * @Version: 1.0.0
 * @Author: 李晨光
 * @Date: 2020-07-04 11:17:02
 * @LastEditors: 赵亮
 * @LastEditTime: 2021-10-20 14:44:58
 */
import Vue from 'vue'
import App from './App.vue'
import '$public/core/lazy_use'
import router from '$public/router'
import store from '$public/vuex'

// import '$public/assets/css/reset.css'
import 'ant-design-vue/lib/style/index'
import '$public/assets/css/common.css'
import '$public/assets/css/antd-reset.css'
import '$public/components/global.less'

Vue.config.productionTip = false

let vm = new Vue({
  router,
  store,
  data: {
    eventHub: new Vue() // 事件中心
  },
  render: h => h(App)
}).$mount('#app')

export default vm
