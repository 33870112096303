/*
 * @Description:
 * @Version: 1.0.0
 * @Author: 王召强
 * @Date: 2023-03-10 15:31:53
 * @LastEditors: 王召强
 * @LastEditTime: 2023-03-21 18:53:31
 */
const qualityTemplate = [
  {
    path: '/qualityTemplate/register',
    name: 'QualityTemplateregister',
    meta: {
      name: '质量样板登记',
      isAuth: false,
      authCode: 'web_pm_quality_qualityTemplate_QualityTemplateregister'
    },
    component: () => import('$quality/views/qualityTemplate/register/Index.vue')
  },
  {
    path: '/qualityTemplate/register/Add',
    name: 'AddRegister',
    meta: {
      name: '新增',
      type: 1,
      authCode: 'web_pm_quality_qualityTemplate_QualityTemplateregister',
      isAuth: false
    },
    component: () => import('$quality/views/qualityTemplate/register/AddRegister.vue')
  },
  {
    path: '/qualityTemplate/register/edit',
    name: 'EditRegister',
    meta: {
      name: '编辑',
      type: 2,
      authCode: 'web_pm_quality_qualityTemplate_QualityTemplateregister',
      isAuth: false
    },
    component: () => import('$quality/views/qualityTemplate/register/AddRegister.vue')
  },
  {
    path: '/qualityTemplate/register/details',
    name: 'DetailsRegister',
    meta: {
      name: '详情',
      authCode: 'web_pm_quality_qualityTemplate_QualityTemplateregister',
      isAuth: false
    },
    component: () => import('$quality/views/qualityTemplate/register/Details.vue')
  },

  // -------------------------------------------------------------------------------------
  {
    path: '/qualityTemplate/disclose',
    name: 'QualityTemplateDisclose',
    meta: {
      name: '质量样板交底',
      isAuth: true,
      authCode: 'web_pm_quality_qualityTemplate_disclose'
    },
    component: () => import('$quality/views/qualityTemplate/disclose/Index.vue')
  },
  {
    path: '/qualityTemplate/disclose/Add',
    name: 'AddDisclose',
    meta: {
      name: '新增',
      authCode: 'web_pm_quality_qualityTemplate_disclose',
      type: 1,
      isAuth: false
    },
    component: () => import('$quality/views/qualityTemplate/disclose/AddDisclose.vue')
  },
  {
    path: '/qualityTemplate/disclose/edit',
    name: 'EditDisclose',
    meta: {
      name: '编辑',
      authCode: 'web_pm_quality_qualityTemplate_disclose',
      type: 2,
      isAuth: false
    },
    component: () => import('$quality/views/qualityTemplate/disclose/AddDisclose.vue')
  },
  {
    path: '/qualityTemplate/disclose/details',
    name: 'DetailsDisclose',
    meta: {
      name: '详情',
      authCode: 'web_pm_quality_qualityTemplate_disclose',
      isAuth: false
    },
    component: () => import('$quality/views/qualityTemplate/disclose/Details.vue')
  },
  // -----------------------------------------------------------
  {
    path: '/qualityTemplate/analyse',
    name: 'QualityTemplateAnalyse',
    meta: {
      name: '样板交底分析',
      isAuth: true,
      authCode: 'web_pm_quality_qualityTemplate_analyse'
    },
    component: () => import('$quality/views/qualityTemplate/analyse/Index.vue')
  }
]
export default qualityTemplate
