/*
 * @Description:
 * @Version: 1.0.0
 * @Author: 李帅
 * @Date: 2023-02-02 15:29:10
 * @LastEditors: 王召强
 * @LastEditTime: 2024-05-28 20:16:19
 */
/*
 * @Description:
 * @Version: 1.0.0
 * @Author: 李晨光
 * @Date: 2020-08-20 14:11:45
 * @LastEditors: 赵亮
 * @LastEditTime: 2023-08-16 11:24:01
 */

const isStandard = process.env.VUE_APP_VERSION == 'standard'

const domainName = window.location.hostname
// import Main from '$public/views/BasicLayout'
// import Main from '$public/views/layout/Layout' // 八局南方
import MainStandard from '$public/views/layout/LayoutStandard' // 标板
// import IframeRouter from '$public/views/IframeRouter'
import * as BS from '$base/router/index' // 导入平台相关路由
import * as QA from '$quality/router/index' // 导入质量相关路由
import * as SF from '$safety/router/index' // 导入安全相关路由
import * as MC from '$mechanical/router/index' // 导入设备相关路由
import * as IOT from '$aiot/router/index' // 导入设备相关路由
import * as LB from '$labor/router/index' // 导入劳务相关路由
import * as PRD_S from '$productionSchedule/router/index' // 导入生产进度相关路由
import * as BIDDING from '$bidding/router/index' // 导入生产进度相关路由
import * as OA from '$oa/router/index' // 导入生产进度相关路由
import * as TowerGuard from '$mechanical/router/TowerGuard' // 导入生产进度相关路由
import * as StructureGuard from '$safety/router/StructureGuard' // 导入生产进度相关路由
import * as Analog from '$analog/router/index' // 导入模拟数据相关路由
import * as WorkOrder from '$workOrder/router/index' // 导入工单相关路由
import * as Materials from '$materials/router/index' // 导入物料相关路由
import * as CompletionData from '$completionData/router/index' // 导入完工资料相关路由
import * as SupervisionPlatform from '$supervisionPlatform/router/index' // 导入完工资料相关路由
export const publicRoute = [
    // 上报数据台账单独网页（混凝土拌合站管理-生产管理104国道需求）
    {
        path: '/ReportDataLedger',
        component: () =>
            import(
                /*/* : "errors-404" */
                '$aiot/views/mixingStationManage/production/ReportDataLedger.vue'
            ),
        meta: {
            Permissions: true
        }
    },
    {
        path: '/ImportDataLedger',
        component: () =>
            import(
                /*/* : "errors-404" */
                '$aiot/views/mixingStationManage/production/ImportDataLedger.vue'
            ),
        meta: {
            Permissions: true
        }
    },
    {
        path: '*',
        component: () =>
            import(
                /*/* : "errors-404" */
                '$public/views/common/error/404.vue'
            )
    },
    {
        path: '/404',
        name: '404',
        meta: {
            title: 'Not Found'
        },
        component: () =>
            import(
                /*/* : "errors-404" */
                '$public/views/common/error/404.vue'
            )
    },
    {
        path: '/500',
        name: '500',
        meta: {
            title: 'Server Error'
        },
        component: () =>
            import(
                /*/* : "errors-500" */
                '$public/views/common/error/500.vue'
            )
    },
    {
        path: '/403',
        name: '403',
        meta: {
            title: 'Server Error'
        },
        component: () =>
            import(
                /*/* : "errors-403" */
                '$public/views/common/error/403.vue'
            )
    }
]
// 基础
const BASE = BS.protectedRoute
// 质量
const Quality = QA.protectedRoute
// 安全
const SAFETY = SF.protectedRoute
// 机械
const MECH = MC.protectedRoute
const AIOT = IOT.protectedRoute
const LABOR = LB.protectedRoute
// 塔机卫士
const TG = TowerGuard.protectedRoute
// 结构卫士
const SG = StructureGuard.protectedRoute
//模拟数据
const ANALOG = Analog.protectedRoute
// 工单系统
const WO = WorkOrder.protectedRoute
// 物料
const ML = Materials.protectedRoute
// 完工资料
const CD = CompletionData.protectedRoute
const SP = SupervisionPlatform.protectedRoute
export const protectedRoute = [
    {
        path: '/',
        redirect: '/login',
        name: 'Index'
    },
    {
        path: '/login',
        name: 'Login',
        component: domainName == 'zgjj.1357.cn' ? () => import('$public/views/LoginZGJJ') :
            isStandard
                ? () => import('$public/views/LoginStandard')
                : () => import('$public/views/LoginBJNF')
    },
    {
        path: '/forgotPwd',
        name: 'ForgotPwd',
        component: () => import(/*/* : "登录" */ '$public/views/ForgotPwdStandard')
    },
    {
        path: '/thirdJumpLink',
        name: 'ForgotPwd',
        component: () => import(/*/* : "登录" */ '$public/views/ThirdJumpLink')
    },
    {
        path: '/main/welcome',
        name: 'Welcome',
        meta: {
            name: '首页'
        },
        component: isStandard
            ? () => import('$public/views/WelcomeStandard')
            : () => import('$public/views/WelcomeBJNF')
    },
    {
        path: '/main/officeViewer',
        name: 'OfficeViewer',
        meta: {
            isAuth: false
        },
        component: () => import('$public/views/standard/OfficeViewer')
    },
    {
        path: '/main/iframeTest',
        name: 'IframeTest',
        meta: {
            name: '工作台',
            isAuth: false
        },
        component: () => import('$public/views/standard/IframeTest')
    },
    {
        path: '/blackDemo',
        name: 'BlackDemo',
        meta: {
            name: '测试地址',
            isAuth: false
        },
        component: () => import('$public/views/demo/BlackDemo')
    },
    {
        path: '/main',
        name: 'Main',
        component: MainStandard,
        children: [
            {
                path: '/main',
                name: 'main',
                meta: {
                    name: '工作台'
                },
                component: () => import('$public/views/workbench/Index'),
                children: [
                    {
                        path: '/main/iframe',
                        name: 'Iframe',
                        meta: {
                            isAuth: false,
                            authCode: ''
                        },
                        component: () => import('$public/views/IframeRouter')
                    },
                    {
                        path: '/main/thirdIframe',
                        name: 'ThirdIframe',
                        meta: {
                            isAuth: false,
                            authCode: ''
                        },
                        component: () => import('$public/views/ThirdIframeRouter')
                    },
                    {
                        path: '/main/developing',
                        name: 'Developing',
                        meta: {
                            name: '开发中',
                            isAuth: false,
                            authCode: ''
                        },
                        component: () => import('$public/views/common/developing/Index')
                    },
                    {
                        path: '/main/workbench',
                        name: 'Workbench',
                        meta: {
                            name: '工作台'
                        },
                        component: () => import('$public/views/workbench/Workbench')
                    },
                    {
                        path: '/main/workbench/backlog',
                        name: 'Backlog',
                        meta: {
                            name: '待办'
                        },
                        component: () => import('$public/views/workbench/backlog/Backlog')
                    },
                    {
                        path: '/main/workbench/message',
                        name: 'Message',
                        meta: {
                            name: '消息'
                        },
                        component: () => import('$public/views/workbench/message/Message')
                    },
                    {
                        path: '/main/workbench/preWarning',
                        name: 'PreWarning',
                        meta: {
                            name: '预警'
                        },
                        component: () => import('$public/views/workbench/preWarning/PreWarning')
                    }
                ]
            },
            {
                path: '/main/standard/table/detail',
                name: 'Detail',
                meta: {
                    name: 'Standard标准页'
                },
                component: () => import('$public/views/standard/table/Index'),
                children: [
                    {
                        path: '/main/standard/table',
                        name: 'Table',
                        meta: {
                            name: '标准Table页'
                        },
                        component: () => import('$public/views/standard/table/Table')
                    },
                    {
                        path: '/main/standard/table2',
                        name: 'Table2',
                        meta: {
                            name: '标准Table页2'
                        },
                        component: () => import('$public/views/standard/table/Table2')
                    },
                    {
                        path: '/main/standard/table/detail',
                        name: 'Detail',
                        meta: {
                            name: '标准详情页'
                        },
                        component: () => import('$public/views/standard/table/Detail')
                    },
                    {
                        path: '/main/standard/table2/detail2',
                        name: 'Detail2',
                        meta: {
                            name: '标准详情页2'
                        },
                        component: () => import('$public/views/standard/table/Detail2')
                    },
                    {
                        path: '/main/standard/table/setItem',
                        name: 'SetItem',
                        meta: {
                            name: '标准表单页'
                        },
                        component: () => import('$public/views/standard/table/SetItem')
                    }
                ]
            },
            ...BASE,
            ...Quality,
            ...SAFETY,
            ...MECH,
            ...AIOT,
            ...LABOR,
            ...PRD_S.protectedRoute,
            ...BIDDING.protectedRoute,
            ...OA.protectedRoute,
            ...TG,
            ...SG,
            ...ANALOG,
            ...WO,
            ...ML,
            ...CD,
            ...SP
        ]
    }
]
