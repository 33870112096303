/*
 * @Description:
 * @Version: 1.0.0
 * @Author: 李晨光
 * @Date: 2020-08-20 14:11:46
 * @LastEditors: 任笠
 * @LastEditTime: 2021-09-23 10:15:15
 */
export const SET_NAME = 'SET_NAME'
export const SET_LOGINMSG = 'SET_LOGINMSG'
export const SET_CONTRACTOR_INFO = 'SET_CONTRACTOR_INFO'
export const SET_COLOR = 'SET_COLOR'
export const SET_OPENKEYS = 'SET_OPENKEYS'
export const SET_CURR_ORG = 'SET_CURR_ORG'
export const SET_MENU_MODE = 'SET_MENU_MODE'
export const SET_CURR_TENANT = 'SET_CURR_TENANT'
export const SET_CURR_TENANT_CODE = 'SET_CURR_TENANT_CODE'
export const SET_LOADING_FINISH = 'SET_LOADING_FINISH'
export const SET_COLLECTIONS = 'SET_COLLECTIONS'
export const SET_CITYS = 'SET_CITYS'
export const SET_RESOURCES = 'SET_RESOURCES'
export const SET_DICTS = 'SET_DICTS'
export const SET_LOGO = 'SET_LOGO'
export const USER_SIGNOUT = 'USER_SIGNOUT'
export const SET_FEATUREAUTH = 'SET_FEATUREAUTH'
export const SET_TITLEBAR = 'SET_TITLEBAR'
export const SET_EMBEDD_BREAD_CRUMBS = 'SET_EMBEDD_BREAD_CRUMBS'
export const SET_LAST_ACTIVITY = 'SET_LAST_ACTIVITY'
