<!--
 * @Description: 组织筛选自定义组件
 * @Version: 1.0.0
 * @Author: 任笠
 * @Date: 2022-03-05 14:16:18
 * @LastEditors: 任笠
 * @LastEditTime: 2022-03-08 17:57:33
-->
<comment>
  # 组件注释
</comment>
<template>
  <div id="OrgSelectCom">
    <a-select
      v-model="params.name"
      class="m-r-10"
      :disabled="disabled || record.options.disabled || parentDisabled"
      placeholder="请选择"
      :style="`width:${record.options.width}`"
      allow-clear
      :show-arrow="false"
      :filter-option="false"
      :not-found-content="null"
      @change="setValue([])">
    </a-select>
    <a-button
      :disabled="disabled || record.options.disabled || parentDisabled"
      @click="params.visible = true">
      选择
    </a-button>
    <!-- 引入组织筛选组件 -->
    <component
      :is="dynamicData[record.options.dynamicKey]"
      v-if="params.visible"
      ref="cmp"
      :api-type="record.options.apiType"
      :is-visible="params.visible"
      @change="setValue"
      @closed="params.visible = false"></component>
  </div>
</template>

<script>
export default {
  name: 'OrgSelectCom',
  props: [
    'record',
    'value',
    'parentDisabled',
    'disabled',
    'dynamicData',
    'multiple',
    'dynamicKey',
    'config'
  ],
  components: {},
  data() {
    return {
      params: {
        visible: false,
        checked: []
      }
    }
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {
    value: {
      handler(val) {
        if (!this.record.options.multiple && val) {
          // 单选
          this.params.checked = [val]
          this.params.name = val.name
        } else {
          // 多选
          if (val) {
            this.params.checked = val
            this.params.name = val.map(v => v.name).join(',') // 姓名
          }
        }
        // 重置支持
        if (!val) {
          this.params.name = undefined // 姓名
          this.params.checked = []
        }
      },
      immediate: true
    }
  },
  methods: {
    setValue(val) {
      if (val && val.length) {
        if (!this.record.options.multiple) {
          this.params.name = val[0].name // 姓名
          // 单选
          this.$emit('change', val[0])
        } else {
          this.params.name = val.map(v => v.name).join(',') // 姓名
          // 多选
          this.$emit('change', val)
        }
      } else {
        this.params.name = undefined // 姓名
        this.params.checked = []
        this.$emit('change', undefined)
      }
    }
  },
  destroyed() {}
}
</script>

<style lang="less"></style>
