/*
 * @Descripttion:
 * @Version: 1.0.0
 * @Author: 李晨光
 * @Date: 2020-07-04 11:38:46
 * @LastEditors: 李晨光
 * @LastEditTime: 2020-11-02 15:45:55
 */
/**
 * 项目默认配置项
 * primaryColor - 默认主题色, 如果修改颜色不生效，请清理 localStorage
 *
 */

export default {
  primaryColor: '#3B97FE', // primary color of ant design
  production: process.env.NODE_ENV === 'production' && process.env.VUE_APP_PREVIEW == 'true'
}
