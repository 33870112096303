/*
 * @Description:第三方业务
 * @Version: 1.0.0
 * @Author: 王召强
 * @Date: 2022-08-15 16:48:49
 * @LastEditors: 王召强
 * @LastEditTime: 2024-01-29 10:59:36
 */
const thirdParty = [
  //积分奖惩管理
  //积分台账
  {
    path: '/pointPenalty/pointsLedger',
    name: 'PointsLedger',
    component: () => import('$labor/views/pointPenalty/PointsLedger.vue'),
    meta: {
      name: '积分台账',
      isAuth: true,
      authCode: 'web_pm_labor_pointPenalty_pointsLedger'
    }
  },
  {
    path: '/pointPenalty/pointsLedger/record',
    name: 'PointsLedgerRecord',
    component: () => import('$labor/views/pointPenalty/PointsLedgerRecord.vue'),
    meta: {
      name: '积分记录',
      isAuth: true,
      authCode: 'web_pm_labor_pointPenalty_pointsLedger'
    }
  },
  //订单管理
  // {
  //   path: '/pointPenalty/OrderManagement',
  //   name: 'OrderManagement',
  //   component: () => import('$labor/views/pointPenalty/OrderManagement.vue'),
  //   meta: {
  //     name: '订单管理',
  //     isAuth: true,
  //     authCode: 'web_pm_labor_pointPenalty_orderManagement'
  //   }
  // },

  //订单管理
  {
    path: '/pointPenalty/PunishmentSettings',
    name: 'OrderManagementPunishmentSettings',
    component: () => import('$labor/views/pointPenalty/punishmentSettings/Index.vue'),
    meta: {
      name: '积分奖惩设置',
      isAuth: true,
      authCode: 'web_base_labor_PunishmentSettings'
    }
  },

  //国贸中心
  {
    path: '/labor/userSynchronization',
    name: 'UserSynchronization',
    component: () => import('$labor/views/userSynchronization/Index.vue'),
    meta: {
      name: '人员同步',
      isAuth: true,
      authCode: 'web_pm_labor_realName_synchronization'
    }
  },
  {
    path: '/labor/userSynchronization/import',
    name: 'UserSynchronizationImport',
    component: () => import('$labor/views/userSynchronization/UserImport.vue'),
    meta: {
      name: '导入',
      isAuth: true,
      authCode: 'web_pm_labor_realName_synchronization'
    }
  }
]

export default thirdParty
