/*
 * @Description:
 * @Version: 1.0.0
 * @Author: 蔡亚岗
 * @Date: 2022-12-14 10:59:00
 * @LastEditors: 赵亮
 * @LastEditTime: 2023-03-29 10:17:38
 */
const rewardAndPunish = [
  {
    path: 'rewardAndPunish',
    name: 'rewardAndPunish',
    meta: {
      name: '质量奖罚'
    },
    component: () =>
      import(
        // 质量奖罚
        '$quality/views/rewardAndPunish/Index'
      ),
    children: [
      {
        path: 'scoreRanking',
        name: 'scoreRanking',
        meta: {
          name: '质量评分排名',
          isAuth: true,
          authCode: 'web_pm_quality_rewardAndPunish_scoreRanking'
        },
        component: () =>
          import(
            // 质量评分排名
            '$quality/views/rewardAndPunish/scoreRanking/Index'
          )
      },
      {
        path: 'subScoreRankingDetail',
        name: 'subScoreRankingDetail',
        meta: {
          name: '分包排行榜',
          isAuth: false,
          authCode: 'web_pm_quality_rewardAndPunish_scoreRanking_detail'
        },
        component: () =>
          import(
            // 排行榜
            '$quality/views/rewardAndPunish/scoreRanking/components/RankLIst'
          )
      },
      {
        path: 'quarScoreRankingDetail',
        name: 'quarScoreRankingDetail',
        meta: {
          name: '班组排行榜',
          isAuth: false,
          authCode: 'web_pm_quality_rewardAndPunish_scoreRanking_detail'
        },
        component: () =>
          import(
            // 排行榜
            '$quality/views/rewardAndPunish/scoreRanking/components/RankLIst'
          )
      },
      {
        path: 'statisticalScoring',
        name: 'statisticalScoring',
        meta: {
          name: '数据统计打分',
          isAuth: true,
          authCode: 'web_pm_quality_rewardAndPunish_statisticalScoring'
        },
        component: () =>
          import(
            // 数据统计打分
            '$quality/views/rewardAndPunish/statisticalScoring/Index'
          )
      },
      {
        path: 'statisticalScoringUpdata',
        name: 'statisticalScoringUpdata',
        meta: {
          name: '更新',
          isAuth: false,
          authCode: 'web_pm_quality_rewardAndPunish_statisticalScoring'
        },
        component: () =>
          import(
            // 更新
            '$quality/views/rewardAndPunish/statisticalScoring/components/UpdataLIst'
          )
      },
      {
        path: 'statisticalScoringDetail',
        name: 'statisticalScoringDetail',
        meta: {
          name: '详情',
          isAuth: false,
          authCode: 'web_pm_quality_rewardAndPunish_statisticalScoring'
        },
        component: () =>
          import(
            // 更新
            '$quality/views/rewardAndPunish/statisticalScoring/components/UpdataLIst'
          )
      }
    ]
  }
]
export default rewardAndPunish
