<!--
 * @Description: 上栏导航--新版
 * @Version: 1.0.0
 * @Author: 李帅
 * @Date: 2020-11-25 09:37:40
 * @LastEditors: shawn-web lishuaistart@163.com
 * @LastEditTime: 2024-11-22 14:56:20
-->
<comment>
  # 组件注释
  用户中心
  1.更换主题
  2.退出登录
  3.其他
</comment>
<template>
  <div id="UserMenu">
    <div class="user-wrapper">
      <div class="content-box">
        <a-dropdown style="width: 144px">
          <div class="pointer d-flex align-center p-l-12 p-r-12">
            <a-avatar
              class="m-r-10"
              style="width: 24px; height: 24px; line-height: 24px"
              :style="{ background: color }"
              :src="login_msg.profile">
              {{ login_msg.name.substring(0, 1) }}
            </a-avatar>
            <span class="nick">{{ login_msg.name }}</span>
          </div>
          <a-menu slot="overlay" class="user-dropdown-menu-wrapper">
            <a-sub-menu key="test">
              <span slot="title">
                <span>
                  <a-icon type="swap" class="m-r-8" style="font-size: 14px" />

                  <span>切换租户</span>
                </span>
              </span>
              <a-menu-item
                v-for="(item, index) in tenantListData"
                :key="index + 'btn'"
                :class="item.id == tenantId ? 'ant-dropdown-menu-item-active1' : ''"
                @click="handleClick(item.id)"
                >{{ item.companyName }}</a-menu-item
              >
            </a-sub-menu>
            <a-menu-item key="2" @click="changePwd">
              <span>
                <icon-font type="icon-Icon-Line-Lock" style="font-size: 14px" />
                <span>修改密码</span>
              </span>
            </a-menu-item>

            <a-menu-item
              key="3"
              :disabled="isPlatform == 'yes' ? true : false"
              @click="changeUserInfo">
              <span>
                <icon-font type="icon-Icon-Line-User" style="font-size: 14px" />
                <span>个人设置</span>
              </span>
            </a-menu-item>
            <a-menu-divider />
            <a-menu-item key="4" @click="handleLogout">
              <a href="javascript:;" style="color: #ff4d4f">
                <a-icon type="logout" class="m-r-8" style="font-size: 14px" />
                <span>退出登录</span>
              </a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>
    </div>
    <ChangePwd v-show="false" ref="changePWD"></ChangePwd>
    <UserInfo v-show="false" ref="UserInfo"></UserInfo>
  </div>
</template>

<script>
// import ThemeColor from '$public/components/setting/themeColor/index'
import ChangePwd from '$base/views/userInfo/ChangePwd'
import UserInfo from '$base/views/userInfo/UserInfo'

import { mapState } from 'vuex'
import Cookie from 'js-cookie'

export default {
  name: 'UserMenu',
  components: {
    // ThemeColor,
    ChangePwd,
    UserInfo
  },
  inject: ['reload'],
  props: {},
  data() {
    return {
      isPlatform: '',
      api: {
        tenant_list: '/web/passport/user/get-tenant-list', // 切换租户 租户列表数据
        change_tenant: '/web/passport/user/switch-tenant', // 切换租户 确认操作接口
        getResourceApi: '/web/role-appInfo/list', // 获取资源
        getHasAuthButtonApi: '/web/role-appInfo/listButton', // 获取按钮权限集合
        logoutApi: Util.baseAuth2 + '/web/passport/user/logout',
        thirdLogoutApi: '/web/third-platform/logout'
      },
      tenantListData: [],
      thirdPlatform: ['lujian']
    }
  },
  computed: {
    ...mapState({
      login_msg: state => state.login_msg,
      curr_org: state => state.curr_org,
      tenantId: state => state.curr_tenant,
      orgId: state => state.curr_org.id,
      menu_mode: state => state.menu_mode,
      color: state => state.color
    })
  },
  created() {
    this.isPlatform = Cookie.get('isPlatform')
  },
  mounted() {
    this.$post(this.api.tenant_list)
      .then(response => {
        this.tableLoading = false
        Util.processRes(response, () => {
          this.tenantListData = response.data
        })
        Util.processError(this, response)
      })
      .catch(res => {
        this.tableLoading = false
        console.log(res)
      })
  },
  watch: {
    /* login_msg: {
        handler(val) {
          this.visible = val;
          console.log(val);
        },
        immediate: true
      },*/
  },
  methods: {
    // 登出
    handleLogout() {
      this.$confirm({
        title: '提示',
        content: '确定要注销登录吗 ?',
        okText: '确认',
        centered: true,
        cancelText: '放弃',
        onOk: () => {
          // this.$requestCancel()
          this.judgeLogout()
        },
        onCancel() {}
      })
    },
    // 注销
    judgeLogout() {
      let loginPlatform = localStorage.getItem('loginPlatform')
      console.log('loginPlatform', loginPlatform)
      this.logout()
      if (this.thirdPlatform.indexOf(loginPlatform) > -1) {
        this.thirdLogout()
      }
    },
    // 本系统登出
    logout() {
      this.$post(this.api.logoutApi)
        .then(response => {
          Util.processRes(response, () => {
            // 原登出逻辑
            this.$requestCancel()
            Cookie.remove('jwt')
            Cookie.remove('simpleToken')
            Cookie.remove('sessions')
            // localStorage.removeItem('resources')
            localStorage.clear()
            sessionStorage.clear()
            if (this.isPlatform == 'yes') {
              this.$router.push({ name: 'LoginByAdmin' })
            } else {
              this.$router.push({ name: 'Login' })
              this.$store.commit('USER_SIGNOUT')
            }
            this.$root.eventHub.$emit('loginOut')
          })
          Util.processError(this, response)
        })
        .catch(err => {})
    },
    // 三方登出
    thirdLogout() {
      this.$post(this.api.thirdLogoutApi)
        .then(response => {
          Util.processRes(response, () => {
            localStorage.removeItem('loginPlatform')
          })
          Util.processError(this, response)
        })
        .catch(err => {})
    },
    gotoBackend() {
      // this.$root.eventHub.$emit('sysModeChange', 'backend')
      // return
      // this.sysModeChange('backend')
      this.$router.push({
        name: 'Workbench'
      })
      this.$eventBus.$emit('sysModeChange', 'backend')
    },
    switchModule() {
      this.$router.push({
        name: 'Welcome'
      })
      this.$emit('change', false)
    },
    /**
     * 收藏按钮增加属性
     * @param arr
     * @returns {Array}
     */
    addCollectionProps(arr) {
      let resources = []
      let _bi = {
        web_pm_bi_pm: '/pm-bi/#/main?token=',
        web_pm_bi_monitor: '/monitor-bi/#/main?token=',
        web_pm_bi_IotComm: '/command-bi/#/main?token='
      }
      let origin = window.location.origin
      let host = window.location.host
      let httpOriginWeb = process.env.VUE_APP_ORIGIN_HTTP_WEB
      arr.length &&
        arr.forEach(item => {
          if (item.type == 'menu') {
            item['isCollected'] = false
          }
          if (item.uri && item.uri.includes('?token=') && !this.getQueryString(item.uri, 'token')) {
            item.uri += Cookie.get('simpleToken')
          }
          // 大屏访问优化，（经拉通，私有化环境后续不用在运维端单独处理访问前缀）
          if (item.uri && Object.keys(_bi).includes(item.appCode)) {
            // 存在host私有化配置则按域名访问，不存在即为项管家/本地环境
            if (
              Object.keys(hostConfig).includes(host) &&
              process.env.VUE_APP_CURRENTMODE == 'prod'
            ) {
              item.uri = `${window.location.origin}${_bi[item.appCode]}${Cookie.get('simpleToken')}`
            } else {
              item.uri = `${httpOriginWeb}${_bi[item.appCode]}${Cookie.get('simpleToken')}`
              if (process.env.VUE_APP_CURRENTMODE == 'test' && item.uri.includes('/command-bi')) {
                item.uri = `https://tapp.autobuild.com.cn${_bi[item.appCode]}${Cookie.get(
                  'simpleToken'
                )}`
              }

              if (
                process.env.VUE_APP_CURRENTMODE == 'prod' &&
                (item.uri.includes('/command-bi') || item.uri.includes('/monitor-bi'))
              ) {
                item.uri = `https://wxx.1357.cn${_bi[item.appCode]}${Cookie.get('simpleToken')}`
              }
            }
          }
          if (item.children && item.children.length) {
            item.children = this.addCollectionProps(item.children)
          }
          resources.push(item)
        })
      return resources
    },
    getQueryString(url, name) {
      var reg = new RegExp('(^|\\?)' + name + '=([^&]*)(&|$)', 'i')
      var r = url.substr(1).match(reg)
      if (r != null) {
        return unescape(r[2])
      }
      return null
    },
    // 修改密码
    changePwd() {
      this.$refs['changePWD'].openModal()
    },
    // 修改个人信息
    changeUserInfo() {
      this.$refs['UserInfo'].openModal()
    },

    /**
     * 获取资源
     * */
    getResource(id, roleId, type, menuMode) {
      this.$get(this.api.getResourceApi, {
        tenantId: id,
        roleId: roleId,
        clientType: 'web',
        scope: 1
      })
        .then(response => {
          this.loading = false
          Util.processRes(response, () => {
            if (response.data) {
              this.$store.commit(
                'SET_RESOURCES',
                this.addCollectionProps(Util.resetTree(response.data))
              )
              // 储存原始数据，用于切换组织时获取
              localStorage.setItem(
                'resources',
                JSON.stringify(this.addCollectionProps(response.data))
              )
              // 控制菜单层级权限
              this.setResource({ orgType: type }, menuMode)
              this.$store.commit('SET_OPENKEYS', [])
            } else {
              this.$store.commit('SET_RESOURCES', [])
              localStorage.setItem('resources', JSON.stringify([]))
              this.$router.push('/main/welcome')
              // this.$router.push('/main/home')
            }
            this.$root.eventHub.$emit('changeResources', Util.resetTree(response.data))
            this.$root.eventHub.$emit('tenantChange')
          })
          // Util.processError(this, response);
          if (response.statusCode != 200) {
            this.$message.error(response.statusMessage)
          }
          this.$nextTick(() => {
            this.reload()
            this.$eventBus.$emit('getOrgTree')
          })
        })
        .catch(err => {
          this.$message.error(err.statusMessage)

          this.loading = false
        })
    },
    // 如需修改 请同步修改 OrgTree.vue中的方法
    resetTreeData(treeData, orgType) {
      let setTree = list => {
        list &&
          list.length &&
          list.forEach((item, i) => {
            // item.loading = true
            if (item.children && item.children.length) {
              setTree(item.children)
            }
            // if (item.children && !item.children.length && !item.uri) {
            //   list.splice(i, 1)
            //   setTree(list)
            // }
            if (item.children && !item.children.length) {
              delete item.children
            }
            // item.orgType  1公司级 2分公司级 3项目级
            if (!item.orgTypeList.includes(1)) {
              //  公司级
              let type = ['1']
              if (type.includes(String(orgType))) {
                list.splice(i, 1)
                setTree(list)
              }
            }
            if (!item.orgTypeList.includes(2)) {
              // 分公司级
              let type = ['2', '3', '4', '5']
              if (type.includes(String(orgType))) {
                list.splice(i, 1)
                setTree(list)
              }
            }
            if (!item.orgTypeList.includes(3)) {
              // 项目级
              let type = ['6']
              if (type.includes(String(orgType))) {
                list.splice(i, 1)
                setTree(list)
              }
            }
          })
      }
      setTree(treeData)
      return treeData
    },
    // 切换租户处理
    handleClick(id) {
      let params = {
        tenantId: id
      }
      this.$get(this.api.change_tenant, params)
        .then(response => {
          this.loading = false
          Util.processRes(response, () => {
            if (response.data) {
              this.$store.commit('SET_RESOURCES', [])
              localStorage.removeItem('bj_spm_main_test')
              localStorage.removeItem('resources')
              sessionStorage.clear()
              Cookie.remove('jwt')
              Cookie.remove('simpleToken')
              Cookie.remove('sessions')

              Cookie.set('jwt', response.data.tokenVo.accessToken, {
                expires: 7
              })
              Cookie.set('simpleToken', response.data.tokenVo.simpleToken, {
                expires: 7
              })
              Cookie.set(
                'sessions',
                JSON.stringify({
                  passportId: response.data.userMemberVo.passportId
                })
              )
              this.$store.commit('SET_LOGINMSG', response.data.userMemberVo)
              // 参建单位信息引入
              if (response.data.userMemberVo.userType === 2) {
                this.$store.commit('SET_CONTRACTOR_INFO', response.data.contractorInfo)
              }
              this.$store.commit('SET_LOGO', response.data.tenantInfoVo.logoPic || '')
              this.$store.commit('SET_CURR_TENANT', response.data.userMemberVo.tenantId)
              this.$store.commit('SET_CURR_TENANT_CODE', response.data.userMemberVo.personNo)
              this.$store.commit('SET_CURR_ORG', {
                name: response.data.userMemberVo.orgName,
                id: response.data.userMemberVo.changeOrgId,
                type: response.data.userMemberVo.orgType,
                code: response.data.userMemberVo.orgCode
              })
              this.getResource(
                response.data.userMemberVo.tenantId,
                response.data.userMemberVo.id,
                response.data.userMemberVo.orgType,
                response.data.tenantInfoVo.menuMode
              )
              // 菜单模式-二特殊处理
              if (this.menu_mode == 2) {
                this.$store.commit('SET_OPENKEYS', [])
                this.reload()
                this.$eventBus.$emit('getOrgTree')
                return
              }
              this.$router.push({
                path: '/main/welcome'
              })
              this.$store.commit('SET_OPENKEYS', [])
              this.$message.success('切换成功')
            }
          })
          if (response.statusCode != 200) {
            this.$message.error(response.statusMessage)
          }
        })
        .catch(res => {
          this.loading = false
          Util.processError(this, res)
        })
    },
    // 层级权限相关 初次登录权限控制层级
    setResource(curr, menuMode) {
      // 切换层级时判断层级权限过滤数据
      let originalResources = JSON.parse(localStorage.getItem('resources')) // 原始菜单数据
      // 储存原始数据，用于切换组织时获取
      localStorage.setItem(
        'resources',
        JSON.stringify(
          this.resetTreeData(
            Util.resetTree(originalResources), // 去掉菜单中的查看
            curr.orgType
          )
        )
      )
      this.$store.commit(
        'SET_RESOURCES',
        this.resetTreeData(
          Util.resetTree(originalResources), // 去掉菜单中的查看
          curr.orgType
        )
      )
      if (menuMode === 2) {
        this.$router.push('/main')
        this.$store.commit('SET_OPENKEYS', [])
        this.$emit('change', false)
      }
    }
  },
  destroyed() {}
}
</script>

<style lang="less">
.ant-dropdown-menu-item-active1 {
  // .primary_bg_color();
  background: #fff6f0;
}
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: #f3f3f3;
}
#UserMenu {
  height: 100%;
  .pointer {
    transition: all 0.5s;
    &:hover {
      background: rgba(255, 255, 255, 0.12);
    }
  }
  .user-wrapper {
    height: 100%;
    .content-box {
      height: 100%;
      .ant-dropdown-trigger {
        height: 100%;
      }
    }
  }
  .nick {
    max-width: 70px;
    margin-right: 0;
    line-height: 48px;
    color: #fff;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
</style>
