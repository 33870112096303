/*
 * @Description:监测相关路由
 * @Version: 1.0.0
 * @Author: 杜凡
 * @Date: 2022-03-29 10:21:33
 * @LastEditors: 赵亮
 * @LastEditTime: 2024-08-02 17:40:52
 */

const monitorRoute = [
  //基坑相关=============================================
  {
    //基坑-隐患排查设置
    path: '/PitHiddenDangerSetUp',
    name: 'PitHiddenDangerSetUp',
    meta: {
      name: '隐患排查设置',
      isAuth: true,
      authCode: 'web_base_IoTplatform_deepPitBase_check',
      id: ''
    },
    component: () => import('$safety/views/foundationPitConfiguration/PitHiddenDangerSetUp')
  },
  {
    //基坑隐患库导入
    path: '/setUp/hiddenDangerInvestigationSetUp/PitImportRisks',
    name: 'PitImportRisks',
    component: () => import('$safety/views/setUp/hiddenDangerInvestigationSetUp/ImportRisks'),
    meta: {
      isAuth: true,
      authCode: 'web_base_IoTplatform_deepPitBase_check_importAll',
      name: '导入'
    }
  },
  {
    //基坑信息设置
    path: '/acceptance/foundationPitConfiguration',
    name: 'FoundationPitConfiguration',
    component: () => import('$safety/views/foundationPitConfiguration/InformationSet'),
    meta: {
      name: '基坑信息设置',
      isAuth: true,
      authCode: 'web_base_IoTplatform_deepPitBase_pitInfo'
    }
  },
  {
    //基坑推送设置
    path: '/acceptance/PushInformationSettings',
    name: 'FoundationPitConfiguration',
    component: () => import('$safety/views/foundationPitConfiguration/PushInformationSettings'),
    meta: {
      name: '基坑推送设置',
      isAuth: true,
      authCode: 'web_iot_safety_pit_pushSetting'
    }
  },
  {
    //基坑监测企业级
    path: '/foundationPitMonitoring/company',
    name: 'foundationPitMonitoringCompany',
    meta: {
      name: '基坑监测',
      isAuth: true,
      authCode: 'web_pm_IoTplatform_deepPit_CompanyIndex',
      id: ''
    },
    component: () => import('$safety/views/foundationPitMonitoring/company/Index')
  },
  {
    // 基坑监测 企业级跳转项目级
    path: '/foundationPitMonitoring/companyToProject',
    name: 'foundationPitMonitoringCompanyToProject',
    meta: {
      name: '基坑监测',
      isAuth: false,
      authCode: 'web_pm_IoTplatform_deepPit_ProjectIndex'
    },
    component: () => import('$safety/views/foundationPitMonitoring/project/Index')
  },
  {
    // 基坑监测项目级
    path: '/foundationPitMonitoring/project',
    name: 'foundationPitMonitoringProject',
    meta: {
      name: '基坑监测',
      isAuth: true,
      authCode: 'web_pm_IoTplatform_deepPit_ProjectIndex',
      id: ''
    },
    component: () => import('$safety/views/foundationPitMonitoring/project/Index')
  },
  {
    // 基坑监测台账
    path: '/pitParameter',
    name: 'pitParameter',
    meta: {
      name: '深基坑台账',
      isAuth: true,
      authCode: 'web_base_monitoringData_deepPit',
      id: ''
    },
    component: () => import('$safety/views/pitParameter/Index')
  },
  {
    //基坑监测企业级
    path: '/foundationPitMonitoring/waterAoMen',
    name: 'foundationPitMonitoring/waterAoMen',
    meta: {
      name: '水位监测（澳门）',
      isAuth: true,
      authCode: 'web_pm_structure_deepPit_waterAoMen',
      id: ''
    },
    component: () => import('$safety/views/foundationPitMonitoring/waterAoMen/WaterMonitoring')
  },
  {
    // 边坡监测台账
    path: '/slopeParameter',
    name: 'slopeParameter',
    meta: {
      name: '边坡台账',
      isAuth: true,
      authCode: 'web_base_monitoringData_slope',
      id: ''
    },
    component: () => import('$safety/views/slopeParameter/Index')
  },
  {
    // 高墩监测台账
    path: '/heightPierParameter',
    name: 'heightPierParameter',
    meta: {
      name: '高墩台账',
      isAuth: true,
      authCode: 'web_base_monitoringData_heightPier',
      id: ''
    },
    component: () => import('$safety/views/heightPierParameter/Index')
  },
  {
    //巡视检查项设置
    path: '/acceptance/patrolInspectionSettings',
    name: 'PatrolInspectionSettings',
    component: () => import('$safety/views/foundationPitConfiguration/PatrolInspectionSettings'),
    meta: {
      name: '巡视检查项设置',
      isAuth: true,
      authCode: 'web_base_IoTplatform_deepPitBase_patrolInspectionSettings'
    }
  },

  //高支模相关=============================================
  {
    //高支模信息设置
    path: '/highSupportMoldMonitoring/InformationSet',
    name: 'highSupportMoldMonitoring/InformationSet',
    component: () => import('$safety/views/highSupportMoldMonitoring/InformationSet'),
    meta: {
      name: '高支模信息设置',
      isAuth: true,
      authCode: 'web_base_IoTplatform_highModulusBase_info'
    }
  },
  {
    //高支模监测 项目级
    path: '/highSupportMoldMonitoring/project',
    name: 'highSupportMoldMonitoring/project',
    component: () => import('$safety/views/highSupportMoldMonitoring/project/Index'),
    meta: {
      name: '高支模监测',
      isAuth: true,
      authCode: 'web_pm_IoTplatform_formwork_ProjectIndex'
    }
  },
  {
    // 高支模监测台账
    path: '/highParameter',
    name: 'highParameter',
    meta: {
      name: '高支模台账',
      isAuth: true,
      authCode: 'web_base_monitoringData_highModulus',
      id: ''
    },
    component: () => import('$safety/views/highParameter/Index')
  },
  {
    //倾角转换设置
    path: '/acceptance/AngleConversion',
    name: 'AngleConversion',
    component: () => import('$safety/views/foundationPitConfiguration/AngleConversion'),
    meta: {
      name: '倾角转换设置',
      isAuth: true,
      authCode: 'web_base_IoTplatform_deepPitBase_angleConversion'
    }
  },

  //边坡相关=============================================
  {
    //边坡信息设置
    path: '/slopeMonitoring/InformationSet',
    name: 'slopeMonitoring/InformationSet',
    component: () => import('$safety/views/slopeMonitoring/InformationSet'),
    meta: {
      name: '边坡信息设置',
      isAuth: true,
      authCode: 'web_base_IoTplatform_slope_info'
    }
  },
  {
    //边坡监测 项目级
    path: '/slopeMonitoring/project',
    name: 'slopeMonitoring/project',
    component: () => import('$safety/views/slopeMonitoring/project/Index'),
    meta: {
      name: '边坡监测',
      isAuth: true,
      authCode: 'web_pm_IoTplatform_slope_ProjectIndex'
    }
  },

  //高墩监测相关=============================================
  {
    //高墩信息设置
    path: '/highPierMonitoring/InformationSet',
    name: 'highPierMonitoring/InformationSet',
    component: () => import('$safety/views/highPierMonitoring/InformationSet'),
    meta: {
      name: '高墩信息设置',
      isAuth: true,
      authCode: 'web_base_IoTplatform_highPierBasc_info'
    }
  },

  //塔机相关=============================================
  {
    // 塔机监测台账
    path: '/towerParameter',
    name: 'towerParameter',
    meta: {
      name: '塔机监测台账',
      isAuth: true,
      authCode: 'web_base_monitoringData_tower',
      id: ''
    },
    component: () => import('$safety/views/towerParameter/Index')
  },

  //环境监测相关=============================================
  {
    //环境监测台账
    path: '/envirParameter',
    name: 'envirParameter',
    meta: {
      name: '环境监测台账',
      isAuth: false,
      authCode: 'web_base_monitoringData_envMonitor',
      id: ''
    },
    component: () => import('$safety/views/envirParameter/Index')
  },

  //其他=============================================
  {
    //短信推送设置
    path: '/pushSetting/UserPushConfig',
    name: 'UserPushConfig',
    component: () => import('$safety/views/pushSetting/UserPushConfig'),
    meta: {
      name: '短信推送设置',
      isAuth: false,
      authCode: ''
    }
  },
  {
    //App推送
    path: '/pushSetting/AppPushSettingPage',
    name: 'AppPushSettingPage',
    component: () => import('$safety/views/pushSetting/PushSettingPage'),
    meta: {
      name: 'App推送',
      isAuth: true,
      authCode: 'web_base_IoTplatform_MessagePush_app'
    }
  },
  {
    //短信推送
    path: '/pushSetting/MSMPushSettingPage',
    name: 'MSMPushSettingPage',
    component: () => import('$safety/views/pushSetting/PushSettingPageMSM'),
    meta: {
      name: '短信推送',
      isAuth: true,
      authCode: 'web_base_IoTplatform_MessagePush_MSM'
    }
  },
  {
    path: '/pitPointCode',
    name: 'pitPointCode',
    meta: {
      name: '点位二维码管理',
      isAuth: true,
      authCode: 'web_base_IoTplatform_deepPitBase_pitPointCode',
      id: ''
    },
    component: () =>
      import(
        /*/* : "编辑安全调度周报表" */
        '$safety/views/pitPointCode/Index'
      )
  }
]

export default monitorRoute
