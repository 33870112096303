/*
 * @Description:
 * @Version: 1.0.0
 * @Author: 蔡亚岗
 * @Date: 2022-12-14 10:59:00
 * @LastEditors: 赵亮
 * @LastEditTime: 2024-04-23 10:57:32
 */
const createExcellence = [
  {
    path: 'createExcellence',
    name: 'createExcellence',
    meta: {
      name: '质量创优'
    },
    component: () =>
      import(
        // 质量创优
        '$quality/views/createExcellence/Index'
      ),
    children: [
      {
        path: 'visualization',
        name: 'visualization',
        meta: {
          name: '创优全过程可视化',
          isAuth: true,
          authCode: 'web_pm_quality_createExcellence_visualization'
        },
        component: () =>
          import(
            // 创优全过程可视化
            '$quality/views/createExcellence/visualization/Index'
          )
      },
      {
        path: 'planWarn',
        name: 'planWarn',
        meta: {
          name: '创优计划预警',
          isAuth: true,
          keepAlive: true,
          authCode: 'web_pm_quality_createExcellence_planWarn'
        },
        component: () =>
          import(
            // 创优计划预警
            '$quality/views/createExcellence/planWarn/Index'
          )
      },
      // 创优计划管理
      {
        path: 'plan',
        name: 'plan',
        component: () =>
          import(
            // 创优计划管理
            '$quality/views/createExcellence/plan/Index'
          ),
        meta: {
          name: '创优计划管理',
          isAuth: true,
          keepAlive: true,
          authCode: 'web_pm_quality_createExcellence_plan'
        }
      },
      // 更新
      {
        path: 'planUpdate',
        name: 'planUpdate',
        component: () =>
          import(
            // 创优计划管理
            '$quality/views/createExcellence/plan/PlanUpdate.vue'
          ),
        meta: {
          name: '更新',
          isAuth: true,
          authCode: 'web_pm_quality_createExcellence_plan_update'
        }
      },
      // 详情
      {
        path: 'planDetail',
        name: 'planDetail',
        component: () =>
          import(
            // 创优计划管理
            '$quality/views/createExcellence/plan/PlanDetail.vue'
          ),
        meta: {
          name: '详情',
          isAuth: true,
          authCode: 'web_pm_quality_createExcellence_plan_detail'
        }
      },
      //  创优计划跟踪
      {
        path: 'planFollow',
        name: 'planFollow',
        component: () =>
          import(
            //  创优计划跟踪
            '$quality/views/createExcellence/planFollow/Index'
          ),
        meta: {
          name: ' 创优计划跟踪',
          isAuth: true,
          keepAlive: true,
          authCode: 'web_pm_quality_createExcellence_planFollow'
        }
      }
    ]
  }
]
export default createExcellence
