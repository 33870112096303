<!--
 * @Descripttion:
 * @Version: 1.0.0
 * @Author: 李晨光
 * @Date: 2020-07-06 15:07:36
 * @LastEditors: 李晨光
 * @LastEditTime: 2020-12-21 11:57:46
-->
<template>
  <div id="ThemeColor">
    <a-tooltip
      v-for="(item, index) in colorList"
      :key="index"
      class="setting-drawer-theme-color-colorBlock">
      <template slot="title">
        {{ item.key }}
      </template>
      <a-tag :color="item.color" style="width: 28px; height: 22px" @click="changeColor(item.color)">
        <a-icon v-if="item.color === primaryColor" type="check"></a-icon>
      </a-tag>
    </a-tooltip>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import config from '$public/components/setting/themeColor/defaultSettings'
import { updateTheme, updateColorWeak, colorList } from './settingConfig'
export default {
  components: {},
  data() {
    return {
      colorList,
      baseConfig: Object.assign({}, config)
    }
  },
  computed: {
    ...mapState({
      primaryColor: state => state.color
    })
  },
  watch: {},
  mounted() {
    this.primaryColor && updateTheme(this.primaryColor)
  },
  methods: {
    changeColor(color) {
      this.baseConfig.primaryColor = color
      this.$store.commit('SET_COLOR', color)
      updateTheme(color)
      //         , () => {
      //   let mapFrame = document.getElementById('iframe')
      //   if (mapFrame) {
      //     let iframeWin = mapFrame.contentWindow
      //     iframeWin.postMessage(
      //             {
      //               primaryColor: color
      //             },
      //             '*'
      //     )
      //   }
      // }
    }
  }
}
</script>

<style lang="less" scoped>
#ThemeColor {
  height: 30px;
  display: flex;
  align-items: center;
}
</style>
