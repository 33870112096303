/*
 * @Description:
 * @Version: 1.0.0
 * @Author: 李帅
 * @Date: 2020-12-14 11:03:20
 * @LastEditors: 赵亮
 * @LastEditTime: 2023-03-29 11:13:56
 */
const technicalReport = [
  {
    path: '/',
    name: 'TechnicalReport',
    meta: {
      name: '技术报表',
      keepAlive: false
    },
    component: () =>
      import(
        // 技术报表
        '$quality/views/technicalReport/Index'
      ),
    children: [
      {
        path: '/main/quality/technicalReport/standard',
        name: 'TechnicalStandardIndex',
        meta: {
          name: '标准规范',
          keepAlive: false,
          isAuth: false,
          authCode: ''
        },
        component: () =>
          import(
            // 技术标准规范管理 -- 标准规范
            '$quality/views/technicalReport/standard/Index'
          ),
        children: [
          {
            path: 'specification',
            name: 'Specification',
            meta: {
              name: '标准规范',
              keepAlive: false,
              isAuth: false,
              authCode: 'web_pm_technologyManage_standard_specification'
            },
            component: () =>
              import(
                // 技术标准规范管理 -- 标准规范
                '$quality/views/technicalReport/standard/specification/Specification'
              ),
            children: []
          },
          {
            path: 'importSpecification',
            name: 'ImportSpecification',
            meta: {
              name: '标准规范导入',
              keepAlive: false,
              isAuth: false,
              authCode: ''
            },
            component: () =>
              import(
                // 技术标准规范管理 -- 标准规范导入
                '$quality/views/technicalReport/standard/specification/ImportSpecification'
              ),
            children: []
          }
        ]
      },
      {
        path: '/main/quality/technicalReport/drawingDesign',
        name: 'DrawingDesignIndex',
        meta: {
          name: '图纸管理与设计变更',
          keepAlive: false,
          isAuth: false,
          authCode: ''
        },
        component: () =>
          import(
            // 图纸管理与设计变更 -- 技术文件收文登记表
            '$quality/views/technicalReport/drawingDesign/Index'
          ),
        children: [
          {
            path: 'receipt',
            name: 'Receipt',
            meta: {
              name: '技术文件收文登记表',
              keepAlive: false,
              isAuth: false,
              authCode: 'web_pm_technologyManage_file_receipt'
            },
            component: () =>
              import(
                // 图纸管理与设计变更 -- 技术文件收文登记表
                '$quality/views/technicalReport/drawingDesign/receipt/Receipt'
              ),
            children: []
          },
          {
            path: 'importReceipt',
            name: 'ImportReceipt',
            meta: {
              name: '技术文件收文登记表导入',
              keepAlive: false,
              isAuth: false,
              authCode: ''
            },
            component: () =>
              import(
                // 图纸管理与设计变更 -- 技术文件收文登记表导入
                '$quality/views/technicalReport/drawingDesign/receipt/ImportReceipt'
              ),
            children: []
          },
          {
            path: 'preTrialRecord',
            name: 'PreTrialRecord',
            meta: {
              name: '图纸预审记录表',
              keepAlive: false,
              isAuth: false,
              authCode: 'web_pm_technologyManage_file_preTrialRecord'
            },
            component: () =>
              import(
                // 图纸管理与设计变更 -- 图纸预审记录表
                '$quality/views/technicalReport/drawingDesign/preTrialRecord/PreTrialRecord'
              ),
            children: []
          },
          {
            path: 'ImportPreTrialRecord',
            name: 'ImportPreTrialRecord',
            meta: {
              name: '图纸预审记录表导入',
              keepAlive: false,
              isAuth: false,
              authCode: ''
            },
            component: () =>
              import(
                // 图纸管理与设计变更 -- 图纸预审记录表导入
                '$quality/views/technicalReport/drawingDesign/preTrialRecord/ImportPreTrialRecord'
              ),
            children: []
          },
          {
            path: 'managementAccount',
            name: 'ManagementAccount',
            meta: {
              name: '洽商单管理台账',
              keepAlive: false,
              authCode: 'web_pm_technologyManage_file_managementAccount'
            },
            component: () =>
              import(
                // 图纸管理与设计变更 -- 图纸会审/设计变更/工程技术洽商单管理台账
                '$quality/views/technicalReport/drawingDesign/managementAccount/ManagementAccount'
              ),
            children: []
          },
          {
            path: 'ImportManagementAccount',
            name: 'ImportManagementAccount',
            meta: {
              name: '洽商单管理台账导入',
              keepAlive: false
            },
            component: () =>
              import(
                // 图纸管理与设计变更 -- 图纸会审/设计变更/工程技术洽商单管理台账
                '$quality/views/technicalReport/drawingDesign/managementAccount/ImportManagementAccount'
              ),
            children: []
          },
          {
            path: 'grant',
            name: 'Grant',
            meta: {
              name: '技术文件发放记录表',
              keepAlive: false,
              isAuth: false,
              authCode: 'web_pm_technologyManage_file_grant'
            },
            component: () =>
              import(
                // 图纸管理与设计变更 -- 技术文件发放记录表
                '$quality/views/technicalReport/drawingDesign/grant/Grant'
              ),
            children: []
          },
          {
            path: 'ImportGrant',
            name: 'ImportGrant',
            meta: {
              name: '技术文件发放登记表导入',
              keepAlive: false,
              isAuth: false,
              authCode: ''
            },
            component: () =>
              import(
                // 图纸管理与设计变更 -- 技术文件发放登记表导入
                '$quality/views/technicalReport/drawingDesign/grant/ImportGrant'
              ),
            children: []
          },
          {
            path: 'changeRequest',
            name: 'ChangeRequest',
            meta: {
              name: '工程洽商变更申请表',
              keepAlive: false,
              isAuth: false,
              authCode: 'web_pm_technologyManage_file_changeRequest'
            },
            component: () =>
              import(
                // 图纸管理与设计变更 -- 工程洽商变更申请表
                '$quality/views/technicalReport/drawingDesign/changeRequest/ChangeRequest'
              ),
            children: []
          },
          {
            path: 'ImportChangeRequest',
            name: 'ImportChangeRequest',
            meta: {
              name: '工程洽商变更申请表导入',
              keepAlive: false,
              isAuth: false,
              authCode: ''
            },
            component: () =>
              import(
                // 图纸管理与设计变更 -- 工程洽商变更申请表导入
                '$quality/views/technicalReport/drawingDesign/changeRequest/ImportChangeRequest'
              ),
            children: []
          }
        ]
      },
      {
        path: '/main/quality/technicalReport/organizationScheme',
        name: 'OrganizationSchemeIndex',
        meta: {
          name: '施工组织设计与施工方案',
          keepAlive: false
        },
        component: () =>
          import(
            // 施工组织设计与施工方案
            '$quality/views/technicalReport/organizationScheme/Index'
          ),
        children: [
          {
            path: 'approvalPlan',
            name: 'ApprovalPlan',
            meta: {
              name: '施工方案审批计划表',
              keepAlive: false,
              isAuth: false,
              authCode: ''
            },
            component: () =>
              import(
                // 施工组织设计与施工方案 -- 施工方案审批计划表
                '$quality/views/technicalReport/organizationScheme/approvalPlan/ApprovalPlan'
              ),
            children: []
          },
          {
            path: 'ImportApprovalPlan',
            name: 'ImportApprovalPlan',
            meta: {
              name: '施工方案审批计划表导入',
              keepAlive: false,
              isAuth: false,
              authCode: ''
            },
            component: () =>
              import(
                // 施工组织设计与施工方案 -- 施工方案审批计划表导入
                '$quality/views/technicalReport/organizationScheme/approvalPlan/ImportApprovalPlan'
              ),
            children: []
          },
          {
            path: 'approvalAccount',
            name: 'ApprovalAccount',
            meta: {
              name: '施工组织设计审批台账',
              keepAlive: false,
              isAuth: false,
              authCode: 'web_pm_technologyManage_plan_approvalAccount'
            },
            component: () =>
              import(
                // 施工组织设计与施工方案 -- 施工组织设计审批台账
                '$quality/views/technicalReport/organizationScheme/approvalAccount/ApprovalAccount'
              ),
            children: []
          },
          {
            path: 'ImportApprovalAccount',
            name: 'ImportApprovalAccount',
            meta: {
              name: '施工组织设计审批台账导入',
              keepAlive: false
            },
            component: () =>
              import(
                // 施工组织设计与施工方案 -- 施工组织设计审批台账导入
                '$quality/views/technicalReport/organizationScheme/approvalAccount/ImportApprovalAccount'
              ),
            children: []
          },
          {
            path: 'summaryDesignDisclosure',
            name: 'SummaryDesignDisclosure',
            meta: {
              name: '方案交底汇总表',
              keepAlive: false,
              authCode: 'web_pm_technologyManage_plan_summaryDesignDisclosure'
            },
            component: () =>
              import(
                // 施工组织设计与施工方案 -- 施工组织设计（方案）交底汇总表
                '$quality/views/technicalReport/organizationScheme/summaryDesignDisclosure/SummaryDesignDisclosure'
              ),
            children: []
          },
          {
            path: 'importSummaryDesignDisclosure',
            name: 'ImportSummaryDesignDisclosure',
            meta: {
              name: '方案交底汇总表导入',
              keepAlive: false
            },
            component: () =>
              import(
                // 施工组织设计与施工方案 -- 方案交底汇总表导入
                '$quality/views/technicalReport/organizationScheme/summaryDesignDisclosure/ImportSummaryDesignDisclosure'
              ),
            children: []
          },
          {
            path: 'subItemsSummaryDisclosure',
            name: 'SubItemsSummaryDisclosure',
            meta: {
              name: '分部分项交底汇总表',
              keepAlive: false,
              authCode: 'web_pm_technologyManage_plan_subItemsSummaryDisclosure'
            },
            component: () =>
              import(
                // 施工组织设计与施工方案 -- 分部分项交底汇总表
                '$quality/views/technicalReport/organizationScheme/subItemsSummaryDisclosure/SubItemsSummaryDisclosure'
              ),
            children: []
          },
          {
            path: 'importSubItemsSummaryDisclosure',
            name: 'ImportSubItemsSummaryDisclosure',
            meta: {
              name: '分布分项交底汇总表导入',
              keepAlive: false
            },
            component: () =>
              import(
                // 施工组织设计与施工方案 -- 分布分项交底汇总表导入
                '$quality/views/technicalReport/organizationScheme/subItemsSummaryDisclosure/ImportSubItemsSummaryDisclosure'
              ),
            children: []
          },
          {
            path: 'schemeAuditor',
            name: 'SchemeAuditor',
            meta: {
              name: '方案审核师表',
              keepAlive: false,
              isAuth: false,
              authCode: 'web_pm_technologyManage_plan_schemeAuditor'
            },
            component: () =>
              import(
                // 施工组织设计与施工方案 -- 方案审核师表
                '$quality/views/technicalReport/organizationScheme/schemeAuditor/SchemeAuditor'
              ),
            children: []
          },
          {
            path: 'importSchemeAuditor',
            name: 'ImportSchemeAuditor',
            meta: {
              name: '方案审核师表导入',
              keepAlive: false
            },
            component: () =>
              import(
                // 施工组织设计与施工方案 -- 方案审核师表导入
                '$quality/views/technicalReport/organizationScheme/schemeAuditor/ImportSchemeAuditor'
              ),
            children: []
          }
        ]
      },
      {
        path: '/main/quality/technicalReport/technicalManagement',
        name: 'TechnicalManagementIndex',
        meta: {
          name: '双优化及钢筋技术管理',
          keepAlive: false
        },
        component: () =>
          import(
            // 双优化及钢筋技术管理
            '$quality/views/technicalReport/technicalManagement/Index'
          ),
        children: [
          {
            path: 'rewardList',
            name: 'RewardList',
            meta: {
              name: '双优化项目拟计提奖励清单',
              keepAlive: false,
              authCode: 'web_pm_technologyManage_optimization_RewardList'
            },
            component: () =>
              import(
                // 双优化及钢筋技术管理 -- 双优化项目拟计提奖励清单
                '$quality/views/technicalReport/technicalManagement/rewardList/RewardList'
              ),
            children: []
          },
          {
            path: 'importRewardList',
            name: 'ImportRewardList',
            meta: {
              name: '双优化项目拟计提奖励清单导入',
              keepAlive: false
            },
            component: () =>
              import(
                // 双优化及钢筋技术管理 -- 双优化项目拟计提奖励清单导入
                '$quality/views/technicalReport/technicalManagement/rewardList/ImportRewardList'
              ),
            children: []
          },
          {
            path: 'statisticalTable',
            name: 'StatisticalTable',
            meta: {
              name: '钢筋技术优化效益统计表',
              keepAlive: false,
              authCode: 'web_pm_technologyManage_optimization_StatisticalTable'
            },
            component: () =>
              import(
                // 双优化及钢筋技术管理 -- 钢筋技术优化效益统计表
                '$quality/views/technicalReport/technicalManagement/statisticalTable/StatisticalTable'
              ),
            children: []
          },
          {
            path: 'importStatisticalTable',
            name: 'ImportStatisticalTable',
            meta: {
              name: '钢筋技术优化效益统计表导入',
              keepAlive: false
            },
            component: () =>
              import(
                // 双优化及钢筋技术管理 -- 钢筋技术优化效益统计表导入
                '$quality/views/technicalReport/technicalManagement/statisticalTable/ImportStatisticalTable'
              ),
            children: []
          }
        ]
      },
      {
        path: '/main/quality/technicalReport/technicalReview',
        name: 'TechnicalReviewIndex',
        meta: {
          name: '技术复核',
          keepAlive: false
        },
        component: () =>
          import(
            // 技术复核
            '$quality/views/technicalReport/technicalReview/Index'
          ),
        children: [
          {
            path: 'schedule',
            name: 'Schedule',
            meta: {
              name: '技术复核计划表',
              keepAlive: false,
              authCode: 'web_pm_technologyManage_review_schedule'
            },
            component: () =>
              import(
                // 技术复核 -- 技术复核计划表
                '$quality/views/technicalReport/technicalReview/schedule/Schedule'
              ),
            children: []
          },
          {
            path: 'ImportSchedule',
            name: 'ImportSchedule',
            meta: {
              name: '技术复核计划表导入',
              keepAlive: false
            },
            component: () =>
              import(
                // 技术复核 -- 技术复核计划表导入
                '$quality/views/technicalReport/technicalReview/schedule/ImportSchedule'
              ),
            children: []
          },
          {
            path: 'recordSheet',
            name: 'RecordSheet',
            meta: {
              name: '基准点交接记录表',
              keepAlive: false,
              authCode: 'web_pm_technologyManage_review_recordSheet'
            },
            component: () =>
              import(
                // 技术复核 -- 基准点交接记录表
                '$quality/views/technicalReport/technicalReview/recordSheet/RecordSheet'
              ),
            children: []
          },
          {
            path: 'ImportRecordSheet',
            name: 'ImportRecordSheet',
            meta: {
              name: '基准点交接记录表导入',
              keepAlive: false
            },
            component: () =>
              import(
                // 技术复核 -- 基准点交接记录表
                '$quality/views/technicalReport/technicalReview/recordSheet/ImportRecordSheet'
              ),
            children: []
          },
          {
            path: 'managementAccount',
            name: 'ManagementAccount',
            meta: {
              name: '技术复核管理台账',
              keepAlive: false,
              authCode: 'web_pm_technologyManage_review_managementAccount'
            },
            component: () =>
              import(
                // 技术复核 -- 技术复核管理台账
                '$quality/views/technicalReport/technicalReview/managementAccount/ManagementAccount'
              ),
            children: []
          },
          {
            path: 'importAccount',
            name: 'ImportAccount',
            meta: {
              name: '技术复核管理台账导入',
              keepAlive: false
            },
            component: () =>
              import(
                //  技术复核 -- 技术复核管理台账导入
                '$quality/views/technicalReport/technicalReview/managementAccount/ImportAccount'
              ),
            children: []
          }
        ]
      },
      {
        path: '/main/quality/technicalReport/engineeringTechnical',
        name: 'EngineeringTechnicalIndex',
        meta: {
          name: '工程技术资料管理',
          keepAlive: false
        },
        component: () =>
          import(
            // 工程技术资料管理
            '$quality/views/technicalReport/engineeringTechnical/Index'
          ),
        children: [
          {
            path: 'list',
            name: 'List',
            meta: {
              name: '竣工单位工程情况一览表',
              keepAlive: false
            },
            component: () =>
              import(
                // 工程技术资料管理 -- 竣工单位工程情况一览表
                '$quality/views/technicalReport/engineeringTechnical/list/List'
              ),
            children: []
          },
          {
            path: 'authorizedAccount',
            name: 'AuthorizedAccount',
            meta: {
              name: '南方公司总工授权台账',
              keepAlive: false,
              authCode: 'web_pm_technologyManage_plan_authorizedAccount'
            },
            component: () =>
              import(
                // 工程技术资料管理 -- 南方公司总工授权台账
                '$quality/views/technicalReport/engineeringTechnical/authorizedAccount/AuthorizedAccount'
              ),
            children: []
          },
          {
            path: 'ImportAuthorizedAccount',
            name: 'ImportAuthorizedAccount',
            meta: {
              name: '南方公司总工授权台账',
              keepAlive: false
            },
            component: () =>
              import(
                // 工程技术资料管理 -- 南方公司总工授权台账导入
                '$quality/views/technicalReport/engineeringTechnical/authorizedAccount/ImportAuthorizedAccount'
              ),
            children: []
          },
          {
            path: 'demandRegistration',
            name: 'DemandRegistration',
            meta: {
              name: '技术服务需求登记表',
              keepAlive: false
            },
            component: () =>
              import(
                // 工程技术资料管理 -- 技术服务需求登记表
                '$quality/views/technicalReport/engineeringTechnical/demandRegistration/DemandRegistration'
              ),
            children: []
          },
          {
            path: 'ImportDemandRegistration',
            name: 'ImportDemandRegistration',
            meta: {
              name: '技术服务需求登记表导入',
              keepAlive: false
            },
            component: () =>
              import(
                // 工程技术资料管理 -- 技术服务需求登记表导入
                '$quality/views/technicalReport/engineeringTechnical/demandRegistration/ImportDemandRegistration'
              ),
            children: []
          },
          {
            path: 'employeeEvaluation',
            name: 'EmployeeEvaluation',
            meta: {
              name: '技术服务实施效果反馈、考核表',
              keepAlive: false
            },
            component: () =>
              import(
                // 工程技术资料管理 -- 技术服务实施效果反馈、考核表
                '$quality/views/technicalReport/engineeringTechnical/employeeEvaluation/EmployeeEvaluation'
              ),
            children: []
          },
          {
            path: '/main/quality/technicalReport/ScienceTechnologyManagement',
            name: 'ScienceTechnologyManagement',
            meta: {
              name: '科技信息填报',
              keepAlive: false,
              authCode: 'web_pm_technologyManage_scienceaAndTechnology'
            },
            component: () =>
              import(
                // 科技信息填报
                '$quality/views/technicalReport/scienceTechnologyManagementData/ScienceTechnologyManagement'
              )
          },
          {
            path: '/main/quality/technicalReport/DesignManagement',
            name: 'DesignManagement',
            meta: {
              name: '设计信息填报',
              keepAlive: false,
              authCode: 'web_pm_technologyManage_scienceaAndTechnology_designDataFile'
            },
            component: () =>
              import(
                // 设计信息填报
                '$quality/views/technicalReport/scienceTechnologyManagementData/DesignManagement'
              )
          }
        ]
      }
    ]
  }
]
export default technicalReport
