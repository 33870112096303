/*
 * @Description:
 * @Version: 1.0.0
 * @Author: 李帅
 * @Date: 2020-11-09 11:27:31
 * @LastEditors: 赵亮
 * @LastEditTime: 2023-03-28 16:23:09
 */
const dataStatisticsReport = [
  /*/* : "检查数据统计报表" */
  {
    path: 'dataStatisticsReport',
    name: 'DataStatisticsReport',
    meta: {
      name: '检查数据统计报表',
      keepAlive: false
    },
    component: () => import('$quality/views/statisticsReport/Index'),
    children: [
      /*/* : "检查问题分析" */
      {
        path: 'inspectProblem',
        name: 'InspectProblem',
        meta: {
          name: '检查问题分析',
          keepAlive: false,
          isAuth: false,
          authCode: ''
        },
        component: () => import('$quality/views/statisticsReport/inspectProblem/Index'),
        children: []
      },
      /*/* : "分包单位分析" */
      {
        path: 'contact',
        name: 'Contact',
        meta: {
          name: '分包单位分析',
          keepAlive: false,
          isAuth: false,
          authCode: ''
        },
        component: () => import('$quality/views/statisticsReport/contact/Index'),
        children: []
      }
    ]
  }
]
export default dataStatisticsReport
