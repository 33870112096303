/*
 * @Description:  OSS文件上传
 * @Version: 1.0.0
 * @Author: 李晨光
 * @Date: 2020-07-25 10:54:08
 * @LastEditors: 李帅
 * @LastEditTime: 2023-05-08 09:52:17
 */
import vm from '../../../../main'
// oss
import OSS from 'ali-oss'
// 依赖库，对文件进行md5加密唯一标识
// import SparkMD5 from 'spark-md5'

let client = []
/**
 * @Description: oss配置
 * @Param: {endpoint} Your endpoint
 * @Param: {accessKeyId}Your AccessKeyId
 * @Param: {accessKeySecret} Your AccessKeySecret
 * @Param: {bucket} Your bucket nam
 * @Return:
 */
let ossConfig = {
  // endpoint: 'oss-cn-beijing.aliyuncs.com',
  // accessKeyId: 'LTAI5tAp3Pg8YdenE59svAbj',
  // accessKeySecret: 'V5HZabIVCqV8HN4MgpVLBiSER8447P',
  // bucket: 'spm3-file',
}
let fileRowObj = {}
let checkpoint = []
// 断点记录点。浏览器重启后无法直接继续上传，您需要手动触发上传操作。
const pointerCache = {
  get(file, uid) {
    const cache = localStorage.getItem('cacheKey_' + file.uid)
    if (!cache || cache == 'undefined') return null
    const cacheJson = JSON.parse(cache)
    if (cacheJson && cacheJson.file.uid !== uid) return null
    cacheJson.file = file
    return cacheJson
  },
  set(pointer, uid) {
    localStorage.setItem('cacheKey_' + uid, JSON.stringify(pointer))
  },
  remove(file) {
    localStorage.removeItem('cacheKey_' + file.uid)
  },
}
const oss = {
  getOSSMsg() {
    return new Promise((resolve, reject) => {
      // todo
      let tenantId = JSON.parse(
        localStorage.getItem('bj_spm_main_test')
      ).curr_tenant
      vm.$get(Util.baseUrlSafety + '/web/oss/config/get', {
        tenantId,
        ossType: 1, // oss分类：1阿里云，2腾讯云，3，minio,4,七牛，5，本地
      })
        .then((response) => {
          Util.processRes(response, () => {
            if (response.data) {
              let { endpoint, accessKeyId, accessKeySecret, bucket } =
                response.data
              let config = {
                endpoint,
                accessKeyId: accessKeyId,
                accessKeySecret,
                bucket,
              }
              resolve(config)
            } else {
              vm.$message.error('获取OSS配置失败')
            }
          })
        })
        .catch((res) => {
          vm.$message.error('获取OSS配置失败')
        })
    })
  },
  /**
   * @Description: 文件上传
   * @Param: {file} file 文件
   * @Param: {path} 文件路径
   * @Param: {name} file名称
   * @Param: {fileObj} 其他属性
   * @Param: {retry} 重试次数
   * @Param: {callback} 上传成功回调
   */
  async upload(
    file,
    path = '',
    name,
    fileObj,
    retry = 3,
    progress,
    successCallback,
    failCallback
  ) {
    fileRowObj = fileObj
    ossConfig = Object.keys(ossConfig).length
      ? ossConfig
      : await oss.getOSSMsg()
    // 阿里文件上传对象
    let _client = new OSS(ossConfig)
    // 断点续传指针
    let cacheKey = pointerCache.get(file, file.uid)
    cacheKey && checkpoint.push(cacheKey)

    if (client && client.length) {
      // 上传文件不存在相同的时
      if (client.filter((v) => v.file.name == file.name).length == 0) {
        client.push({
          file,
          name,
          successCallback,
          _client,
        })
      } else {
        // 存在相同的时覆盖
        client.forEach((v, i) => {
          if (v.file.name == file.name) {
            client[i] = {
              file,
              name,
              successCallback,
              _client,
            }
          }
        })
      }
    } else {
      // 第一次选择上传文件
      client.push({
        file,
        name,
        successCallback,
        _client,
      })
    }

    // 失败后尝试两次
    for (let i = 0; i < retry; i++) {
      try {
        if (fileRowObj.isPaused) return
        let _id = new Date().valueOf() + '_'
        let uploadName = [path + _id + name]
        const result = await _client.multipartUpload(uploadName[0], file, {
          checkpoint:
            checkpoint && checkpoint.length
              ? checkpoint.find((v) => v && v.file && v.file.name == file.name)
              : null,
          async progress(percentage, cpt) {
            // 上传进度
            fileObj.status = 0
            fileObj.progress = parseInt(percentage * 100, 10)
            fileObj.uploadSize =
              percentage != 1
                ? (parseInt(percentage * 100, 10) / 100) * fileObj.size
                : fileObj.size
            pointerCache.set(cpt, file.uid)
            if (checkpoint && checkpoint.length) {
              let num = checkpoint
                .map((v) => v && v.file && v.file.name)
                .indexOf(file.name)
              if (num > -1) {
                checkpoint[num] = cpt
              } else {
                checkpoint[0] = cpt
              }
            }

            // if (cpt.file.uid == file.uid) checkpoint = cpt
            progress && progress(fileObj.progress, uploadName)
          },
        })
        if (result.res.status == '200') {
          // console.log(result.res.requestUrls)
          fileObj.status = 1
          // let url = `https://${ossConfig.bucket}.${ossConfig.endpoint}/${path}${name}`
          let url = result.res.requestUrls[0].split('?')[0]
          console.log(url)
          this.emptyUpdateData(client, file, checkpoint)
          successCallback && successCallback(url)
        }
        // this.$emit('successed', files[index].fileMsg, url, index, files)
        break // break if success
      } catch (e) {
        if (i == 2) {
          fileRowObj.status = -1
          fileRowObj.uid = file.uid

          this.emptyUpdateData(client, file, checkpoint)
          failCallback && failCallback(e, fileRowObj)
          // console.error('上传失败：', e)
        }
      }
    }
  },
  /**
   * 清空上传数据
   * client
   * file  当前上传文件
   * checkpoint 断点续传记录
   * isRemove 是否删除断点续传记录
   */
  emptyUpdateData(client, file, checkpoint) {
    client.forEach((v, i) => {
      if (v.file.name == file.name) {
        client.splice(i, 1)
      }
    })
    pointerCache.remove(file)

    checkpoint &&
      checkpoint.length &&
      checkpoint.forEach((v, i) => {
        if (v && v.file && v.file.name == file.name) {
          checkpoint.splice(i, 1)
        }
      })
  },
  /**
   * @Description: 暂停上传
   * @Param:
   */
  pause(file, callback) {
    fileRowObj.isPaused = true
    if (client && client.length) {
      client.find((v) => v.file.name == file.name)._client.cancel()
      callback && callback()
    } else {
    }
  },
  /**
   * @Description: 取消上传
   * @Param:
   */
  cancel(file, callback) {
    // 暂停分片上传方法
    if (client && client.length) {
      client.find((v) => v.file.name == file.name)._client.cancel()
      callback && callback()
      // 清除断点记录点
      pointerCache.remove(file)
    } else {
      this.message.success('取消失败！')
    }
  },
  async deleteFile(file, successCallback, failCallback) {
    ossConfig = Object.keys(ossConfig).length
      ? ossConfig
      : await oss.getOSSMsg()
    // 阿里文件上传对象
    let _client = new OSS(ossConfig)
    // 断点续传指针
    try {
      this.pause(file)
      let result = await _client.deleteMulti(file.uploadName)
      successCallback && successCallback(result)
      // this.cancel(file)
      client &&
        client.length &&
        client.forEach((v, i) => {
          if (v.file.name == file.name) {
            client.splice(i, 1)
          }
        })
    } catch (e) {
      console.log(e)
      failCallback && failCallback()
    }
  },
}

export default oss
