/** * @Author: 李晨光 * @Date: 2019/9/9 * @Version: 3.0 * @Last Modified by: 任笠 * @Last Modified
time: 2021-06-08 **/
<comment>
  # 组件注释
  个人中心
</comment>
<template>
  <div id="UserInfo">
    <a-modal id="UserInfo-Modal"
             title="个人信息"
             centered
             wrap-class-name="userInfo"
             :width="800"
             :visible="formModalVisible"
             :mask-closable="false"
             @ok="changeUserInfo"
             @cancel="closeModal()">
      <a-skeleton active
                  :loading="false"
                  :paragraph="{ rows: 10 }">
        <a-form :form="form">
          <div class="flex"
               style="position: relative">
            <!-- <a-upload
               class="profilePhoto"
               :fileList="fileList"
               listType="picture-card"
               :remove="handleRemove"
               :customRequest="customRequest"
               :beforeUpload="beforeUpload"
               @preview="handlePreview"
             >
               <div v-if="fileList.length < 1" style="border-radius: 50px">
                 <a-icon type="plus" />
                 <div class="ant-upload-text">上传</div>
               </div>
             </a-upload>

             <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
               <img alt="example" style="width: 100%" class="m-t-20" :src="previewImage" />
             </a-modal>
             <a-input
               style="position: absolute;z-index: -1"
               type="text"
               placeholder=""
               v-decorator="[
                 'icon',
                 {rules: [
                   { required: true, message: '请输入应用描述！' },
                 ]
               }
            ]"/>-->
            <div class="profile pointer"
                 @click="iconModalVisible = !iconModalVisible">
              <img :src="icon || '../../../../static/man_2.jpg'"
                   alt />
            </div>
            <div style="margin-top: 80px">
              <a-row :gutter="20">
                <a-col v-show="!newPhone"
                       :xs="24"
                       :sm="24"
                       :md="12"
                       :lg="12"
                       :xl="12">
                  <a-form-item label="手机号"
                               :validate-status="formError(form, 'oldPhone') ? 'error' : ''"
                               :help="formError(form, 'oldPhone') || ''">
                    <div class="d-flex align-center">
                      <a-input v-decorator="[
                          'oldPhone',
                          { rules: [{ required: true, message: '请输入手机号！' }] }
                        ]"
                               type="text"
                               disabled
                               placeholder="请输入手机号" />
                      <!-- 用于参建单位人员时，不可修改手机号 -->
                      <span v-if="login_msg.userType === 1"
                            class="icon-btn m-l-6"
                            title="修改手机号"
                            @click="changePhoneNumber()">
                        <icon-font type="spm-icon-bianji" />
                      </span>
                    </div>
                    <!-- <a-button @click="changePhoneNumber()"
                              class="m-t-10">
                      <span>修改手机号</span>
                    </a-button> -->
                  </a-form-item>
                </a-col>
                <a-col v-show="newPhone"
                       :xs="24"
                       :sm="24"
                       :md="12"
                       :lg="12"
                       :xl="12">
                  <a-form-item label="手机号"
                               :validate-status="formError(form, 'newPhone') ? 'error' : ''"
                               :help="formError(form, 'newPhone') || ''">
                    <div class="d-flex align-center">
                      <a-input v-decorator="['newPhone', { rules: [] }]"
                               type="text"
                               disabled
                               placeholder="请输入手机号" />
                      <!-- 用于参建单位人员时，不可修改手机号 -->
                      <span v-if="login_msg.userType === 1"
                            class="icon-btn m-l-6"
                            title="修改手机号"
                            @click="changePhoneNumber()">
                        <icon-font type="spm-icon-bianji" />
                      </span>
                      <!-- <a-button @click="changePhoneNumber()">
                      <span>修改手机号</span>
                    </a-button> -->
                    </div>
                  </a-form-item>
                </a-col>
                <a-col :xs="24"
                       :sm="24"
                       :md="12"
                       :lg="12"
                       :xl="12">
                  <a-form-item label="用户名"
                               :validate-status="formError(form, 'name') ? 'error' : ''"
                               :help="formError(form, 'name') || ''">
                    <div class="d-flex align-center">
                      <!-- 用于参建单位人员时，不可修改用户名 -->
                      <a-input v-decorator="['name', { rules: [{ required: true, message: '用户名！' }] }]"
                               type="text"
                               :disabled="login_msg.userType !== 1"
                               placeholder="用户名" />
                    </div>
                  </a-form-item>
                </a-col>
                <a-col :xs="24"
                       :sm="24"
                       :md="12"
                       :lg="12"
                       :xl="12">
                  <a-form-item label="邮箱"
                               :validate-status="formError(form, 'mail') ? 'error' : ''"
                               :help="formError(form, 'mail') || ''">
                    <a-input v-decorator="['mail', { rules: [{ required: false, message: '邮箱！' }] }]"
                             type="text"
                             placeholder="邮箱" />
                  </a-form-item>
                </a-col>

                <a-col :xs="24"
                       :sm="24"
                       :md="24"
                       :lg="24"
                       :xl="24">
                  <a-form-item label="签名"
                               :validate-status="formError(form, 'signature') ? 'error' : ''"
                               :help="formError(form, 'signature') || ''">
                    <v-electronicSignature ref="electronicSignature"
                                           :defaultImageUrl='signature'
                                           v-if="isCanvasBox"
                                           @content-change="contentChange">
                    </v-electronicSignature>
                  </a-form-item>
                </a-col>
              </a-row>
            </div>
          </div>
        </a-form>
      </a-skeleton>
      <template slot="footer">
        <a-button @click="closeModal()">取 消</a-button>
        <a-button style="margin-left: 10px"
                  type="primary"
                  :disabled="hasErrors(form.getFieldsError())"
                  @click="changeUserInfo">保 存</a-button>
      </template>
    </a-modal>
    <a-modal title="上传图片"
             centered
             :width="500"
             :visible="iconModalVisible"
             :mask-closable="false"
             @cancel="iconModalVisible = false">
      <a-skeleton active
                  :loading="false"
                  :paragraph="{ rows: 10 }">
        <div class="flex align-center justify-center">
          <croppa ref="croppa"
                  v-model="myCroppa"
                  :width="300"
                  :height="300"
                  placeholder="上传头像"
                  prevent-white-space
                  canvas-color="transparent"
                  :initial-image="icon || '../../../static/man_2.jpg'"
                  @zoom="onZoom"
                  @init="onInit">
            <icon-font type="spm-icon-shouqicopy"></icon-font>
          </croppa>
          <a-slider id="test"
                    v-model="sliderVal"
                    :marks="{ 0: '缩小', 100: '放大' }"
                    style="height: 200px; margin-left: 60px"
                    :vertical="true"
                    :zoom-speed="10"
                    :min="sliderMin"
                    :max="sliderMax"
                    :step="10"
                    @new-image-drawn="onNewImage"
                    @change="changeIconSize" />
        </div>
      </a-skeleton>
      <template slot="footer">
        <a-button @click="iconModalVisible = false">取 消</a-button>
        <a-button style="margin-left: 10px"
                  type="primary"
                  @click="submitImg">确 认</a-button>
      </template>
    </a-modal>
    <ChangeMobile ref="ChangeMobile"
                  @callback="successChangeMobile"></ChangeMobile>
  </div>
</template>

<script>
import Title from '$public/components/layouts/Title'
import ChangeMobile from './ChangeMobile'
import { mapState } from 'vuex'
import Vue from 'vue'
import VueCroppa from 'vue-croppa'
import ElectronicSignature from '$public/components/lib/ElectronicSignature.vue'
Vue.use(VueCroppa)

export default {
  name: 'UserInfo',
  components: {
    Title,
    ChangeMobile,
    'v-electronicSignature': ElectronicSignature
  },
  props: {},
  data() {
    return {
      api: {
        getUserData: '/app/passport/user/detail',
        changeUserInfoApi: '/web/passport/user/update-user-info'
      },
      isCanvasBox: false,
      signature: '',
      form: this.$form.createForm(this), // 新增应用
      fileList: [],
      previewVisible: false,
      previewImage: '',
      myCroppa: {},
      iconModalVisible: false,
      sliderVal: 0,
      sliderMin: 0,
      sliderMax: 100,
      icon: '',
      formModalVisible: false,
      newPhone: '',
      times: 0,
      oldPhone: '',
      oldIcon: '',
      // 当前成功验证码
      smsCode: null
    }
  },
  computed: {
    ...mapState({
      login_msg: state => state.login_msg,
      curr_org: state => state.curr_org,
      tenantId: state => state.curr_tenant
    })
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      this.oldIcon == this.icon
    })
  },
  watch: {
    login_msg: {
      handler(value) {
        this.icon = value.profile
        if (this.times == 0) {
          this.times++
          this.oldIcon = this.icon
        }
      },
      deep: true,
      immediate: true
    }
    // myCroppa:{
    //   handler(value) {
    //       console.log(value, ')
    //     },
    //     deep: true,
    //     immediate: true
    // }
  },
  methods: {
    /**
     * 签名成功后点击条按钮返回签名数据
     * @param values 返回签名数据
     */
    contentChange(values) {
      if (values.signature) {
        this.snapshotedCallBack(values.signature)
      } else {
        this.signature = ''
      }
    },
    /**
     * 签名图片base64转换为文件格式
     * @param dataurl 签名图片base64
     * @param filename 签名图片名称
     *
     */
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, { type: mime })
    },
    /**
     * 签名成功后点击条按钮返回签名数据
     * @param val 返回的签名字符
     */
    snapshotedCallBack(val) {
      let imgName = Date.now() + '.png'
      let file = this.dataURLtoFile(val, imgName)
      this.savePhotoName(file)
    },
    /**
     * 签名图片base64转换为文件格式
     * @param data 签名图片base64
     */
    savePhotoName(data) {
      let params = {
        path: 'libs/1336232091474825218/1336491856715821057',
        file: data
      }
      let _api = Util.baseUrlUpload + '/file/upload'
      this.$uploadFile(_api, params)
        .then(response => {
          Util.processRes(response, () => {
            this.$nextTick(() => {
              this.signature = response.data.resultUrl
            })
            this.$forceUpdate()
          })
        })
        .catch(res => {})
    },
    // 手机号验证成功后回调
    successChangeMobile(obj) {
      this.newPhone = obj.phone
      this.smsCode = obj.code
      this.oldPhone = this.form.getFieldValue('oldPhone')
      this.form.setFieldsValue({
        newPhone: obj.phone
      })
    },
    openModal() {
      this.isCanvasBox = false
      this.newPhone = undefined
      this.formModalVisible = true

      this.$post(this.api.getUserData, {})
        .then(response => {
          Util.processRes(response, () => {
            let _data = response.data
            let _obj = Util.delParams(_data, ['name', 'phone', 'mail'])
            this.signature = _data.signature
            _obj.oldPhone = Util.deepCopy(_obj.phone)
            // this.newPhone = Util.deepCopy(_obj.phone)

            this.$nextTick(() => {
              this.form.setFieldsValue(_obj)
              this.form.validateFields()
              setTimeout(() => {
                this.isCanvasBox = true
              }, 1)
            })
          })
          Util.processError(this, response)
        })
        .catch(res => {
          console.log(res)
        })
    },
    changePhoneNumber() {
      this.$refs['ChangeMobile'].openModal(this.form.getFieldValue('oldPhone'))
    },
    closeModal() {
      this.formModalVisible = false
      this.form.resetFields()
    },
    // 验证表单 获取表单validate 状态 按钮根据状态可不可操作
    formError(form, field) {
      const { getFieldError, isFieldTouched } = form
      return isFieldTouched(field) && getFieldError(field)
    },
    hasErrors(fieldsError) {
      return Object.keys(fieldsError).some(field => fieldsError[field])
    },
    handlePreview(file) {
      this.previewImage = file.url || file.thumbUrl
      this.previewVisible = true
    },
    handleChange({ fileList }) {
      this.fileList = fileList
    },
    handleCancel() {
      this.previewVisible = false
    },
    /**
     * 上传文件之前
     */
    beforeUpload(file) {
      // console.log(file)
      // this.fileList = [file];
      //return false;
    },
    //上传文件
    customRequest(data) {
      // console.log(this.fileList)
      //固定参数
      if (!data) {
        this.$message.error('请选择图片')
        return
      }
      const params = {
        path: `libs/${this.tenantId}/${this.curr_org.id}`,
        file: data
      }

      this.$uploadFile(Util.baseUrlUpload + '/file/upload', params)
        .then(response => {
          Util.processRes(response, () => {
            this.$message.success('上传成功！')
            this.icon = response.data.resultUrl
            this.iconModalVisible = false
          })
          Util.processError(this, response)
        })
        .catch(res => {})
    },

    /**
     * 删除文件
     */
    handleRemove(file) {
      const index = this.fileList.indexOf(file)
      const newFileList = this.fileList.slice()
      newFileList.splice(index, 1)
      this.fileList = newFileList
      if (this.fileList.length > 0) {
      } else {
        this.fileObj = {
          fileName: '',
          url: ''
        }
        this.form.setFieldsValue({
          icon: this.fileObj.url
        })
      }
      this.$nextTick(() => {
        this.form.validateFields()
      })
    },
    onInit() {
      this.myCroppa.addClipPlugin(function (ctx, x, y, w, h) {
        /*
         * ctx: canvas context
         * x: start point (top-left corner) x coordination
         * y: start point (top-left corner) y coordination
         * w: croppa width
         * h: croppa height
         */
        ctx.beginPath()
        ctx.arc(x + w / 2, y + h / 2, w / 2, 0, 2 * Math.PI, true)
        ctx.closePath()
      })
    },
    changeIconSize(e) {
      this.myCroppa.scaleRatio = e / 10
      console.log(this.myCroppa.scaleRatio)
    },
    onZoom() {
      this.sliderVal = this.myCroppa.scaleRatio * 10
    },
    onNewImage() {
      this.sliderVal = this.myCroppa.scaleRatio
      this.sliderMin = this.myCroppa.scaleRatio / 2
      this.sliderMax = this.myCroppa.scaleRatio * 2
    },
    submitImg() {
      console.log(this.myCroppa)
      this.myCroppa.generateBlob(
        blob => {
          console.log(blob)
          this.customRequest(blob)
        },
        'image/png',
        0.8
      )
    },
    // 保存方法
    changeUserInfo() {
      this.form.validateFields((err, values) => {
        if (!err) {
          let _obj = Util.deepCopy(this.login_msg)
          Object.assign(_obj, values)
          _obj.profile = this.icon
          let _params = Util.delParams(_obj, ['name', 'profile', 'mail'])
          if (this.newPhone) _params.newPhone = this.newPhone
          _params.oldPhone = values.oldPhone
          _params = Object.assign({ smsCode: this.smsCode }, _params)
          console.log(_params)
          _params.signature = this.signature
          this.$post(this.api.changeUserInfoApi, _params)
            .then(response => {
              Util.processRes(response, () => {
                this.$message.success(response.statusMessage)
                response.data.userId = response.data.id
                response.data.userName = response.data.name
                this.$store.commit('SET_LOGINMSG', response.data)
                // this.times = this.times + 1
                this.closeModal()
              })
              Util.processError(this, response)
            })
            .catch(res => {
              console.log(res)
            })
        }
      })
    }
  },
  destroyed() {}
}
</script>

<style lang="less">
#UserInfo {
  .profilePhoto {
    .ant-upload.ant-upload-select-picture-card > .ant-upload {
      border-radius: 50px;
    }
    .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
      width: 100px;
      height: 100px;
    }
  }
  .croppa-container {
    border-radius: 50%;
  }
}

.userInfo {
  .ant-modal-header {
    height: 170px;
    background: url('../../assets/image/login/userInfo-bg.jpg') no-repeat;
    opacity: 0.9;
    background-size: 100% 100%;
    color: #fff;
  }
  .ant-modal-title,
  .ant-modal-close {
    color: #fff;
  }
  .profile {
    position: absolute;
    left: 0;
    right: 0;
    top: -105px;
    margin: 0 auto;
    width: 150px;
    height: 150px;
    padding: 5px;
    background: #eee;
    border-radius: 75px;
    overflow: hidden;
    img {
      width: 140px;
      height: 140px;
      border-radius: 70px;
    }
  }
}
#UserInfo-Modal {
  .ant-modal-body {
    overflow: visible;
  }
}
</style>
