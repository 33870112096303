<!--
 * @Description: 最近访问
 * @Version: 1.0.0
 * @Author: 李帅
 * @Date: 2021-11-13 15:53:10
 * @LastEditors: 赵亮
 * @LastEditTime: 2022-03-28 19:57:40
-->
<comment>
# 最近访问
</comment>
<template>
  <div id="LastActivity">
    <div class="title">最近访问</div>
    <ul v-if="lastActivity && lastActivity.length">
      <li v-for="item in lastActivity.filter(v => v)" :key="item.id" :hz-code="item.appCode">
        <div
          :class="item.disabled ? 'notAccessible' : 'accessible'"
          @click="!item.disabled && routerLink(item)">
          <icon-font
            v-if="item.iconName"
            :type="item.iconName"
            style="font-size: 16px; margin-right: 10px" />
          <a-avatar
            v-if="!item.icon && !item.iconName"
            style="width: 16px; height: 16px; line-height: 14px; margin-right: 10px"
            class="avatar">
            {{ item.name.substring(0, 1) }}
          </a-avatar>
          <a-tooltip placement="topRight">
            <template slot="title">
              <span>{{ item.name }}</span>
              <span v-if="item.disabled" class="m-l-8" style="color: #ccc"
                >(该页面不属于当前层级,禁止访问)</span
              >
            </template>
            <span>{{ item.name }}</span>
          </a-tooltip>
        </div>
      </li>
    </ul>
    <div v-else class="guess">暂无访问</div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { mapState } from 'vuex'
import vm from '../../../../../main'
import throttle from 'lodash.throttle'
export default {
  name: 'LastActivity',
  //import引入的组件需要注入到对象中才能使用
  components: {},
  props: {},
  data() {
    //这里存放数据
    return {
      api: {
        historicalAddUrl: Util.baseUrl + '/web/historical/add',
        historicalDetailUrl: Util.baseUrl + '/web/historical/detail'
      },
      lastActivity: []
    }
  },
  //监听属性 类似于data概念
  computed: {
    ...mapState({
      resources: state => state.resources,
      curr_org: state => state.curr_org,
      userInfo: state => state.login_msg,
      tenantId: state => state.curr_tenant
    })
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getLastActivityFun()

    this.$root.eventHub.$off('routeSetLastActivity')
    this.$root.eventHub.$on('routeSetLastActivity', currentData => {
      currentData && currentData.length && this.setLastActivity(currentData[0])
    })
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // ----设置 取
    getLastActivityFun: throttle(function () {
      let apiData = {
        userId: this.userInfo.userId,
        tenantId: this.tenantId
      }
      this.$post(this.api.historicalDetailUrl, apiData)
        .then(response => {
          Util.processRes(response, () => {
            if (response.data) {
              this.lastActivity = Util.deepCopy(JSON.parse(response.data))
              this.lastActivity.forEach(element => {
                let type = []
                if (element.orgTypeList.includes(1)) {
                  //  公司级
                  type.push('1')
                }
                if (element.orgTypeList.includes(2)) {
                  // 分公司级
                  type.push('2', '3', '4', '5')
                }
                if (element.orgTypeList.includes(3)) {
                  // 项目级
                  type.push('6')
                }
                if (type.includes(String(this.curr_org.orgType))) {
                  element.disabled = false
                } else {
                  element.disabled = true
                }
              })
            }
          })
        })
        .catch(res => {
          console.log(res)
        })
    }, 200),
    routerLink(data) {
      this.$root.eventHub.$emit('isShowDrawer', false)
      if (data.appType == 3 || data.appType == 4) {
        window.open(data.uri)
      } else if (data.appType == 1 || data.appType == 2) {
        let item
        // console.log(data)
        if (data.level == 3) {
          item = data
        } else {
          let treeFirst = Util.getTreeUriFirst(data.id, this.resources)
          item = Util.getTree(treeFirst.pid, this.resources)
        }
        this.$store.commit('SET_FEATUREAUTH', item)
        let treeFirst = item.uri ? item : Util.getTreeUriFirst(item.id, [item])
        if (treeFirst.appType == 1 || treeFirst.appType == 2)
          this.$router.push({
            path: treeFirst.uri
          })
        else {
          window.open(treeFirst.uri)
        }
      }
    },
    /**
     * 设置最近访问
     */
    setLastActivity: throttle(function (item) {
      if (item) {
        let arr = []
        if (this.lastActivity && this.lastActivity.length) {
          let lastActivityIndex = this.lastActivity.map(v => v && v.id).indexOf(item.id)
          if (lastActivityIndex != -1) this.lastActivity.splice(lastActivityIndex, 1)
          arr.push(...this.lastActivity)
        }
        if (item.level == 3 || item.appType == 3 || item.appType == 4) {
          arr.unshift(item)
          // -----设置 存
          // this.$store.commit('SET_LAST_ACTIVITY', arr.splice(0, 7))
          let apiData = {
            userId: this.userInfo.userId,
            tenantId: this.tenantId,
            hisRecord: arr.splice(0, 7)
          }
          this.$post(this.api.historicalAddUrl, apiData)
            .then(response => {
              Util.processRes(response, () => {
                this.getLastActivityFun()
              })
            })
            .catch(res => {
              console.log(res)
            })
        } else {
          this.setLastActivity(Util.getTreeChild(item.pid, this.resources, 'id')[0])
        }
      }
    }, 200)
  },
  destroyed() {
    this.$root.eventHub.$off('routeSetLastActivity')
  } //生命周期 - 销毁完成
}
</script>
<style lang="less">
#LastActivity {
  padding: 16px;
  flex: 1;
  .title {
    font-size: 12px;
    color: #303133;
    line-height: 20px;
    font-weight: 800;
    margin-bottom: 18px;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  ul > li {
    height: 45px;
    line-height: 45px;
    font-size: 14px;
    color: #606266;
    cursor: pointer;
    display: flex;
    align-items: center;
    > div {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .notAccessible {
      color: #ccc;
      cursor: no-drop;
    }
    .accessible:hover .avatar {
      border-color: #1890ff;
      color: #1890ff;
    }
    .accessible:hover {
      color: #1890ff;
    }
  }
  .guess {
    font-size: 12px;
    text-align: center;
    color: #ccc;
    margin-top: 50px;
  }
}
</style>
