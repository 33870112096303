/*
 * @Description: 安全
 * @Version: 1.0.0
 * @Author: 李晨光
 * @Date: 2020-11-02 11:23:34
 * @LastEditors: 李帅
 * @LastEditTime: 2023-09-12 18:08:52
 */

export const protectedRoute = [
  {
    path: '/main/materials',
    component: () => import(/*/* : "物验通页面入口" */ '$safety/views/Index'),
    children: [
      {
        path: '/materials/contractsManagement',
        name: 'contractsManagement',
        meta: {
          name: '动态合同管理',
          keepAlive: true,
          isAuth: true,
          authCode: 'web_pm_materials_contractsManagement',
          id: ''
        },
        component: () => import('$materials/views/contractsManagement/Index')
      },
      {
        //合同管理导入
        path: '/materials/importContracts',
        name: 'ImportContracts',
        meta: {
          name: '导入',
          isAuth: true,
          authCode: 'web_pm_materials_contractsManagement_import',
          id: ''
        },
        component: () => import('$materials/views/contractsManagement/ImportContracts')
      },
      {
        path: '/materials/listManage',
        name: 'ListManage',
        meta: {
          name: '合同清单管理',
          keepAlive: true,
          isAuth: true,
          authCode: 'web_pm_materials_contractsManagement_listManage',
          id: ''
        },
        component: () => import('$materials/views/contractsManagement/ListManage')
      },
      {
        //审核详情页
        path: '/materials/approvalListManageDetail',
        name: 'ApprovalListManageDetail',
        meta: {
          name: '合同清单管理',
          id: '',
          isAuth: false,
          authCode: 'web_pm_materials_contractsManagement'
        },
        component: () => import('$materials/views/contractsManagement/ApprovalListManageDetail')
      },
      {
        //合同清单管理-物资详情导入
        path: '/materials/importListManage',
        name: 'ImportListManage',
        meta: {
          name: '导入',
          isAuth: false,
          authCode: 'web_pm_materials_contractsManagement_importListManage',
          id: ''
        },
        component: () => import('$materials/views/contractsManagement/ImportListManage')
      },
      {
        //合同清单管理-附加费用导入
        path: '/materials/importAdditional',
        name: 'ImportAdditional',
        meta: {
          name: '导入',
          isAuth: false,
          authCode: 'web_pm_materials_contractsManagement_importAdditional',
          id: ''
        },
        component: () => import('$materials/views/contractsManagement/ImportAdditional')
      },
      {
        path: '/materials/feedListManage',
        name: 'FeedListManage',
        meta: {
          name: '甲供料清单管理',
          isAuth: true,
          authCode: 'web_pm_materials_contractsManagement_feedList',
          id: ''
        },
        component: () => import('$materials/views/contractsManagement/FeedListManage')
      },
      // {
      //   path: '/materials/supplierManagement/analysis',
      //   name: 'supplierManagement',
      //   meta: {
      //     name: '供应商分析',
      //     isAuth: true,
      //     authCode: 'web_pm_materials_supplierManagement_analysis',
      //     id: '',
      //   },
      //   component: () => import('$materials/views/supplierManagement/Index'),
      // },
      {
        path: '/materials/supplierManagement/ledger',
        name: 'supplierManagement',
        meta: {
          name: '台账管理',
          //   keepAlive: true,
          isAuth: true,
          authCode: 'web_pm_materials_supplierManagement_ledger',
          id: ''
        },
        component: () => import('$materials/views/supplierManagement/ledger')
      },
      {
        path: '/materials/library/classification',
        name: 'Classification',
        meta: {
          name: '分类库',
          isAuth: true,
          authCode: 'web_pm_materials_library_classification',
          id: ''
        },
        component: () => import('$materials/views/library/Classification')
      },
      {
        path: '/materials/library/classification/import',
        name: 'ClassificationImportExcel',
        meta: {
          name: '分类库导入',
          isAuth: true,
          authCode: 'web_pm_materials_library_classification_import'
        },
        component: () => import('$materials/views/library/ClassificationImportExcel')
      },
      {
        path: '/materials/library/detail',
        name: 'Detail',
        meta: {
          name: '科目库',
          isAuth: true,
          authCode: 'web_pm_materials_library_detail',
          id: ''
        },
        component: () => import('$materials/views/library/Detail')
      },
      {
        path: '/materials/library/detail/import',
        name: 'DetailImportExcel',
        meta: {
          name: '科目库导入',
          isAuth: true,
          authCode: 'web_pm_materials_library_detail_import',
          id: ''
        },
        component: () => import('$materials/views/library/DetailImportExcel')
      },
      {
        path: '/materials/library/import',
        name: 'ClassificationDetailsImportExcel',
        meta: {
          name: '分类库和科目库导入',
          isAuth: false,
          authCode: 'web_pm_materials_library',
          id: ''
        },
        component: () => import('$materials/views/library/ClassificationDetailsImportExcel')
      },
      {
        path: '/materials/library/projectDetail',
        name: 'ProjectDetail',
        meta: {
          name: '项目常用物资库',
          isAuth: true,
          authCode: 'web_pm_materials_library_projectDetail'
        },
        component: () => import('$materials/views/library/ProjectDetail')
      },
      {
        path: '/materials/library/proportioningFormula',
        name: 'ProportioningFormula',
        meta: {
          name: '配比公式',
          isAuth: true,
          authCode: 'web_pm_materials_library_proportioningFormula',
          id: ''
        },
        component: () => import('$materials/views/library/ProportioningFormula')
      },
      // 需用计划管理 start
      {
        path: '/materials/planManagement/generalPlan',
        name: 'planManagement/generalPlan',
        meta: {
          name: '总控计划',
          isAuth: true,
          authCode: 'web_pm_materials_planManagement_generalPlan',
          id: ''
        },
        component: () => import('$materials/views/planManagement/Index')
      },
      {
        path: '/materials/planManagement/yearPlan',
        name: 'planManagement/yearPlan',
        meta: {
          name: '年度计划',
          isAuth: true,
          authCode: 'web_pm_materials_planManagement_yearPlan',
          id: ''
        },
        component: () => import('$materials/views/planManagement/Index')
      },
      {
        path: '/materials/planManagement/quarterPlan',
        name: 'planManagement/quarterPlan',
        meta: {
          name: '季度计划',
          isAuth: true,
          authCode: 'web_pm_materials_planManagement_quarterPlan',
          id: ''
        },
        component: () => import('$materials/views/planManagement/Index')
      },
      {
        path: '/materials/planManagement',
        name: 'planManagement',
        meta: {
          keepAlive: true,
          name: '需用计划',
          isAuth: true,
          authCode: 'web_pm_materials_planManagement',
          id: ''
        },
        component: () => import('$materials/views/planManagement/Index')
      },
      {
        path: '/materials/planManagement/modifiedPlan',
        name: 'planManagement/modifiedPlan',
        meta: {
          name: '新增计划',
          isAuth: false,
          authCode: 'web_pm_materials_planManagement_modifiedPlan',
          id: ''
        },
        component: () => import('$materials/views/planManagement/ModifiedPlan')
      },
      // 月度计划详情
      {
        path: '/materials/planManagement/detail',
        name: 'planManagement/detail',
        meta: {
          name: '计划详情',
          isAuth: false,
          authCode: 'web_pm_materials_planManagement_detail',
          id: ''
        },
        component: () => import('$materials/views/planManagement/DetailPage.vue')
      },
      // 需用计划管理 end
      // 物资需用计划 start
      {
        path: '/materials/materialPlan/generalPlan',
        name: 'materialPlan/generalPlan',
        meta: {
          name: '总控计划',
          isAuth: true,
          authCode: 'web_pm_materials_materialPlan_generalPlan',
          id: ''
        },
        component: () => import('$materials/views/materialPlan/Index')
      },
      {
        path: '/materials/materialPlan/dailyPlan',
        name: 'materialPlan/dailyPlan',
        meta: {
          name: '日常计划',
          isAuth: true,
          authCode: 'web_pm_materials_materialPlan_dailyPlan',
          id: ''
        },
        component: () => import('$materials/views/materialPlan/Index')
      },
      {
        path: '/materials/materialPlan/dailyPlan/modifiedPlan',
        name: 'materialPlan/modifiedPlan',
        meta: {
          name: '新增计划',
          isAuth: false,
          authCode: 'web_pm_materials_materialPlan_dailyPlan_modifiedPlan',
          id: ''
        },
        component: () => import('$materials/views/materialPlan/ModifiedPlan')
      },
      // 物资需用计划 end
      {
        path: '/materials/purchaseOrder/plan',
        name: 'Plan',
        meta: {
          name: '计划采购订单',
          isAuth: true,
          authCode: 'web_pm_materials_purchaseOrder_plan',
          id: ''
        },
        component: () => import('$materials/views/purchaseOrder/Plan')
      },
      {
        path: '/materials/purchaseOrder/plan/add',
        name: 'AddPlan',
        meta: {
          name: '新增计划采购订单',
          isAuth: true,
          authCode: 'web_pm_materials_purchaseOrder_plan_add',
          id: ''
        },
        component: () => import('$materials/views/purchaseOrder/SetPlan')
      },
      {
        path: '/materials/purchaseOrder/plan/edit',
        name: 'EditPlan',
        meta: {
          name: '编辑计划采购订单',
          isAuth: true,
          authCode: 'web_pm_materials_purchaseOrder_plan_edit',
          id: ''
        },
        component: () => import('$materials/views/purchaseOrder/SetPlan')
      },
      {
        path: '/materials/purchaseOrder/plan/detail/:id',
        name: 'PlanDetails',
        meta: {
          name: '计划采购订单详情',
          isAuth: true,
          authCode: 'web_pm_materials_purchaseOrder_plan_detail',
          id: ''
        },
        component: () => import('$materials/views/purchaseOrder/Details')
      },
      {
        path: '/materials/purchaseOrder/sporadic',
        name: 'Sporadic',
        meta: {
          name: '零星采购订单',
          isAuth: true,
          authCode: 'web_pm_materials_purchaseOrder_sporadic',
          id: ''
        },
        component: () => import('$materials/views/purchaseOrder/Sporadic')
      },
      {
        path: '/materials/purchaseOrder/sporadic/add',
        name: 'AddSporadic',
        meta: {
          name: '新增零星采购订单',
          isAuth: true,
          authCode: 'web_pm_materials_purchaseOrder_sporadic_add',
          id: ''
        },
        component: () => import('$materials/views/purchaseOrder/SetSporadic')
      },
      {
        path: '/materials/purchaseOrder/sporadic/edit',
        name: 'EditSporadic',
        meta: {
          name: '编辑零星采购订单',
          isAuth: true,
          authCode: 'web_pm_materials_purchaseOrder_sporadic_edit',
          id: ''
        },
        component: () => import('$materials/views/purchaseOrder/SetSporadic')
      },
      {
        path: '/materials/purchaseOrder/sporadic/detail/:id',
        name: 'SporadicDetails',
        meta: {
          name: '零星采购订单详情',
          isAuth: true,
          authCode: 'web_pm_materials_purchaseOrder_sporadic_detail',
          id: ''
        },
        component: () => import('$materials/views/purchaseOrder/Details')
      },
      {
        path: '/materials/acceptance',
        name: 'Acceptance',
        meta: {
          name: '物资验收台账',
          isAuth: true,
          authCode: 'web_pm_materials_acceptMange_acceptance',
          id: ''
        },
        component: () => import('$materials/views/acceptance/Index')
      },
      {
        path: '/materials/mobilizationDetails',
        name: 'MobilizationDetails',
        meta: {
          name: '物资进场明细',
          isAuth: true,
          authCode: 'web_pm_materials_acceptMange_mobilizationDetails',
          id: ''
        },
        component: () => import('$materials/views/acceptance/MobilizationDetails')
      },
      {
        path: '/materials/images',
        name: 'Images',
        meta: {
          name: '验收影像管理',
          isAuth: true,
          authCode: 'web_pm_materials_images',
          id: ''
        },
        component: () => import('$materials/views/images/Index')
      },
      {
        path: '/materials/configuration/WarningConfiguration',
        name: 'WarningConfiguration',
        meta: {
          name: '预警配置',
          isAuth: true,
          authCode: 'web_base_materials_warning',
          id: ''
        },
        component: () => import('$materials/views/configuration/WarningConfiguration')
      },
      {
        path: '/materials/configuration/PlanType',
        name: 'PlanType',
        meta: {
          name: '需用计划类型',
          isAuth: false,
          authCode: 'web_base_materials_business_planManagement_PlanType',
          id: ''
        },
        component: () => import('$materials/views/configuration/PlanType')
      },
      {
        path: '/materials/configuration/PlanPush',
        name: 'PlanPush',
        meta: {
          name: '短信通知',
          isAuth: false,
          authCode: 'web_base_materials_business_planManagement_PlanPush',
          id: ''
        },
        component: () => import('$materials/views/configuration/PlanPush')
      },
      {
        // 需用计划管理
        path: '/materials/configuration/BusinessConfiguration/planManagement',
        name: 'BusinessConfigurationPlanManagement',
        meta: {
          name: '需用计划',
          isAuth: true,
          authCode: 'web_base_materials_business_planManagement_planManagement',
          id: ''
        },
        component: () => import('$materials/views/configuration/BusinessConfiguration')
      },
      // 业务配置 start
      {
        // 需用计划管理
        path: '/materials/configuration/BusinessConfiguration/general',
        name: 'BusinessConfigurationGeneral',
        meta: {
          name: '需用总控计划',
          isAuth: true,
          authCode: 'web_base_materials_business_planManagement_general',
          id: ''
        },
        component: () => import('$materials/views/configuration/BusinessConfiguration')
      },
      {
        // 需用计划管理
        path: '/materials/configuration/BusinessConfigurationGeneral/year',
        name: 'BusinessConfigurationGeneralYear',
        meta: {
          name: '需用年度计划',
          isAuth: true,
          authCode: 'web_base_materials_business_planManagement_year',
          id: ''
        },
        component: () => import('$materials/views/configuration/BusinessConfiguration')
      },
      {
        // 需用计划管理
        path: '/materials/configuration/BusinessConfigurationGeneral/quarter',
        name: 'BusinessConfigurationGeneralQuarter',
        meta: {
          name: '需用季度计划',
          isAuth: true,
          authCode: 'web_base_materials_business_planManagement_quarter',
          id: ''
        },
        component: () => import('$materials/views/configuration/BusinessConfiguration')
      },
      {
        // 需用计划管理
        path: '/materials/configuration/BusinessConfigurationGeneral/monthly',
        name: 'BusinessConfigurationGeneralMonthly',
        meta: {
          name: '需用月度计划',
          isAuth: true,
          authCode: 'web_base_materials_business_planManagement_monthly',
          id: ''
        },
        component: () => import('$materials/views/configuration/BusinessConfiguration')
      },
      {
        // 需用计划管理
        path: '/materials/configuration/BusinessConfigurationGeneral/dailyPlan',
        name: 'BusinessConfigurationGeneralMaterialPlanDailyPlan',
        meta: {
          name: '日常计划',
          isAuth: true,
          authCode: 'web_base_materials_business_materialPlan_dailyPlan',
          id: ''
        },
        component: () => import('$materials/views/configuration/BusinessConfiguration')
      },
      {
        path: '/materials/configuration/BusinessConfigurationGeneral/plan',
        name: 'BusinessConfigurationGeneralPlan',
        meta: {
          name: '计划采购订单',
          isAuth: true,
          authCode: 'web_base_materials_business_purchaseOrder_plan',
          id: ''
        },
        component: () => import('$materials/views/configuration/BusinessConfiguration')
      },
      {
        path: '/materials/configuration/BusinessConfigurationGeneral/sporadic',
        name: 'BusinessConfigurationGeneralSporadic',
        meta: {
          name: '零星采购订单',
          isAuth: true,
          authCode: 'web_base_materials_business_purchaseOrder_sporadic',
          id: ''
        },
        component: () => import('$materials/views/configuration/BusinessConfiguration')
      },
      {
        path: '/materials/configuration/BusinessConfigurationGeneral/specialGoods',
        name: 'BusinessConfigurationGeneralSpecialGoods',
        meta: {
          name: '超计划量下单审批',
          isAuth: true,
          authCode: 'web_base_materials_business_purchaseOrder_specialGoods',
          id: ''
        },
        component: () => import('$materials/views/configuration/BusinessConfiguration')
      },
      {
        path: '/materials/configuration/BusinessConfigurationGeneral/close',
        name: 'BusinessConfigurationGeneralClose',
        meta: {
          name: '一般材料结算单',
          isAuth: true,
          authCode: 'web_base_materials_business_settlement_close',
          id: ''
        },
        component: () => import('$materials/views/configuration/BusinessConfiguration')
      },
      {
        path: '/materials/configuration/BusinessConfigurationRefer/close',
        name: 'BusinessConfigurationReferClose',
        meta: {
          name: '参考网价结算单',
          isAuth: true,
          authCode: 'web_base_materials_business_settlement_referClose',
          id: ''
        },
        component: () => import('$materials/views/configuration/BusinessConfiguration')
      },
      // 结算管理-结算单-新
      {
        path: '/materials/configuration/BusinessConfiguration/templateSettlement',
        name: 'BusinessConfigurationTemplateSettlement',
        meta: {
          name: '结算单',
          isAuth: true,
          authCode: 'web_base_materials_business_templateSettlement',
          id: ''
        },
        component: () => import('$materials/views/configuration/BusinessConfiguration')
      },
      // 动态合同管理 start
      {
        path: '/materials/contractsManagement/businessConfigurationBalancing',
        name: 'BusinessConfigurationBalancing',
        meta: {
          name: '合同审批',
          isAuth: true,
          authCode: 'web_base_materials_business_contractManagement_balancing',
          id: ''
        },
        component: () => import('$materials/views/contractsManagement/BusinessConfiguration')
      },
      {
        path: '/materials/contractsManagement/businessPricing',
        name: 'BusinessPricing',
        meta: {
          name: '计价方式',
          isAuth: true,
          authCode: 'web_base_materials_business_contractManagement_pricing',
          id: ''
        },
        component: () => import('$materials/views/contractsManagement/BusinessPricing')
      },
      {
        path: '/materials/supplierEvaluation',
        name: 'SupplierEvaluation',
        meta: {
          name: '供应商评价',
          isAuth: true,
          authCode: 'web_base_materials_business_supplierEvaluation',
          id: ''
        },
        component: () => import('$materials/views/configuration/BusinessConfiguration')
      },
      {
        path: '/materials/businessCharges',
        name: 'BusinessCharges',
        meta: {
          name: '字典配置',
          isAuth: true,
          authCode: 'web_base_materials_business_charges',
          id: ''
        },
        component: () => import('$materials/views/contractsManagement/BusinessCharges')
      },
      {
        path: '/materials/businessCharges/mportExcelCharges',
        name: 'ImportExcelCharges',
        meta: {
          name: '字典配置导入',
          isAuth: false,
          authCode: 'web_base_materials_business_charges_import',
          id: ''
        },
        component: () => import('$materials/components/contractsManagement/ImportExcelCharges')
      },
      // 动态合同管理 end
      {
        path: '/materials/configuration/BusinessConfigurationGeneral/InPutPurchase',
        name: 'BusinessConfigurationGeneralInPutPurchase',
        meta: {
          name: '采购入库',
          isAuth: true,
          authCode: 'web_base_materials_business_inventory_inPutPurchase',
          id: ''
        },
        component: () => import('$materials/views/configuration/BusinessConfiguration')
      },
      {
        path: '/materials/configuration/BusinessConfigurationGeneral/InPutRequisition',
        name: 'BusinessConfigurationGeneralInPutRequisition',
        meta: {
          name: '领料退回',
          isAuth: true,
          authCode: 'web_base_materials_business_inventory_inPutRequisition',
          id: ''
        },
        component: () => import('$materials/views/configuration/BusinessConfiguration')
      },
      {
        path: '/materials/configuration/BusinessConfigurationGeneral/OutPutRequisition',
        name: 'BusinessConfigurationGeneralOutPutRequisition',
        meta: {
          name: '领料出库',
          isAuth: true,
          authCode: 'web_base_materials_business_inventory_outPutRequisition',
          id: ''
        },
        component: () => import('$materials/views/configuration/BusinessConfiguration')
      },
      {
        path: '/materials/configuration/CheckingPersonnelConfiguration',
        name: 'CheckingPersonnelConfiguration',
        meta: {
          name: '验收人员配置',
          isAuth: true,
          authCode: 'web_base_materials_business_other_checkingPersonnel',
          id: ''
        },
        component: () => import('$materials/views/configuration/CheckingPersonnelConfiguration')
      },
      // 业务配置 end
      {
        path: '/materials/configuration/RuleConfiguration',
        name: 'RuleConfiguration',
        meta: {
          name: '规则配置',
          isAuth: true,
          authCode: 'web_base_materials_rule',
          id: ''
        },
        component: () => import('$materials/views/configuration/RuleConfiguration')
      },
      {
        path: '/materials/configuration/dockManage',
        name: 'DockManage',
        meta: {
          name: '对接管理',
          isAuth: true,
          authCode: 'web_base_materials_dockManage',
          id: ''
        },
        component: () => import('$materials/views/configuration/DockManage')
      },
      {
        path: '/materials/configuration/BusinessConfigurationGeneral/regional',
        name: 'BusinessConfigurationGeneralRegional',
        meta: {
          name: '区域设置',
          isAuth: true,
          authCode: 'web_base_materials_business_other_regional',
          id: ''
        },
        component: () => import('$materials/views/configuration/RegionalSetting')
      },
      {
        path: '/materials/settlement/pendingSettlement',
        name: 'PendingSettlement',
        meta: {
          name: '待结算验收单台账',
          isAuth: true,
          authCode: 'web_pm_materials_settlement_pendingSettlement',
          id: ''
        },
        component: () => import('$materials/views/settlement/PendingSettlement.vue')
      },
      {
        path: '/materials/settlement/settlement/general',
        name: 'GeneralLedger',
        meta: {
          name: ' 一般材料结算单',
          isAuth: true,
          authCode: 'web_pm_materials_settlement_settlement_general',
          id: ''
        },
        component: () => import('$materials/views/settlement/SettlementLedger.vue')
      },
      {
        path: '/materials/settlement/settlementList',
        name: 'SettlementList',
        meta: {
          name: '结算单台账',
          isAuth: true,
          authCode: 'web_pm_materials_settlement_settlementList',
          id: ''
        },
        component: () => import('$materials/views/settlement/SettlementList.vue')
      },
      {
        path: '/materials/settlement/supplierSummaryCalculation',
        name: 'SupplierSummaryCalculation',
        meta: {
          name: '供应商总结算台账',
          isAuth: true,
          authCode: 'web_pm_materials_settlement_supplierSummaryCalculation'
        },
        component: () => import('$materials/views/settlement/SupplierSummaryCalculation.vue')
      },
      {
        path: '/materials/settlement/settlement/refer',
        name: 'ReferLedger',
        meta: {
          name: ' 参考网价结算单',
          isAuth: true,
          authCode: 'web_pm_materials_settlement_settlement_refer',
          id: ''
        },
        component: () => import('$materials/views/settlement/SettlementLedger.vue')
      },
      {
        path: '/materials/analysis/Material',
        name: 'ReferLedger',
        meta: {
          name: ' 物资活动分析',
          isAuth: true,
          authCode: 'web_pm_materials_analysis_material',
          id: ''
        },
        component: () => import('$materials/views/analysis/material/Index.vue')
      },
      {
        path: '/materials/analysis/supplier',
        name: 'SupplierAnalysis',
        meta: {
          name: ' 供应商分析',
          isAuth: true,
          authCode: 'web_pm_materials_analysis_supplier',
          id: ''
        },
        component: () => import('$materials/views/analysis/supplier/Index.vue')
      },
      {
        path: '/materials/analysis/supplier/detail',
        name: 'SupplierEvaluateDetail',
        meta: {
          name: ' 供应商评价详情',
          isAuth: true,
          authCode: 'web_pm_materials_analysis_supplier_evaluate_detail'
        },
        component: () => import('$materials/views/analysis/supplier/EvaluateDetail.vue')
      },
      {
        path: '/materials/analysis/supplier/set',
        name: 'SupplierEvaluate',
        meta: {
          name: ' 供应商评价',
          isAuth: true,
          authCode: 'web_pm_materials_analysis_supplier_evaluate_set'
        },
        component: () => import('$materials/views/analysis/supplier/Evaluate.vue')
      },
      // 月度计划详情
      {
        path: '/materials/planManagement/monthlyPlan/detail',
        name: 'planManagement/monthlyPlan/detail',
        meta: {
          name: '计划详情',
          isAuth: false,
          authCode: 'web_pm_materials_planManagement_monthlyPlan_detail',
          id: ''
        },
        component: () => import('$materials/views/planManagement/DetailPage.vue')
      },
      // 日常计划详情
      {
        path: '/materials/materialPlan/dailyPlan/detail',
        name: 'materialPlan/dailyPlan/detail',
        meta: {
          name: '计划详情',
          isAuth: false,
          authCode: 'web_pm_materials_materialPlan_dailyPlan_detail',
          id: ''
        },
        component: () => import('$materials/views/planManagement/DetailPage.vue')
      },
      {
        path: '/materials/configuration/BusinessConfigurationGeneral/WarehouseSetting',
        name: 'WarehouseSetting',
        meta: {
          name: '仓库设置',
          isAuth: true,
          authCode: 'web_base_materials_business_other_warehouseSet',
          id: ''
        },
        component: () => import('$materials/views/configuration/WarehouseSetting')
      },
      // 库管理 出入库
      {
        path: '/materials/inventoryManagement/outInPut',
        name: 'inventoryManagement/outInPut',
        meta: {
          name: '出入库流水',
          isAuth: true,
          authCode: 'web_pm_materials_inventory_outInPut',
          id: ''
        },
        component: () => import('$materials/views/inventoryManagement/outInPut/Index.vue')
      },
      // 采购入库-新增
      {
        path: '/materials/inventoryManagement/outInPut/inPutPurchaseAdd',
        name: 'inventoryManagement/outInPut/inPutPurchaseAdd',
        meta: {
          name: '新增采购入库',
          isAuth: false,
          authCode: 'web_pm_materials_inventory_outInPut_inPutPurchaseAdd',
          id: ''
        },
        component: () =>
          import('$materials/views/inventoryManagement/outInPut/inPutPurchase/Add.vue')
      },
      // 采购入库-详情
      {
        path: '/materials/inventoryManagement/outInPut/inPutPurchaseDetail',
        name: 'inventoryManagement/outInPut/inPutPurchaseDetail',
        meta: {
          name: '采购入库详情',
          isAuth: false,
          authCode: 'web_pm_materials_inventory_outInPut_inPutPurchaseDetail',
          id: ''
        },
        component: () =>
          import('$materials/views/inventoryManagement/outInPut/inPutPurchase/Detail.vue')
      },
      // 领料退回-新增
      {
        path: '/materials/inventoryManagement/outInPut/inPutRequisitionAdd',
        name: 'inventoryManagement/outInPut/inPutRequisitionAdd',
        meta: {
          name: '新增领料退回',
          isAuth: false,
          authCode: 'web_pm_materials_inventory_outInPut_inPutRequisitionAdd',
          id: ''
        },
        component: () =>
          import('$materials/views/inventoryManagement/outInPut/inPutRequisition/Add.vue')
      },
      // 领料退回-详情
      {
        path: '/materials/inventoryManagement/outInPut/inPutRequisitionDetail',
        name: 'inventoryManagement/outInPut/inPutRequisitionDetail',
        meta: {
          name: '领料退回详情',
          isAuth: false,
          authCode: 'web_pm_materials_inventory_outInPut_inPutRequisitionDetail',
          id: ''
        },
        component: () =>
          import('$materials/views/inventoryManagement/outInPut/inPutRequisition/Detail.vue')
      },
      // 领料出库-新增
      {
        path: '/materials/inventoryManagement/outInPut/outPutRequisitionAdd',
        name: 'inventoryManagement/outInPut/outPutRequisitionAdd',
        meta: {
          name: '新增领料出库',
          isAuth: false,
          authCode: 'web_pm_materials_inventory_outInPut_outPutRequisitionAdd',
          id: ''
        },
        component: () =>
          import('$materials/views/inventoryManagement/outInPut/outPutRequisition/Add.vue')
      },
      // 领料出库-详情
      {
        path: '/materials/inventoryManagement/outInPut/outPutRequisitionDetail',
        name: 'inventoryManagement/outInPut/outPutRequisitionDetail',
        meta: {
          name: '领料出库详情',
          isAuth: false,
          authCode: 'web_pm_materials_inventory_outInPut_outPutRequisitionDetail',
          id: ''
        },
        component: () =>
          import('$materials/views/inventoryManagement/outInPut/outPutRequisition/Detail.vue')
      },
      // 库管理 实时库存
      {
        path: '/materials/inventoryManagement/realTime',
        name: 'inventoryManagement/realTime',
        meta: {
          name: '实时库存',
          isAuth: true,
          authCode: 'web_pm_materials_inventory_realTime',
          id: ''
        },
        component: () => import('$materials/views/inventoryManagement/realTime/Index.vue')
      },
      {
        path: '/materials/inventoryManagement/realTime/ImportExcel',
        name: 'inventoryManagement/realTime/ImportExcel',
        meta: {
          name: '导入',
          isAuth: false,
          authCode: 'web_pm_materials_inventory_realTime_ImportExcel',
          id: ''
        },
        component: () => import('$materials/views/inventoryManagement/realTime/ImportExcel.vue')
      },
      {
        path: '/materials/configuration/AutoStorage',
        name: 'AutoStorage',
        meta: {
          name: '自动入库',
          isAuth: false,
          authCode: 'web_base_materials_business_inventory_AutoStorage',
          id: ''
        },
        component: () => import('$materials/views/configuration/AutoStorage.vue')
      },
      // 主材分析
      {
        path: '/materials/analysis/mainMaterial',
        name: 'MainMaterial',
        meta: {
          name: '主材分析',
          isAuth: true,
          authCode: 'web_pm_materials_analysis_mainMaterial',
          id: ''
        },
        component: () => import('$materials/views/analysis/mainMaterial/MainMaterial.vue')
      },
      {
        path: '/materials/configuration/weighbridgeVideo',
        name: 'WeighbridgeVideo',
        meta: {
          name: '地磅视频配置',
          isAuth: false,
          authCode: 'web_base_materials_business_other_weighbridgeVideo'
        },
        component: () => import('$materials/views/configuration/WeighbridgeVideo')
      },
      {
        path: '/materials/configuration/BusinessConfiguration/supplierSummaryCalculation',
        name: 'BusinessConfigurationSupplierSummaryCalculation',
        meta: {
          name: '供应商总结算单',
          isAuth: true,
          authCode: 'web_base_materials_business_supplierSummaryCalculation'
        },
        component: () => import('$materials/views/configuration/BusinessConfiguration')
      }
    ]
  }
]
