/*
 * @Description: 全局公用方法
 * @Version: 1.0.0
 * @Author: 李晨光
 * @Date: 2020-07-07 13:47:47
 * @LastEditors: 李晨光
 * @LastEditTime: 2024-09-25 15:41:07
 */

import store from '../../vuex'
import scroll from '@/public/assets/js/scroll'
import vm from '../../../main'
import BaseUrlConfig from './baseUrlConfig'
;(function (window) {
  let Util = BaseUrlConfig
  const debug = process.env.NODE_ENV !== 'production' // 开发环境中为true，否则为false

  Util.pagination = {
    current: 1,
    total: 0,
    size: 'small',
    showSizeChanger: true,
    pageSize: 10,
    pageSizeOptions: ['10', '20', '50', '100'],
    showTotal: (total, range) => {
      return `${range[0]} - ${range[1]}条 / 共 ${total} 条`
    }
  }

  // ------------------------spm2.5 JAVA 接口返回值处理 start ----------------------- //
  Util.processResJava = function (response, sucessCallback, failCallback, isScroll) {
    if (response.code == 200) {
      sucessCallback && sucessCallback(response)
    } else {
      failCallback && failCallback(response)
    }
    // vm.$nextTick(() => {
    //   if (!isScroll)
    //     new scroll({
    //       el: '.ant-table-body',
    //       fixedBottom: 48,
    //       showScrollY: false,
    //     })
    //   else
    //     new scroll({
    //       showScrollY: false,
    //       fixedBottom: 48,
    //       ...isScroll,
    //     })
    // })
  }
  Util.processErrorJava = function (vm, response) {
    console.log(response)
    if (response.code == 200) {
      // vm.$message.success(response.statusMessage);
    } else {
      ;(response.body || response.message) && vm.$message.error(response.body || response.message)
    }
    return false
  }
  // ------------------------spm2.5 JAVA 接口返回值处理 end ----------------------- //
  // ------------------------spm3.0 JAVA 接口返回值处理 start ----------------------- //
  Util.processError = function (vm, response) {
    if (response.statusCode == 200) {
      // vm.$message.success(response.statusMessage);
    } else {
      response.statusMessage && vm.$message.error(response.statusMessage)
    }
    return false
  }
  /**
   * 作者 李晨光
   * 日期 20190610
   * 处理所有的vue-axios的response 并判断是否成功
   * 2个回调函数, 处理成功和失败
   * @param response
   * @param sucessCallback 成功回调
   * @param failCallback  失败回调
   */

  Util.processRes = function (response, sucessCallback, failCallback, isScroll) {
    if (response.statusCode == 200) {
      sucessCallback && sucessCallback(response)
    } else {
      failCallback && failCallback(response)
    }
    // vm.$nextTick(() => {
    //   if (!isScroll)
    //     new scroll({
    //       el: '.ant-table-body',
    //       fixedBottom: 48,
    //       showScrollY: false,
    //     })
    //   else
    //     new scroll({
    //       showScrollY: false,
    //       fixedBottom: 48,
    //       ...isScroll,
    //     })
    // })
  }
  // ------------------------spm3.0 JAVA 接口返回值处理 end ----------------------- //
  /**
   * 将blob转换成JSON
   * @param data
   * @param success  成功回调
   * @param fail     失败回调
   */
  Util.dealBlobToJson = function (data, success, fail) {
    let r = new FileReader()
    r.onload = function () {
      try {
        let response = JSON.parse(this.result) // this.result为FileReader获取blob数据转换为json后的数据，即后台返回的原始数据
        fail && fail(response)
      } catch (err) {
        success && success()
      }
    }
    r.readAsText(data)
  }
  /**
   * 文件下载
   * @param file 文件file
   * @param name 文件名称
   */
  Util.downloadFile = function (file, name) {
    let urlObject = window.URL || window.webkitURL || window
    let downloadData = new Blob([file], {
      type: file.type + ';charset=utf-8'
    })
    let save_link = document.createElementNS('http://www.w3.org/1999/xhtml', 'a')
    save_link.href = urlObject.createObjectURL(downloadData)
    save_link.download = name
    let ev = document.createEvent('MouseEvents')
    ev.initMouseEvent(
      'click',
      true,
      false,
      window,
      0,
      0,
      0,
      0,
      0,
      false,
      false,
      false,
      false,
      0,
      null
    )
    save_link.dispatchEvent(ev)
  }
  /**
   * 文件下载
   * @param file 文件file
   * @param name 文件名称
   */
  Util.downloadFileByLink = function (file, name) {
    let save_link = document.createElementNS('http://www.w3.org/1999/xhtml', 'a')
    if (
      Util.getFileType(file) == 'image' ||
      Util.getFileType(file) == 'pdf' ||
      Util.getFileType(file) == 'video'
    ) {
      save_link.target = '_blank'
    }
    save_link.href = file
    // save_link.download = name
    let ev = document.createEvent('MouseEvents')
    ev.initMouseEvent(
      'click',
      true,
      false,
      window,
      0,
      0,
      0,
      0,
      0,
      false,
      false,
      false,
      false,
      0,
      null
    )
    save_link.dispatchEvent(ev)
  }
  /**
   * 作者 李晨光
   * 深拷贝对象,数组
   */
  Util.deepCopy = function (obj) {
    let result = typeof obj.splice === 'function' ? [] : {} //判断数据类型
    if (obj && typeof obj === 'object') {
      for (let key in obj) {
        if (obj[key] && typeof obj[key] === 'object') {
          result[key] = Util.deepCopy(obj[key]) //如果对象的属性值为object的时候，递归调用deepClone,即在吧某个值对象复制一份到新的对象的对应值中。
        } else {
          result[key] = obj[key] //如果对象的属性值不为object的时候，直接复制参数对象的每一个键值到新的对象对应的键值对中。
        }
      }
      return result
    }
    return obj
  }
  /**
   * @Description: 数组深拷贝删除子节点无内容属性
   * @Param:
   * @Return:
   */
  Util.deepCopyDelChildrenNull = function (arr) {
    let result = []
    arr.length &&
      arr.forEach(item => {
        let obj = {}
        if (item.children && item.children.length) {
          obj = item
          obj.children = Util.deepCopyDelChildrenNull(item.children)
        } else {
          for (let key in item) {
            if (Object.hasOwnProperty.call(item, key) && key != 'children') {
              obj[key] = item[key]
            }
          }
        }
        result.push(obj)
      })
    return result
  }
  /**
   * 处理树结构数据
   */
  Util.addProps = function (
    tree,
    props = {
      expand: true
    }
  ) {
    let arr = []
    tree.length &&
      tree.forEach((item, i) => {
        let obj = {}
        obj = Util.deepCopy(item)
        for (const key in props) {
          if (Object.hasOwnProperty.call(props, key)) {
            obj[key] = props[key]
          }
        }
        if (item.children && item.children.length) {
          obj.children = Util.dealData(item.children)
        }
        arr.push(obj)
      })
    return arr
  }
  /**
   * 处理树结构数据
   */
  Util.dealData = function (tree, clickType, deploy) {
    let arr = []
    tree.length &&
      tree.forEach((item, i) => {
        let obj = {}
        obj = Util.deepCopy(item)
        obj.pId = item.pid
        obj.value = item.id
        obj.label = item.name
        obj.key = item.id
        obj.enableEdit = false
        obj.title = item.name
        obj.index = i
        obj.maxLen = tree.length - 1
        if (item.isDefult == 1) {
          obj.isDefult = item.isDefult
        }
        if (clickType) {
          item.type == 2 ? (obj.disabled = true) : (obj.disabled = false)
        }
        if (deploy) {
          for (let key in deploy) {
            obj[key] = item[deploy[key]]
          }
        }
        obj.scopedSlots = {
          icon: 'custom',
          title: 'title'
        }
        if (item.children && item.children.length) {
          obj.children = Util.dealData(item.children, clickType, deploy)
        }
        arr.push(obj)
      })
    return arr
  }
  /**
   * 处理树结构数据
   * 只有项目级才可以点击
   */
  Util.dealDataProjectTypeDisabled = function (tree) {
    let arr = []
    tree.length &&
      tree.forEach(item => {
        let obj = {}
        obj = Util.deepCopy(item)
        obj.pId = item.pid
        obj.value = item.id
        obj.label = item.name
        obj.key = item.id
        obj.enableEdit = false
        obj.title = item.name
        if (item.orgType && item.orgType == '8') {
          obj.disabled = false
        } else {
          obj.disabled = true
        }
        obj.scopedSlots = {
          icon: 'custom'
        }
        if (item.children && item.children.length) {
          obj.children = Util.dealDataProjectTypeDisabled(item.children)
        }
        arr.push(obj)
      })
    return arr
  }
  /**
   * 处理树结构数据 使 只有最后一级（没有children）可以点击
   *
   */
  Util.dealDataLastDisabled = function (tree, id = '') {
    let arr = []
    tree.length &&
      tree.forEach((item, index) => {
        let obj = {}
        obj = Util.deepCopy(item)
        obj.pId = item.pid
        obj.value = item.id
        obj.label = item.name
        obj.key = item.id
        obj.title = item.name
        obj.index = index
        if (item.children && item.children.length) {
          obj.disabled = true
          obj.children = Util.dealDataLastDisabled(item.children, id)
        } else {
          obj.disabled = false
          if (id && id == obj.key) {
            obj.disabled = true
          }
        }
        arr.push(obj)
      })
    return arr
  }
  /**
   * 处理树结构数据 使
   * 特定的ids不可以点击
   *
   */
  Util.dealDataIdsDisabled = function (tree, ids) {
    let arr = []
    tree.length &&
      tree.forEach((item, index) => {
        let obj = {}
        obj = Util.deepCopy(item)
        obj.pId = item.pid
        obj.value = item.id
        obj.label = item.name
        obj.key = item.id
        obj.title = item.name
        obj.index = index

        if (item.children && item.children.length) {
          obj.children = Util.dealDataIdsDisabled(item.children, ids)
        } else {
          ids.includes(item.id) ? (obj.disabled = true) : (obj.disabled = false)
        }
        arr.push(obj)
      })
    return arr
  }
  /**
   * 处理树结构数据 增加层级level
   * @param tree:树形结构的数据  level:树形结构的层级
   */
  Util.dealTreeData = function (tree, level) {
    let arr = []
    tree.length &&
      tree.forEach((item, index) => {
        let obj = {}
        obj = Util.deepCopy(item)
        obj.pId = item.pid
        obj.value = item.id
        obj.label = item.name
        obj.key = item.id
        obj.title = item.name
        obj.level = level
        obj.index = index
        obj.enableEdit = false
        if (item.children && item.children.length) {
          obj.children = Util.dealTreeData(item.children, level + 1)
        }
        arr.push(obj)
      })
    return arr
  }

  /**
   * 改变树级结构为普通数组对象类型
   */
  Util.initTree = function (tree) {
    let arr = []
    tree.length &&
      tree.forEach(item => {
        if (item.children && item.children.length) {
          arr.push(...Util.initTree(item.children))
          let a = Util.deepCopy(item)
          // delete a.children
          arr.push(a)
        } else {
          arr.push(item)
        }
      })
    return arr
  }
  /**获取树结构的所有id组成一维数组
   * @param level:获取层级为level的所有树结构的id
   * 不传参数时，获取所有层级的id
   */
  Util.getTreeAllId = function (tree, level) {
    let arr = []
    tree.length &&
      tree.forEach(item => {
        if (level) {
          if (item.level == level) {
            arr.push(item.id)
          }
        } else {
          arr.push(item.id)
        }
        if (item.children && item.children.length) {
          arr.push(...Util.getTreeAllId(item.children, level))
        }
      })
    return arr
  }
  /**
   * 通过props获取数据对象
   * @param data 数组对象（树形/普通数组对象）
   * @param prop
   * @param keyword
   * @returns {{}}
   */
  Util.getDataByProps = function (data, prop = 'id', keyword = '') {
    let result = {}
    data.length &&
      data.forEach(item => {
        if (Object.hasOwnProperty.call(item, prop) && item[prop] && item[prop] == keyword) {
          result = Util.deepCopy(item)
        }
        if (item.children && item.children.length) {
          Util.getDataByProps(item.children, prop, keyword)[prop] &&
            (result = Util.getDataByProps(item.children, prop, keyword))
        }
      })
    return result
  }
  /**
   * 获取最外两层ID集合
   * @param data
   * @returns {Array}
   */
  Util.getIds = function (data) {
    let arr = []
    data.length &&
      data.forEach(item => {
        if (item.children && item.children.length) {
          arr.push(item.id)
          item.children.forEach(item => {
            arr.push(item.id)
          })
        }
      })
    return arr
  }
  /**
   * 处理对象填充
   * @param obj
   * @param ids
   * @returns {*}
   */
  Util.delParams = function (obj = {}, ids = []) {
    let result = typeof obj.splice === 'function' ? [] : {}
    if (obj && typeof obj === 'object') {
      for (let key in obj) {
        if (obj[key] && typeof obj[key] === 'object') {
          if (ids.includes(key)) {
            result[key] = Util.deepCopy(obj[key]) //如果对象的属性值为object的时候，递归调用deepClone,即在吧某个值对象复制一份到新的对象的对应值中。
          }
        } else {
          if (ids.includes(key)) {
            result[key] = obj[key] //如果对象的属性值不为object的时候，直接复制参数对象的每一个键值到新的对象对应的键值对中。
          }
        }
      }
      return result
    }
    return obj
  }
  /**
   * 获取树结构数据最后一级数据
   */
  Util.getLastLevelData = function (tree) {
    let arr = []
    tree.length &&
      tree.forEach(item => {
        if (item.children && item.children.length) {
          arr.push(...Util.getLastLevelData(item.children))
        } else {
          if (!item.disabled) {
            arr.push(item)
          }
        }
      })
    return arr
  }
  Util.dateDiff = function (sDate1, sDate2) {
    //sDate1和sDate2是2017-9-25格式
    var aDate, oDate1, oDate2, iDays
    aDate = sDate1.split('-')
    oDate1 = new Date(aDate[1] + '-' + aDate[2] + '-' + aDate[0]) //转换为9-25-2017格式
    aDate = sDate2.split('-')
    oDate2 = new Date(aDate[1] + '-' + aDate[2] + '-' + aDate[0])
    iDays = parseInt(Math.abs(oDate1 - oDate2) / 1000 / 60 / 60 / 24) //把相差的毫秒数转换为天数
    return iDays
  }
  /**
   * 获取日期
   * @param AddDayCount -1 昨天 0 今天 1 明天 2 后天
   * @param symbol
   * @returns {string}
   * @constructor
   */
  Util.getDateByType = function (currDate, AddDayCount, type, symbol = '-') {
    let dd = new Date(currDate)
    dd.setDate(dd.getDate() + AddDayCount) //获取AddDayCount天后的日期
    let y = dd.getFullYear()
    let m = dd.getMonth() + 1 //获取当前月份的日期
    if (m < 10) {
      m = '0' + m
    }
    let d = dd.getDate()
    if (d < 10 && type != 1) {
      d = '0' + d
    }
    if (type == 1) {
      return d
    } else {
      return y + symbol + m + symbol + d
    }
  }

  /**
   * 全屏
   */
  Util.toggleFullScreen = function () {
    let doc = window.document
    let docEl = doc.documentElement
    let requestFullScreen =
      docEl.requestFullscreen ||
      docEl.mozRequestFullScreen ||
      docEl.webkitRequestFullScreen ||
      docEl.msRequestFullscreen
    let cancelFullScreen =
      doc.exitFullscreen ||
      doc.mozCancelFullScreen ||
      doc.webkitExitFullscreen ||
      doc.msExitFullscreen

    if (
      !doc.fullscreenElement &&
      !doc.mozFullScreenElement &&
      !doc.webkitFullscreenElement &&
      !doc.msFullscreenElement
    ) {
      requestFullScreen.call(docEl)
    } else {
      cancelFullScreen.call(doc)
    }
  }
  /**
   * 倒计时----秒
   * @param seconds 初始值
   * @param progressCallback  过程回调
   * @param finishCallback 完成回调
   */
  Util.countDown = function (seconds, progressCallback = () => {}, finishCallback = () => {}) {
    let timer = setInterval(() => {
      seconds--
      progressCallback && progressCallback(seconds)
      if (seconds < 1) {
        clearInterval(timer)
        finishCallback && finishCallback()
      }
    }, 1000)
  }
  /**
   * * 作者 李晨光
   * 树结构查询
   * @param keyword  关键词 本上级
   * @param arr
   * @returns {Array}
   */
  Util.treeSearch = function (keyword = '', props = 'title', arr) {
    let _newarr = []
    arr.length &&
      arr.forEach(element => {
        if (element.children && element.children.length) {
          let ab = Util.treeSearch(keyword, props, element.children)
          let obj = {
            ...element,
            children: ab
          }
          if (ab && ab.length) {
            _newarr.push(obj)
          } else {
            if (
              Object.hasOwnProperty.call(element, props) &&
              element[props] &&
              element[props].includes(keyword)
            ) {
              _newarr.push({
                ...element,
                children: []
              })
            }
          }
        } else {
          if (
            Object.hasOwnProperty.call(element, props) &&
            element[props] &&
            element[props].includes(keyword)
          ) {
            _newarr.push(element)
          }
        }
      })
    return _newarr
  }

  /**
   * 树结构模糊查询
   * 父节点存在，则展示所有节点，父节点不存在，则展示符合条件的节点
   * @param {*} keyword 关键词
   * @param {*} arr
   * @param {*} props
   */
  Util.treeSearchHot = function (keyword = '', props = 'title', arr) {
    let _newarr = []
    arr.length &&
      arr.forEach(element => {
        if (element.children && element.children.length) {
          if (
            Object.hasOwnProperty.call(element, props) &&
            element[props] &&
            element[props].includes(keyword)
          ) {
            _newarr.push({
              ...element,
              children: element.children
            })
          } else {
            let ab = Util.treeSearchHot(keyword, props, element.children)
            let obj = {
              ...element,
              children: ab
            }
            if (ab && ab.length) {
              _newarr.push(obj)
            }
          }
        } else {
          if (
            Object.hasOwnProperty.call(element, props) &&
            element[props] &&
            element[props].includes(keyword)
          ) {
            _newarr.push({
              ...element,
              children: element.children
            })
          }
        }
      })
    return _newarr
  }
  /**
   * * 作者 李帅
   * 树结构模糊查询 关键字标色
   * @param keyword  关键词
   * @param arr
   * @returns {Array}
   */
  Util.treeSearchAll = function (
    keyword = '',
    props = 'title',
    alias = 'alias',
    color = '#f50',
    arr
  ) {
    arr &&
      arr.length &&
      arr.forEach(element => {
        if (element.children && element.children.length) {
          Util.treeSearchAll(keyword, props, alias, color, element.children)
        }
        if (element[props].includes(keyword)) {
          element[alias] = element[props].replace(
            new RegExp(keyword, 'g'),
            '<span style="color:' + color + '">' + keyword + '</span>'
          )
        }
      })
    return arr
  }

  Util.treeSearchResource = function (keyword = '', props = 'title', arr) {
    let _newarr = []
    arr.length &&
      arr.forEach(element => {
        if (element.children && element.children.length) {
          let ab = Util.treeSearchResource(keyword, props, element.children)
          let obj = {
            ...element,
            children: ab
          }
          if (ab && ab.length) {
            _newarr.push(obj)
          } else {
            if (
              Object.hasOwnProperty.call(element, props) &&
              element[props] &&
              element[props] == keyword
            ) {
              _newarr.push({
                ...element,
                children: []
              })
            }
          }
        } else {
          if (
            Object.hasOwnProperty.call(element, props) &&
            element[props] &&
            element[props] == keyword
          ) {
            _newarr.push(element)
          }
        }
      })
    return _newarr
  }
  /**
   * 模糊匹配
   * @param {} keyword
   * @param {*} props
   * @param {*} arr
   * @returns
   */
  Util.treeSearchFuzzyResource = function (keyword = '', props = 'title', arr) {
    let _newarr = []
    arr.length &&
      arr.forEach(element => {
        if (element.children && element.children.length) {
          let ab = Util.treeSearchFuzzyResource(keyword, props, element.children)
          let obj = {
            ...element,
            children: ab
          }
          if (ab && ab.length) {
            _newarr.push(obj)
          } else {
            if (
              Object.hasOwnProperty.call(element, props) &&
              element[props] &&
              element[props].includes(keyword)
            ) {
              _newarr.push({
                ...element,
                children: []
              })
            }
          }
        } else {
          if (
            Object.hasOwnProperty.call(element, props) &&
            element[props] &&
            element[props].includes(keyword)
          ) {
            _newarr.push(element)
          }
        }
      })
    return _newarr
  }
  /**
   * * 作者 李晨光
   * 树结构(根据子节点 查询所有包括父节点的树结构)
   * @param ids  子节点集合
   * @param arr
   * @returns {Array}
   */
  Util.treeSearchByIds = function (ids = [], arr, key = 'id') {
    let _newarr = []
    arr.length &&
      arr.forEach(element => {
        if (element.children && element.children.length) {
          let ab = Util.treeSearchByIds(ids, element.children, key)
          let obj = {
            ...element,
            children: ab
          }
          if (ab && ab.length) {
            _newarr.push(obj)
          } else {
            if (ids.includes(element[key])) {
              _newarr.push({
                ...element,
                children: []
              })
            }
          }
        } else {
          if (ids.includes(element[key])) {
            _newarr.push(element)
          }
        }
      })
    return _newarr
  }
  Util.getValueByKey = function (data = [], key = 'id', value = 'name', keyword) {
    let res = undefined
    data.length &&
      data.forEach(item => {
        if (item[key] == keyword) {
          res = item[value]
        }
      })
    return res
  }
  /**
   * @Description: 获取tree
   * @Param:
   * @Return:
   */
  Util.getTree = function (id, resources) {
    let obj = {}
    let setObj = (id, resources) => {
      resources &&
        resources.length &&
        resources.forEach(v => {
          if (v.id == id) {
            obj = v
          }
          if (v.children && v.children.length) {
            setObj(id, v.children)
          }
        })
    }
    setObj(id, resources)
    return obj
  }
  /**
   * @Description: 获取当前id父级
   * @Param:
   * @Return:
   */
  Util.getParentTree = function (id, resources) {
    let obj = {}
    let setObj = (id, resources) => {
      resources &&
        resources.length &&
        resources.forEach(v => {
          if (v.children && v.children.length) {
            if (v.children.filter(v => v.id == id).length) {
              obj = v
            }
            setObj(id, v.children)
          }
        })
    }
    setObj(id, resources)
    return obj
  }
  /**
   * @Description: 获取子节点
   * @Param:
   * @Return:
   * @param {*} id
   * @param {*} resources
   * @param {*} props
   */
  Util.getTreeChild = function (id, resources, props = 'id') {
    let subArr = []
    let copyResources = Util.deepCopy(resources)
    let setData = (id, resources) => {
      resources &&
        resources.length &&
        resources.forEach(v => {
          if (v[props] == id) {
            subArr.push(v || [])
          } else {
            if (v.children && v.children.length) {
              setData(id, v.children, props)
            }
          }
        })
    }
    setData(id, copyResources)
    return subArr
  }
  // 清除不需要的字段
  Util.resetTree = function (treeData) {
    let setTree = list => {
      list &&
        list.length &&
        list.forEach((item, i) => {
          // item.loading = true
          if (item.children && item.children.length) {
            setTree(item.children)
          }
          if (item.children && !item.children.length) {
            delete item.children
          }
          if (item.appCode.includes('$DEFAULT$')) {
            list.splice(i, 1)
            setTree(list)
          }
        })
    }
    setTree(treeData)
    return treeData
  }
  /**
   * @Description: 获取指点tree下第一个有uri的菜单
   * @Param:
   * @Return:
   */
  Util.getTreeFirst = function (id, tree) {
    let menu = Util.getTreeChild(id, tree)[0]
    let obj = {}
    let setMenu = (id, menu) => {
      let i = 0
      if (menu && menu.uri) {
        obj = menu
        return menu
      } else {
        if (menu && menu.children && menu.children.length && i < menu.children.length) {
          let _obj = setMenu(id, menu.children[i])
          // 增加判断 防止第一项菜单地址为空导致整个菜单不显示问题
          if ((!_obj || !Object.keys(_obj).length) && !menu.children[i].children) {
            i++
            setMenu(id, menu.children[i])
          }
        }
      }
    }
    setMenu(id, menu)
    return obj
  }
  // Util.getTreeFirst 这个方法的替代方案，后续菜单项优化时可以用
  Util.getTreeUriFirst = function (id, tree) {
    let menus = Util.getTreeChild(id, tree)
    let obj = {}
    let setTree = menus => {
      for (let i = 0; i < menus.length; i++) {
        if (menus[i] && menus[i].uri && menus[i].uri != '/main/developing') {
          if (!Object.keys(obj).length) obj = menus[i]
          break
        } else {
          if (menus[i] && menus[i].children && menus[i].children.length) {
            setTree(menus[i].children)
          }
        }
      }
    }
    setTree(menus)
    return Object.keys(obj).length ? obj : { uri: '/main/developing', appType: 1 }
  }
  /**
   * @Description: 获取id集合
   * @Param:
   * @Return:
   */
  Util.getTreeIds = function (id, resources) {
    let ids = []
    let arr = Util.treeSearchByIds(id, resources)
    let getIds = arr => {
      arr &&
        arr.length &&
        arr.forEach(v => {
          if (v.children && v.children.length) {
            getIds(v.children)
          }
          !ids.includes(v.id) && ids.unshift(v.id)
        })
    }
    getIds(arr)
    return ids
  }
  /**
   * 主要应用于暂存业务
   * 存在格式错误返回true
   * 判断form表单提交是否仅存在格式错误，不考虑必填项为空情况
   * @param {*} err form中validateFields输出的错误对象
   * @param {*} arr 必填的提示语数组,用于区分必填错误提示与格式错误提示，例如['请输入'，'请选择']
   */
  Util.formHasEarr = function (err, arr = ['请输入', '请选择']) {
    // 判断提交格式是否正确
    if (err && Object.keys(err).length) {
      for (let key in err) {
        // 判断该参数是否存在错误提示数组
        if (err[key] && err[key].errors && err[key].errors.length) {
          // 判断是否有非必填项校验的
          let _isErr = err[key].errors.some(val => {
            if (val.message && val.message.trim()) {
              // 判断错误提示是否包含必填项的错误提示
              let _isEverImport = arr.some(v => val.message.includes(v))
              if (!_isEverImport) {
                return true
              }
            }
          })

          if (_isErr) {
            return true
          }
        }
      }
    }
    return false
  }
  /**
   * 设置form表单值--防止便捷赋值操作时控制台报错问题
   * form form对象
   * values Object 所在值集合
   *disabledVal Array 禁止赋值的集合
   * callback 设置完成的回调
   * */
  Util.setFormValues = function (form, values, disabledVal, callback) {
    vm.$nextTick(() => {
      let _obj = Util.deepCopy(form.getFieldsValue())
      // _obj中清除禁止赋值的的字段
      if (disabledVal && disabledVal.length) {
        disabledVal.forEach(item => delete _obj[item])
      }
      for (let i in _obj) {
        _obj[i] = values[i]
      }
      form.resetFields()
      form.setFieldsValue(_obj)
      form.validateFields()
      callback && callback()
    })
  }
  //设置localStorage
  Util.setLocalStorage = function (key, value) {
    localStorage.setItem(key, value)
  }
  Util.getLocalStorage = function (key) {
    return localStorage.getItem(key)
  }
  Util.removeLocalStorage = function (key) {
    return localStorage.removeItem(key)
  }
  //设置localStorage
  Util.setSessionStorage = function (key, value) {
    sessionStorage.setItem(key, value)
  }
  Util.getSessionStorage = function (key) {
    return sessionStorage.getItem(key)
  }
  Util.removeSessionStorage = function (key) {
    return sessionStorage.removeItem(key)
  }
  /*
                      根据文件名称判断文件类型
                      * @param: fileName - 文件名称
                      * @param: 数据返回 1) 无后缀匹配 - false
                      * @param: 数据返回 2) 匹配图片 - image
                      * @param: 数据返回 3) 匹配 txt - txt
                      * @param: 数据返回 4) 匹配 excel - excel
                      * @param: 数据返回 5) 匹配 word - word
                      * @param: 数据返回 6) 匹配 pdf - pdf
                      * @param: 数据返回 7) 匹配 ppt - ppt
                      * @param: 数据返回 8) 匹配 视频 - video
                      * @param: 数据返回 9) 匹配 音频 - radio
                      * @param: 数据返回 10) 其他匹配项 - other
                      */
  Util.getFileType = function (fileName) {
    // 后缀获取
    var suffix = ''
    // 获取类型结果
    var result = ''
    try {
      var flieArr = fileName.toLocaleLowerCase().split('.')
      suffix = flieArr[flieArr.length - 1]
    } catch (err) {
      suffix = ''
    }
    // fileName无后缀返回 false
    if (!suffix) {
      result = false
      return result
    }
    // 图片格式
    var imglist = ['png', 'jpg', 'jpeg', 'bmp', 'gif']
    // 进行图片匹配
    result = imglist.some(function (item) {
      return item == suffix
    })
    if (result) {
      result = 'image'
      return result
    }
    // 匹配txt
    var txtlist = ['txt']
    result = txtlist.some(function (item) {
      return item == suffix
    })
    if (result) {
      result = 'txt'
      return result
    }
    // 匹配 excel
    var excelist = ['xls', 'xlsx']
    result = excelist.some(function (item) {
      return item == suffix
    })
    if (result) {
      result = 'excel'
      return result
    }
    // 匹配 word
    var wordlist = ['doc', 'docx']
    result = wordlist.some(function (item) {
      return item == suffix
    })
    if (result) {
      result = 'word'
      return result
    }
    // 匹配 pdf
    var pdflist = ['pdf']
    result = pdflist.some(function (item) {
      return item == suffix
    })
    if (result) {
      result = 'pdf'
      return result
    }
    // 匹配 ppt
    var pptlist = ['ppt', 'pptx']
    result = pptlist.some(function (item) {
      return item == suffix
    })
    if (result) {
      result = 'ppt'
      return result
    }
    // 匹配 视频
    var videolist = ['mp4', 'm2v', 'mkv']
    result = videolist.some(function (item) {
      return item == suffix
    })
    if (result) {
      result = 'video'
      return result
    }
    // 匹配 音频
    var radiolist = ['mp3', 'wav', 'wmv']
    result = radiolist.some(function (item) {
      return item == suffix
    })
    if (result) {
      result = 'radio'
      return result
    }
    // 匹配zip
    var zipList = ['zip', 'rar']
    result = zipList.some(function (item) {
      return item == suffix
    })
    if (result) {
      result = 'zip'
      return result
    }
    // 其他 文件类型
    result = 'other'
    return result
  }

  Util.formatMoney = function (number, places, symbol, thousand, decimal) {
    number = number || 0
    if (number === 0 || number === '0') {
      return '0'
    }
    // 如果不存在places places默认值为2  如果places=='keep' 则使用原数据默认小数点位
    let res, _places
    if (places == 'keep') {
      let _decimalIndex = number.toString().indexOf('.') > -1
      if (_decimalIndex) {
        res = number.toString().split('.')
        _places = res[1].toString().length
      }
    } else {
      _places = 2
    }
    places = !isNaN((places = Math.abs(places))) ? places : _places
    symbol = symbol !== undefined ? symbol : '¥'
    thousand = thousand || ','
    decimal = decimal || '.'
    let negative = number < 0 ? '-' : '',
      i = parseInt((number = Math.abs(+number || 0).toFixed(places)), 10) + '',
      j = ''
    j = (j = i.length) > 3 ? j % 3 : 0
    return (
      symbol +
      negative +
      (j ? i.substr(0, j) + thousand : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousand) +
      (places
        ? decimal +
          Math.abs(number - i)
            .toFixed(places)
            .slice(2)
        : '')
    )
  }
  /**
   * @Description: 数组对象去重
   * @Param:
   * @Return:
   */
  Util.unique = function (arr, u_key) {
    let obj = {}
    let result = []
    arr.forEach(item => {
      let typeof_key = typeof item[u_key] + item[u_key]
      obj[typeof_key] = item
    })
    for (let key in obj) {
      result.push(obj[key])
    }
    return result
  }
  //判断用户时候有页面访问权限
  Util.isPageAuth = function (meta) {
    // let resources = store.getters.resources
    let resources = []
    // 非空判断
    if (localStorage.getItem('resources')) {
      resources = JSON.parse(localStorage.getItem('resources'))
    }
    let { id, isAuth, authCode } = meta
    let arr = []
    let restructure = data => {
      data &&
        data.length &&
        data.forEach(v => {
          if (v.children && v.children.length) {
            restructure(v.children)
          }
          arr.push(v)
        })
    }
    restructure(resources)
    // 判断是否受权限控制，否返回可访问改页面，是判断后返回
    if (!isAuth) {
      return true
    } else {
      if (authCode) {
        return arr.find(v => v.appCode == authCode)
      } else {
        return arr.find(v => v.id == id)
      }
    }
  }
  /**
   * 判断当前Tab/页面是否时否含有列表操作功能权限
   * @param {*} authCode 页面权限编码，唯一值
   * @param {*} btnArr 操作按钮编码列表
   *
   * 若不含有，返回false,若含有，返回该权限所有内容及子内容
   */
  Util.isOperaAuth = function (authCode, btnArr) {
    // let resources = store.getters.resources
    let resources = []
    // 非空判断
    if (localStorage.getItem('resources')) {
      resources = JSON.parse(localStorage.getItem('resources'))
    }
    let arr = []
    let restructure = data => {
      data &&
        data.length &&
        data.forEach(v => {
          if (v.children && v.children.length) {
            restructure(v.children)
          }
          arr.push(v)
        })
    }
    restructure(resources)
    let _auth = arr.find(v => v.appCode == authCode)
    // 判断是否受权限控制，否返回可访问改页面，是判断后返回
    // 存在权限页面下，校验是否存在操作权限按钮
    if (_auth && _auth.children && _auth.children.length) {
      // 如果当前权限与列表操作权限存在交集，即表明操作栏存在
      if (_auth.children.filter(val => btnArr.indexOf(val.appCode) > -1).length > 0) return true
    }
    // 菜单模式-二 暂时过滤权限
    // 特殊处理菜单模式二
    if (store.getters.menu_mode && store.getters.menu_mode == 2) {
      return true
    }
    return false
  }
  Util.getPageButtonAuth = function (meta) {
    let resources = store.getters.resources
    let { id, isAuth, authCode } = meta
    let arr = []
    let restructure = data => {
      data &&
        data.length &&
        data.forEach(v => {
          if (v.id && v.id == id) {
            if (v.children) arr.push(...v.children)
          } else {
            restructure(v.children)
          }
        })
    }
    if (id) restructure(resources)
    else return JSON.parse(localStorage.getItem('pageButtonAuth'))
    return arr
  }

  /**
   * 判断页面时否含有功能权限
   * @param {*} code 权限编码，唯一值
   * @param {*} type 类型，present--该页面直系权限，all--其它页面权限
   *
   * 若不含有，返回false,若含有，返回该权限所有内容及子内容
   */
  Util.getBtnAuth = function (code, type = 'present') {
    let _obj = {}
    if (localStorage.getItem('bj_spm_main_test')) {
      _obj = JSON.parse(localStorage.getItem('bj_spm_main_test')) || {}
    }

    //默认从当前页面资源查找
    let _arr = _obj.featureAuth || []
    if (type === 'all') {
      //从所有资源中查找
      _arr = _obj.resources || []
    }

    //递归获取权限
    let _getAuth = (data, value) => {
      let _auth = false
      if (data && data.length) {
        for (let i = 0; i < data.length; i++) {
          if (value.includes(data[i].appCode)) {
            //当在该模块下时
            if (value == data[i].appCode) {
              _auth = Util.deepCopy(data[i])
              return _auth
            }
            if (value.length > data[i].appCode.length && value[data[i].appCode.length] != '_') {
              //当不等于且不为当前判定权限父级时 即出现 value = a_b_c* appCode = a_b_c时直接进行下一循环
              continue
            }
            if (data[i].children && data[i].children.length) {
              //当该权限有子菜单时递归调用
              return _getAuth(data[i].children, value)
            }
          }
        }
      }
      return _auth
    }
    // 特殊处理菜单模式二
    if (store.getters.menu_mode && store.getters.menu_mode == 2) {
      return true
    }
    return _getAuth(_arr, code)
  }
  Util.addPropsByKey = (
    initArr,
    oldProps = ['id', 'name'],
    newProps = ['value', 'label'],
    replace = false
  ) => {
    let arr = []
    initArr.length &&
      initArr.forEach(item => {
        let _obj = replace ? { ...item } : {}
        oldProps.length &&
          oldProps.forEach((prop, index) => {
            _obj[newProps[index]] = item[prop] || ''
          })
        arr.push(_obj)
      })
    return arr
  }
  //格式化文件大小
  Util.renderSize = function (value) {
    if (null == value || value == '') {
      return '0 Bytes'
    }
    var unitArr = new Array('Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB')
    var index = 0
    var srcsize = parseFloat(value)
    index = Math.floor(Math.log(srcsize) / Math.log(1024))
    var size = srcsize / Math.pow(1024, index)
    size = size.toFixed(2) //保留的小数位数
    return size + unitArr[index]
  }
  //  host定制化处理
  Util.hostSet = function () {
    let host = window.location.host

    let tripartiteName = sessionStorage.getItem('tripartiteName')
    let _icons = {
      智能安全管理平台: 'https://www.ccccltd.cn/images/zgjj-icon.png'
    }

    // 改变当前favicon
    const changeFavicon = link => {
      let $favicon = document.querySelector('link[rel="shortcut icon"]')
      if ($favicon !== null) {
        $favicon.href = _icons[tripartiteName] ? _icons[tripartiteName] : link
      } else {
        $favicon = document.createElement('link')
        $favicon.rel = 'shortcut icon'
        $favicon.href = _icons[tripartiteName] ? _icons[tripartiteName] : link
        document.head.appendChild($favicon)
      }
    }
    let _obj = {}
    // 获取当前平台端内容
    try {
      _obj = hostConfig[host]?.platform || hostConfig['default']?.platform
    } catch (error) {
      _obj = hostConfig['default']?.platform
    }
    // 修改当前标题
    document.title = tripartiteName ? tripartiteName : _obj.headerTitle
    // 修改当前favicon
    changeFavicon(_obj.favicon)
    return _obj
  }
  // 将阿拉伯数字转换为中文汉字
  Util.noToChinese = num => {
    if (!/^\d*(\.\d*)?$/.test(num)) return ' '
    let AA = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九']
    let BB = ['', '十', '百', '千', '万', '亿', '点', '']
    let a = ('' + num).replace(/(^0*)/g, '').split('.'),
      k = 0,
      re = ''
    for (let i = a[0].length - 1; i >= 0; i--) {
      switch (k) {
        case 0:
          re = BB[7] + re
          break
        case 4:
          if (!new RegExp('0{4}\\d{' + (a[0].length - i - 1) + '}$').test(a[0])) re = BB[4] + re
          break
        case 8:
          re = BB[5] + re
          BB[7] = BB[5]
          k = 0
          break
      }
      if (k % 4 == 2 && a[0].charAt(i + 2) != 0 && a[0].charAt(i + 1) == 0) re = AA[0] + re
      if (a[0].charAt(i) != 0) re = AA[a[0].charAt(i)] + BB[k % 4] + re
      k++
    }
    if (a.length > 1) {
      //加上小数部分(如果有小数部分)
      re += BB[6]
      for (let i = 0; i < a[1].length; i++) re += AA[a[1].charAt(i)]
    }
    //替换所有无用汉字，直到没有此类无用的数字为止
    while (
      re.search('零零') != -1 ||
      re.search('零亿') != -1 ||
      re.search('亿万') != -1 ||
      re.search('零万') != -1
    ) {
      re = re.replace('零亿', '亿')
      re = re.replace('亿万', '亿')
      re = re.replace('零万', '万')
      re = re.replace('零零', '零')
    }
    //替换以“一十”开头的，为“十”
    if (re.indexOf('一十') == 0) {
      re = re.substr(1)
    }
    //替换以“零”结尾的，为“”
    if (re.lastIndexOf('零') == re.length - 1) {
      re = re.substr(0, re.length - 1)
    }
    return re
  }

  /**
   * 高德坐标系 (GCJ-02) 转换成 百度坐标系 (BD-09)：
   * @param {*} lng 经度
   * @param {*} lat 纬度
   * @returns {lng,lat} 百度坐标
   */
  Util.gcj02tobd09 = (lng, lat) => {
    // let x_pi = (3.14159265358979324 * 3000.0) / 180.0
    let x_pi = (3.141592653589793 * 3000.0) / 180.0
    let z = Math.sqrt(lng * lng + lat * lat) + 0.00002 * Math.sin(lat * x_pi)
    let theta = Math.atan2(lat, lng) + 0.000003 * Math.cos(lng * x_pi)
    let bd_lng = z * Math.cos(theta) + 0.0065
    let bd_lat = z * Math.sin(theta) + 0.006
    return { lng: bd_lng, lat: bd_lat }
  }

  /**
   * 百度坐标系 (BD-09) 转换成 火星坐标系 (GCJ-02)：
   * @param {*} bd_lon 经度
   * @param {*} bd_lat 纬度
   * @returns
   */
  Util.bd09togcj02 = (bd_lon, bd_lat) => {
    let x_pi = (3.141592653589793 * 3000.0) / 180.0
    let x = bd_lon - 0.0065
    let y = bd_lat - 0.006
    let z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * x_pi)
    let theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * x_pi)
    let gg_lng = z * Math.cos(theta)
    let gg_lat = z * Math.sin(theta)
    return { lng: gg_lng, lat: gg_lat }
  }

  // 地图全局配置
  Util.mapConfig = {
    features: [],
    lng: 0,
    lat: 0,
    loaded: true,
    pitch: 43,
    viewMode: '2D',
    rotation: 10,
    resizeEnable: true,
    rotateEnable: true,
    pitchEnable: true,
    buildingAnimation: true,
    mapStyle: 'amap://styles/grey'
  }
  Util.Reg = /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^0\.([0][1-9]$))|(^0\.[1-9][0-9]?)|(^0$)/ //费用可为0 0,0.1,0.01，10……
  Util.Reg2 = /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^0\.([0][1-9]$))|(^0\.[1-9][0-9]?)/ //费用不能为0  0.1,0.01，10……
  Util.Reg3 = /(^[1-9]([0-9]+)?(\.[0][0]?)?$)|(^0\.0[0]?$)|(^0$)/ //正整数 + 0
  Util.Reg4 = /(^[1-9]([0-9]+)?(\.[0][0]?)?$)|(^0\.0[0]?$)/ //正整数

  Util.Reg7 = /^[0-9A-Z]{18}$/ //18位大写字母或数字
  Util.Reg9 = /^[0-9A-Za-z]+$/ //18位大写字母或数字
  Util.tel = /^1[3456789]\d{9}$/ //手机号验证
  Util.password =
    /^(?![\d]+$)(?![a-zA-Z]+$)(?![!#$%^&*,\>\<\?\-\./@_\+\=\(\)\~\`]+$)[\da-zA-Z!#$%^&*€£¥,\>\<\?\-\./@_\+\=\(\)\~\`]{8,16}$/i //8-16位(数字、字母)、(数字、字母·特殊字符)组合 //8-16位(数字、字母)、(数字、字母·特殊字符)组合
  Util.chinese = /^[\u4E00-\u9FA5]+$/ // 中文
  Util.Reg10 = /^(0|[1-9][0-9]*|-[1-9][0-9]*)$/ //正负整数 + 0
  Util.email =
    /^[A-Za-z0-9]+([-._][A-Za-z0-9]+)*@[A-Za-z0-9]+(-[A-Za-z0-9]+)*(\.[A-Za-z]{2,6}|[A-Za-z]{2,4}\.[A-Za-z]{2,3})$/ // 邮箱正则表达式
  Util.url = /(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?/ // url正则表达式
  Util.adminName = /^[A-Za-z0-9\u4e00-\u9fa5]+/ // 管理员名称 请输入汉字、英文、数字或其组合
  Util.adminAccount = /^(?=.*_)(?=.*\d)(?=.*[a-zA-Z]).{6,18}$/ // 管理员账号 请输入6-18位字符，同时包含英文字母、数字、下划线
  Util.beginWidthAb = /^[a-zA-Z]+/ //以英文字母开头
  Util.iCard = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9X]$/ //身份证校验
  Util.noChinese = /^[u4E00-u9FA5]+$/ //非汉字
  Util.Reg11 = /^[0-9a-zA-Z_-]{1,}$/ // 只能输入数字、英文字母、下划线、短横线
  Util.Reg12 = /(^[1-9]([0-9]+)?(\.[0][0]?)?$)|(^0\.0[0]?$)|(^0$)|(^\+?(\d*\.\d{1,3}))$/ //保留小数点1~3位小数
  Util.Reg13 = /(^[1-9]([0-9]+)?(\.[0][0]?)?$)|(^0\.0[0]?$)|(^0$)|(^\+?(\d*\.\d{1,2}))$/ //保留小数点1~2位小数
  Util.Reg14 = /(^-?(?:\d+|\d{1,3}(?:,\d{3})+)(?:\.\d{1,2})?$)/ //保留小数点1~2位小数(含负数)
  Util.Reg15 = /(^-?[0-9]{1,6}$)|(^-?[0-9]{1,6}[\.]{1}[0-9]{1,3}$)/ //保留小数点1~3位小数(含负数)
  Util.Reg16 = /(^-?[0-9]{1,6}$)|(^-?[0-9]{1,6}[\.]{1}[0-9]{1,4}$)/ //保留小数点1~4位小数(含负数)
  Util.Reg17 = /^[0-9]+$/ //纯数字
  Util.ip_port =
    /^(25[0-5]|2[0-4]\d|[0-1]\d{2}|[1-9]?\d)\.(25[0-5]|2[0-4]\d|[0-1]\d{2}|[1-9]?\d)\.(25[0-5]|2[0-4]\d|[0-1]\d{2}|[1-9]?\d)\.(25[0-5]|2[0-4]\d|[0-1]\d{2}|[1-9]?\d)\:(6553[0-5]|655[0-2]\d|65[0-4]\d{2}|6[0-4]\d{3}|[0-5]\d{4}|[1-9]\d{0,3})$/ // 正则ip端口
  Util.ip = /^((2[0-4]\d|25[0-5]|[01]?\d\d?)\.){3}(2[0-4]\d|25[0-5]|[01]?\d\d?)$/ // 正则ip无端口
  Util.Pile = /^[A-Za-z]+[0-9]{0,9}\+([0-9]{1,3})(\.\d{1,2})?$/ // 正则桩号 ex: K0+01 = 1m
  Util.getBrowserInfo = function () {
    var Sys = {}
    var ua = navigator.userAgent.toLowerCase()
    var re = /(msie|firefox|chrome|opera|version).*?([\d.]+)/
    var m = ua.match(re)
    Sys.browser = m[1].replace(/version/, "'safari")
    Sys.version = m[2]
    return Sys
  }
  window.Util = Util
})(window)

export default {
  install: function (Vue) {
    Vue.Util
  }
}
