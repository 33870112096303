/*
 * @Description:
 * @Version: 1.0.0
 * @Author: 李晨光
 * @Date: 2020-08-20 14:11:46
 * @LastEditors: 任笠
 * @LastEditTime: 2021-09-23 10:14:10
 */
import Vue from 'vue'
import {
  SET_NAME,
  SET_LOGINMSG,
  SET_CONTRACTOR_INFO,
  SET_COLOR,
  SET_OPENKEYS,
  SET_CURR_ORG,
  SET_MENU_MODE,
  SET_CURR_TENANT,
  SET_LOADING_FINISH,
  SET_COLLECTIONS,
  SET_CITYS,
  SET_RESOURCES,
  SET_DICTS,
  SET_LOGO,
  SET_CURR_TENANT_CODE,
  SET_FEATUREAUTH,
  USER_SIGNOUT,
  SET_EMBEDD_BREAD_CRUMBS,
  SET_LAST_ACTIVITY
} from './mutationTypes'
export default {
  [SET_NAME](state, name) {
    state.name = name
  },
  [SET_LOGINMSG](state, login_msg) {
    state.login_msg = login_msg
  },
  [SET_CONTRACTOR_INFO](state, contractorInfo) {
    state.contractorInfo = contractorInfo
  },
  [SET_COLOR]: (state, color) => {
    state.color = color
  },

  [SET_OPENKEYS]: (state, open_keys) => {
    state.open_keys = open_keys
  },
  [SET_CURR_ORG]: (state, curr_org) => {
    state.curr_org = curr_org
  },
  [SET_MENU_MODE]: (state, menu_mode) => {
    state.menu_mode = menu_mode
  },
  [SET_CURR_TENANT]: (state, curr_tenant) => {
    state.curr_tenant = curr_tenant
  },
  [SET_CURR_TENANT_CODE]: (state, curr_tenant_code) => {
    state.curr_tenant_code = curr_tenant_code
  },
  [SET_LOADING_FINISH]: (state, loading_finish) => {
    state.loading_finish = loading_finish
  },
  [SET_COLLECTIONS]: (state, collections) => {
    state.collections = collections
  },
  [SET_CITYS]: (state, citys) => {
    state.citys = citys
  },
  [SET_RESOURCES]: (state, resources) => {
    state.resources = resources
  },
  [SET_DICTS]: (state, dicts) => {
    state.dicts = dicts
  },
  [SET_LOGO]: (state, logo) => {
    state.logo = logo
  },
  [USER_SIGNOUT](state) {
    Object.keys(state).forEach(k => {
      Vue.delete(state, k)
    })
  },
  [SET_FEATUREAUTH]: (state, featureAuth) => {
    state.featureAuth = featureAuth
  },
  [SET_EMBEDD_BREAD_CRUMBS]: (state, embeddBreadCrumbs) => {
    state.embedd_bread_crumbs = embeddBreadCrumbs
  },
  [SET_LAST_ACTIVITY]: (state, last_activity) => {
    state.last_activity = last_activity
  }
}
