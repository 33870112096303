/*
 * @Description: 安全教育路由文件
 * @Version: 1.0.0
 * @Author: 王召强
 * @Date: 2022-08-15 16:48:49
 * @LastEditors: 赵亮
 * @LastEditTime: 2023-03-28 11:22:44
 */
const safetyEducationRoute = [
  // =={===> 安全教育
  {
    path: '/labor/safetyEducation/PlannedEducation',
    name: 'PlannedEducation',
    component: () => import(/*/* : "计划教育" */ '$labor/views/safetyEducation/PlannedEducation'),
    meta: {
      name: '计划教育',
      isAuth: true,
      authCode: 'web_pm_labor_safeEducation_PlannedEducation'
    }
  },
  // 计划教育新增
  {
    path: '/labor/safetyEducation/PlannedEducation/add',
    name: 'PlannedEducationAdd',
    meta: {
      name: '新增',
      type: 0,
      authCode: 'web_pm_labor_safeEducation_PlannedEducation_add',
      keepAlive: false
    },
    component: () =>
      import(/*/* : "计划教育新增" */ '$labor/views/safetyEducation/components/PlannedEducationAdd')
  },
  // 计划教育新增
  {
    path: '/labor/safetyEducation/PlannedEducation/add',
    name: 'PlannedEducationEdit',
    meta: {
      name: '编辑',
      type: 1,
      authCode: 'web_pm_labor_safeEducation_PlannedEducation_add',
      keepAlive: false
    },
    component: () =>
      import(/*/* : "计划教育编辑" */ '$labor/views/safetyEducation/components/PlannedEducationAdd')
  },
  // 计划教育详情
  {
    path: '/labor/safetyEducation/PlannedEducation/detail',
    name: 'PlannedEducationDetail',
    meta: {
      name: '详情',
      type: 1,
      authCode: 'web_pm_labor_safeEducation_PlannedEducation_detail',
      keepAlive: false
    },
    component: () =>
      import(/*/* : "计划教育详情" */ '$labor/views/safetyEducation/components/PlannedEduDetail')
  },
  {
    path: '/labor/safetyEducation/FieldEducation',
    name: 'FieldEducation',
    component: () => import(/*/* : "现场教育" */ '$labor/views/safetyEducation/FieldEducation'),
    meta: {
      name: '现场教育',
      isAuth: true,
      authCode: 'web_pm_labor_safeEducation_FieldEducation'
    }
  },
  // 现场教育-查看详情
  {
    path: '/labor/safetyEducation/fieldEducationDetail',
    name: 'FieldEducationDetail',
    component: () =>
      import(
        /*/* : "现场教育-查看详情" */ '$labor/views/safetyEducation/components/FieldEducationDetail'
      ),
    meta: {
      name: '现场教育明细',
      isAuth: true,
      authCode: 'web_pm_labor_safeEducation_FieldEducation_detail'
    }
  },
  {
    path: '/labor/safetyEducation/CoursewareManagement',
    name: 'CoursewareManagement',
    component: () =>
      import(/*/* : "课件管理" */ '$labor/views/safetyEducation/coursewareManagement/Courseware'),
    meta: {
      name: '课件管理',
      isAuth: true,
      authCode: 'web_pm_labor_safeEducation_CoursewareManage'
    }
  },
  {
    path: '/labor/safetyEducation/CoursewareManagement/add',
    name: 'CoursewareAdd',
    component: () =>
      import(
        /*/* : "课件管理" */ '$labor/views/safetyEducation/coursewareManagement/CoursewareAdd'
      ),
    meta: {
      name: '新增',
      isAuth: false,
      authCode: 'web_pm_labor_safeEducation_CoursewareManage_add'
    }
  },
  {
    path: '/labor/safetyEducation/CoursewareManagement/edit',
    name: 'CoursewareEdit',
    component: () =>
      import(
        /*/* : "课件管理" */ '$labor/views/safetyEducation/coursewareManagement/CoursewareAdd'
      ),
    meta: {
      name: '编辑',
      isAuth: false,
      authCode: 'web_pm_labor_safeEducation_CoursewareManage_edit'
    }
  },
  // 基础设置-教育方式与类型设置
  {
    path: '/labor/safetyEducation/eduSeting',
    name: 'EduTypeSetting',
    component: () => import(/*/* : "教育类型设置" */ '$labor/views/safetyEducation/EduSeting'),
    meta: {
      name: '教育方式与类型设置',
      isAuth: true,
      authCode: 'web_base_labor_safetyEducation_eduSeting'
    }
  },
  // 基础设置-通用设置
  {
    path: '/labor/safetyEducation/generalSetting',
    name: 'GeneralSetting',
    component: () => import(/*/* : "通用设置" */ '$labor/views/safetyEducation/GeneralSetting'),
    meta: {
      name: '通用设置',
      isAuth: true,
      authCode: 'web_base_labor_safetyEducation_generalSetting'
    }
  },
  {
    path: '/labor/safetyEducation/CoursewareManagement/edit',
    name: 'CoursewareManagementEdit',
    component: () =>
      import(/*/* : "课件管理" */ '$labor/views/safetyEducation/CoursewareManagementAdd'),
    meta: {
      name: '编辑',
      isAuth: false,
      authCode: 'web_pm_labor_safeEducation_CoursewareManage_edit'
    }
  },
  {
    path: '/labor/safetyEducation/CoursewareManagement/Details',
    name: 'CoursewareManagementDetails',
    component: () =>
      import(/*/* : "课件管理详情" */ '$labor/views/safetyEducation/CoursewareManagementDetails'),
    meta: {
      name: '详情',
      isAuth: false,
      authCode: ''
    }
  },

  //试卷管理
  {
    path: '/labor/testPaperManagement/TestPaper',
    name: 'TestPaper',
    component: () =>
      import(/*/* : "课件管理" */ '$labor/views/safetyEducation/testPaperManagement/TestPaper'),
    meta: {
      name: '试卷管理',
      isAuth: true,
      authCode: 'web_pm_labor_safeEducation_testPaper'
    }
  },
  {
    path: '/labor/testPaperManagement/TestDeatils',
    name: 'TestDeatils',
    component: () =>
      import(/*/* : "课件管理" */ '$labor/views/safetyEducation/testPaperManagement/TestDeatils'),
    meta: {
      name: '试卷详情',
      isAuth: false,
      authCode: 'web_pm_labor_safeEducation_testPaper_detail'
    }
  },
  {
    path: '/labor/testPaperManagement/TestPaper/add',
    name: 'TestPaperAdd',
    component: () =>
      import(/*/* : "课件管理" */ '$labor/views/safetyEducation/testPaperManagement/TestPaperAdd'),
    meta: {
      name: '编辑试卷',
      isAuth: false,
      authCode: 'web_pm_labor_safeEducation_testPaper_edit'
    }
  },
  {
    path: '/labor/testPaperManagement/TestPaper/fileadd',
    name: 'TestPaperFileAdd',
    component: () =>
      import(
        /*/* : "课件管理" */ '$labor/views/safetyEducation/testPaperManagement/TestPaperFileAdd'
      ),
    meta: {
      name: '新增试卷',
      isAuth: false,
      authCode: 'web_pm_labor_safeEducation_testPaper_add'
    }
  },
  //在线考试
  {
    path: '/labor/safetyEducation/onlineExame',
    name: 'onlineExame',
    component: () =>
      import(/*/* : "在线考试" */ '$labor/views/safetyEducation/onlineExame/OnlineExame.vue'),
    meta: {
      name: '在线考试',
      isAuth: true,
      authCode: 'web_pm_labor_safeEducation_onlineExame'
    }
  },
  //在线考试新增
  {
    path: '/labor/safetyEducation/onlineExame/onlineExameAdd',
    name: 'onlineExameAdd',
    component: () =>
      import(
        /*/* : "在线考试新增" */ '$labor/views/safetyEducation/onlineExame/components/OnlineExameAdd'
      ),
    meta: {
      name: '新增',
      isAuth: false,
      authCode: 'web_pm_labor_safeEducation_onlineExame_add'
    }
  },
  //在线编辑
  {
    path: '/labor/safetyEducation/onlineExame/onlineExameEdit',
    name: 'onlineExameEdit',
    component: () =>
      import(
        /*/* : "在线考试编辑" */ '$labor/views/safetyEducation/onlineExame/components/OnlineExameAdd'
      ),
    meta: {
      name: '编辑',
      isAuth: false,
      authCode: 'web_pm_labor_safeEducation_onlineExame_edit'
    }
  },

  //在线考试详情
  {
    path: '/labor/safetyEducation/onlineExame/detail',
    name: 'onlineExameDetail',
    meta: {
      name: '详情',
      type: 1,
      isAuth: false,
      authCode: 'web_pm_labor_safeEducation_onlineExame_detail',
      keepAlive: false
    },
    component: () =>
      import(
        /*/* : "计划教育详情" */ '$labor/views/safetyEducation/onlineExame/components/OnlineExameDetail'
      )
  },
  {
    path: '/labor/safetyEducation/safetyEducationAccount',
    name: 'safetyEducationAccount',
    component: () =>
      import(
        /*/* : "安全教育台账" */ '$labor/views/safetyEducation/safetyEducationAccount/SafetyEducationAccount.vue'
      ),
    meta: {
      name: '安全教育台账',
      isAuth: false,
      authCode: 'web_pm_labor_safeEducation_safetyEducationAccount'
    }
  },
  //安全教育预警
  {
    path: '/labor/safetyEducation/earlyWarning/index',
    name: 'safetyEducationAccountEarlyWarning',
    component: () =>
      import(/*/* : "安全教育台账" */ '$labor/views/safetyEducation/earlyWarning/Index.vue'),
    meta: {
      name: '安全教育预警',
      isAuth: true,
      authCode: 'web_pm_labor_alarm_earlyWarning'
    }
  }
]

export default safetyEducationRoute
