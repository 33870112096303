/*
 * @Description:
 * @Version: 1.0.0
 * @Author: 李晨光
 * @Date: 2020-09-21 14:25:45
 * @LastEditors: 李晨光
 * @LastEditTime: 2024-11-25 15:39:29
 */
import store from '../vuex'
import Vue from 'vue'
const isStandard = process.env.VUE_APP_VERSION == 'standard'
import VueRouter from 'vue-router'
import vm from '../../main.js'
import { publicRoute, protectedRoute } from './config'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
const routes = publicRoute.concat(protectedRoute)
let buriedPointStartTime = new Date().getTime()
import Cookie from 'js-cookie'
if (!isStandard) {
  Vue.use(VueRouter)
}
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const router = new VueRouter({
  mode: 'hash',
  linkActiveClass: 'active',
  routes: routes
})
// router gards
router.beforeEach((to, from, next) => {
  //auth route is authenticated
  const jwt = Cookie.get('jwt')
  // 重置组件缓存
  let toDepth = to.path.split('/').length
  let fromDepth = from.path.split('/').length
  // if (toDepth <= fromDepth) {
  //   from.meta.keepAlive = false
  //   to.meta.keepAlive = true
  // }
  vm && vm.$root.eventHub.$emit('routeData', { to, from })
  // this.$eventBus.$emit('routeData', { to, from })
  if (
    to.path == '/login' ||
    to.path == '/forgotPwd' ||
    to.path == '/loginByAdmin' ||
    to.path == '/main/iframeTest' ||
    // 三方跳转登录路由
    to.path == '/thirdJumpLink' ||
    to.path == '/ReportDataLedger' ||
    to.path == '/ImportDataLedger'
  ) {
    NProgress.start()
    if (jwt && to.path == '/login') next('/main/welcome')
    else next()
  } else {
    if (jwt) {
      localStorage.setItem('pageButtonAuth', JSON.stringify(Util.getPageButtonAuth(to.meta)))
      NProgress.start()
      if (to.path !== '/404') {
        // 页面权限
        // 模式二特殊处理
        if (Util.isPageAuth(to.meta) || store.getters.menu_mode == 2) {
          next()
        } else {
          // next('/403')
          next()
        }
      } else {
        next()
      }
    } else {
      next({ path: '/login', query: to.query })
    }
  }
  // NProgress.start()
  //     next()
})

router.afterEach((to, from) => {
  if (from.path != '/' && from.path != '/login') {
    vm.$root.eventHub.$emit('eventBuriedPoint', {
      route: from.path,
      name: from.meta.name,
      module: from.meta.name,
      startTime: buriedPointStartTime,
      endTime: new Date().getTime()
    })
    buriedPointStartTime = new Date().getTime()
  }

  NProgress.done()
})

export default router
