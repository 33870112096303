/*
 * @Description: 环境变量
 * @Version: 1.0.0
 * @Author: 赵亮
 * @Date: 2021-10-20 19:29:42
 * @LastEditors: 赵亮
 * @LastEditTime: 2021-10-21 09:33:56
 */
const configArr = ['dev', 'test', 'gray', 'presentation', 'production']
export default configArr
