/*
 * @Description:隐患排查页面路由
 * @Version: 1.0.0
 * @Author: 杜凡
 * @Date: 2022-03-29 10:21:33
 * @LastEditors: 李晨光
 * @LastEditTime: 2025-03-12 14:54:48
 */
import routerName from '../config/routerName.config'

const hiddenCheckRoute = [
  // 检查任务管理=============================================
  {
    //检查任务
    path: '/hiddenDangerInvestigation/inspectionTask/InspectionTask',
    name: 'InspectionTask',
    component: () =>
      import('$safety/views/hiddenDangerInvestigation/inspectionTask/InspectionTask'),
    meta: {
      name: routerName('InspectionTask'),
      isAuth: true,
      keepAlive: true,
      authCode: 'web_pm_safety_hiddenDanger_checkTask'
    }
  },
  {
    //上级检查新增
    path: '/hiddenDangerInvestigation/inspectionTask/addSuperiorInspectionTask',
    name: 'AddSuperiorInspectionTask',
    component: () =>
      import('$safety/views/hiddenDangerInvestigation/inspectionTask/AddSuperiorInspectionTask'),
    meta: {
      name: '新增',
      isAuth: true,
      id: '',
      authCode: 'web_pm_safety_hiddenDanger_checkTask_superior_add'
    }
  },
  {
    //上级检查编辑
    path: '/hiddenDangerInvestigation/inspectionTask/editSuperiorInspectionTask',
    name: 'EditSuperiorInspectionTask',
    component: () =>
      import('$safety/views/hiddenDangerInvestigation/inspectionTask/AddSuperiorInspectionTask'),
    meta: {
      name: '编辑',
      isAuth: true,
      authCode: 'web_pm_safety_hiddenDanger_checkTask_superior_edit'
    }
  },
  {
    //专项检查任务新增
    path: '/hiddenDangerInvestigation/inspectionTask/addSpecSupInspectionTask',
    name: 'AddSpecSupInspectionTask',
    component: () =>
      import('$safety/views/hiddenDangerInvestigation/inspectionTask/AddSpecSupInspectionTask'),
    meta: {
      name: '新增',
      isAuth: true,
      id: '',
      authCode: 'web_pm_safety_hiddenDanger_checkTask_specSup_add'
    }
  },
  {
    //专项检查任务编辑
    path: '/hiddenDangerInvestigation/inspectionTask/editSpecSupInspectionTask',
    name: 'EditSpecSupInspectionTask',
    component: () =>
      import('$safety/views/hiddenDangerInvestigation/inspectionTask/AddSpecSupInspectionTask'),
    meta: {
      name: '编辑',
      isAuth: true,
      authCode: 'web_pm_safety_hiddenDanger_checkTask_specSup_edit'
    }
  },
  {
    //检查任务详情周
    path: '/hiddenDangerInvestigation/inspectionTask/detailInspectionTaskWeek',
    name: 'DetailInspectionTaskWeek',
    component: () =>
      import('$safety/views/hiddenDangerInvestigation/inspectionTask/DetailInspectionTask'),
    meta: {
      name: '详情',
      isAuth: true,
      authCode: 'web_pm_safety_hiddenDanger_checkTask_week_detail'
    }
  },
  {
    //检查任务详情月
    path: '/hiddenDangerInvestigation/inspectionTask/detailInspectionTaskMonth',
    name: 'DetailInspectionTaskMonth',
    component: () =>
      import('$safety/views/hiddenDangerInvestigation/inspectionTask/DetailInspectionTask'),
    meta: {
      name: '详情',
      isAuth: true,
      authCode: 'web_pm_safety_hiddenDanger_checkTask_month_detail'
    }
  },
  {
    //检查任务详情领导带班
    path: '/hiddenDangerInvestigation/inspectionTask/detailInspectionTaskLeaderLed',
    name: 'DetailInspectionTaskLeaderLed',
    component: () =>
      import('$safety/views/hiddenDangerInvestigation/inspectionTask/DetailInspectionTask'),
    meta: {
      name: '详情',
      isAuth: true,
      authCode: 'web_pm_safety_hiddenDanger_checkTask_leaderLed_detail'
    }
  },
  {
    //检查任务详情专项检查
    path: '/hiddenDangerInvestigation/inspectionTask/detailSpecialTask',
    name: 'DetailSpecialTask',
    component: () =>
      import('$safety/views/hiddenDangerInvestigation/inspectionTask/DetailSpecialTask'),
    meta: {
      name: '详情',
      isAuth: true,
      authCode: 'web_pm_safety_hiddenDanger_checkTask_special_detail'
    }
  },
  {
    //检查任务详情专项检查任务
    path: '/hiddenDangerInvestigation/inspectionTask/detailSpecSupTask',
    name: 'DetailSpecSupTask',
    component: () =>
      import('$safety/views/hiddenDangerInvestigation/inspectionTask/DetailSpecSupTask'),
    meta: {
      name: '详情',
      isAuth: true,
      authCode: 'web_pm_safety_hiddenDanger_checkTask_specSup_detail'
    }
  },
  {
    //检查任务详情上级检查
    path: '/hiddenDangerInvestigation/inspectionTask/detailSuperiorInspectionTask',
    name: 'DetailSuperiorInspectionTask',
    component: () =>
      import('$safety/views/hiddenDangerInvestigation/inspectionTask/DetailSuperiorInspectionTask'),
    meta: {
      name: '详情',
      isAuth: true,
      authCode: 'web_pm_safety_hiddenDanger_checkTask_superior_detail'
    }
  },
  {
    //检查任务详情上级-子详情
    path: '/hiddenDangerInvestigation/inspectionTask/detailInspectionTask',
    name: 'DetailInspectionTask',
    component: () =>
      import('$safety/views/hiddenDangerInvestigation/inspectionTask/DetailInspectionTask'),
    meta: {
      name: '详情',
      isAuth: false,
      authCode: 'web_pm_safety_hiddenDanger_checkTask'
    }
  },
  {
    //检查任务详情重大危险源监管
    path: '/hiddenDangerInvestigation/inspectionTask/detailMajorRegulation',
    name: 'DetailMajorRegulation',
    component: () =>
      import('$safety/views/hiddenDangerInvestigation/inspectionTask/DetailMajorRegulation'),
    meta: {
      name: '详情',
      isAuth: true,
      authCode: 'web_pm_safety_hiddenDanger_checkTask_hazard_detail'
    }
  },

  // 检查记录=============================================
  {
    //检查记录
    path: '/hiddenDangerInvestigation/inspectionRecord/InspectionRecord',
    name: 'InspectionRecord',
    component: () =>
      import('$safety/views/hiddenDangerInvestigation/inspectionRecord/InspectionRecord'),
    meta: {
      name: routerName('InspectionRecord'),
      isAuth: true,
      keepAlive: true,
      authCode: 'web_pm_safety_hiddenDanger_checkAcount'
    }
  },
  {
    //检查记录详情日常
    path: '/hiddenDangerInvestigation/inspectionRecord/dailyDetailInspectionRecord',
    name: 'DailyDetailInspectionRecord',
    component: () =>
      import('$safety/views/hiddenDangerInvestigation/inspectionRecord/DetailInspectionRecord'),
    meta: {
      name: '详情',
      isAuth: true,
      authCode: 'web_pm_safety_hiddenDanger_checkAcount_daily_detail'
    }
  },
  {
    //检查记录详情公司日常
    path: '/hiddenDangerInvestigation/inspectionRecord/dailySupDetailInspectionRecord',
    name: 'SupDailyDetailInspectionRecord',
    component: () =>
      import('$safety/views/hiddenDangerInvestigation/inspectionRecord/DetailInspectionRecord'),
    meta: {
      name: '详情',
      isAuth: true,
      authCode: 'web_pm_safety_hiddenDanger_checkAcount_dailySup_detail'
    }
  },
  {
    //检查记录详情基坑
    path: '/hiddenDangerInvestigation/inspectionRecord/pitDailyDetailInspectionRecord',
    name: 'PitDailyDetailInspectionRecord',
    component: () =>
      import('$safety/views/hiddenDangerInvestigation/inspectionRecord/DetailInspectionRecord'),
    meta: {
      name: '详情',
      isAuth: true,
      authCode: 'web_base_monitoringData_deepPit_daily_detail'
    }
  },
  {
    //检查记录详情周
    path: '/hiddenDangerInvestigation/inspectionRecord/weekDetailInspectionRecord',
    name: 'WeekDetailInspectionRecord',
    component: () =>
      import('$safety/views/hiddenDangerInvestigation/inspectionRecord/DetailInspectionRecord'),
    meta: {
      name: '详情',
      isAuth: true,
      authCode: 'web_pm_safety_hiddenDanger_checkAcount_week_detail'
    }
  },
  {
    //检查记录详情月
    path: '/hiddenDangerInvestigation/inspectionRecord/monthDetailInspectionRecord',
    name: 'MonthDetailInspectionRecord',
    component: () =>
      import('$safety/views/hiddenDangerInvestigation/inspectionRecord/DetailInspectionRecord'),
    meta: {
      name: '详情',
      isAuth: true,
      authCode: 'web_pm_safety_hiddenDanger_checkAcount_month_detail'
    }
  },
  {
    //检查记录详情领导带班
    path: '/hiddenDangerInvestigation/inspectionRecord/leaderLedDetailInspectionRecord',
    name: 'LeaderLedDetailInspectionRecord',
    component: () =>
      import('$safety/views/hiddenDangerInvestigation/inspectionRecord/DetailInspectionRecord'),
    meta: {
      name: '详情',
      isAuth: true,
      authCode: 'web_pm_safety_hiddenDanger_checkAcount_leaderLed_detail'
    }
  },
  {
    //检查记录详情专项
    path: '/hiddenDangerInvestigation/inspectionRecord/specialDetailInspectionRecord',
    name: 'SpecialDetailInspectionRecord',
    component: () =>
      import('$safety/views/hiddenDangerInvestigation/inspectionRecord/DetailInspectionRecord'),
    meta: {
      name: '详情',
      isAuth: true,
      authCode: 'web_pm_safety_hiddenDanger_checkAcount_special_detail'
    }
  },
  {
    //检查记录详情专项任务
    path: '/hiddenDangerInvestigation/inspectionRecord/specSupDetailInspectionRecord',
    name: 'SpecSupDetailInspectionRecord',
    component: () =>
      import('$safety/views/hiddenDangerInvestigation/inspectionRecord/DetailInspectionRecord'),
    meta: {
      name: '详情',
      isAuth: true,
      authCode: 'web_pm_safety_hiddenDanger_checkAcount_specSup_detail'
    }
  },
  {
    //检查记录详情 任务跳转
    path: '/hiddenDangerInvestigation/inspectionRecord/detailInspectionRecord',
    name: 'DetailInspectionRecord',
    component: () =>
      import('$safety/views/hiddenDangerInvestigation/inspectionRecord/DetailInspectionRecord'),
    meta: {
      name: '详情',
      isAuth: false,
      authCode: 'web_pm_safety'
    }
  },
  {
    //检查记录详情重大危险源
    path: '/hiddenDangerInvestigation/inspectionRecord/majorDetailInspectionRecord',
    name: 'MajorDailyDetailInspectionRecord',
    component: () =>
      import('$safety/views/hiddenDangerInvestigation/inspectionRecord/DetailInspectionRecord'),
    meta: {
      name: '详情',
      isAuth: true,
      authCode: 'web_pm_safety_hiddenDanger_checkAcount_major_detail'
    }
  },
  {
    //检查记录详情上级
    path: '/hiddenDangerInvestigation/inspectionRecord/superiorDetailInspectionRecord',
    name: 'SuperiorDetailInspectionRecord',
    component: () =>
      import('$safety/views/hiddenDangerInvestigation/inspectionRecord/DetailInspectionRecord'),
    meta: {
      name: '详情',
      isAuth: true,
      authCode: 'web_pm_safety_hiddenDanger_checkAcount_superior_detail'
    }
  },

  // 隐患台账=============================================
  {
    //隐患台账 做缓存
    path: '/hiddenDangerInvestigation/hiddenDangerAccount/hiddenDangerAccount',
    name: 'HiddenDangerAccount',
    component: () =>
      import('$safety/views/hiddenDangerInvestigation/hiddenDangerAccount/HiddenDangerAccount'),
    meta: {
      name: '隐患台账',
      isAuth: true,
      keepAlive: true, //此组件需要被缓存
      authCode: 'web_pm_safety_hiddenDanger_hiddenAccount'
    }
  },
  {
    //隐患台账详情
    path: '/hiddenDangerInvestigation/hiddenDangerAccount/hiddenDangerDetails',
    name: 'HiddenDangerDetails',
    component: () =>
      import('$safety/views/hiddenDangerInvestigation/hiddenDangerAccount/HiddenDangerDetails'),
    meta: {
      name: '详情',
      isAuth: true,
      authCode: 'web_pm_safety_hiddenDanger_hiddenAccount_detail'
    }
  },

  // 表单管理=============================================
  {
    //表单管理
    path: '/hiddenDangerInvestigation/formManagement/formManagement',
    name: 'FormManagement',
    component: () =>
      import('$safety/views/hiddenDangerInvestigation/formManagement/FormManagement'),
    meta: {
      name: routerName('FormManagement'),
      isAuth: true,
      authCode: 'web_pm_safety_hiddenDanger_form'
    }
  },
  {
    //公司随机检查记录
    path: '/hiddenDangerInvestigation/check-record/company',
    name: 'InspectRecord',
    component: () =>
      import('$safety/views/hiddenDangerInvestigation/inspectionRecordCompany/InspectRecord'),
    meta: {
      name: '公司随机检查台账',
      isAuth: true,
      keepAlive: true, //此组件需要被缓存
      authCode: 'web_pm_safety_hiddenDanger_checkRecordCompany'
    }
  },
  {
    //公司随机检查记录详情
    path: '/hiddenDangerInvestigation/check-record/company/detail',
    name: 'InspectRecordDetail',
    component: () =>
      import('$safety/views/hiddenDangerInvestigation/inspectionRecordCompany/InspectRecordDetail'),
    meta: {
      name: '详情',
      isAuth: true,
      authCode: 'web_pm_safety_hiddenDanger_checkRecordCompany_detail'
    }
  },
  {
    //公司隐患台账
    path: '/hiddenDangerInvestigation/hidden-danger/company',
    name: 'HiddenDanger',
    component: () =>
      import('$safety/views/hiddenDangerInvestigation/hiddenDangerCompany/HiddenDangerAccount'),
    meta: {
      name: '公司随机检查隐患',
      isAuth: true,
      keepAlive: true, //此组件需要被缓存
      authCode: 'web_pm_safety_hiddenDanger_hiddenDangerCompany'
    }
  },
  {
    //公司隐患台账详情
    path: '/hiddenDangerInvestigation/hidden-danger/company/detail',
    name: 'HiddenDangerDetailCompany',
    component: () =>
      import('$safety/views/hiddenDangerInvestigation/hiddenDangerCompany/HiddenDangerDetails'),
    meta: {
      name: '详情',
      isAuth: true,
      authCode: 'web_pm_safety_hiddenDanger_hiddenDangerCompany_detail'
    }
  }
]

export default hiddenCheckRoute
