<!--
 * @Description: 头部
 * @Date: 2019-12-30 00:37:05
 * @LastEditors: 任笠
 * @LastEditTime: 2022-08-15 10:59:26
 -->
<template>
  <header class="header" v-text="title"></header>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: "表单设计器"
    }
  }
};
</script>
