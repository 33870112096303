/*
 * @Description: 自定义组件统一入口 [务必与运维端保持一致]
 * @Version: 1.0.0
 * @Author: 任笠
 * @Date: 2022-03-03 18:17:18
 * @LastEditors: 任笠
 * @LastEditTime: 2022-09-16 10:25:32
 */

// [参数务必与运维端保持一致]
import InternalUsersCom from '@/base/components/basicFormComponents/InternalUsersCom'
import OrgSelectCom from '@/base/components/basicFormComponents/OrgSelectCom'
import AppendixUpload from '@/base/components/basicFormComponents/AppendixUpload'

const customComponent = {
  title: '平台自定义组件',
  list: [
    // 用户筛选-自定义组件
    {
      type: 'customUser', // 组件类型
      label: '用户筛选', // 组件名称
      component: InternalUsersCom, // 组件
      icon: 'icon-gallery',
      list: [],
      options: {
        defaultValue: null,
        multiple: false, // 可选值
        disabled: false, // 可选值
        hidden: false,
        width: '100%',
        // 需要传入动态函数
        handle: 'submit',
        // 固定动态函数,用于绑定组件
        dynamicKey: 'customUserCmp',
        dynamic: true
      },
      model: '', // 可选值
      key: '',
      help: '',
      rules: [
        // 可选值
        {
          required: true,
          message: '必填项'
        }
      ]
    },
    // 组织筛选
    {
      type: 'customOrg', // 组件类型
      label: '组织筛选', // 组件名称
      component: OrgSelectCom, // 组件
      icon: 'icon-juxingkaobei',
      list: [],
      options: {
        defaultValue: null,
        // multiple: false, // 可选值
        disabled: false, // 可选值
        hidden: false,
        width: '80%',
        // 需要传入动态函数
        handle: 'submit',
        apiType: 1,
        // 固定动态函数
        dynamicKey: 'customOrgCmp',
        dynamic: true
      },
      model: '', // 可选值
      key: '',
      rules: [
        // 可选值
        {
          required: true,
          message: '必填项'
        }
      ]
    },
    // 上传组件-附件- oss
    {
      type: 'customUpload', // 组件类型
      label: '附件上传', // 组件名称
      component: AppendixUpload, // 组件
      icon: 'icon-upload',
      list: [],
      options: {
        defaultValue: '[]',
        multiple: false, // 可选值
        disabled: false, // 可选值
        hidden: false,
        width: '100%',
        // 固定动态函数
        dynamicKey: 'customUpload',
        dynamic: true,

        data: '{}',
        // 是否文件夹上传
        directory: false,
        accept: '',
        placeholder: '上传',
        limit: 3
      },
      model: '', // 可选值
      key: '',
      help: '',
      rules: [
        // 可选值
        {
          required: true,
          message: '必填项'
        }
      ]
    }
  ]
}
export default customComponent
