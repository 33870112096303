/*
 * @Description: index
 * @Version: 1.0.0
 * @Author: 李晨光
 * @Date: 2020-07-07 13:46:46
 * @LastEditors: 李晨光
 * @LastEditTime: 2020-11-20 17:32:36
 */
import Vue from 'vue'
import Vuex from 'vuex'
import state from './state.js'
import * as getters from './getters.js'
import mutations from './mutations.js'
import actions from './actions.js'
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)
const debug = process.env.NODE_ENV !== 'production' // 开发环境中为true，否则为false

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules: {},
  plugins: debug
    ? [createPersistedState({ key: 'bj_spm_main_test' })]
    : [createPersistedState({ key: 'bj_spm_main_test' })] // 开发环境下显示vuex的状态修改
  // plugins: debug ? [createLogger()] : [] // 开发环境下显示vuex的状态修改
  // plugins:[createPersistedState()]
})
