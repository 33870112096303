<!--
 * @Description:
 * @Version: 1.0.0
 * @Author: 李晨光
 * @Date: 2020-11-24 17:02:34
 * @LastEditors: 李帅
 * @LastEditTime: 2021-11-17 16:39:48
-->
<comment>
# 组件注释
</comment>
<template>
  <div id="Layout">
    <a-layout style="width: 100%; height: 100%">
      <hz-toolbar :is-show-menu="isShowMenu" @switchMenu="switchMenu"></hz-toolbar>
      <a-layout
        style="overflow: hidden; position: relative; height: calc(100 - 50px)"
        class="layout">
        <a-drawer
          placement="left"
          :closable="true"
          :visible="isShowMenu"
          :mask="true"
          :mask-closable="true"
          :get-container="false"
          :wrap-class-name="'menuDrawer'"
          :wrap-style="{ position: 'absolute' }"
          :body-style="{ height: '100%', padding: '0!important' }"
          @close="onClose">
          <drawer-menu></drawer-menu>
        </a-drawer>
        <hz-nav v-if="$route.path != '/main/developing'" v-model="collapsed" />
        <!-- @change="navChange" -->
        <a-layout style="height: 100%; overflow: hidden">
          <a-layout-content id="a-layout-content" class="a-layout-content">
            <div
              v-if="$route.path === '/main' || $route.path === '/main/'"
              class="d-flex justify-center">
              <div
                class="imgEle"
                :style="{
                  backgroundImage: `url(${require('$public/assets/image/visit/visit.png')})`
                }"></div>
            </div>
            <!-- 这里是会被缓存的视图组件，比如列表A页面 -->
            <keep-alive>
              <router-view v-if="$route.meta.keepAlive" />
            </keep-alive>
            <!-- 这里是不被缓存的视图组件，比如详情B页面-->
            <router-view v-if="!$route.meta.keepAlive" />
          </a-layout-content>
        </a-layout>
      </a-layout>
    </a-layout>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
import Nav from '$public/components/layouts/NavStandard'
import Toolbar from '$public/components/layouts/ToolbarStandard'
import drawerMenu from '$public/components/layouts/drawer/Index'
import { mapState } from 'vuex'

export default {
  name: 'Layout',
  //import引入的组件需要注入到对象中才能使用
  components: {
    'hz-nav': Nav,
    'hz-toolbar': Toolbar,
    'drawer-menu': drawerMenu
  },
  props: {},
  data() {
    //这里存放数据
    return { collapsed: true, subNavCollapsed: false, isShowMenu: false }
  },
  //监听属性 类似于data概念
  computed: {
    ...mapState({
      menu_mode: state => state.menu_mode
    })
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.$root.eventHub.$on('isShowDrawer', isShowMenu => {
      this.isShowMenu = isShowMenu
    })
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    switchMenu() {
      this.isShowMenu = !this.isShowMenu
    },
    onClose() {
      this.isShowMenu = false
    }
  },
  destroyed() {} //生命周期 - 销毁完成
}
</script>
<style lang="less">
#Layout {
  height: 100%;
  .imgEle {
    margin-top: 250px;
    width: 160px;
    height: 160px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
  }
  .a-layout-content {
    position: relative;
    height: 100%;
    overflow-x: auto;
    > div {
      height: 100%;
    }
  }
  .slide-fade-enter-active {
    transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
    transform-origin: top;
  }
  .slide-fade-leave-active {
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
    transform-origin: top;
  }
  .slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active for below version 2.1.8 */ {
    /*// transform: translateX(200px);*/
    /*transform: scale(0, 0.2);*/
    opacity: 0;
  }
  .menuDrawer {
    .ant-drawer-content-wrapper {
      width: auto !important;
    }
  }
}
</style>
