<!--
 * @Description:  面包屑导航
 * @Version: 1.0.0
 * @Author: 李晨光
 * @Date: 2020-09-08 10:37:06
 * @LastEditors: 任笠
 * @LastEditTime: 2023-02-27 18:16:08
-->

<comment>
  # 组件注释
  全局内容区header
  title 标题名
</comment>
<template>
  <div id="Title">
    <div class="flex title-card align-center">
      <div
        class="d-flex align-center left-title"
        :style="{ maxWidth: $slots.tabs ? '30%' : 'auto' }">
        <a-button v-if="back" class="m-r-16" @click="pageBack">
          <a-icon type="left" />
        </a-button>
        <a-tooltip>
          <template slot="title">
            <slot name="beforeTitle"></slot>
            {{ title }}
            <slot name="afterTitle"></slot>
          </template>
          <span style="font-size: 18px; font-weight: 600" class="m-r-25 publicTitle">
            <slot name="beforeTitle"></slot>
            {{ title }}
            <slot name="afterTitle"></slot>
          </span>
        </a-tooltip>
      </div>
      <slot name="tabs"></slot>
    </div>
    <slot></slot>
  </div>
</template>

<script>
const isStandard = process.env.VUE_APP_VERSION == 'standard'
export default {
  name: 'Title',
  components: {},
  props: {
    back: {
      type: Boolean,
      default: false
    },
    // 是否需要routerBack回调
    routerBack: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isStandard,
      routes: [] // 面包屑路由
    }
  },
  computed: {},
  created() {
    this.title = this.title || this.$route.meta.name
  },
  mounted() {},
  watch: {},
  methods: {
    pageBack() {
      if (this.routerBack) {
        this.$emit('routerBack')
      } else {
        this.$router.back()
      }
    }
  },
  destroyed() {}
}
</script>

<style lang="less">
#Title {
  position: relative;
  z-index: 1;
  background: #fff;
  .title {
    /*font-size:;*/
  }
  .back-btn {
    .primary_color();
    font-size: 24px;
    margin-right: 5px;
    cursor: pointer;
    line-height: 24px;
  }
  .title-card {
    min-height: 48px;
    padding: 0 24px;
  }
  .ant-tabs-bar {
    border: 0;
  }
  // 防止子元素margin 无法撑开父元素
  &:before,
  &:after {
    content: '';
    display: table;
  }
  .publicTitle {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
</style>
