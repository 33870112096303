/*
 * @Description:扣款管理,后端服务为劳务，故写到劳务文件夹下
 * @Version: 1.0.0
 * @Author: 杜凡
 * @Date: 2022-08-15 16:48:49
 * @LastEditors: 杜凡
 * @LastEditTime: 2024-05-17 10:40:26
 */
const deductionManage = [
  {
    path: '/deductionManage/deductionManage',
    name: 'DeductionManage',
    component: () => import('$labor/views/deductionManage/DeductionManage.vue'),
    meta: {
      name: '扣款管理',
      keepAlive: true, // 优化需求，该页面做缓存
      isAuth: true,
      authCode: 'web_pm_deductionManage_deductionManage'
    }
  },
  // 扣款管理确认页面
  {
    path: '/deductionManage/addDeduction',
    name: 'AddDeduction',
    component: () => import('$labor/views/deductionManage/AddDeduction.vue'),
    meta: {
      name: '确认',
      isAuth: true,
      authCode: 'web_pm_deductionManage_deductionManage_edit'
    }
  },
  {
    path: '/deductionManage/deductionList',
    name: 'DeductionList',
    component: () => import('$labor/views/deductionManage/DeductionList.vue'),
    meta: {
      name: '扣款台账',
      isAuth: true,
      authCode: 'web_pm_deductionManage_deductionList'
    }
  },
  {
    path: '/deductionManage/deductionSetting',
    name: 'DeductionSetting',
    component: () => import('$labor/views/deductionManage/DeductionSetting.vue'),
    meta: {
      name: '扣款设置',
      isAuth: true,
      authCode: 'web_base_finance_deductionSetting'
    }
  }
]

export default deductionManage
