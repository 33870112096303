/*
 * @Description: 安全
 * @Version: 1.0.0
 * @Author: 李晨光
 * @Date: 2020-11-02 11:23:34
 * @LastEditors: 王召强
 * @LastEditTime: 2024-04-01 10:39:29
 */
import HiddenCheckRoute from './hiddenCheck'
import riskClassificationRoute from './riskClassification'
import setUpRoute from './setUp'
import monitorRoute from './monitor'
import reportFillingRoute from './reportFilling'
import manageMenuRoute from './manageMenu'
import riskGradingControl from './riskGradingControl'
import responseAreaPlan from './responseAreaPlan'

export const protectedRoute = [
  {
    path: '/main/safety',
    name: 'Safety',
    component: () => import(/*/* : "安全页面入口" */ '$safety/views/Index'),
    children: [
      ...HiddenCheckRoute, //隐患排查模块路由
      ...riskClassificationRoute, //风险分级管控模块路由 危险源辨识、重大危险源监管
      ...setUpRoute, //安全管理业务设置模块路由
      ...monitorRoute, //监测类路由 基坑-高支模-边坡相关
      ...reportFillingRoute, //报表填报类业务路由
      ...manageMenuRoute, //管理流程类路由
      ...riskGradingControl,
      ...responseAreaPlan, //责任区域平面图

      {
        //安全管理首页-项目级
        path: '/safetyProjectBI',
        name: 'safetyProjectBI',
        meta: {
          name: '安全管理',
          isAuth: true,
          authCode: 'web_pm_safety_indexPro',
          id: ''
        },
        component: () => import('$safety/views/safetyBI/Project')
      },
      {
        //安全管理首页-企业级
        path: '/safetyComBI',
        name: 'SafetyComBI',
        meta: {
          name: '安全管理',
          isAuth: true,
          authCode: 'web_pm_safety_indexCom',
          id: ''
        },
        component: () => import('$safety/views/safetyBI/Company')
      }
    ]
  }
]
