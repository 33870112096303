/*
 * @Description: 
 * @Version: 1.0.0
 * @Author: 王召强
 * @Date: 2024-04-01 10:37:56
 * @LastEditors: 申文慧
 * @LastEditTime: 2024-04-18 16:14:44
 */
const riskGradingControl = [
  {
    path: '/main/safety/riskGradingControl/hazardReporting',
    name: 'HazardReporting',
    meta: {
      name: '危险源上报管理',
      isAuth: true,
      authCode: 'web_pm_safety_riskGradingControl_HazardReporting',
    },
    component: () =>
      import(
        '$safety/views/riskGradingControl/HazardReporting'
      ),
  },
  {
    path: '/main/safety/riskGradingControl/hazardReporting/add',
    name: 'HazardReportingAdd',
    meta: {
      name: '新增',
      isAuth: false,
      authCode: 'web_pm_safety_riskGradingControl_HazardReporting',
    },
    component: () =>
      import(
        '$safety/views/riskGradingControl/HazardReportingAdd'
      ),
  },
  {
    path: '/main/safety/riskGradingControl/hazardReporting/edit',
    name: 'HazardReportingEdit',
    meta: {
      name: '编辑',
      isAuth: false,
      authCode: 'web_pm_safety_riskGradingControl_HazardReporting',
    },
    component: () =>
      import(
        '$safety/views/riskGradingControl/HazardReportingEdit'
      ),
  },
  {
    path: '/main/safety/riskGradingControl/hazardReporting/approval',
    name: 'HazardReportingApproval',
    meta: {
      name: '审批',
      isAuth: false,
      authCode: 'web_pm_safety_riskGradingControl_HazardReporting',
    },
    component: () =>
      import(
        '$safety/views/riskGradingControl/HazardReportingApproval'
      ),
  },
  {
    path: '/main/safety/riskGradingControl/hazardReporting/details',
    name: 'HazardReportingDetails',
    meta: {
      name: '详情',
      isAuth: false,
      authCode: 'web_pm_safety_riskGradingControl_HazardReporting',
    },
    component: () =>
      import(
        '$safety/views/riskGradingControl/HazardReportingDetails'
      ),
  },
  {
    path: '/main/safety/riskGradingControl/hazardousSourcesList',
    name: 'HazardousSourcesList',
    meta: {
      name: '危险源清单',
      isAuth: true,
      authCode: 'web_pm_safety_riskGradingControl_hazardousSourcesList',
    },
    component: () =>
      import(
        '$safety/views/riskGradingControl/HazardousSourcesList'
      ),
  },
  {
    path: '/main/safety/riskGradingControl/majorHazardSources',
    name: 'MajorHazardSources',
    meta: {
      name: '重大危险源管理',
      isAuth: true,
      authCode: 'web_pm_safety_riskGradingControl_majorHazardSources',
    },
    component: () => import(
      '$safety/views/riskGradingControl/MajorHazardSources'
    ),

  },
  {
    path: '/main/safety/riskGradingControl/majorHazardSources/deatilMajorHazardSources',
    name: 'DeatilMajorHazardSources',
    meta: {
      name: '详情',
      isAuth: false,
      authCode: 'web_pm_safety_riskGradingControl_majorHazardSources_detail',
    },
    component: () =>
      import(
        '$safety/views/riskGradingControl/DeatilMajorHazardSources'
      ),
  },
  {
    path: '/main/safety/riskGradingControl/settings',
    name: 'RiskGradingControlSettings',
    meta: {
      name: '风险分级管控',
      isAuth: true,
      authCode: 'web_base_riskGradingControl',
    },
    component: () =>
      import(
        '$safety/views/riskGradingControl/RiskGradingControlSettings'
      ),
  },
]

export default riskGradingControl
