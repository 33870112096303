<!--
 * @Description:
 * @Version: 1.0.0
 * @Author: 李帅
 * @Date: 2021-11-13 15:29:56
 * @LastEditors: 李帅
 * @LastEditTime: 2021-11-13 15:49:54
-->
<comment>
# 菜单
</comment>
<template>
  <div id="Drawer">
    <left></left>
    <right></right>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import Left from './left/Index'
import Right from './right/Index'
export default {
  name: 'Drawer',
  //import引入的组件需要注入到对象中才能使用
  components: { left: Left, right: Right },
  props: {},
  data() {
    //这里存放数据
    return {}
  },
  //监听属性 类似于data概念
  computed: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {},
  destroyed() {} //生命周期 - 销毁完成
}
</script>
<style lang="less">
#Drawer {
  background-color: #fff;
  display: flex;
  height: 100%;
}
</style>
