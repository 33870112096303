/*
 * @Descripttion:
 * @Version: 1.0.0
 * @Author: 李晨光
 * @Date: 2020-07-06 15:07:36
 * @LastEditors: 李晨光
 * @LastEditTime: 2020-07-06 15:10:02
 */
import ThemeColorSetting from './ThemeColorSetting'
export default ThemeColorSetting
