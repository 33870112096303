<!--
 * @Description:
 * @Version: 1.0.0
 * @Author: 李晨光
 * @Date: 2020-07-04 11:17:02
 * @LastEditors: 王召强
 * @LastEditTime: 2024-05-31 10:20:04
-->
<comment>
# 组件注释
</comment>
<template>
  <div id="app">
    <a-config-provider :locale="locale">
      <!-- isRouterAlive 保证keep-alive时，不影响全局刷新 -->
      <div v-if="isRouterAlive"
           id="appContainer">
        <transition name="slide-fade"
                    mode="out-in">
          <router-view />
        </transition>
      </div>
    </a-config-provider>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import ThemeColorSetting from '$public/components/setting/themeColor/index'
import { mapState } from 'vuex'
import config from '$public/components/setting/themeColor/defaultSettings'
import { updateTheme } from '$public/components/setting/themeColor/settingConfig'
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
import Cookie from 'js-cookie'
import Scroll from '@/public/assets/js/scroll'
import debounce from 'lodash.debounce'
import throttle from 'lodash.throttle'
export default {
  name: '',
  //import引入的组件需要注入到对象中才能使用
  components: { ThemeColorSetting },
  props: {},
  provide() {
    return {
      reload: this.reload
    }
  },
  data() {
    //这里存放数据
    return {
      baseConfig: Object.assign({}, config),
      isRouterAlive: true,
      locale: zhCN,
      api: {
        getDictApi: '/web/dictionary/content/init-data', // 获取系统字典
        // 第三方集成单点登录通过token获取登录信息
        thirdAuthenticationApi: '/web/passport/user/third-user-login-info',
        getResourceApi: '/web/role-appInfo/list', // 获取资源
        setBuriedPoint: Util.baseUrlPlatform + '/api/web/analysis-data/add' // 埋点设置
      },
      timer: null
    }
  },
  //监听属性 类似于data概念
  computed: {
    ...mapState({
      primaryColor: state => state.color,
      tenantId: state => state.curr_tenant,
      login_msg: state => state.login_msg,
      curr_org: state => state.curr_org
    })
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    if (this.primaryColor !== config.primaryColor || true) {
      this.isRouterAlive = true
      updateTheme(this.primaryColor, () => {
        if (document.getElementById('loading-loader')) {
          // console.log('我加载完了')
          document.body.removeChild(document.getElementById('loading-loader'))
          //this.getDictAll();
        }
      })
    }
    // 三方鉴权
    this.tokenAuthentication()
    this.eventBuriedPoint()
    Util.hostSet()
    console.log(hostConfig, 'hostConfig')
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    // this.setMark()
    // window.addEventListener('resize', () => {
    //   console.log('resize')
    //   this.setMark()
    // })
    if (document.getElementById('loading-loader')) {
      // console.log('我加载完了')
      document.body.removeChild(document.getElementById('loading-loader'))
      //this.getDictAll();
      this.$root.eventHub.$on('bussChange', () => {
        // 请求业务字典数据
        this.getBussData()
      })
    }
    //监听localStorage变化
    window.addEventListener('storage', e => {
      if (e.key === 'resources') {
        window.location.reload()
      }
    })
    // 监听dom变化
    this.getListenerDom()
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 埋点方法 跳转路由时调用，只针对页面级
    eventBuriedPoint() {
      let sys = Util.getBrowserInfo() // 获取浏览器名称及版本号
      this.$root.eventHub.$on('eventBuriedPoint', data => {
        this.$post(this.api.setBuriedPoint, {
          tenantId: this.tenantId,
          userId: this.login_msg.userId,
          orgId: this.curr_org.id,
          operateType: 'normal', // 统计非页面埋点，该字段需要和后端商定
          scope: 'web', // app web bi
          brandName: sys.browser, //浏览器名称
          deviceModel: sys.version, //浏览器版本号
          ...data
        })
          .then(response => {
            // Util.processRes(response, () => {})
            Util.processError(this, response)
          })
          .catch(res => {
            console.log(res)
          })
      })
    },
    getListenerDom() {
      const targetNode = document.body
      // 观察器的配置（需要观察什么变动）
      const config = {
        attributes: false,
        childList: true,
        subtree: true
      }
      // 当观察到变动时执行的回调函数
      const callback = throttle(function (mutationsList, observer) {
        for (let mutation of mutationsList) {
          // if (mutation.type === 'childList') {
          if (
            mutation.target.className !== 'ant-table-tbody' &&
            mutation.target.className !== 'vxe-header--row' &&
            // 甘特图滚动卡顿修复
            !mutation.target.className.includes('gantt_') &&
            mutation.target.className
          ) {
            // console.log(
            //   '有节点发生改变，当前节点的内容是：' + mutation.target.className
            // )
            if (document.querySelector('#app .ant-table-body'))
              new Scroll({ el: '#app .ant-table-body', type: 'page' })
            if (document.querySelector('.ant-modal-body .ant-table-body'))
              new Scroll({
                el: '.ant-modal-body .ant-table-body',
                type: 'modal'
              })
          }
          // }
        }
      }, 100)
      // 创建一个观察器实例并传入回调函数
      const observer = new MutationObserver(callback)
      // 以上述配置开始观察目标节点
      observer.observe(targetNode, config)
      // 之后，可停止观察
      // observer.disconnect();
    },
    setMark() {
      this.$nextTick(() => {
        const element = document.getElementById('app')
        this.watermark(element)
      })
    },
    watermark(element, config) {
      let marks = document.querySelectorAll('.watermark-item')
      marks.length &&
        marks.forEach(mark => {
          mark.remove()
        })
      // 获取元素的坐标
      function getOffset(el) {
        if (el.offsetParent) {
          return {
            x: el.offsetLeft + getOffset(el.offsetParent).x,
            y: el.offsetTop + getOffset(el.offsetParent).y
          }
        }
        return {
          x: el.offsetLeft,
          y: el.offsetTop
        }
      }
      if (!element) return
      // 默认配置
      const _config = {
        text1: '项管家', //文本1
        text2: '华筑科技', // 文本2
        start_x: 100, // x轴起始位置
        start_y: 0, // y轴起始位置
        space_x: 100, // x轴间距
        space_y: 50, // y轴间距
        width: 210, // 宽度
        height: 80, // 长度
        fontSize: 12, // 字体
        color: '#aaa', // 字色
        alpha: 0.2, // 透明度
        rotate: 15 // 倾斜度
      }
      // 替换默认配置
      if (arguments.length === 2 && typeof arguments[1] === 'object') {
        const src = arguments[1] || {}
        for (let key in src) {
          if (src[key] && _config[key] && src[key] === _config[key]) {
            continue
          } else if (src[key]) {
            _config[key] = src[key]
          }
        }
      }
      // 节点的总宽度
      const total_width = element.clientWidth
      // 节点的总高度
      const total_height = element.clientHeight
      // 创建文本碎片，用于包含所有的插入节点
      const mark = document.createDocumentFragment()
      // 水印节点的起始坐标
      const position = getOffset(element)
      let x = position.x + _config.start_x,
        y = position.y + _config.start_y
      // 先循环y轴插入水印
      do {
        // 再循环x轴插入水印
        do {
          // 创建单个水印节点
          const item = document.createElement('div')
          item.className = 'watermark-item'
          // 设置节点的样式
          item.style.position = 'absolute'
          item.style.zIndex = 99999
          item.style.left = `${x}px`
          item.style.top = `${y}px`
          item.style.width = `${_config.width}px`
          item.style.height = `${_config.height}px`
          item.style.fontSize = `${_config.fontSize}px`
          item.style.color = _config.color
          item.style.textAlign = 'center'
          item.style.opacity = _config.alpha
          item.style.filter = `alpha(opacity=${_config.alpha * 100})`
          // item.style.filter = `opacity(${_config.alpha * 100}%)`;
          item.style.webkitTransform = `rotate(-${_config.rotate}deg)`
          item.style.MozTransform = `rotate(-${_config.rotate}deg)`
          item.style.msTransform = `rotate(-${_config.rotate}deg)`
          item.style.OTransform = `rotate(-${_config.rotate}deg)`
          item.style.transform = `rotate(-${_config.rotate}deg)`
          item.style.pointerEvents = 'none' //让水印不遮挡页面的点击事件
          // 创建text1水印节点
          const text1 = document.createElement('div')
          text1.appendChild(document.createTextNode(_config.text1))
          item.append(text1)
          // 创建text2水印节点
          const text2 = document.createElement('div')
          text2.appendChild(document.createTextNode(_config.text2))
          item.append(text2)
          // 添加水印节点到文本碎片
          mark.append(item)
          // x坐标递增
          x = x + _config.width + _config.space_x
          // 超出文本右侧坐标停止插入
        } while (total_width + position.x > x + _config.width)
        // 重置x初始坐标
        x = position.x + _config.start_x
        // y坐标递增
        y = y + _config.height + _config.space_y
        // 超出文本底部坐标停止插入
      } while (total_height + position.y > y + _config.height)
      // 插入文档碎片
      element.append(mark)
    },
    // 全局刷新
    reload() {
      this.isRouterAlive = false
      this.$nextTick(() => {
        this.isRouterAlive = true
      })
    },
    // 获取业务字典
    getBussData() {
      this.$post(this.api.getDictApi, { tenantId: this.tenantId })
        .then(response => {
          Util.processRes(response, () => {
            if (response.data) {
              // this.dicts[key] = response.data;
              this.$store.commit('SET_DICTS', response.data)
              // this.setDicts(this.dicts);
            }
          })
        })
        .catch(res => {
          console.log(res)
        })
    },
    getParametes() {
      var r = window.location.href,
        str = ''
      if (r.indexOf('?' > -1)) {
        str = r.substr(r.indexOf('?') + 1)
      }
      if (str.indexOf('#') > -1) {
        str = str.substring(0, str.indexOf('#'))
      }
      let arr = str.split('&'),
        quer = {}
      for (let i = 0; i < arr.length; i++) {
        let now = arr[i].split('=')
        quer[now[0]] = now[1]
      }
      return quer
    },
    tokenAuthentication() {
      // Cookie.remove('token')
      // Cookie.remove('jwt')
      console.log('权限校验')
      let { token, clientId, clientType, tripartiteName } = this.getParametes()
      Cookie.set('token', token, { expires: 7 })
      clientId && Cookie.set('clientId', clientId, { expires: 7 })
      clientType && Cookie.set('clientType', clientType, { expires: 7 })
      localStorage.setItem('token', token)
      clientId && localStorage.setItem('clientId', clientId)
      clientType && localStorage.setItem('clientType', clientType)

      //第三方跳转过来的项目名称
      if (tripartiteName) {
        sessionStorage.setItem('tripartiteName', decodeURIComponent(tripartiteName))
        Util.hostSet()
      }

      let _api = ''
      let _params = {}
      if (clientType && clientType == 'ossLogin') {
        if (clientId) {
          _api = this.api.thirdAuthenticationApi
          _params = { token, clientId }
        } else {
          return
        }
      } else {
        return
      }
      if (!token) return
      this.$get(_api, _params)
        .then(response => {
          Util.processRes(response, () => {
            // 逻辑处理 同登录
            this.thirdLogin(response)
          })
          Util.processError(this, response)
        })
        .catch(res => {
          console.log(res)
        })
    },
    thirdLogin(response) {
      Cookie.set('isPlatform', 'no')
      // Cookie.set('jwt', 'Bearer ' + response.data.tokenVo.accessToken, {expires: 7})

      Cookie.set('jwt', response.data.tokenVo.accessToken, { expires: 7 })
      Cookie.set('simpleToken', response.data.tokenVo.simpleToken, {
        expires: 7
      })
      Cookie.set(
        'sessions',
        JSON.stringify({
          passportId: response.data.userMemberVo.passportId
        })
      )
      this.$store.commit('SET_LOGINMSG', response.data.userMemberVo)
      // 参建单位信息引入
      if (response.data.userMemberVo.userType === 2) {
        this.$store.commit('SET_CONTRACTOR_INFO', response.data.contractorInfo)
      }
      this.$store.commit('SET_LOGO', response.data.tenantInfoVo.logoPic || '')
      this.$store.commit('SET_MENU_MODE', response.data.tenantInfoVo.menuMode || 1)
      this.$store.commit('SET_CURR_TENANT', response.data.userMemberVo.tenantId)
      this.$store.commit('SET_CURR_TENANT_CODE', response.data.userMemberVo.personNo)
      this.$store.commit('SET_CURR_ORG', {
        name: response.data.userMemberVo.orgName,
        shortName: response.data.userMemberVo.orgShortName,
        id: response.data.userMemberVo.changeOrgId,
        type: response.data.userMemberVo.orgType,
        orgType: response.data.userMemberVo.orgType,
        code: response.data.userMemberVo.orgCode,
        pid: response.data.userMemberVo.pid
      })
      // this.getCurrOrg(response.data.userMemberVo.tenantId,response.data.userMemberVo.changeOrgId,response.data.userMemberVo.name)
      this.getResource(
        response.data.userMemberVo.tenantId,
        response.data.userMemberVo.id,
        response.data.userMemberVo.orgType,
        response.data.tenantInfoVo.menuMode
      )
      // this.getHasAuthButton(response.data.userMemberVo.tenantId)
      this.getDictAll(response.data.userMemberVo.tenantId)
    },
    // 层级权限相关 初次登录权限控制层级
    setResource(curr, menuMode) {
      // 切换层级时判断层级权限过滤数据
      let originalResources = JSON.parse(localStorage.getItem('resources')) // 原始菜单数据
      // 储存原始数据，用于切换组织时获取
      localStorage.setItem(
        'resources',
        JSON.stringify(
          this.resetTreeData(
            Util.resetTree(originalResources), // 去掉菜单中的查看
            curr.orgType
          )
        )
      )
      this.$store.commit(
        'SET_RESOURCES',
        this.resetTreeData(
          Util.resetTree(originalResources), // 去掉菜单中的查看
          curr.orgType
        )
      )
      if (menuMode === 2) {
        this.$router.push('/main')
        this.$store.commit('SET_OPENKEYS', [])
        this.$emit('change', false)
      }
    },
    // 如需修改 请同步修改 OrgTree.vue, App.vue中的方法
    resetTreeData(treeData, orgType) {
      let setTree = list => {
        list &&
          list.length &&
          list.forEach((item, i) => {
            // item.loading = true
            if (item.children && item.children.length) {
              setTree(item.children)
            }
            // if (item.children && !item.children.length && !item.uri) {
            //   list.splice(i, 1)
            //   setTree(list)
            // }
            if (item.children && !item.children.length) {
              delete item.children
            }
            // item.orgType  1公司级 2分公司级 3项目级
            if (!item.orgTypeList.includes(1)) {
              //  公司级
              let type = ['1']
              if (type.includes(String(orgType))) {
                list.splice(i, 1)
                setTree(list)
              }
            }
            if (!item.orgTypeList.includes(2)) {
              // 分公司级
              let type = ['2', '3', '4', '5']
              if (type.includes(String(orgType))) {
                list.splice(i, 1)
                setTree(list)
              }
            }
            if (!item.orgTypeList.includes(3)) {
              // 项目级
              let type = ['6']
              if (type.includes(String(orgType))) {
                list.splice(i, 1)
                setTree(list)
              }
            }
          })
      }
      setTree(treeData)
      return treeData
    },
    /**
     * 收藏按钮增加属性
     * @param arr
     * @returns {Array}
     */
    addCollectionProps(arr) {
      let resources = []
      let _bi = {
        web_pm_bi_pm: '/pm-bi/#/main?token=',
        web_pm_bi_monitor: '/monitor-bi/#/main?token=',
        web_pm_bi_IotComm: '/command-bi/#/main?token='
      }
      let origin = window.location.origin
      let host = window.location.host
      let httpOriginWeb = process.env.VUE_APP_ORIGIN_HTTP_WEB
      arr.length &&
        arr.forEach(item => {
          if (item.type == 'menu') {
            item['isCollected'] = false
          }
          if (item.uri && item.uri.includes('?token=') && !this.getQueryString(item.uri, 'token')) {
            item.uri += Cookie.get('simpleToken')
          }
          // 大屏访问优化，（经拉通，私有化环境后续不用在运维端单独处理访问前缀）
          if (item.uri && Object.keys(_bi).includes(item.appCode)) {
            // 存在host私有化配置则按域名访问，不存在即为项管家/本地环境
            if (Object.keys(hostConfig).includes(host)) {
              item.uri = `${window.location.origin}${_bi[item.appCode]}${Cookie.get('simpleToken')}`
            } else {
              item.uri = `${httpOriginWeb}${_bi[item.appCode]}${Cookie.get('simpleToken')}`
            }
          }
          if (item.children && item.children.length) {
            item.children = this.addCollectionProps(item.children)
          }
          resources.push(item)
        })
      return resources
    },
    /**
     * 获取资源
     * */
    getResource(id, roleId, type, menuMode) {
      this.loading = true
      this.$get(this.api.getResourceApi, {
        tenantId: id,
        userRoleId: roleId,
        // appProperty: type,
        clientType: 'web',
        scope: 1
      })
        .then(response => {
          this.loading = false
          Util.processRes(response, () => {
            if (response.data) {
              this.$store.commit(
                'SET_RESOURCES',
                this.addCollectionProps(Util.resetTree(response.data))
              )
              // 储存原始数据，用于切换组织时获取
              localStorage.setItem(
                'resources',
                JSON.stringify(this.addCollectionProps(response.data))
              )
              // 控制菜单层级权限
              this.setResource({ orgType: type }, menuMode)
              console.log(this.$route)
              // 定位菜单模式
              // if (menuMode && menuMode !== 2) {
              // this.$router.push('/main/welcome')
              this.$store.commit('SET_OPENKEYS', [])
              this.$emit('change', false)
              // }
            } else {
              this.$store.commit('SET_RESOURCES', [])
              localStorage.setItem('resources', JSON.stringify([]))
              this.$router.push('/main/welcome')
              this.$emit('change', false)
            }
          })
          Util.processError(this, response)
        })
        .catch(err => {
          this.loading = false
        })
    },
    // 获取词典
    getDictAll(tenantId) {
      this.$post(this.api.getDictApi, { tenantId: tenantId })
        .then(response => {
          Util.processRes(response, () => {
            if (response.data) {
              this.$store.commit('SET_DICTS', response.data)
            }
          })
        })
        .catch(res => {
          console.log(res)
        })
      // }
      console.log(this.dicts)
    },
    getQueryString(url, name) {
      var reg = new RegExp('(^|\\?)' + name + '=([^&]*)(&|$)', 'i')
      var r = url.substr(1).match(reg)
      if (r != null) {
        return unescape(r[2])
      }
      return null
    }
  },
  updated() {},
  destroyed() {} //生命周期 - 销毁完成
}
</script>
<style lang="less">
html,
body {
  width: 100%;
  height: 100%;
}

#app {
  width: 100%;
  height: 100%;
  // min-width: 1440px;
  overflow: hidden;
}
#appContainer {
  height: 100%;
  overflow: hidden;
  // .slide-fade-enter-active {
  //   transition: all 1s ease;
  // }
  // .slide-fade-leave-active {
  //   transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
  // }
  // .slide-fade-enter, .slide-fade-leave-to
  //   /* .slide-fade-leave-active for below version 2.1.8 */ {
  //   /*transform: translateX(10px);*/
  //   opacity: 0;
  // }
}

/** 滚动条样式 */
// ::-webkit-scrollbar {
//   width: 8px;
//   height: 150px;
// }
// ::-webkit-scrollbar-thumb {
//   border-radius: 4px;
//   -webkit-box-shadow: inset 0 0 5px rgba(142, 142, 142, 1);
//   background: rgba(142, 142, 142, 1);
// }
// ::-webkit-scrollbar-track {
//   -webkit-box-shadow: inset 0 0 5px rgba(204, 204, 204, 1);
//   border-radius: 0;
//   background: rgba(204, 204, 204, 1);
// }
</style>
