/*
 * @Description: baseUrl
 * @Version: 1.0.0
 * @Author: 李晨光
 * @Date: 2020-07-07 13:47:47
 * @LastEditors: 王召强
 * @LastEditTime: 2024-08-12 10:55:10
 */
// http orgin
let httpOriginWeb = process.env.VUE_APP_ORIGIN_HTTP_WEB
// http orgin
let httpOrigin = process.env.VUE_APP_ORIGIN_HTTP_API
// ws orgin
let wsOrigin = process.env.VUE_APP_ORIGIN_WS_API
// 后端http
let backendHttp = {
  // 平台端
  baseUrl: `${httpOrigin}/hz-platform/api`,
  // 运维端
  baseUrlPlatform: `${httpOrigin}/hz-operation`,
  // 安全
  baseUrlSafety: `${httpOrigin}/hz-safe/api`,
  // 质量
  baseUrlQuality: `${httpOrigin}/hz-quality/api`,
  // 劳务
  baseUrlLabor: `${httpOrigin}/hz-labour`,
  // 机械
  baseUrlMechanical: `${httpOrigin}/hz-devices`,
  // AIOT平台
  baseUrlAiot: `${httpOrigin}/hz-aiot-platform`,
  // 塔机
  baseUrlTower: `${httpOrigin}/hz-aiot-towercrane`,
  // 文件上传
  baseUrlUpload: `${httpOrigin}/hz-file-upload`,
  // 生产进度
  baseUrlProductionSchedule: `${httpOrigin}/hz-progress`,
  // ai识别 -  已废弃
  baseUrlAiotAI: `${httpOrigin}/`,
  // 人脸识别
  baseUrlAiotAccess: `${httpOrigin}/hz-face`,
  // 已废弃
  baseUrlAiotVideo: `${httpOrigin}/`,
  // 视频监控
  baseUrlAiotLiveMonitor: `${httpOrigin}/hz-video-hub`,
  // 基坑
  baseUrlFoundationPit: `${httpOrigin}/hz-deeppit/api`, // 基坑
  //用电监测
  baseUrlAiotElectroMonitor: `${httpOrigin}/hz-condition-monitor`,
  // 工作流
  baseUrlWorkFlow: `${httpOrigin}/hz-workflow`,
  // 帆软配置
  baseUrlReport: `${httpOrigin}/hz-report`,
  // 物验通
  baseUrlMaterial: `${httpOrigin}/hz-material`,
  // 供应商
  baseUrlSupplier: `${httpOrigin}/hz-supplier`,
  // 三方平台对接
  baseUrlThirdDocking: `${httpOrigin}/hz-third`,
  // auth2认证
  baseAuth2: `${httpOrigin}/hz-auth/api`,
  // 隧道
  baseUrlTunnel: `${httpOrigin}/hz-tunnel`
}
// 后端websocket
let backendWS = {
  // 安全ws
  baseUrlWsSafety: `${wsOrigin}/hz-safe`,
  // 劳务ws
  baseUrlWsLabor: `${wsOrigin}/hz-labour`,
  // 塔机ws
  baseUrlJavaWsTower: `${wsOrigin}/hz-consume-towercrane`
}
// 前端
let front = {
  // 公共h5页面
  baseUrlH5: `${httpOriginWeb}/mb/#`,
  // 塔机h5页面
  baseUrlAiotTower: `${httpOriginWeb}/h5-tower`,
  // 文件预览 (存在host私有化配置则按域名访问，不存在即为项管家/本地环境)
  baseUrlPreview: Object.keys(hostConfig).includes(window.location.host)
    ? `${window.location.origin}/wiki`
    : `${httpOriginWeb}/wiki`
}

let cdnAssets = {
  chinaMap: 'https://cdn.1357.cn/china-map/json/',
  chinaMapV2: 'https://cdn.1357.cn/china-map/v2/json/'
}
let BaseUrlConfig = {
  ...backendHttp,
  ...backendWS,
  ...front,
  ...cdnAssets
}

export default BaseUrlConfig
