<!--
 * @Description:
 * @Version: 1.0.0
 * @Author: 李帅
 * @Date: 2021-11-13 15:31:34
 * @LastEditors: 李帅
 * @LastEditTime: 2022-01-13 16:16:51
-->
<comment>
# 组件注释
</comment>
<template>
  <div id="DrawerLeft">
    <a-menu class="menu menu-top">
      <a-menu-item :disabled="true">
        <a-icon type="appstore" theme="filled" />
        产品与服务
        <a-icon type="right" style="position: absolute; right: 0; line-height: 45px" />
      </a-menu-item>
    </a-menu>
    <last-activity style="flex: 1"></last-activity>
    <a-menu class="menu menu-bottom">
      <a-menu-item v-if="hasShowMenus('web_base')" hz-code="web_base" @click="jump('web_base')">
        <a-icon type="setting" theme="filled" />
        基础管理
      </a-menu-item>
      <a-menu-item v-if="hasShowMenus('web_back')" hz-code="web_back" @click="jump('web_back')">
        <a-icon type="setting" theme="filled" />
        后台管理
      </a-menu-item>
    </a-menu>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { mapState } from 'vuex'
import LastActivity from './LastActivity'

export default {
  name: 'DrawerLeft',
  //import引入的组件需要注入到对象中才能使用
  components: { 'last-activity': LastActivity },
  props: {},
  data() {
    //这里存放数据
    return { Util }
  },
  //监听属性 类似于data概念
  computed: {
    ...mapState({
      resources: state => state.resources
    })
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    hasShowMenus(code) {
      let currentData = this.resources.filter(v => v.appCode == code)
      if (currentData && currentData.length) {
        let treeFirstData = Util.getTreeUriFirst(currentData[0].id, currentData)
        return (
          currentData.map(v => v.appCode).includes(code) &&
          treeFirstData &&
          Object.keys(treeFirstData).length
        )
      } else {
        return false
      }
    },
    jump(appCode) {
      // 获取通过菜单唯一标识appCode获取后台管理菜单
      this.$root.eventHub.$emit('isShowDrawer', false)
      let item = Util.getTreeChild(appCode, this.resources, 'appCode')[0]
      if (item && (item.appType == 1 || item.appType == 2)) {
        let treeFirst = item.uri ? item : Util.getTreeUriFirst(item.id, [item])
        if (treeFirst.appType == 1 || treeFirst.appType == 2)
          this.$router.push({ path: treeFirst.uri })
        else window.open(treeFirst.uri)
        this.$root.eventHub.$emit('routeSetLastActivity', [treeFirst])
      } else if (item.appType == 3 || item.appType == 4) {
        window.open(item.uri)
        this.$root.eventHub.$emit('routeSetLastActivity', [item])
      }
    }
  },
  destroyed() {} //生命周期 - 销毁完成
}
</script>
<style lang="less">
#DrawerLeft {
  width: 208px;
  box-shadow: 1px 0 0 0 rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;

  .menu {
    /*height: 48px;*/
    border-right: none;

    .ant-menu-item {
      height: 48px;
      line-height: 48px;
      margin: 0;
    }
  }

  .menu-top {
    background: #f5f5f7;
  }

  .menu-bottom {
    .ant-menu-item {
      border-top: 1px solid #ddd;
    }
  }

  .ant-menu-item-disabled {
    background: #f5f5f7;
    color: #606266 !important;

    .anticon-right {
      line-height: 48px !important;
      font-size: 12px;
    }
  }
}
</style>
