/*
 * @Description:
 * @Version: 1.0.0
 * @Author: 糜龙
 * @Date: 2021-01-05 11:37:23
 * @LastEditors: 赵亮
 * @LastEditTime: 2023-03-30 15:22:32
 */
const qualityReport = [
  {
    path: '/',
    name: 'QualityReport',
    meta: {
      name: '质量BI数据填报',
      keepAlive: false
    },
    component: () =>
      import(
        // 质量BI数据填报
        '$quality/views/qualityReport/Index'
      ),
    children: [
      {
        path: '/main/quality/qualityReport/patternMaking/patternMakingList',
        name: 'PatternMakingList',
        meta: {
          name: '样板制作',
          keepAlive: false,
          isAuth: true,
          authCode: 'web_pm_quality_form_prototyping'
        },
        component: () => import('$quality/views/qualityReport/patternMaking/List'),
        children: []
      },
      {
        path: '/main/quality/qualityReport/patternMaking/patternMakingSet',
        name: 'PatternMakingSet',
        meta: {
          name: '样板制作计划填报',
          keepAlive: false,
          isAuth: false,
          authCode: 'web_pm_quality_form_prototyping_add'
        },
        component: () => import('$quality/views/qualityReport/patternMaking/SetData'),
        children: []
      },
      {
        path: '/main/quality/qualityReport/highlights/HighlightsList',
        name: 'HighlightsList',
        meta: {
          name: '亮点工艺',
          keepAlive: false,
          isAuth: true,
          authCode: 'web_pm_quality_form_craft'
        },
        component: () => import('$quality/views/qualityReport/highlights/List'),
        children: []
      },
      {
        path: '/main/quality/qualityReport/highlights/highlightsSet',
        name: 'HighlightsSet',
        meta: {
          name: '亮点工艺填报',
          keepAlive: false,
          isAuth: false,
          authCode: 'web_pm_quality_form_craft_add'
        },
        component: () => import('$quality/views/qualityReport/highlights/SetData'),
        children: []
      },
      {
        path: '/main/quality/qualityReport/specialProcess/specialProcessList',
        name: 'SpecialProcessList',
        meta: {
          name: '特殊过程监控',
          keepAlive: false,
          isAuth: true,
          authCode: 'web_pm_quality_form_monitoring'
        },
        component: () => import('$quality/views/qualityReport/specialProcess/List'),
        children: []
      },
      {
        path: '/main/quality/qualityReport/specialProcess/specialProcessSet',
        name: 'SpecialProcessSet',
        meta: {
          name: '特殊过程监控填报',
          keepAlive: false,
          isAuth: false,
          authCode: 'web_pm_quality_form_monitoring_add'
        },
        component: () => import('$quality/views/qualityReport/specialProcess/SetData'),
        children: []
      },
      {
        path: '/main/quality/qualityReport/star/starList',
        name: 'StarList',
        meta: {
          name: '工匠之星',
          keepAlive: false,
          isAuth: true,
          authCode: 'web_pm_quality_form_craftsmanStar'
        },
        component: () => import('$quality/views/qualityReport/star/List'),
        children: []
      },
      {
        path: '/main/quality/qualityReport/star/starSet',
        name: 'StarSet',
        meta: {
          name: '工匠之星填报',
          keepAlive: false,
          isAuth: false,
          authCode: 'web_pm_quality_form_craftsmanStar_add'
        },
        component: () => import('$quality/views/qualityReport/star/SetData'),
        children: []
      },
      // 项目目标
      {
        path: '/main/quality/qualityReport/target/targetSet',
        name: 'TargetSet',
        meta: {
          name: '质量目标填写',
          keepAlive: false,
          isAuth: true,
          authCode: 'web_pm_quality_form_projectTarget'
        },
        component: () => import('$quality/views/qualityReport/target/SetData'),
        children: []
      },

      {
        path: '/main/quality/qualityReport/qualityPlanning/list',
        name: 'QualityPlanningList',
        meta: {
          name: '质量策划',
          keepAlive: false,
          isAuth: true,
          authCode: 'web_pm_quality_form_qualityPlanning'
        },
        component: () => import('$quality/views/qualityReport/qualityPlanning/Index.vue'),
        children: []
      },
      {
        path: '/main/quality/qualityReport/qualityPlanning/set',
        name: 'QualityPlanningSet',
        meta: {
          name: '质量策划填报',
          keepAlive: false,
          isAuth: false,
          authCode: 'web_pm_quality_form_qualityPlanning_add'
        },
        component: () => import('$quality/views/qualityReport/qualityPlanning/AddAndEdit.vue'),
        children: []
      },

      {
        path: '/main/quality/qualityReport/countryQC/countryQCList',
        name: 'CountryQCList',
        meta: {
          name: '国家级QC奖',
          keepAlive: false,
          isAuth: true,
          authCode: 'web_pm_quality_form_countryQC'
        },
        component: () => import('$quality/views/qualityReport/countryQC/List'),
        children: []
      },
      {
        path: '/main/quality/qualityReport/countryQC/countryQCSet',
        name: 'CountryQCSet',
        meta: {
          name: '国家级QC奖填报',
          keepAlive: false,
          isAuth: false,
          authCode: 'web_pm_quality_form_countryQC_add'
        },
        component: () => import('$quality/views/qualityReport/countryQC/SetData'),
        children: []
      },

      {
        path: '/main/quality/qualityReport/projectExcellent/List',
        name: 'ProjectExcellentList',
        meta: {
          name: '工程质量奖',
          keepAlive: false,
          isAuth: true,
          authCode: 'web_pm_quality_form_projectExcellent'
        },
        component: () => import('$quality/views/qualityReport/projectExcellent/List'),
        children: []
      },
      {
        path: '/main/quality/qualityReport/projectExcellent/set',
        name: 'ProjectExcellentSet',
        meta: {
          name: '工程质量奖填写',
          keepAlive: false,
          isAuth: false,
          authCode: 'web_pm_quality_form_projectExcellent_add'
        },
        component: () => import('$quality/views/qualityReport/projectExcellent/SetData'),
        children: []
      },

      {
        path: '/main/quality/qualityReport/heightProject/List',
        name: 'HeightProjectList',
        meta: {
          name: '优质工程',
          keepAlive: false,
          isAuth: true,
          authCode: 'web_pm_quality_form_heightProject'
        },
        component: () => import('$quality/views/qualityReport/heightProject/List'),
        children: []
      },
      {
        path: '/main/quality/qualityReport/heightProject/set',
        name: 'HeightProjectSet',
        meta: {
          name: '优质工程填写',
          keepAlive: false,
          isAuth: false,
          authCode: 'web_pm_quality_form_heightProject_add'
        },
        component: () => import('$quality/views/qualityReport/heightProject/SetData'),
        children: []
      },
      {
        path: '/main/quality/qualityReport/thirdMeasure/List',
        name: 'ThirdMeasureList',
        meta: {
          name: '质量方案',
          keepAlive: false,
          isAuth: true,
          authCode: 'web_pm_quality_form_thirdMeasure'
        },
        component: () => import('$quality/views/qualityReport/thirdMeasure/List'),
        children: []
      },
      {
        path: '/main/quality/qualityReport/thirdMeasure/set',
        name: 'ThirdMeasureSet',
        meta: {
          name: '质量方案填写',
          keepAlive: false,
          isAuth: false,
          authCode: 'web_pm_quality_form_thirdMeasure_edit'
        },
        component: () => import('$quality/views/qualityReport/thirdMeasure/SetData'),
        children: []
      }
    ]
  }
]
export default qualityReport
