/*
 * @Description: 地图配置
 * @Version: 1.0.0
 * @Author: 赵亮
 * @Date: 2021-10-20 19:26:17
 * @LastEditors: 赵亮
 * @LastEditTime: 2021-10-21 09:36:38
 */
import configArr from './environmentConfig'
const mapObj = {
  standard: {
    dev: {
      key: '02b2d70ea2143d0000d17e5a26a16b1b',
      plugin: [
        'AMap.Geolocation',
        'AMap.Autocomplete',
        'AMap.PlaceSearch',
        'AMap.Scale',
        'AMap.OverView',
        'AMap.ToolBar',
        'AMap.MapType',
        'AMap.PolyEditor',
        'AMap.CircleEditor',
        'AMap.Circle',
        'AMap.MarkerClusterer',
        'AMap.MouseTool'
      ],
      v: '1.4.15'
    }, // 开发
    test: {
      key: '02b2d70ea2143d0000d17e5a26a16b1b',
      plugin: [
        'AMap.Geolocation',
        'AMap.Autocomplete',
        'AMap.PlaceSearch',
        'AMap.Scale',
        'AMap.OverView',
        'AMap.ToolBar',
        'AMap.MapType',
        'AMap.PolyEditor',
        'AMap.CircleEditor',
        'AMap.Circle',
        'AMap.MarkerClusterer',
        'AMap.MouseTool'
      ],
      v: '1.4.15'
    }, // 测试
    gray: {
      key: '02b2d70ea2143d0000d17e5a26a16b1b',
      plugin: [
        'AMap.Geolocation',
        'AMap.Autocomplete',
        'AMap.PlaceSearch',
        'AMap.Scale',
        'AMap.OverView',
        'AMap.ToolBar',
        'AMap.MapType',
        'AMap.PolyEditor',
        'AMap.CircleEditor',
        'AMap.Circle',
        'AMap.MarkerClusterer',
        'AMap.MouseTool'
      ],
      v: '1.4.15'
    }, // 灰度
    presentation: {
      key: '02b2d70ea2143d0000d17e5a26a16b1b',
      plugin: [
        'AMap.Geolocation',
        'AMap.Autocomplete',
        'AMap.PlaceSearch',
        'AMap.Scale',
        'AMap.OverView',
        'AMap.ToolBar',
        'AMap.MapType',
        'AMap.PolyEditor',
        'AMap.CircleEditor',
        'AMap.Circle',
        'AMap.MarkerClusterer',
        'AMap.MouseTool'
      ],
      v: '1.4.15'
    }, // 预发布
    production: {
      key: '02b2d70ea2143d0000d17e5a26a16b1b',
      plugin: [
        'AMap.Geolocation',
        'AMap.Autocomplete',
        'AMap.PlaceSearch',
        'AMap.Scale',
        'AMap.OverView',
        'AMap.ToolBar',
        'AMap.MapType',
        'AMap.PolyEditor',
        'AMap.CircleEditor',
        'AMap.Circle',
        'AMap.MarkerClusterer',
        'AMap.MouseTool'
      ],
      v: '1.4.15'
    }, // 线上
    other: {
      key: '02b2d70ea2143d0000d17e5a26a16b1b',
      plugin: [
        'AMap.Geolocation',
        'AMap.Autocomplete',
        'AMap.PlaceSearch',
        'AMap.Scale',
        'AMap.OverView',
        'AMap.ToolBar',
        'AMap.MapType',
        'AMap.PolyEditor',
        'AMap.CircleEditor',
        'AMap.Circle',
        'AMap.MarkerClusterer',
        'AMap.MouseTool'
      ],
      v: '1.4.15'
    }
  }, // 标版
  bjnf: {
    dev: {
      key: '02b2d70ea2143d0000d17e5a26a16b1b',
      plugin: [
        'AMap.Geolocation',
        'AMap.Autocomplete',
        'AMap.PlaceSearch',
        'AMap.Scale',
        'AMap.OverView',
        'AMap.ToolBar',
        'AMap.MapType',
        'AMap.PolyEditor',
        'AMap.CircleEditor',
        'AMap.Circle',
        'AMap.MarkerClusterer',
        'AMap.MouseTool'
      ],
      v: '1.4.15'
    }, // 开发
    test: {
      key: '02b2d70ea2143d0000d17e5a26a16b1b',
      plugin: [
        'AMap.Geolocation',
        'AMap.Autocomplete',
        'AMap.PlaceSearch',
        'AMap.Scale',
        'AMap.OverView',
        'AMap.ToolBar',
        'AMap.MapType',
        'AMap.PolyEditor',
        'AMap.CircleEditor',
        'AMap.Circle',
        'AMap.MarkerClusterer',
        'AMap.MouseTool'
      ],
      v: '1.4.15'
    }, // 测试
    gray: {
      key: '02b2d70ea2143d0000d17e5a26a16b1b',
      plugin: [
        'AMap.Geolocation',
        'AMap.Autocomplete',
        'AMap.PlaceSearch',
        'AMap.Scale',
        'AMap.OverView',
        'AMap.ToolBar',
        'AMap.MapType',
        'AMap.PolyEditor',
        'AMap.CircleEditor',
        'AMap.Circle',
        'AMap.MarkerClusterer',
        'AMap.MouseTool'
      ],
      v: '1.4.15'
    }, // 灰度
    presentation: {
      key: '02b2d70ea2143d0000d17e5a26a16b1b',
      plugin: [
        'AMap.Geolocation',
        'AMap.Autocomplete',
        'AMap.PlaceSearch',
        'AMap.Scale',
        'AMap.OverView',
        'AMap.ToolBar',
        'AMap.MapType',
        'AMap.PolyEditor',
        'AMap.CircleEditor',
        'AMap.Circle',
        'AMap.MarkerClusterer',
        'AMap.MouseTool'
      ],
      v: '1.4.15'
    }, // 预发布
    production: {
      key: '02b2d70ea2143d0000d17e5a26a16b1b',
      plugin: [
        'AMap.Geolocation',
        'AMap.Autocomplete',
        'AMap.PlaceSearch',
        'AMap.Scale',
        'AMap.OverView',
        'AMap.ToolBar',
        'AMap.MapType',
        'AMap.PolyEditor',
        'AMap.CircleEditor',
        'AMap.Circle',
        'AMap.MarkerClusterer',
        'AMap.MouseTool'
      ],
      v: '1.4.15'
    }, // 线上
    other: {
      key: '02b2d70ea2143d0000d17e5a26a16b1b',
      plugin: [
        'AMap.Geolocation',
        'AMap.Autocomplete',
        'AMap.PlaceSearch',
        'AMap.Scale',
        'AMap.OverView',
        'AMap.ToolBar',
        'AMap.MapType',
        'AMap.PolyEditor',
        'AMap.CircleEditor',
        'AMap.Circle',
        'AMap.MarkerClusterer',
        'AMap.MouseTool'
      ],
      v: '1.4.15'
    }
  } // 八局南方
}
let mapConfig =
  mapObj[process.env.VUE_APP_VERSION][
    configArr.includes(process.env.VUE_APP_CURRENTMODE) ? process.env.VUE_APP_CURRENTMODE : 'other'
  ]
export default mapConfig
