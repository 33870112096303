/*
 * @Description: 
 * @Version: 1.0.0
 * @Author: 任笠
 * @Date: 2022-08-15 10:04:17
 * @LastEditors: 任笠
 * @LastEditTime: 2022-08-15 10:58:17
 */
import KFormBuild from "./index.vue";

KFormBuild.install = function(Vue) {
  Vue.component(KFormBuild.name, KFormBuild);
};

export default KFormBuild;
