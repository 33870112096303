/*
 * @Description:
 * @Version: 1.0.0
 * @Author: 任笠
 * @Date: 2022-03-29 16:12:53
 * @LastEditors: 赵亮
 * @LastEditTime: 2023-03-22 16:20:32
 */
import routerName from '../config/routerName.config'
export const protectedRoute = [
  // 基坑监测企业级
  {
    path: '/main/guard/foundationPitMonitoring/company',
    name: 'foundationPitMonitoringCompany',
    meta: {
      name: '基坑监测',
      isAuth: true,
      authCode: 'web_pm_structure_deepPit_CompanyIndex',
      id: ''
    },
    component: () => import('$safety/views/foundationPitMonitoring/company/Index')
  },
  // 基坑监测项目级
  {
    path: '/main/guard/foundationPitMonitoring/project',
    name: 'foundationPitMonitoringProject',
    meta: {
      name: '基坑监测',
      isAuth: true,
      authCode: 'web_pm_structure_deepPit_ProjectIndex',
      id: ''
    },
    component: () => import('$safety/views/foundationPitMonitoring/project/Index')
  },
  // 测斜机器人
  {
    path: '/main/guard/foundationPitMonitoring/clinometerRobot',
    name: 'foundationPitMonitoringProject',
    meta: {
      name: '测斜机器人',
      isAuth: true,
      authCode: 'web_pm_structure_deepPit_clinometerRobot',
      id: ''
    },
    component: () => import('$safety/views/foundationPitMonitoring/clinometerRobot/Index')
  }
]
