/*
 * @Description:风险分级管控模块路由
 * @Version: 1.0.0
 * @Author: 杜凡
 * @Date: 2022-03-29 10:21:33
 * @LastEditors: 杜凡
 * @LastEditTime: 2022-03-29 11:17:28
 */
import routerName from '../config/routerName.config'

const riskClassificationRoute = [
  // 危险源辨识与评价=============================================
  {
    //危险源辨识评价与控制
    path: '/main/safety/hazardIdentification/hazardIdentificationEvaluateAndControl',
    name: 'HazardIdentificationEvaluateAndControl',
    meta: {
      name: '危险源辨识评价与控制',
      isAuth: true,
      authCode: 'web_pm_safety_riskControl_evaluate'
    },
    component: () =>
      import(
        '$safety/views/hazardIdentificationEvaluateAndControl/HazardIdentificationEvaluateAndControlLists'
      )
  },
  {
    //危险源辨识评价与控制导入
    path: '/main/organization/ListingLibraryImportOrganization',
    name: 'ListingLibraryImportOrganization',
    component: () =>
      import('$safety/views/hazardIdentificationEvaluateAndControl/ImportOrganization'),
    meta: {
      name: '危险源辨识与风险评价清单导入',
      isAuth: true,
      authCode: 'web_pm_safety_riskControl_evaluate_risk_import_imp'
    }
  },
  //危险源辨识与风险评价清单库导入跳转
  {
    path: '/main/safety/hazardIdentification/riskLibsImport',
    name: 'RiskLibsImport',
    meta: {
      name: routerName('RiskLibsImport'),
      isAuth: true,
      authCode: 'web_pm_safety_riskControl_evaluate_risk_import_inventory'
    },
    component: () => import('$safety/views/setUp/riskLibs/RiskLibs')
  },

  //重大危险源监管=============================================
  {
    //重大危险源管理
    path: '/riskClassificationControl/majorHazardInstallations',
    name: 'MajorHazardInstallations',
    component: () => import('$safety/views/riskClassificationControl/MajorHazardInstallations'),
    meta: {
      name: routerName('MajorHazardInstallations'),
      isAuth: true,
      authCode: 'web_pm_safety_riskControl_supervise'
    }
  },
  {
    //重大危险源新增
    path: '/riskClassificationControl/addMajorHazardInstallations',
    name: 'AddMajorHazardInstallations',
    component: () => import('$safety/views/riskClassificationControl/AddMajorHazardInstallations'),
    meta: {
      name: '新增',
      isAuth: true,
      authCode: 'web_pm_safety_riskControl_supervise_nextData_add'
    }
  },
  {
    //重大危险源编辑
    path: '/riskClassificationControl/editMajorHazardInstallations',
    name: 'EditMajorHazardInstallations',
    component: () => import('$safety/views/riskClassificationControl/AddMajorHazardInstallations'),
    meta: {
      name: '编辑',
      isAuth: true,
      authCode: 'web_pm_safety_riskControl_supervise_nextData_edit'
    }
  },
  {
    //重大危险源详情本月
    path: '/riskClassificationControl/nowDataDetailMajorHazardInstallations',
    name: 'NowDataDetailMajorHazardInstallations',
    component: () =>
      import('$safety/views/riskClassificationControl/DetailMajorHazardInstallations'),
    meta: {
      name: '详情',
      isAuth: true,
      authCode: 'web_pm_safety_riskControl_supervise_nowData_detail'
    }
  },
  {
    //重大危险源详情下月
    path: '/riskClassificationControl/nextDataDetailMajorHazardInstallations',
    name: 'NextDataDetailMajorHazardInstallations',
    component: () =>
      import('$safety/views/riskClassificationControl/DetailMajorHazardInstallations'),
    meta: {
      name: '详情',
      isAuth: true,
      authCode: 'web_pm_safety_riskControl_supervise_nextData_detail'
    }
  },
  {
    //重大危险源复制
    path: '/riskClassificationControl/copyMajorHazardInstallations',
    name: 'CopyMajorHazardInstallations',
    component: () => import('$safety/views/riskClassificationControl/CopyMajorHazardInstallations'),
    meta: {
      name: '复制',
      isAuth: true,
      authCode: 'web_pm_safety_riskControl_supervise_nextData_copy'
    }
  }
]

export default riskClassificationRoute
