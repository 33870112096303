/*
 * @Description: 机械
 * @Version: 1.0.0
 * @Author: 李晨光
 * @Date: 2020-11-02 11:23:34
 * @LastEditors: 任笠
 * @LastEditTime: 2023-03-27 09:57:15
 */

export const protectedRoute = [
  {
    path: '/iframe/Iframe',
    name: 'Iframe',
    meta: {
      isAuth: true,
      authCode: '',
      id: '1272192949610835970'
    },
    component: () => import(/*/* : "运营中心" */ '$base/views/iframe/Iframe')
  },
  {
    path: '/changePwd',
    name: 'ChangePwd',
    component: () => import(/*/* : "修改密码" */ '$base/views/userInfo/ChangePwd')
  },
  {
    path: '/userInfo',
    name: 'UserInfo',
    component: () => import(/*/* : "个人信息" */ '$base/views/userInfo/UserInfo'),
    meta: {
      name: '个人信息'
    }
  },
  // 组织管理
  {
    path: '/main/organization',
    name: 'Organization',
    component: () => import(/*/* : "组织中心" */ '$base/views/organization/Index'),
    children: [
      // 基础组织
      {
        path: '/main/organization/baseOrg',
        name: 'BaseOrg',
        component: () =>
          import(
            /*/* : "基础组织" */
            '$base/views/organization/baseOrg/BaseOrg'
          ),
        meta: {
          name: '基础组织',
          keepAlive: true, //此组件需要被缓存
          isAuth: true,
          authCode: 'web_back_organization_baseOrg',
          id: '1271793906527121410'
        }
      },
      // 调整组织上级
      {
        path: '/main/organization/adjustmentOrg',
        name: 'AdjustmentOrg',
        component: () =>
          import(
            /*/* : "调整组织上级" */
            '$base/views/organization/baseOrg/AdjustmentOrg'
          ),
        meta: {
          name: '调整组织上级',
          isAuth: true,
          authCode: 'web_back_organization_adjustmentOrg',
          id: ''
        }
      },
      {
        path: '/main/organization/ImportOrganization',
        name: 'ImportOrganization',
        component: () =>
          import(
            /*/* : "导入组织" */
            '$base/views/organization/baseOrg/ImportOrganization'
          ),
        meta: {
          name: '导入组织',
          isAuth: true,
          authCode: 'web_back_organization_baseOrg_import_organize',
          id: ''
        }
      },
      {
        path: '/main/organization/ImportDepartment',
        name: 'ImportDepartment',
        component: () =>
          import(
            /*/* : "组织部门导入" */
            '$base/views/organization/baseOrg/ImportDepartment'
          ),
        meta: {
          name: '导入部门',
          isAuth: true,
          authCode: 'web_back_organization_baseOrg_import_department',
          id: ''
        }
      },
      {
        path: '/main/organization/ImportProjectDepartment',
        name: 'ImportProjectDepartment',
        component: () =>
          import(
            /*/* : "项目部导入" */
            '$base/views/organization/projectOrg/ImportProjectDepartment'
          ),
        meta: {
          name: '导入项目部',
          isAuth: true,
          authCode: 'web_back_organization_baseOrg_import_project',
          id: ''
        }
      },
      {
        path: '/main/organization/baseOrg/add',
        name: 'BaseOrgAdd',
        component: () =>
          import(
            /*/* : "新增基础组织" */
            '$base/views/organization/baseOrg/BaseOrgAdd'
          ),
        meta: {
          name: '新增基础组织',

          isAuth: true,
          authCode: 'web_back_organization_baseOrg_add',
          id: ''
        }
      },
      {
        path: '/main/organization/baseOrg/edit',
        name: 'BaseOrgEdit',
        component: () =>
          import(
            /*/* : "编辑基础组织" */
            '$base/views/organization/baseOrg/BaseOrgEdit'
          ),
        meta: {
          name: '编辑基础组织',

          isAuth: true,
          authCode: 'web_back_organization_baseOrg_edit',
          id: ''
        }
      },
      {
        path: '/main/organization/baseOrg/detail',
        name: 'BaseOrgDetail',
        component: () =>
          import(
            /*/* : "基础组织详情" */
            '$base/views/organization/baseOrg/BaseOrgDetail'
          ),
        meta: {
          name: '基础组织详情',
          isAuth: true,
          authCode: 'web_back_organization_baseOrg_detail',
          id: ''
        }
      },
      {
        path: '/main/organization/projectOrg',
        name: 'ProjectOrg',
        component: () =>
          import(
            /*/* : "项目部" */
            '$base/views/organization/projectOrg/ProjectOrg'
          ),
        meta: {
          name: '项目部'
        }
      },
      // 岗位字典分类
      {
        path: '/main/organization/postClassify',
        name: 'PostClassify',
        component: () =>
          import(
            /*/* : "岗位字典分类" */
            '$base/views/organization/postDict/PostClassify'
          ),
        meta: {
          name: '岗位字典分类',
          isAuth: true,
          authCode: 'web_back_organization_postClassify',
          id: '1271794391673876482'
        }
      },
      // 岗位字典
      {
        path: '/main/organization/postDict',
        name: 'PostDict',
        component: () =>
          import(
            /*/* : "岗位字典管理" */
            '$base/views/organization/postDict/PostDict'
          ),
        meta: {
          name: '岗位字典',
          isAuth: true,
          authCode: 'web_back_organization_postDict',
          id: '1271794241954000898'
        }
      },
      {
        path: '/main/organization/postDictImport',
        name: 'PostDictImport',
        component: () =>
          import(
            /*/* : "岗位字典导入" */
            '$base/views/organization/postDict/PostDictImport'
          ),
        meta: {
          name: '岗位字典导入',
          isAuth: true,
          authCode: 'web_back_organization_postDict_import',
          id: ''
        }
      },
      // 岗位管理
      {
        path: '/main/organization/post',
        name: 'Post',
        component: () => import(/*/* : "岗位管理" */ '$base/views/organization/post/Post'),
        meta: {
          name: '岗位管理',
          isAuth: true,
          authCode: 'web_back_organization_post',
          id: '1271794519298158593'
        }
      },
      {
        path: '/main/organization/importPostManagement',
        name: 'ImportPostManagement',
        component: () =>
          import(
            /*/* : "岗位管理导入" */
            '$base/views/organization/post/PostManagement'
          ),
        meta: {
          name: '岗位管理导入',
          isAuth: true,
          authCode: 'web_back_organization_post_import',
          id: ''
        }
      },
      // 项目信息自定义
      {
        path: '/main/organization/projectShowItemConfig',
        name: 'ProjectShowItemConfig',
        component: () =>
          import(
            /*/* : "项目信息自定义" */
            '$base/views/organization/projectShowItemConfig/ProjectShowItemConfig'
          ),
        meta: {
          name: '项目信息自定义',
          isAuth: true,
          authCode: 'web_back_archives_config',
          id: '1271810278481162241'
        }
      },
      // 管理范围配置
      {
        path: '/main/organization/jurisdiction',
        name: 'JurisdictionConfig',
        component: () =>
          import(
            /*/* : "管理区域配置" */
            '$base/views/organization/jurisdictionConfig/JurisdictionConfig'
          ),
        meta: {
          name: '管理范围配置',
          isAuth: true,
          authCode: 'web_back_organization_jurisdiction',
          id: ''
        }
      }
    ]
  },
  //  用户管理
  {
    path: '/main/user',
    name: 'User',
    component: () => import(/*/* : "用户中心" */ '$base/views/user/Index'),
    children: [
      // 用户管理
      {
        path: '/main/user/accountManage',
        name: 'AccountManage',
        component: () =>
          import(
            /*/* : "用户账号管理" */
            '$base/views/user/accountManage/AccountManage'
          ),
        meta: {
          name: '用户管理',
          isAuth: true,
          authCode: 'web_back_user_account',
          id: '1271809059608031233'
        }
      },
      // 人员管理
      {
        path: '/main/user/userManage',
        name: 'UserManage',
        component: () =>
          import(
            /*/* : "组织人员管理" */
            '$base/views/user/userManage/UserManage'
          ),
        meta: {
          name: '人员管理',
          isAuth: true,
          authCode: 'web_back_user_manage',
          id: '1271808925147033601'
        }
      },
      {
        path: '/main/user/userManage/userBulkImport',
        name: 'UserBulkImport',
        component: () =>
          import(
            /*/* : "导入人员" */
            '$base/views/user/userManage/UserBulkImport'
          ),
        meta: {
          name: '导入人员',
          isAuth: true,
          authCode: 'web_back_user_manage_import',
          id: '1271808925147033601'
        }
      }
    ]
  },
  {
    path: '/main/role',
    name: 'Role',
    component: () => import(/*/* : "角色中心" */ '$base/views/role/Index'),
    children: [
      // 角色管理
      {
        path: '/main/role/roleManage',
        name: 'RoleManage',
        component: () => import(/*/* : "角色管理" */ '$base/views/role/roleManage/RoleManage'),
        meta: {
          name: '角色管理',
          keepAlive: true, //此组件需要被缓存
          isAuth: true,
          authCode: 'web_back_auth_roleManage',
          id: '1271817653091004418'
        }
      },
      {
        path: '/main/role/roleManage/associatingUsers',
        name: 'AssociatingUsers',
        component: () =>
          import(
            /*/* : "关联用户" */
            '$base/views/role/roleManage/AssociatingUsers'
          ),
        meta: {
          name: '关联用户',

          isAuth: true,
          authCode: 'web_back_auth_roleManage_associatedUser',
          id: '1271817653091004418'
        }
      },
      // 角色分类
      {
        path: '/main/role/roleClassify',
        name: 'RoleClassify',
        component: () =>
          import(
            /*/* : "角色分类" */
            '$base/views/role/roleManage/RoleClassify'
          ),
        meta: {
          name: '角色分类',
          isAuth: true,
          authCode: 'web_back_auth_roleClassify',
          id: '1271816523871121410'
        }
      },
      {
        path: '/main/role/roleManage/applicationLicense',
        name: 'ApplicationLicense',
        component: () =>
          import(
            /*/* : "应用授权" */
            '$base/views/role/roleManage/ApplicationLicense'
          ),
        meta: {
          name: '应用授权',

          isAuth: true,
          authCode: 'web_back_auth_roleManage_authorization',
          id: '1271819741938282498'
        }
      },
      // 管理员授权范围配置
      {
        path: '/main/role/appLicenseScope',
        name: 'AppLicenseScope',
        component: () =>
          import(
            /*/* : "管理员授权范围配置" */
            '$base/views/role/appLicenseScope/AppLicenseScope'
          ),
        meta: {
          name: '管理员授权范围配置',
          isAuth: true,
          authCode: 'web_back_auth_appLicenseScope',
          id: '1271817179365339137'
        }
      },
      {
        path: '/main/role/appLicenseScope/chooseApplication',
        name: 'ChooseApplication',
        component: () =>
          import(
            /*/* : "应用授权" */
            '$base/views/role/appLicenseScope/ChooseApplication'
          ),
        meta: {
          name: '选择应用',

          keepAlive: true, //此组件需要被缓存
          isAuth: true,
          authCode: 'web_back_auth_appLicenseScope_select',
          id: '1271817179365339137'
        }
      },
      // 用户权限查询
      {
        path: '/main/role/userPermissionQuery',
        name: 'UserPermissionQuery',
        component: () =>
          import(
            /*/* : "用户权限查询" */
            '$base/views/role/userPermission/UserPermissionQuery'
          ),
        meta: {
          name: '用户权限查询',
          keepAlive: true, //此组件需要被缓存
          isAuth: true,
          authCode: 'web_back_auth_userPermissionQuery',
          id: '1271819741938282498'
        }
      },
      // 角色管理-应用授权-查看权限
      {
        path: '/main/role/userPermissionQuery/authorizationPermission',
        name: 'AuthorizationPermission',
        component: () =>
          import(
            /*/* : "查看权限" */
            '$base/views/role/userPermission/QueryPermission'
          ),
        meta: {
          name: '查看权限',

          isAuth: true,
          authCode: 'web_back_auth_roleManage_authorization',
          id: '1271819741938282498'
        }
      },
      // 用户权限查询-查看权限
      {
        path: '/main/role/userPermissionQuery/viewPermission',
        name: 'ViewPermission',
        component: () =>
          import(
            /*/* : "查看权限" */
            '$base/views/role/userPermission/QueryPermission'
          ),
        meta: {
          name: '查看权限',

          isAuth: true,
          authCode: 'web_back_auth_userPermissionQuery_view',
          id: '1271819741938282498'
        }
      }
    ]
  },
  {
    path: '/main/operationCenter',
    name: 'OperationCenter',
    component: () => import(/*/* : "运营中心" */ '$base/views/operationCenter/Index'),
    children: [
      {
        path: '/main/operationCenter/applicationAuthorization',
        name: 'ApplicationAuthorization',
        component: () =>
          import(
            /*/* : "BI应用授权" */
            '$base/views/operationCenter/operationCenter/ApplicationAuthorization'
          ),
        meta: {
          name: 'BI应用授权',
          isAuth: true,
          authCode: '',
          id: '1287934084304056321'
        }
      },
      {
        path: '/main/operationCenter/userAuthorization',
        name: 'UserAuthorization',
        component: () =>
          import(
            /*/* : "用户授权" */
            '$base/views/operationCenter/operationCenter/UserAuthorization'
          ),
        meta: {
          name: '用户授权'
        }
      }
    ]
  },

  {
    path: '/main/participateInConstruction',
    name: 'ParticipateInConstruction',
    component: () =>
      import(
        /*/* : "参建单位" */
        '$base/views/participateInConstruction/Index'
      ),
    children: [
      // 项目参建单位
      {
        path: '/main/participateInConstruction/particInConstrManage',
        name: 'ParticInConstrManage',
        component: () =>
          import(
            /*/* : "项目参建单位" */
            '$base/views/participateInConstruction/particInConstrManage/ParticInConstrManage'
          ),
        meta: {
          name: '项目参建单位',
          isAuth: true,
          authCode: 'web_back_units_project',
          id: '1271795468750172162'
        }
      },
      {
        path: '/main/participateInConstruction/importProject',
        name: 'ImportProject',
        component: () =>
          import(
            /*/* : "项目参建单位导入" */
            '$base/views/participateInConstruction/particInConstrManage/ImportProject'
          ),
        meta: {
          name: '项目参建单位导入'
        }
      },
      // 参建单位人员
      {
        path: '/main/participateInConstruction/personManage',
        name: 'PersonManage',
        component: () =>
          import(
            /*/* : "参建单位人员" */
            '$base/views/participateInConstruction/person/PersonManage'
          ),
        meta: {
          name: '参建单位人员',
          isAuth: true,
          authCode: 'web_back_units_person',
          id: '1271795773034344450'
        }
      },
      {
        path: '/main/participateInConstruction/importPerson',
        name: 'ImportPerson1',
        component: () =>
          import(
            /*/* : "参建单位人员导入" */
            '$base/views/participateInConstruction/person/ImportPerson'
          ),
        meta: {
          name: '参建单位人员导入'
        }
      },
      // 参建单位台账
      {
        path: '/main/participateInConstruction/record',
        name: 'Record',
        component: () =>
          import(
            /*/* : "参建单位台账" */
            '$base/views/participateInConstruction/record/Record'
          ),
        meta: {
          name: '参建单位台账',
          isAuth: true,
          authCode: 'web_back_units_record',
          id: '1271795332967968770'
        }
      },
      {
        path: '/main/participateInConstruction/record/bulkImport',
        name: 'BulkImport',
        component: () =>
          import(
            /*/* : "参建单位导入" */
            '$base/views/participateInConstruction/record/BulkImport'
          ),
        meta: {
          name: '参建单位导入',
          isAuth: true,
          authCode: 'web_back_units_record_import',
          id: '1271795332967968770'
        }
      },
      {
        path: '/main/participateInConstruction/particInConstrManage/particInConstrManage/particInBulkImport',
        name: 'ParticInBulkImport',
        component: () =>
          import(
            /*/* : "项目参建单位导入" */
            '$base/views/participateInConstruction/particInConstrManage/ParticInBulkImport'
          ),
        meta: {
          name: '项目参建单位导入',
          isAuth: true,
          authCode: 'web_back_units_project_import',
          id: '1271795332967968770'
        }
      },
      {
        path: '/main/participateInConstruction/personManage/personBulkImport',
        name: 'PersonBulkImport',
        component: () =>
          import(
            /*/* : "参建人员导入" */
            '$base/views/participateInConstruction/person/PersonBulkImport'
          ),
        meta: {
          name: '参建人员导入',
          isAuth: true,
          authCode: 'web_back_units_person_import',
          id: '1271795332967968770'
        }
      }
    ]
  },
  {
    path: '/main/participatingCompany',
    name: 'ParticipatingCompany',
    component: () => import(/*/* : "参建单位" */ '$base/views/participatingCompany/Index'),
    children: [
      // 班组管理
      {
        path: '/main/particpatingCompany/team/Team',
        name: 'Team',
        component: () =>
          import(
            /*/* : "班组管理" */
            '$base/views/participatingCompany/team/Team'
          ),
        meta: {
          name: '班组管理',
          isAuth: true,
          authCode: 'web_back_units_team',
          id: '1271795615227850754'
        }
      }
    ]
  },
  {
    path: '/main/workbench',
    name: 'Workbench',
    component: () => import(/*/* : "工作台" */ '$base/views/workbench/Index'),
    children: [
      {
        path: '/main/workbench/bench/Bench',
        name: 'Bench',
        component: () => import(/*/* : "单岗位应用" */ '$base/views/workbench/bench/Bench'),
        meta: {
          name: '工作台'
        }
      },
      {
        path: '/main/workbench/benchSetting/BenchSetting',
        name: 'BenchSetting',
        component: () =>
          import(
            /*/* : "工作台设置" */
            '$base/views/workbench/benchSetting/BenchSetting'
          ),
        meta: {
          name: '工作台设置'
        }
      }
    ]
  },
  // 平台日志管理
  {
    path: '/main/blatformOperation',
    name: 'BlatformOperation',
    component: () => import(/*/* : "平台运营管理" */ '$base/views/participatingCompany/Index'),
    children: [
      // 系统平台日志
      {
        path: '/main/blatformOperation/operationLog/OperationLog',
        name: 'OperationLog',
        component: () =>
          import(
            /*/* : "系统平台日志" */
            '$base/views/blatformOperation/operationLog/OperationLog'
          ),
        meta: {
          name: '系统平台日志',
          isAuth: true,
          authCode: 'web_back_log_operation',
          id: '1274986232451952641'
        }
      }
    ]
  },
  // 工作流引入
  {
    path: '/main/workflow',
    name: 'WorkFlow',
    component: () => import('$base/views/workflow/Index'),
    children: [
      // 工作流展示
      {
        path: '/main/workflow/Index',
        name: 'WorkFlowView',
        component: () =>
          import(
            /* webpackChunkName: "工作流展示" */
            '$base/views/workflow/Center'
          ),
        meta: {
          name: '工作流',
          isAuth: true,
          keepAlive: true,
          authCode: 'web_pm_collaboration_workflow',
          id: '1274986232451952641'
        }
      },
      {
        path: '/main/workflow/view/detail',
        name: 'WorkFlowDetail',
        meta: {
          name: '详情',
          isAuth: true,
          authCode: 'web_pm_collaboration_workflow_detail'
        },
        component: () => import('$base/views/workflow/Detail')
      }
    ]
  },
  {
    path: '/main/basicBusinessConfig',
    name: 'BasicBusinessConfig',
    component: () => import(/*/* : "基础业务配置" */ '$base/views/basicBusinessConfig/Index'),
    children: [
      {
        path: '/main/basicBusinessConfig/generalFileDefinition',
        name: 'GeneralFileDefinition',
        component: () =>
          import(
            /*/* : "业务档案模型" */
            '$base/views/basicBusinessConfig/generalFileDefinition/GeneralFileDefinition'
          ),
        meta: {
          name: '业务字典管理',
          keepAlive: true, //此组件需要被缓存
          isAuth: true,
          authCode: 'web_back_dictionaries_general',
          id: '1271811882940854274'
        },
        children: []
      },
      {
        path: '/main/basicBusinessConfig/listCommon/generalFileImport',
        name: 'GeneralFileImport',
        component: () =>
          import(
            /*/* : "业务档案模型" */
            '$base/views/basicBusinessConfig/generalFileDefinition/GeneralFileImport'
          ),
        meta: {
          name: '业务档案导入',
          keepAlive: true //此组件需要被缓存
        }
      },
      {
        path: '/main/basicBusinessConfig/listCommon',
        name: 'ListCommon',
        component: () =>
          import(
            /*/* : "数据字典定义-普通列表" */
            '$base/views/basicBusinessConfig/listCommon/ListCommon'
          ),
        meta: {
          name: '业务字典内容-普通列表',
          isAuth: true,
          authCode: 'web_back_dictionaries_general_detail',
          id: '1271809938348273665'
        }
      },
      {
        path: '/main/basicBusinessConfig/listTree',
        name: 'ListTree',
        component: () =>
          import(
            /*/* : "数据字典定义-树形列表" */
            '$base/views/basicBusinessConfig/listTree/ListTree'
          ),
        meta: {
          name: '业务字典内容-树状列表',
          isAuth: true,
          authCode: 'web_back_dictionaries_general_detail',
          id: '1271809938348273665'
        }
      }
    ]
  },
  {
    path: '/main/projectFile',
    name: 'ProjectFile',
    component: () => import(/*/* : "项目总览" */ '$base/views/projectFile/Index'),
    children: [
      // 项目总览
      {
        path: '/main/projectFile',
        component: () => import(/*/* : '项目总览'*/ '$base/views/projectFile/ProjectFile'),
        meta: {
          name: '项目总览',
          keepAlive: true, //此组件需要被缓存
          isAuth: true,
          authCode: 'web_back_archives_project',
          id: '1271809938348273665'
        }
      },
      {
        path: '/main/projectFile/detailProject',
        name: 'ProjectDetails',
        component: () => import(/*/* : '项目详情'*/ '$base/views/projectFile/ProjectDetails'),
        meta: {
          name: '项目详情',

          isAuth: true,
          authCode: 'web_back_archives_project_detail',
          id: '1271809938348273665'
        }
      }
    ]
  },
  {
    path: '/main/normPartItem',
    name: 'NormPartItem',
    meta: {
      name: '标准分部分项库',
      isAuth: true,
      keepAlive: true,
      authCode: 'web_base_normPartItem'
    },
    component: () => import(/*/* : "标准分部分项" */ '$base/views/normPartItem/Index')
  },
  {
    path: '/main/normPartItem/details',
    name: 'NormPartItemDetails',
    meta: {
      name: '标准分部分项详情',
      isAuth: false,
      authCode: 'web_base_normPartItem_details'
    },
    component: () => import(/*/* : "标准分部分项" */ '$base/views/normPartItem/Details')
  },
  {
    path: '/main/normPartItem/detailsImportExcel',
    name: 'DetailsImportExcel',
    meta: {
      name: '导入',
      isAuth: false,
      authCode: 'web_base_normPartItem_import'
    },
    component: () =>
      import(
        /*/* : "标准分部分项" */
        '$base/views/normPartItem/DetailsImportExcel'
      )
  },
  {
    path: '/main/wbs',
    name: 'Wbs',
    meta: {
      name: 'WBS',
      isAuth: true,
      authCode: 'web_base_wbs'
    },
    component: () => import(/*/* : "wbs" */ '$base/views/wbs/Index')
  },
  {
    path: '/main/wbs/detail',
    name: 'wbsDetail',
    meta: {
      name: 'wbsDetail',
      isAuth: true,
      authCode: 'web_base_wbs_detail'
    },
    component: () => import(/*/* : "wbs" */ '$base/views/wbs/WbsDetail')
  },
  {
    path: '/main/formManage',
    name: 'formManage',
    component: () =>
      import(
        /*/* : "业务档案模型" */
        '$base/views/formManage/Index'
      ),
    meta: {
      name: '表单管理',
      keepAlive: false, //此组件需要被缓存
      authCode: 'web_back_formManage_page'
    }
  },
  // 短信管理
  {
    path: '/main/messageManage',
    name: 'messageManage',
    component: () =>
      import(
        /*/* : "短信管理" */
        '$base/views/messageManage/Index'
      ),
    meta: {
      name: '短信管理',
      keepAlive: false, //此组件不需要被缓存
      authCode: 'web_back_shortMessage_messageManage'
    }
  }
]
